import { Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { faClock, faMagnifyingGlass, faFileInvoiceDollar, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getTitle } from '../../i18n';

export default function AdminPage() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_ADMIN_HOME')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_HOME')} - ${t('IDS_ADMIN')}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <p className="small">{t('IDS_ADMIN_SECURE_P1')}</p>
                <p className="small mb-3">{t('IDS_CLIENT_ADMIN_P1_2')}</p>
                <Row xs={1} md={3} className="g-4">
                    <Col>
                        <Card className="h-100 small" onClick={() => navigate('/Admin/ApplicantSearch')}>
                            <Card.Header className="bg-dark-ocean text-white text-center">
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="me-2" />{t('IDS_SEARCH_APPLICANTS')}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Card.Text className="lh-lg">{t('IDS_ADMIN_SECURE_P2')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="h-100 small text-decoration-none" onClick={() => navigate('/Admin/ScheduledTasks')}>
                            <Card.Header className="bg-dark-ocean text-white text-center">
                                <FontAwesomeIcon icon={faClock} className="me-2" />{t('IDS_SCHEDULED_TASKS')}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Card.Text className="lh-lg">{t('IDS_ADMIN_SECURE_P3')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="h-100 small text-decoration-none" onClick={() => navigate('/Admin/Billing')}>
                            <Card.Header className="bg-dark-ocean text-white text-center">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />{t('IDS_BILLING')}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Card.Text className="lh-lg">{t('IDS_ADMIN_SECURE_P4')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="h-100 small text-decoration-none" onClick={() => navigate('/Admin/Maintenance')}>
                            <Card.Header className="bg-dark-ocean text-white text-center">
                                <FontAwesomeIcon icon={faToolbox} className="me-2" />{t('IDS_SITE_MAINTENANCE')}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Card.Text className="lh-lg">{t('IDS_ADMIN_SECURE_P5')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}