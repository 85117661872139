import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';

import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    btnText: string;
    message: string;
    headerText?: string;
    variant?: Variant;
}

export function BsAlertDismissible({ btnText, message, headerText, variant = 'dark-ocean' }: Props) {
    const [show, setShow] = useState(false);

    if (show) {
        return (
            <Alert
                variant={variant}
                onClose={() => setShow(false)}
                className="small"
                dismissible
            >
                {headerText && <b>{`${headerText}: `}</b>}
                {message}
            </Alert>
        );
    }

    return (
        <Button
            variant="link"
            className="link-dark-ocean p-0 mb-2 small d-flex align-items-center"
            onClick={() => setShow(true)}
        >
            <FontAwesomeIcon icon={faPlusSquare} className="me-2" />{btnText}
        </Button>
    );
}