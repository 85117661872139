import { CSSProperties } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faBars, faClock, faFileAlt, faFileInvoiceDollar, faHome, faListAlt, faListUl, faMagnifyingGlass,
    faSlidersH, faTimes, faToolbox
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { transType } from '../../i18next';
import { UserType } from '../enums/userType';
import { ApplicantSessionDto } from '../models/applicantSession';
import { getMaintenanceState } from '../models/responseHelpers/maintenanceDto';
import { useAppSelector } from '../store/storeHooks';
import LocaleMenu from './LocaleMenu';
import UserMenu from './UserMenu';

const counterStyle: CSSProperties = { transform: 'scale(.6)', right: '-0.75rem', top: '-0.75rem' };

export default function Header() {
    const { t } = useTranslation();
    const location  = useLocation();
    const { user, maintenanceInfo } = useAppSelector(state => state.account);
    const { applicant, isInApplication, isInAchiever, isInAptitudeTest, isInDPATInhouse }
        = useAppSelector(state => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const { clientSession } = useAppSelector(state => state.clientSession);
    const { testSession } = useAppSelector(state => state.test);
    const curPath = location.pathname.toLowerCase();
    const isInTest = (curPath.includes('achiever') || curPath.includes('dpat')) && testSession !== null && testSession.pageNumber > 0;
    const isApplicantPage = curPath.startsWith('/applicant') || curPath.startsWith('/authapplicant');
    const isAuthPage = curPath.includes('auth');
    const isVerifyPage = curPath.includes('verify');
    const isSelectClientPage = curPath.includes('selectclient');
    const isChangePasswordPage = curPath.includes('changepassword');
    const isSelectLanguagePage = curPath.includes('selectlanguage');
    const maintState = getMaintenanceState(maintenanceInfo);

    const pages: { text: string, route: string, icon: IconDefinition, disabled: boolean, order: number }[] =
        !user ? [] : (user.userType === UserType.Applicant
            ? [
                { text: 'IDS_HOME', route: '/Applicant', icon: faHome, disabled: isInTest, order: 0 },
                ...(!(applicant.testOnly) ? [{ text: 'IDS_APPLICATION', route: '/Applicant/Application', icon: faFileAlt, disabled: !(isInApplication), order: applicant.applicationOrder }] : []),
                ...(applicant.achiever ? [{ text: 'IDS_ASSESSMENT', route: '/Applicant/Achiever', icon: faListUl, disabled: !(isInAchiever), order: applicant.achieverOrder }] : []),
                ...(applicant.dpat ? [{ text: 'IDS_APTITUDE_TEST', route: '/Applicant/DPAT', icon: faListUl, disabled: !(isInAptitudeTest) || applicant.locked, order: applicant.aptitudeTestOrder }] : []),
                ...(applicant.dpatInhouse ? [{ text: 'IDS_APTITUDE_TEST_INH', route: '/Applicant/DPATInhouse', icon: faListUl, disabled: !(isInDPATInhouse) || applicant.locked, order: applicant.dpatInhouseOrder }] : [])
            ]
            : (user.userType === UserType.Client) ? [
                { text: 'IDS_HOME', route: '/Client', icon: faHome, disabled: false, order: 0 },
                ...(clientSession?.updateClientSetup ? [{ text: 'IDS_SETUP', route: '/Client/Setup', icon: faSlidersH, disabled: false, order: 1 }] : []),
                { text: 'IDS_APPLICANTS', route: '/Client/Applicants', icon: faFileAlt, disabled: false, order: 2 },
                ...(clientSession?.maintainPositions ? [{ text: 'IDS_POSITIONS', route: '/Client/Positions', icon: faListAlt, disabled: false, order: 3 }] : [])
            ]
            : [
                { text: 'IDS_HOME', route: '/Admin', icon: faHome, disabled: false, order: 0 },
                { text: 'IDS_SEARCH_APPLICANTS', route: '/Admin/ApplicantSearch', icon: faMagnifyingGlass, disabled: false, order: 1 },
                { text: 'IDS_SCHEDULED_TASKS', route: '/Admin/ScheduledTasks', icon: faClock, disabled: false, order: 2 },
                { text: 'IDS_BILLING', route: '/Admin/Billing', icon: faFileInvoiceDollar, disabled: false, order: 3 },
                { text: 'IDS_SITE_MAINTENANCE', route: '/Admin/Maintenance', icon: faToolbox, disabled: false, order: 4 }
            ]).sort((a, b) => a.order > b.order ? 1 : -1);

    return (
        <Navbar variant="light" bg="white" expand="lg" collapseOnSelect>
            <Container className="px-0" fluid>
                <img className="navbar-brand py-0" src="/images/TestFirstHiring_Logo.png" width="1496" height="467" alt={t('IDS_TEST_FIRST_HIRING')} />
                <Navbar.Toggle
                    className={`justify-content-center align-items-center p-0 ${isVerifyPage || isSelectClientPage || isChangePasswordPage || isSelectLanguagePage || (!user && !isApplicantPage) ? 'd-none' : ''}`}
                    aria-controls="navbarNavContainer"
                >
                    <span className="d-flex justify-content-center align-items-center flex-column navbar-toggler-bars" title={t('IDS_MENU')}>
                        <span className="d-flex justify-content-center align-items-center p-2 rounded-circle text-white bg-dark-ocean">
                            <span className="fa-layers">
                                <FontAwesomeIcon icon={faBars} fixedWidth />
                                {maintState.inDisplayRange &&
                                    <span className="fa-layers-counter fa-layers-counter-warning fw-bold" style={counterStyle}>!</span>
                                }
                            </span>
                        </span>
                    </span>
                    <span className="d-flex justify-content-center align-items-center flex-column navbar-toggler-close" title={t('IDS_CLOSE')}>
                        <span className="d-flex justify-content-center align-items-center p-2 rounded-circle text-white bg-dark-ocean">
                            <span className="fa-layers">
                                <FontAwesomeIcon icon={faTimes} fixedWidth />
                                {maintState.inDisplayRange &&
                                    <span className="fa-layers-counter fa-layers-counter-warning fw-bold" style={counterStyle}>!</span>
                                }
                            </span>
                        </span>
                    </span>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbarNavContainer">
                    {user != null && !isAuthPage && !isVerifyPage && !isSelectClientPage && !isChangePasswordPage && !isSelectLanguagePage
                        ? (
                            <>
                                <Nav className="me-auto mb-2 mb-lg-0 align-items-center">
                                    {pages.map(({ text, route, icon, disabled }) => (
                                        <Nav.Item key={text}>
                                            <NavLink
                                                {...((disabled || location.pathname === route) && { onClick: e => e.preventDefault() })}
                                                className={`nav-link text-decoration-underline mx-2 d-flex align-items-center ${(disabled ? 'disabled' : '')} ${(location.pathname === route ? 'pe-none' : '')}`}
                                                to={route}
                                                end
                                            >
                                                <FontAwesomeIcon icon={icon} className="me-2" fixedWidth />{t(text as keyof transType)}
                                            </NavLink>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                <UserMenu />
                            </>
                        )
                        : (isApplicantPage && !isVerifyPage && !isChangePasswordPage && !isSelectLanguagePage && <LocaleMenu />)
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};