import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { FilterProps } from 'react-table';

export default function DatePickerColumnFilter<T extends object>({ column: { filterValue, setFilter, render } }: FilterProps<T>) {
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const { t } = useTranslation();

    return (
        <>
            <DatePicker
                className="form-control form-control-xs"
                dateFormat="MM/dd/yyyy"
                strictParsing
                selected={fromDate}
                selectsStart
                startDate={fromDate}
                endDate={toDate}
                onChange={(date: Date) => {
                    setFromDate(date);
                    if (date && toDate) {
                        setFilter({ vals: [date, toDate], condition: 'between' });
                    } else if (filterValue?.vals?.length === 2) {
                        setFilter(undefined);
                    }
                }}
                title={render('Header')?.toString() + ' from filter'}
            />
            <span className="mx-1" style={{ fontSize: '0.767rem' }}>{t('IDS_TO')}</span>
            <DatePicker
                className="form-control form-control-xs"
                dateFormat="MM/dd/yyyy"
                strictParsing
                selected={toDate}
                selectsEnd
                startDate={fromDate}
                endDate={toDate}
                minDate={fromDate}
                onChange={(date: Date) => {
                    setToDate(date);
                    if (date && fromDate) {
                        setFilter({ vals: [fromDate, date], condition: 'between' });
                    } else if (filterValue?.vals?.length === 2) {
                        setFilter(undefined);
                    }
                }}
                title={render('Header')?.toString() + ' to filter'}
            />
        </>
    );
}