import { ChangeEvent, KeyboardEvent, useId } from 'react';
import { Button, ButtonGroup, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
    faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { transType } from '../../../i18next';
import { RegExps } from '../../utility/util';

interface PagerProps {
    isBelow: boolean;
    pageOptions: number[];
    totalRecords: number;
    itemName: string;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageIndex: number;
    pageCount: number;
    pageSize: number;
    pageInputValue: string;
    setPageInputValue: (x: string) => void;
    gotoPage: (x: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    setPageSize: (x: number) => void;
}

export default function BsGridPager(props: PagerProps) {
    const { t } = useTranslation();
    const id = useId();

    return (
        <div className={`bsgrid-pager ${props.isBelow ? 'bsgrid-pager-below' : ''} d-flex justify-content-between align-items-center py-2`}>
            <Form.Group controlId={id + '-pageSize'} className="d-flex pager-page-size">
                <Form.Label
                    column
                    xs="auto"
                    className="col-form-label-sm"
                    style={{color: 'unset'}}
                >
                    {t('IDS_SHOW')}
                </Form.Label>
                <Col xs="auto" className="ps-2">
                    <Form.Select
                        value={props.pageSize}
                        size="sm"
                        onChange={e => {
                            props.setPageInputValue(`${Math.floor(props.pageIndex * props.pageSize / Number(e.target.value)) + 1}`);
                            props.setPageSize(Number(e.target.value));
                        }}
                    >
                        {['10', '20', '30', '40', '50'].map(selectedSize => (
                            <option key={selectedSize} value={selectedSize}>
                                {selectedSize}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Form.Group>

            <div className="pager-pagination d-flex align-items-center justify-content-center">
                <ButtonGroup size="sm" className="mb-0 me-2">
                    <Button
                        variant="silver"
                        disabled={!props.canPreviousPage}
                        onClick={() => {
                            props.setPageInputValue('1');
                            props.gotoPage(0);
                        }}
                        title={t('IDS_FIRST_PAGE')}
                    >
                        <FontAwesomeIcon icon={faAnglesLeft} fixedWidth />
                    </Button>
                    <Button
                        variant="silver"
                        disabled={!props.canPreviousPage}
                        onClick={() => {
                            props.setPageInputValue(`${props.pageIndex}`);
                            props.previousPage();
                        }}
                        title={t('IDS_PREV_PAGE')}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
                    </Button>
                    <Button
                        variant="silver"
                        disabled={!props.canNextPage}
                        onClick={() => {
                            props.setPageInputValue(`${props.pageIndex + 2}`);
                            props.nextPage();
                        }}
                        title={t('IDS_NEXT_PAGE')}
                    >
                        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                    </Button>
                    <Button
                        variant="silver"
                        disabled={!props.canNextPage}
                        onClick={() => {
                            props.setPageInputValue(`${props.pageCount}`);
                            props.gotoPage(props.pageCount - 1);
                        }}
                        title={t('IDS_LAST_PAGE')}
                    >
                        <FontAwesomeIcon icon={faAnglesRight} fixedWidth />
                    </Button>
                </ButtonGroup>
                <div className="d-grid align-items-center justify-content-center" style={{ gridAutoFlow: 'column', gap: '.5rem' }}>
                    <Form.Label
                        className="small mb-0"
                        htmlFor={id + '-inputPage'}
                        style={{color: 'unset'}}
                    >
                        {t('IDS_PAGE')}
                    </Form.Label>
                    <Form.Control
                        id={id + '-inputPage'}
                        size="sm"
                        type="text"
                        style={{ width: '3rem' }}
                        readOnly={!props.canPreviousPage && !props.canNextPage}
                        value={props.pageInputValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === '' || e.target.value.match(RegExps.digits)) {
                                props.setPageInputValue(e.target.value)
                            };
                        }}
                        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter' && props.pageInputValue) {
                                e.preventDefault();
                                const curVal = Number(props.pageInputValue);
                                props.gotoPage(curVal <= 0 ? 0 : curVal - 1);
                            }
                        }}
                    />
                    <small className="text-nowrap">{`${t('IDS_OF')} ${props.pageOptions.length}`}</small>
                    <Button
                        disabled={!props.canPreviousPage && !props.canNextPage}
                        variant="silver"
                        size="sm"
                        onClick={() => {
                            const curVal = Number(props.pageInputValue);
                            props.gotoPage(curVal <= 0 ? 0 : curVal - 1);
                        }}
                    >
                        {t('IDS_GO')}
                    </Button>
                </div>
            </div>

            <div className="pager-info d-flex">
                {`${props.totalRecords} ${t(props.itemName as keyof transType)}(s) ${t('IDS_FOUND')}`}
            </div>
        </div>
    );
}