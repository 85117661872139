import { SyntheticEvent } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';

import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppRefs } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { getLangOrDefault, parseValidationMsg } from '../../../i18n';

export interface CardProps {
    index: number;
}

export default function ReferenceCard({ index }: CardProps) {
    const { t } = useTranslation();
    const { client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const refsReqd = client.references;
    const { register, setValue, formState: { errors } } = useFormContext<FieldValuesAppRefs>();
    const langIsFrench = getLangOrDefault() === 'fr';

    return (
        <div>
            <input type="hidden" {...register(`references.${index}.clientIndex`, { value: index })} />
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <FloatingLabel
                        controlId={`references.${index}.fullName`}
                        className="small"
                        label={t('IDS_FULL_NAME')}
                    >
                        <Form.Control
                            type="text"
                            required={refsReqd > index}
                            maxLength={50}
                            {...register(`references.${index}.fullName`)}
                            isInvalid={!!errors?.references?.[index]?.fullName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.references?.[index]?.fullName?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId={`references.${index}.company`}
                        className="small"
                        label={t('IDS_COMPANY')}
                    >
                        <Form.Control
                            type="text"
                            required={refsReqd > index}
                            maxLength={50}
                            {...register(`references.${index}.company`)}
                            isInvalid={!!errors?.references?.[index]?.company}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.references?.[index]?.company?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId={`references.${index}.title`}
                        className="small"
                        label={t('IDS_TITLE')}
                    >
                        <Form.Control
                            type="text"
                            required={refsReqd > index}
                            maxLength={50}
                            {...register(`references.${index}.title`)}
                            isInvalid={!!errors?.references?.[index]?.title}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.references?.[index]?.title?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId={`references.${index}.phone`}
                        className="small has-tooltip"
                        label={
                            <>
                                <span>{t('IDS_PHONE')}</span>
                                <HelpTooltipIcon
                                    tooltipId={`tooltipRefPhone${index}`}
                                    tooltipText={t('IDS_HELP_VALIDPHONENUMBER_MESSAGE')}
                                    tooltipTitle={t('IDS_HELP_VALIDPHONENUMBER_TITLE')}
                                />
                            </>
                        }
                    >
                        <Form.Control
                            type="text"
                            required={refsReqd > index}
                            inputMode="tel"
                            maxLength={25}
                            {...register(`references.${index}.phone`, {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    e.currentTarget.value = e.currentTarget.value.replace((langIsFrench ? /[^0-9.-]+/g : /[^0-9 ()-]+/g), '');
                                    setValue(`references.${index}.phone`, e.currentTarget.value);
                                }
                            })}
                            isInvalid={!!errors?.references?.[index]?.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.references?.[index]?.phone?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId={`references.${index}.email`}
                        className="small"
                        label={t('IDS_EMAIL')}
                    >
                        <Form.Control
                            type="text"
                            maxLength={50}
                            {...register(`references.${index}.email`)}
                            isInvalid={!!errors?.references?.[index]?.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.references?.[index]?.email?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
        </div>
    );
}