import React from 'react';
import { Col, FloatingLabel, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import colorVars from '../../../app/layout/styles/_colors.module.scss';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';

interface Props {
    languageCode: string;
}

export default function ClientPositionQuestions({ languageCode }: Props) {
    const { t } = useTranslation();
    const { control, register, getValues, watch } = useFormContext();
    const { fields: positionDefaultQuestionFields } = useFieldArray({ control, name: `positionDefaultQuestions-${languageCode}` });
    const { fields: positionQuestionFields } = useFieldArray({ control, name: `positionQuestions-${languageCode}` });
    const watchType = watch([
        `positionQuestions-${languageCode}[0].typeId`,
        `positionQuestions-${languageCode}[1].typeId`,
        `positionQuestions-${languageCode}[2].typeId`,
        `positionQuestions-${languageCode}[3].typeId`,
        `positionQuestions-${languageCode}[4].typeId`,
        `positionQuestions-${languageCode}[5].typeId`
    ]);

    return (
        <>
            <Form.Label>
                <span className="small">{t('IDS_QUESTIONS_TO_DISPLAY')}</span>
                <HelpTooltipIcon
                    tooltipId="tooltipQuestionsToDisplay"
                    tooltipText={t('IDS_HELP_CLIENTPOSITIONDEFAULTQUESTIONS_MESSAGE')}
                    tooltipTitle={t('IDS_HELP_CLIENTPOSITIONDEFAULTQUESTIONS_TITLE')}
                />
            </Form.Label>
            <div className="ms-2">
                {positionDefaultQuestionFields.map((item, index) => {
                    return (
                        <React.Fragment key={item.id}>
                            <input type="hidden" {...register(`positionDefaultQuestions-${languageCode}[${index}].questionId`)} />
                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                id={`positionDefaultQuestions-${languageCode}[${index}]`}
                            >
                                <Form.Check.Input
                                    type="checkbox"
                                    {...register(`positionDefaultQuestions-${languageCode}[${index}].checked`, {})}
                                />
                                <Form.Check.Label className="small">
                                    {getValues(`positionDefaultQuestions-${languageCode}[${index}].question`)}
                                </Form.Check.Label>
                            </Form.Check>
                        </React.Fragment>
                    );
                })}
            </div>

            <Form.Label className="mt-4 small">{t('IDS_POSITION_QUESTIONS')}</Form.Label>
            <div>
                {positionQuestionFields.map((item, index) => {
                    return (
                        <div className="mb-3 ms-2" key={item.id}>
                            <InputGroup className="flex-nowrap">
                                <InputGroup.Text>
                                    <small style={{ minWidth: '1rem' }}>{`${index + 1}.`}</small>
                                </InputGroup.Text>
                                <InputGroup.Text>
                                    <Form.Check
                                        type="checkbox"
                                        {...register(`positionQuestions-${languageCode}[${index}].active`, {})}
                                    />
                                </InputGroup.Text>
                                <FloatingLabel
                                    controlId={`positionQuestions-${languageCode}[${index}].question`}
                                    className="small flex-fill"
                                    label={t('IDS_QUESTION')}
                                >
                                    <Form.Control
                                        type="text"
                                        maxLength={200}
                                        {...register(`positionQuestions-${languageCode}[${index}].question`)}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId={`positionQuestions-${languageCode}[${index}].typeId`}
                                    style={{ width: '12rem' }}
                                    className="small flex-grow-0"
                                    label={t('IDS_TYPE')}
                                >
                                    <Form.Select
                                        {...register(`positionQuestions-${languageCode}[${index}].typeId`)}
                                    >
                                        <option value="2">I Agree</option>
                                        <option value="3">Ranking</option>
                                        <option value="4">Fill in the Blank</option>
                                        <option value="5">Multiple Choice</option>
                                        <option value="6">Yes/No</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </InputGroup>
                            <Row
                                xs={1} sm={2} md={3} xl={5} xxl={5}
                                style={{
                                    display: watchType[index] === '5' ? 'flex' : 'none',
                                    border: `1px solid ${colorVars.eggplant}`,
                                    borderTopWidth: 0,
                                    marginLeft: 'calc(1.5rem + 1em + 1px)',
                                    marginRight: 'calc(1.5rem + 1em + 1px)'
                                }}
                                className="px-3 py-2 g-3 rounded-bottom"
                            >
                                {['A', 'B', 'C', 'D', 'E'].map(letter => (
                                    <Col key={`positionQuestions-${languageCode}[${index}].literal${letter}`}>
                                    <InputGroup size="sm" className="flex-nowrap">
                                        <InputGroup.Text id={`positionQuestions-${languageCode}[${index}].literal${letter}-label`}>
                                            {letter}
                                        </InputGroup.Text>
                                        <FormControl
                                            id={`positionQuestions-${languageCode}[${index}].literal${letter}`}
                                            type="text"
                                            maxLength={50}
                                            aria-label={letter}
                                            aria-describedby={`positionQuestions-${languageCode}[${index}].literal${letter}-label`}
                                            {...register(`positionQuestions-${languageCode}[${index}].literal${letter}`)}
                                        />
                                    </InputGroup>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
