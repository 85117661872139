import { createContext } from 'react';

import { AuthType } from '../enums/authType';
import { UserType } from '../enums/userType';

export interface AuthProps {
    userType: UserType;
    userTypePath: AuthType;
}

export const authPropsClient: AuthProps = {
    userType: UserType.Client,
    userTypePath: AuthType.Client
};

export const authPropsApplicant: AuthProps = {
    userType: UserType.Applicant,
    userTypePath: AuthType.Applicant
};

export const authPropsAdmin: AuthProps = {
    userType: UserType.Admin,
    userTypePath: AuthType.Admin
}

export const RouteAuthCtx = createContext<AuthProps>(authPropsApplicant);