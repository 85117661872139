import { SyntheticEvent, useContext, useState } from 'react';
import { Alert, FloatingLabel, Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { faSignInAlt, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import LoadingButton from '../../app/components/LoadingButton';
import { UserType } from '../../app/enums/userType';
import { FieldValuesLogin } from '../../app/models/fieldValueTypes';
import { getMaintenanceState } from '../../app/models/responseHelpers/maintenanceDto';
import { signInUser } from '../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { RouteAuthCtx } from '../../app/utility/routeAuthCtx';
import { getTitle, parseValidationMsg } from '../../i18n';
import RecaptchaBranding from './RecaptchaBranding';
import { SettingNames } from '../../app/enums/settingNames';
import { getAppSettingVal } from '../../app/utility/util';
import { UserDto } from '../../app/models/responseHelpers/userDto';
import { selectClient } from '../../app/store/clientSlice';
import { initializeTestSession } from '../../app/store/testSlice';
import TermsAndConditions from './TermsAndConditions';

export default function AuthPage() {
    const authCtx = useContext(RouteAuthCtx);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const maintInfo = useAppSelector(state => state.account.maintenanceInfo);
    const maintState = getMaintenanceState(maintInfo);
    const appSettings = useAppSelector(state => state.account.appSettings);
    const mfaEnabled = getAppSettingVal(appSettings, SettingNames.EnableMFA, 'boolean') as boolean;
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(authCtx.userType === UserType.Client && localStorage.getItem('tfhShowClientWelcomeMsg') !== 'false');

    const { formState: { isValid, isSubmitting, errors }, handleSubmit, register, setValue } = useForm<FieldValuesLogin>({
        mode: 'all',
        resolver: yupResolver(yup.object({
            username: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_USERNAME'] }),
            password: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_PASSWORD'] })
        }))
    });

    async function submitForm(data: FieldValuesLogin) {
        try {
            if (!executeRecaptcha) return;
            const token = await executeRecaptcha('login');
            sessionStorage.setItem('reCaptchaToken', token);

            let userPayload = await dispatch(signInUser({ ...data, userType: authCtx.userType }));
            let user = userPayload.payload as UserDto;

            if (authCtx.userType === UserType.Admin) {
                navigate('/Admin');
            } else if (mfaEnabled) {
                navigate(`/Auth${authCtx.userTypePath}/Verify`);
            } else {
                if (authCtx.userType === UserType.Client) {
                    if (user?.forcePasswordReset) {
                        navigate('/AuthClient/ChangePassword');
                    } else if (user?.casObject?.casAccountBC) {
                        if (user.casObject.casAccountBC.length === 1) {
                            await dispatch(selectClient(user.casObject.casAccountBC[0].accountNumber!));
                            navigate('/Client');
                        } else if (user.casObject.casAccountBC.length > 1) {
                            navigate('/AuthClient/SelectClient');
                        }
                    } else {
                        navigate(`/${authCtx.userTypePath}`);
                    }
                } else if (authCtx.userType === UserType.Applicant) {
                    if (user?.forceLanguageSelect) {
                        navigate('/AuthApplicant/SelectLanguage');
                    } else if (user?.forcePasswordReset) {
                        navigate('/AuthApplicant/ChangePassword');
                    } else {
                        await dispatch(initializeTestSession());
                        navigate(`/${authCtx.userTypePath}`);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_LOGIN')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                {showWelcomeMsg &&
                    <Alert
                        dismissible
                        variant="success"
                        className="max-w-fit-content p-3"
                        onClose={() => {
                            localStorage.setItem('tfhShowClientWelcomeMsg', 'false');
                            setShowWelcomeMsg(false);
                        }}
                    >
                        <div className="alert-heading h6 fw-bold">
                            Welcome!
                        </div>
                        <div className="small">
                            We have overhauled the user interface and layout to enhance your online experience. The new site was designed to be faster than ever, easier to navigate, mobile friendly, and more secure.
                        </div>
                    </Alert>
                }
                {maintState.inMaintRange
                    ? <>
                        <Alert variant="cherry" className="d-flex align-items-center max-w-fit-content p-3">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" size="2x" />
                            <div className="fs-6 fw-bold" dangerouslySetInnerHTML={{ __html: maintState.parsedMsg }}></div>
                        </Alert>
                    </>
                    : <>
                        {maintState.inDisplayRange &&
                            <Alert variant="warning" className="d-flex align-items-center max-w-fit-content">
                                <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" size="lg" />
                                <div className="small fw-bold" dangerouslySetInnerHTML={{ __html: maintState.parsedMsg }}></div>
                            </Alert>
                        }
                        <h1 className="mb-4 fw-bold">
                            {`${authCtx.userType === UserType.Applicant ? t('IDS_APPLICANT') : (authCtx.userType === UserType.Client ? t('IDS_CLIENT') : t('IDS_ADMIN'))} ${t('IDS_LOGIN')}`}
                        </h1>
                        <Form onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                            <FloatingLabel
                                controlId="inputUsername"
                                className="small mb-3"
                                label={t('IDS_USERNAME')}
                            >
                                <Form.Control
                                    type="text"
                                    maxLength={50}
                                    autoComplete="username"
                                    required
                                    autoFocus
                                    {...register('username', {
                                        onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                            e.currentTarget.value = e.currentTarget.value.trim();
                                            setValue('username', e.currentTarget.value, { shouldValidate: true });
                                        }
                                    })}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.username?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="inputPassword"
                                className="small mb-3"
                                label={t('IDS_PASSWORD')}
                            >
                                <Form.Control
                                    type="password"
                                    maxLength={50}
                                    autoComplete="current-password"
                                    required
                                    {...register('password')}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.password?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <LoadingButton
                                variant="dark-ocean"
                                type="submit"
                                className="mw-8rem mb-4"
                                loading={isSubmitting}
                                disabled={!isValid || isSubmitting}
                            >
                                <FontAwesomeIcon icon={faSignInAlt} className="me-2" />{t('IDS_LOGIN')}
                            </LoadingButton>
                            {authCtx.userType === UserType.Applicant &&
                                <div className="mb-4 small line-height-100">
                                    <Trans i18nKey={'IDS_FORGOT_USERNAME_OR_PASSWORD'} t={t}
                                        components={[
                                            <span>Forgot your </span>,
                                            <Link to="/AuthApplicant/ForgotUsername" className="link-charcoal">{{ 0: t('IDS_USERNAME').toLowerCase() } as any}</Link>,
                                            <span> or </span>,
                                            <Link to="/AuthApplicant/ForgotPassword" className="link-charcoal">{{ 1: t('IDS_PASSWORD').toLowerCase() } as any}</Link>,
                                            <span>?</span>
                                        ]}>
                                    </Trans>
                                </div>
                            }
                            <TermsAndConditions />
                            <RecaptchaBranding />
                        </Form>
                    </>
                }
            </div>
        </>
    );
}