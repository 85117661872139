import { useState, useEffect, SyntheticEvent } from 'react';
import { StringKeyObject } from '../../app/models/stringKeyObject';
import { useAppDispatch } from '../../app/store/storeHooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, FieldValues, useForm } from 'react-hook-form';
import { FloatingLabel, Form, Tab, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTitle, parseValidationMsg, locales } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import agent from '../../app/api/agent';
import LoadingButton from '../../app/components/LoadingButton';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import BsScrollableTabs, { BsTabs } from '../../app/components/BsScrollableTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { isValidDate } from '../../app/utility/util';
import { FieldValuesMaintenance } from '../../app/models/fieldValueTypes';
import { MaintenanceDto, formDataToMaintenanceDto } from '../../app/models/responseHelpers/maintenanceDto';
import {format} from 'date-fns';
import { BsModalAlert } from '../../app/components/BsModal';

export default function MaintenancePage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    
    const formMethods = useForm<FieldValuesMaintenance>({
        mode: 'all',
        defaultValues: {
            startTime: '',
            endTime: '',
            startDisplayTime: '',
            endDisplayTime: '',
            enUS: ''
        },
        resolver: yupResolver(yup.object().shape({
            startTime: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_START_TIME'] })
                .when(['endTime'], {
                    is: (endTimeVal: any) =>
                        (isValidDate(endTimeVal)),
                    then: rule => rule.max(yup.ref('endTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_START_TIME'] })
                }),
            endTime: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_END_TIME'] })
                .when(['startTime'], {
                    is: (startTimeVal: any) =>
                        (isValidDate(startTimeVal)),
                    then: rule => rule.min(yup.ref('startTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_END_TIME'] })
                }),
            startDisplayTime: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                .when(['endDisplayTime'], {
                    is: (endDisplayTimeVal: any) =>
                        (isValidDate(endDisplayTimeVal)),
                    then: rule => rule.max(yup.ref('endDisplayTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_DISPLAY_START_TIME'] })
                        .max(yup.ref('startTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_DISPLAY_START_TIME'] })
                        .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_DISPLAY_START_TIME'] })
                }),
            endDisplayTime: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                .when(['startDisplayTime'], {
                    is: (startDisplayTimeVal: any) =>
                        (isValidDate(startDisplayTimeVal)),
                    then: rule => rule.min(yup.ref('startDisplayTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_DISPLAY_END_TIME'] })
                        .min(yup.ref('endTime'), { resKeys: ['IDS_IEP_INVALID', 'IDS_DISPLAY_END_TIME'] })
                        .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_DISPLAY_END_TIME'] })
                }),
             enUS: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_MAINTENANCE_MESSAGE'] })
        }, [
            ['startTime', 'endTime'],
            ['startDisplayTime', 'endDisplayTime']
            ])
    ) 
    }),
        { formState: { isValid, isSubmitting, errors}, register, reset, trigger, handleSubmit, getValues } = formMethods;

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                let formData = {};

                setLoading(true);
                let maintenanceInfo = await agent.Admin.getMaintenanceInfo();

                let messages: StringKeyObject<string> = {};
                maintenanceInfo.messages.forEach(item => {
                    messages[`${item.languageCode === "en-US" ? "enUS" : item.languageCode}`] = item.message;
                });

                formData = {
                    'startTime': `${format(new Date(maintenanceInfo.startTime), "yyyy-MM-dd'T'HH:mm:ss")  ?? ''}`,
                    'endTime': `${format(new Date(maintenanceInfo.endTime), "yyyy-MM-dd'T'HH:mm:ss")  ?? ''}`,
                    'startDisplayTime': `${format(new Date(maintenanceInfo.startDisplayTime), "yyyy-MM-dd'T'HH:mm:ss")  ?? ''}`,
                    'endDisplayTime': `${format(new Date(maintenanceInfo.endDisplayTime), "yyyy-MM-dd'T'HH:mm:ss")  ?? ''}`,
                    ...messages
                };
                setLoading(false);
                reset(formData);
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [dispatch, reset]);

    //set the language codes to use for the tabs
    const languageCodes: string[] = [];
    Object.keys(locales).forEach(langCode => {
        languageCodes.push(langCode);
    })

    let maintenanceTabs: BsTabs = {};
    languageCodes?.forEach(item => {
        maintenanceTabs[item] = {
            isInvalid: item === "en-US"? !!errors.enUS : false,
            linkText: item,
            paneComponent: (
                <>
                    <FloatingLabel
                        controlId={`messages-${item === "en-US" ? "enUS" : item}`}
                        className="mb-3 small form-floating-textarea"
                        label={t('IDS_MESSAGE')}
                    >
                        <Form.Control
                            as="textarea"
                            required
                            isInvalid={!!errors.enUS}
                            style={{ height: 'calc(2.25rem + 5.9rem)' }}
                            {...register((`${item === "en-US" ? "enUS" : item}` as any))}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.enUS?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </>
            )
        };
    });

    async function submitForm(data: FieldValues) {
        let maintenanceDto: MaintenanceDto = formDataToMaintenanceDto(data);
        await agent.Admin.updateMaintenanceInfo(maintenanceDto);
        setShowAlert(true);
    }

    if (loading)
        return <LoadingComponent />
    else
        return (
            <>
                <Helmet>
                    <title>{getTitle(t, 'IDS_SITE_MAINTENANCE')}</title>
                </Helmet>
                <div className="main-titlebar main-titlebar-tabs bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span className="fs-5 line-height-100">{`${t('IDS_ADMIN')} - ${t('IDS_SITE_MAINTENANCE')}`}</span>
                        <LoadingButton
                            form="form-maintenance"
                            variant="dark-ocean"
                            type="submit"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCircleCheck} className="me-2" />{t('IDS_UPDATE')}
                        </LoadingButton>
                    </div>
                </div>
                <div className="main-content bg-light-silver">
                    <FormProvider {...formMethods}>
                        <Form id="form-maintenance" onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                            <Row>
                                <Tab.Container defaultActiveKey="en-US">
                                    <BsScrollableTabs tabs={maintenanceTabs} navTabsClassName="nav-tabs-alt" />
                                    <div className="border border-light-eggplant border-2 bg-white p-4">
                                        <Tab.Content>
                                            {Object.keys(maintenanceTabs).map((key) => (
                                                <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                                    {maintenanceTabs[key].paneComponent}
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </Row>
                            <hr className="text-light-eggplant" />
                            <Row xs={1} sm={2} lg={4}>
                                <Col>
                                    <FloatingLabel
                                        controlId="startTime"
                                        className="small"
                                        label={t('IDS_START_TIME')}
                                    >
                                        <Form.Control
                                            type="datetime-local"
                                            required
                                            isInvalid={!!errors?.startTime}
                                            {...register('startTime', {
                                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                    trigger(['endTime', 'startDisplayTime']);
                                                }
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.startTime?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="endTime"
                                        className="small"
                                        label={t('IDS_END_TIME')}
                                    >
                                        <Form.Control
                                            type="datetime-local"
                                            required
                                            isInvalid={!!errors.endTime}
                                            {...register('endTime', {
                                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                    trigger(['startTime', 'endDisplayTime']);
                                                }
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.endTime?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="startDisplayTime"
                                        className="small"
                                        label={t('IDS_DISPLAY_START_TIME')}
                                    >
                                        <Form.Control
                                            type="datetime-local"
                                            required={getValues('endDisplayTime') !== ''}
                                            isInvalid={!!errors?.startDisplayTime}
                                            {...register('startDisplayTime', {
                                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                    trigger(['endDisplayTime', 'startTime']);
                                                }
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.startDisplayTime?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="endDisplayTime"
                                        className="small"
                                        label={t('IDS_DISPLAY_END_TIME')}
                                    >
                                        <Form.Control
                                            type="datetime-local"
                                            required={getValues('startDisplayTime') !== ''}
                                            isInvalid={!!errors.endDisplayTime}
                                            {...register('endDisplayTime', {
                                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                    trigger(['startDisplayTime', 'endTime']);
                                                }
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.endDisplayTime?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Form>
                    </FormProvider>
                </div>
                
                <div className="main-titlebar main-titlebar-bottom bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-end mb-3">
                        <LoadingButton
                            form="form-maintenance"
                            variant="dark-ocean"
                            type="submit"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCircleCheck} className="me-2" />{t('IDS_UPDATE')}
                        </LoadingButton>
                    </div>
                </div>
            
                <BsModalAlert
                    title={t('IDS_SUCCESS')}
                    message={t('IDS_MAINTENANCE_UPDATED')}
                    showModal={showAlert}
                    setShowModal={setShowAlert}
                />
            </>
        );
}