import { useEffect } from 'react';
import { Col, FloatingLabel, Form, Row, Tab } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';
import {
    MultiTextInput, stringToMultiTextInputOptions
} from '../../../app/components/MultiTextInput';
import { TblClientLanguage } from '../../../app/models/dbTables/tblClientLanguage';
import { StringKeyObject } from '../../../app/models/stringKeyObject';
import { selectClient } from '../../../app/store/clientSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { RegExps } from '../../../app/utility/util';
import { parseValidationMsg } from '../../../i18n';
import { PropsSetupTabs } from './shared/setupTabsProps';

export default function TabInitialSetup({ setFormState }: PropsSetupTabs) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const dispatch = useAppDispatch();
    const clientNumber = clientSession?.vwClient.clientNumber;
    const defaultLang = clientSession?.vwClient?.defaultLanguage ?? '';

    const { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors }, handleSubmit, register, getValues, control } = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object({
            clientName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CLIENT_NAME'] }),
            returnedEmail: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_EMAIL_TO_SEND_RETURNS'] })
        }))
    });

    useEffect(() => {
        setFormState({isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset});
    }, [ setFormState, isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset]);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                if (clientNumber) {
                    let { vwClient } = await dispatch(selectClient(clientNumber)).unwrap();

                    if (vwClient) {
                        let clientNotes = await agent.Client.getClientNotes({ clientId: vwClient.id });

                        let noteFields: StringKeyObject<string> = {};
                        clientNotes.forEach(item => {
                            noteFields[`openingNotes-${item.languageCode}`] = item.openingNotes;
                            noteFields[`completionMsg-${item.languageCode}`] = item.closingNotes;
                            noteFields[`lockoutWarning-${item.languageCode}`] = item.lockoutNotes;
                        });

                        reset({
                            'clientId': vwClient.clientNumber ?? '',
                            'clientName': vwClient.name ?? '',
                            'emailApps': stringToMultiTextInputOptions(vwClient.email),
                            'returnedEmail': vwClient.returnedEmail ?? '',
                            'requiredEducation': `${vwClient.education}`,
                            'requiredEmpHistory': `${vwClient.employment}`,
                            'disableDpatLockout': `${vwClient.dpatLockoutExempt}`,
                            'requireEmailAddress': `${vwClient.emailRequired}`,
                            'requestEeoInfo': `${vwClient.requestEeoInfo}`,
                            'requireReferredBy': `${vwClient.referredByRequired}`,
                            'requiredReferences': `${vwClient.references}`,
                            'openingNotes-en-US': vwClient.openingNotes ?? '',
                            'completionMsg-en-US': vwClient.closingNotes ?? '',
                            'lockoutWarning-en-US': vwClient.lockoutText ?? '',
                            ...noteFields
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [dispatch, reset, clientNumber]);

    let initialSetupTabs: BsTabs = {};
    clientSession?.languageBC.forEach(({ languageCode }: TblClientLanguage) => {
        initialSetupTabs[languageCode] = {
            linkText: languageCode,
            paneComponent: (
                <>
                    <FloatingLabel
                        controlId={`openingNotes-${languageCode}`}
                        className="mb-3 small form-floating-textarea"
                        label={
                            <>
                                <span>{t('IDS_OPENING_SCREEN')}</span>
                                <HelpTooltipIcon
                                    tooltipId={`tooltipOpeningNotes-${languageCode}`}
                                    tooltipText={t('IDS_HELP_CLIENTSETUPOPENINGSCREEN_MESSAGE')}
                                    tooltipTitle={t('IDS_HELP_CLIENTSETUPOPENINGSCREEN_TITLE')}
                                />
                            </>
                        }>
                        <Form.Control
                            as="textarea"
                            style={{ height: 'calc(2.25rem + 5.9rem)' }}
                            {...register(`openingNotes-${languageCode}`)}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={`completionMsg-${languageCode}`}
                        className="mb-3 small form-floating-textarea"
                        label={
                            <>
                                <span>{t('IDS_COMPLETION_MSG')}</span>
                                <HelpTooltipIcon
                                    tooltipId={`tooltipCompletionMsg-${languageCode}`}
                                    tooltipText={t('IDS_HELP_CLIENTSETUPCOMPLETIONMESSAGE_MESSAGE')}
                                    tooltipTitle={t('IDS_HELP_CLIENTSETUPCOMPLETIONMESSAGE_TITLE')}
                                />
                            </>
                        }>
                        <Form.Control
                            as="textarea"
                            style={{ height: 'calc(2.25rem + 5.9rem)' }}
                            {...register(`completionMsg-${languageCode}`)}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={`lockoutWarning-${languageCode}`}
                        className="mb-3 small form-floating-textarea"
                        label={
                            <>
                                <span>{t('IDS_LOCKOUT_WARNING')}</span>
                                <HelpTooltipIcon
                                    tooltipId={`tooltipLockoutWarning-${languageCode}`}
                                    tooltipText={t('IDS_HELP_CLIENTSETUPLOCKOUTTEXT_MESSAGE')}
                                    tooltipTitle={t('IDS_HELP_CLIENTSETUPLOCKOUTTEXT_TITLE')}
                                />
                            </>
                        }>
                        <Form.Control
                            as="textarea"
                            style={{ height: 'calc(2.25rem + 5.9rem)' }}
                            {...register(`lockoutWarning-${languageCode}`)}
                        />
                    </FloatingLabel>
                </>)
        };
    });

    return (
        <Form id="form-initialSetup" className="needs-validation" noValidate>
            <div className="mb-5">
                <Form.Group as={Row} className="mb-4" controlId="clientId">
                    <Form.Label column xs="auto">{t('IDS_CLIENT_ID')}</Form.Label>
                    <Col xs="auto">
                        <Form.Control
                            className="fw-bold"
                            plaintext
                            readOnly
                            {...register('clientId')}
                        />
                    </Col>
                </Form.Group>
                <Row xs={1} md={2} lg={3} className="g-4">
                    <Col>
                        <FloatingLabel
                            controlId="clientName"
                            className="small"
                            label={t('IDS_CLIENT_NAME')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={50}
                                required
                                {...register('clientName')}
                                isInvalid={!!errors.clientName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.clientName?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="requiredEducation"
                            className="small"
                            label={t('IDS_REQUIRED_EDUCATION')}
                        >
                            <Form.Select {...register('requiredEducation')}>
                                <option value="1">{t('IDS_HIGH_SCHOOL')}</option>
                                <option value="2">{t('IDS_COLLEGE')}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="requiredEmpHistory"
                            className="small"
                            label={t('IDS_REQUIRED_EMP_HISTORY')}
                        >
                            <Form.Select {...register('requiredEmpHistory')}>
                                {[...Array(4)].map((_, index) => (
                                    <option key={index} value={index}>{index}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="emailApps"
                            className="small has-tooltip"
                            label={
                                <>
                                    <span>{t('IDS_EMAIL_TO_SEND_APPS')}</span>
                                    <HelpTooltipIcon
                                        tooltipId="tooltipemailApps"
                                        tooltipText={t('IDS_HELP_CLIENTSETUPEMAIL_MESSAGE')}
                                        tooltipTitle={t('IDS_HELP_CLIENTSETUPEMAIL_TITLE')}
                                    />
                                </>
                            }
                        >
                            <Controller
                                name="emailApps"
                                control={control}
                                render={({ field }) =>
                                    <MultiTextInput
                                        maxItems={5}
                                        inputValidationMsg={t('IDS_IEP_REQUIRED', { 0: t('IDS_VALID_UNIQUE_EMAIL') })}
                                        inputRegExp={RegExps.email}
                                        inputId="emailApps"
                                        {...field}
                                    />
                                }
                            />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="disableDpatLockout"
                            className="small"
                            label={t('IDS_DISABLE_APTITUDE_LOCKOUT')}
                        >
                            <Form.Select {...register('disableDpatLockout')}>
                                <option value="false">{t('IDS_NO')}</option>
                                <option value="true">{t('IDS_YES')}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="requireEmailAddress"
                            className="small"
                            label={t('IDS_REQUIRE_EMAIL')}
                        >
                            <Form.Select {...register('requireEmailAddress')}>
                                <option value="false">{t('IDS_NO')}</option>
                                <option value="true">{t('IDS_YES')}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    {clientSession?.vwClient.isRecType &&
                        <>
                            <Col>
                                <FloatingLabel
                                    controlId="requestEeoInfo"
                                    className="small"
                                    label={t('IDS_REQUEST_EEO_INFORMATION')}
                                >
                                    <Form.Select {...register('requestEeoInfo')}>
                                        <option value="false">{t('IDS_NO')}</option>
                                        <option value="true">{t('IDS_YES')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="requireReferredBy"
                                    className="small"
                                    label={t('IDS_REQUIRE_REF')}
                                >
                                    <Form.Select {...register('requireReferredBy')}>
                                        <option value="false">{t('IDS_NO')}</option>
                                        <option value="true">{t('IDS_YES')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </>
                    }
                    <Col>
                        <FloatingLabel
                            controlId="returnedEmail"
                            className="small has-tooltip"
                            label={
                                <>
                                    <span>{t('IDS_EMAIL_TO_SEND_RETURNS')}</span>
                                    <HelpTooltipIcon
                                        tooltipId="tooltipReturnedEmail"
                                        tooltipText={t('IDS_HELP_CLIENTSETUPRETURNEDEMAIL_MESSAGE')}
                                        tooltipTitle={t('IDS_HELP_CLIENTSETUPRETURNEDEMAIL_TITLE')}
                                    />
                                </>
                            }
                        >
                            <Form.Control
                                type="text"
                                maxLength={50}
                                required
                                {...register('returnedEmail')}
                                isInvalid={!!errors.returnedEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.returnedEmail?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="requiredReferences"
                            className="small"
                            label={t('IDS_REQUIRED_REFERENCES')}
                        >
                            <Form.Select {...register('requiredReferences')}>
                                <option value="-1">{t('IDS_DISABLE_REFERENCES')}</option>
                                {[...Array(5)].map((_, index) => (
                                    <option key={index} value={index}>{index}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            </div>
            <Tab.Container {...(defaultLang ? { defaultActiveKey: defaultLang } : {})}>
                <BsScrollableTabs tabs={initialSetupTabs} navTabsClassName="nav-tabs-alt" />
                <div className="border border-light-eggplant border-2 bg-white p-4">
                    <Tab.Content>
                        {Object.keys(initialSetupTabs).map((key) => (
                            <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                {initialSetupTabs[key].paneComponent}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </div>
            </Tab.Container>
        </Form>
    );
}