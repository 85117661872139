import { useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringKeyObject } from '../../../app/models/stringKeyObject';
import { useAppSelector } from '../../../app/store/storeHooks';
import { localeCode, locales } from '../../../i18n';
import { PropsSetupTabs } from './shared/setupTabsProps';

export default function TabValidLanguages({ setFormState }: PropsSetupTabs) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientLangs = clientSession?.languageBC;
    const defaultClientLang = clientSession?.vwClient.defaultLanguage;
    const { formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful }, handleSubmit, reset, register, getValues } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset]);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                let objIsSelected: StringKeyObject<boolean> = {};
                Object.keys(locales).forEach(langCode => {
                    objIsSelected[`isSelected-${langCode}`] = clientLangs && clientLangs.some(x => x.languageCode === langCode) ? true : false;
                });
                reset({
                    'defaultLanguage': defaultClientLang ?? '',
                    ...objIsSelected
                });
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [reset, clientLangs, defaultClientLang]);

    return (
        <Form id="form-validLanguages" className="needs-validation" noValidate>
            <small>{t('IDS_CHOOSE_LANGUAGES')}</small>
            <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive mt-3">
                <Table striped borderless hover variant="dark-eggplant" className="mb-0 table-cell-nowrap">
                    <thead>
                        <tr>
                            <th className="text-center"></th>
                            <th>{t('IDS_LANGUAGE_CODE')}</th>
                            <th className="text-center">{t('IDS_FLAG')}</th>
                            <th>{t('IDS_COUNTRY')}</th>
                            <th>{t('IDS_LANGUAGE')}</th>
                            <th className="text-center">{t('IDS_DEFAULT')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(locales).map((langCode, i) => (
                            <tr key={i}>
                                <td className="text-center">
                                    <Form.Check type="switch" {...register(`isSelected-${langCode}`, {})} />
                                </td>
                                <td>{langCode}</td>
                                <td className="text-center">
                                    <span className={`fs-initial fi ${locales[langCode as localeCode].flagClass}`}></span>
                                </td>
                                <td>{locales[langCode as localeCode].country}</td>
                                <td>{locales[langCode as localeCode].language}</td>
                                <td className="text-center">
                                    <Form.Check type="radio" {...register('defaultLanguage')} value={langCode} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Form>
    );
}