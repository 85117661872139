import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import agent from '../api/agent';
import { PdfDocument } from '../models/pdfDocument';

export interface PdfDocumentState {
    pdfObject: PdfDocument | null;
    status: string;
}

const initialState: PdfDocumentState = {
    pdfObject: null,
    status: 'idle'
};

export const getApplicationPdfAsync = createAsyncThunk<PdfDocument, { applicantId: number }>(
    'pdf/getApplication',
    async (data, thunkAPI) => {
        try {
            let application = await agent.FactoryTest.createApplicationPdf(data);
            return application;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const getAchieverPdfAsync = createAsyncThunk<PdfDocument, { applicantId: number }>(
    'pdf/getAchiever',
    async (data, thunkAPI) => {
        try {
            let achiever = await agent.FactoryTest.createAchieverPdf(data);
            return achiever;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const getAchieverPdfWithPositionAsync = createAsyncThunk<PdfDocument, { applicantId: number, positionId: number }>(
    'pdf/getAchiever',
    async (data, thunkAPI) => {
        try {
            let achieverWithPos = await agent.FactoryTest.createAchieverPdfWithPosition(data);
            return achieverWithPos;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const getDpatPdfAsync = createAsyncThunk<PdfDocument, { applicantId: number }>(
    'pdf/getDpat',
    async (data, thunkAPI) => {
        try {
            let dpat = await agent.FactoryTest.createDpatPdf(data);
            return dpat;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const getDpatInhPdfAsync = createAsyncThunk<PdfDocument, { applicantId: number }>(
    'pdf/getDpatInh',
    async (data, thunkAPI) => {
        try {
            let dpatInh = await agent.FactoryTest.createDpatInhousePdf(data);
            return dpatInh;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);

export const pdfDocumentSlice = createSlice({
    name: 'pdfDocument',
    initialState,
    reducers: {
        setPdfDocument: (state, action: { payload: PdfDocument | null }) => {
            state.pdfObject = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addMatcher(isAnyOf(getApplicationPdfAsync.pending, getAchieverPdfAsync.pending, getAchieverPdfWithPositionAsync.pending, getDpatPdfAsync.pending, getDpatInhPdfAsync.pending), (state) => {
            state.status = 'pending';
        });
        builder.addMatcher(isAnyOf(getApplicationPdfAsync.fulfilled, getAchieverPdfAsync.fulfilled, getAchieverPdfWithPositionAsync.fulfilled, getDpatPdfAsync.fulfilled, getDpatInhPdfAsync.fulfilled, getDpatInhPdfAsync.rejected), (state, action) => {
            state.status = 'idle';
            state.pdfObject = action.payload as PdfDocument;
        });
        builder.addMatcher(isAnyOf(getApplicationPdfAsync.rejected, getAchieverPdfAsync.rejected, getAchieverPdfWithPositionAsync.rejected, getDpatPdfAsync.rejected, getDpatInhPdfAsync.rejected), (state, action) => {
            state.status = 'idle';
            state.pdfObject = null;
        });
    })
});

export const { setPdfDocument } = pdfDocumentSlice.actions;
export default pdfDocumentSlice.reducer;