import { SyntheticEvent, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import { BsModalAlert } from '../../app/components/BsModal';
import LoadingButton from '../../app/components/LoadingButton';
import { FieldValuesRecoverUsername } from '../../app/models/fieldValueTypes';
import { getTitle, parseValidationMsg } from '../../i18n';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaBranding from './RecaptchaBranding';
import { RegExps } from '../../app/utility/util';

export default function ForgotUsernamePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showAlert, setShowAlert] = useState(false);
    
    const { formState: { isValid, isSubmitting, errors }, handleSubmit, register, setValue } = useForm<FieldValuesRecoverUsername>({
        mode: 'all',
        resolver: yupResolver(yup.object({
            email: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_EMAIL_ADDRESS'] })
                    .matches(RegExps.email, { message: { resKeys: ['IDS_IEP_REQUIRED', 'IDS_A_VALID_EMAIL_ADDR'] } })
        }))
    });

    async function submitForm(data: FieldValuesRecoverUsername) {
        try {
            if (!executeRecaptcha) return;
            const token = await executeRecaptcha('recoverApplicantUsername');
            sessionStorage.setItem('reCaptchaToken', token);

            await agent.UserAccount.recoverApplicantUsername(data);
            setShowAlert(true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_USERNAME_RECOVERY')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                <h1 className="mb-4 fw-bold">{t('IDS_USERNAME_RECOVERY')}</h1>
                <div className="help-text mb-4 smaller">{t('IDS_FORGOT_USERNAME_INSTRUCT')}</div>
                <Form onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                    <FloatingLabel
                        controlId="inputEmailAddress"
                        className="small mb-3"
                        label={t('IDS_EMAIL_ADDRESS')}
                    >
                        <Form.Control
                            type="text"
                            maxLength={50}
                            required
                            {...register('email', {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    e.currentTarget.value = e.currentTarget.value.trim();
                                    setValue('email', e.currentTarget.value, { shouldValidate: true });
                                }
                            })}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.email?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <LoadingButton
                        variant="dark-ocean"
                        type="submit"
                        className="mw-8rem mb-4"
                        loading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                    >
                        <FontAwesomeIcon icon={faKey} className="me-2" />{t('IDS_RECOVER_USERNAME')}
                    </LoadingButton>
                    <RecaptchaBranding />
                </Form>
            </div>
            
            <BsModalAlert
                title={t('IDS_SUCCESS')}
                message={t('IDS_EMAIL_SENT_USERNAME')}
                showModal={showAlert}
                setShowModal={setShowAlert}
                hideCallback={() => navigate('/AuthApplicant')}
            />
        </>
    );
}