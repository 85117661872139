import { ElementRef, useEffect, useRef, useState } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../../app/api/agent';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import { ApplicantTestProgress } from '../../../app/enums/applicantTestProgress';
import { AchieverSectionId } from '../../../app/enums/testSections';
import { TestType } from '../../../app/enums/testType';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import {
    applicantProgressDtoToRequest
} from '../../../app/models/requestHelpers/applicantProgressRequest';
import { ApplicantProgressDto } from '../../../app/models/responseHelpers/applicantProgressDto';
import { TestSession } from '../../../app/models/test/testSession';
import { TimeSpan } from '../../../app/models/test/timeSpan';
import {
    applicantSessionCompletedSection, updateAndGetApplicantProgress, updateApplicantProgress
} from '../../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import {
    addTestResult, checkMiddleAnswers, setPageNumber, setReviewMiddleAnswers
} from '../../../app/store/testSlice';
import { useTestPrompt } from '../../../app/utility/blockerPrompt';
import { convertMsToTime, TimeSpanMinVal } from '../../../app/utility/util';
import { getTitle } from '../../../i18n';
import { transType } from '../../../i18next';
import TestButtons from './TestButtons';
import TestPagingInput from './TestPagingInput';
import TestProgress from './TestProgress';
import TestQuestion from './TestQuestion';
import { TestTimer } from './TestTimer';

export const ApplicantTests = {
    [TestType.Achiever]: {
        titleKey: 'IDS_ASSESSMENT'
    },
    [TestType.DPAT]: {
        titleKey: 'IDS_APTITUDE_TEST'
    },
    [TestType.DPATInhouse]: {
        titleKey: 'IDS_APTITUDE_TEST_INH'
    }
}

export default function TestPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { user, status: applicantStatus } = useAppSelector(state => state.account);
    const applicantSessionDto = user?.applicantSessionDto;

    const { testSession, status: testStatus } = useAppSelector(state => state.test);
    const { testType = TestType.Achiever, totalQuestions, timeToComplete, questionsPerPage, pageNumber,
        section, isTimed, instructions, middleAnswers, reviewMiddleAnswers, randomizedQuestions
    } = testSession || {} as TestSession;
    
    const isInTest = testSession !== null && pageNumber > 0;

    const timerRef = useRef<ElementRef<typeof TestTimer>>(null);

    const [pageInputValue, setPageInputValue] = useState(pageNumber);
    const [showConfirmFinish, setShowConfirmFinish] = useState(false);

    const inProgress = (applicantSessionDto !== undefined && testSession !== null && (applicantSessionDto.progress.lastPage > 1 ||
        (applicantSessionDto.progress.remainingTimeValue < TimeSpan.fromTime(0, timeToComplete, 0).totalMilliseconds
            && applicantSessionDto.progress.remainingTimeValue > TimeSpan.zero.totalMilliseconds)));

    const totalPages = Math.ceil(totalQuestions / questionsPerPage);
    const { titleKey } = ApplicantTests[testType];
    const numQuestions = testSession !== null
        ? Math.min((pageNumber * questionsPerPage), totalQuestions) - (questionsPerPage * (pageNumber - 1))
        : 0;

    const goToPage = async (pageNum: number) => {
        setPageInputValue(pageNum);
        await dispatch(setPageNumber(pageNum));

        await dispatch(updateApplicantProgress({
            updateDb: true,
            timeRemaining: timerRef.current ? timerRef.current.getRemaining() : TimeSpanMinVal,
            hasStopped: false
        }));

        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const showFinishModal = async () => {
        if (section!.maxMaybes > 0) {
            dispatch(setReviewMiddleAnswers(false));
            await dispatch(checkMiddleAnswers());
        }
        setShowConfirmFinish(true);
    }

    const submitSection = async () => {
        let timeRemaining = timerRef.current
                ? timerRef.current.getRemaining()
                : TimeSpanMinVal
        let transCompleted = false;

        let { applicantSessionDto: updatedApplicantSession } = await dispatch(updateApplicantProgress({
            updateDb: false,
            timeRemaining,
            hasStopped: false
        })).unwrap();

        let updatedApplicantProgress: ApplicantProgressDto = updatedApplicantSession!.progress;
        let currentProgressId = updatedApplicantProgress.progressId;

        if (currentProgressId === ApplicantTestProgress.MechanicalInterest) {
            transCompleted = await dispatch(addTestResult({ fDPAT: false, fDPATInhouse: false })).unwrap();
        } else if (currentProgressId === ApplicantTestProgress.MathWordProblems) {
            transCompleted = await dispatch(addTestResult({ fDPAT: true, fDPATInhouse: false })).unwrap();
        } else if (currentProgressId === ApplicantTestProgress.MathWordProblems2) {
            transCompleted = await dispatch(addTestResult({ fDPAT: false, fDPATInhouse: true })).unwrap();
        }

        let { applicantSessionDto: updatedApplicantSessionDto = {} as ApplicantSessionDto } = await dispatch(applicantSessionCompletedSection()).unwrap();
        let newProgressId = updatedApplicantSessionDto.progress.progressId;

        setPageInputValue(0);
        await dispatch(setPageNumber(0));

        if (newProgressId === ApplicantTestProgress.Complete) {
            await dispatch(updateAndGetApplicantProgress({
                transCompleted,
                oldProgressId: currentProgressId,
                oldLastPage: updatedApplicantProgress.lastPage,
                oldSectionId: updatedApplicantProgress.sectionId,
                oldTimeRemaining: updatedApplicantProgress.remainingTimeValue,
                oldClientTime: updatedApplicantProgress.clientTimeElapsedValue,
                oldServerTime: updatedApplicantProgress.serverTimeElapsedValue
            }));
            navigate('/Applicant');
        } else {
            let { progress, applicant: { clientId, positionId } } = updatedApplicantSessionDto;
            let applicantProgressRequest = applicantProgressDtoToRequest(progress, clientId, positionId, false, false, currentProgressId,
                updatedApplicantProgress.lastPage, updatedApplicantProgress.sectionId, updatedApplicantProgress.remainingTimeValue,
                updatedApplicantProgress.clientTimeElapsedValue, updatedApplicantProgress.serverTimeElapsedValue);
            await agent.ApplicantTest.updateApplicantProgressCompleteSection(applicantProgressRequest);

            if (currentProgressId !== newProgressId &&
                (newProgressId === ApplicantTestProgress.Personality ||
                    newProgressId === ApplicantTestProgress.AlphaSequence ||
                    newProgressId === ApplicantTestProgress.AlphaSequence2) &&
                (currentProgressId === ApplicantTestProgress.MechanicalInterest ||
                    currentProgressId === ApplicantTestProgress.MathWordProblems ||
                    currentProgressId === ApplicantTestProgress.MathWordProblems2)) {
                if (newProgressId === ApplicantTestProgress.AlphaSequence)
                    navigate('/Applicant/DPAT');
                else if (newProgressId === ApplicantTestProgress.AlphaSequence2)
                    navigate('/Applicant/DPATInhouse');
                else
                    navigate('/Applicant/Achiever');
            }
        }
    }
    
    useEffect(() => {
        const handlebeforeunload = async () => {
            if (isInTest) {
                await dispatch(updateApplicantProgress({
                    updateDb: true,
                    timeRemaining: timerRef.current ? timerRef.current.getRemaining() : TimeSpanMinVal,
                    hasStopped: true
                }));
            }
        }

        window.addEventListener('beforeunload', handlebeforeunload);

        return () => window.removeEventListener('beforeunload', handlebeforeunload);
    }, [dispatch, isInTest]);

    useTestPrompt(t('IDS_CONFIRM_LEAVE'), timerRef, isInTest);

    return (
        <>
            <Helmet>
                <title>{getTitle(t, titleKey)}</title>
            </Helmet>
            <LoadingComponentControlled delay={1} show={testStatus.includes('pending') || applicantStatus.includes('pending')} />
            <div className="main-titlebar bg-lightest-ocean pt-3 pb-0 border border-bottom-0">
                <span className="fs-5 line-height-100">
                    {`${t(titleKey as keyof transType)} - ${applicantSessionDto?.applicant.clientName}`}
                </span>
                <TestProgress />
            </div>
            <div className={`main-titlebar bg-lightest-ocean pt-0 pb-2 ${(testSession && pageNumber > 0 && isTimed) ? 'position-sticky top-0 z-index-999' : ''}`}>
                {testSession && pageNumber > 0 &&
                    <div className="d-flex justify-content-between mt-2">
                        <div>
                            {isTimed &&
                                <TestTimer ref={timerRef} submitSection={submitSection} />
                            }
                        </div>

                        <div
                            className="d-inline-flex align-items-center justify-content-center gap-2"
                            style={{ gridAutoFlow: 'column' }}
                        >
                            {pageNumber <= totalPages &&
                                <TestPagingInput
                                    pageInputValue={pageInputValue}
                                    setPageInputValue={setPageInputValue}
                                    goToPage={goToPage}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="main-content bg-light-silver user-select-none">
                {testSession &&
                    <>
                        {pageNumber === 0 &&
                            <>
                                {testType === TestType.Achiever &&
                                    <p className="white-space-pre-line">{instructions}</p>
                                }
                                {(testType === TestType.DPAT || testType === TestType.DPATInhouse) &&
                                    <p dangerouslySetInnerHTML={{ __html: instructions.replaceAll('\n', '<br>') }} />
                                }

                                {inProgress &&
                                    <p className="text-cherry">
                                        {t('IDS_CONTINUE_TEST_INSTR')}
                                        <br /><br />
                                        {isTimed && applicantSessionDto && applicantSessionDto.progress.remainingTimeValue !== TimeSpanMinVal &&
                                            <>
                                                {`${t('IDS_TIME_REMAINING')}: ${convertMsToTime(applicantSessionDto.progress.remainingTimeValue)}`}
                                            </>
                                        }
                                    </p>
                                }
                            </>
                        }

                        {pageNumber > 0 && pageNumber <= totalPages && section?.questions.length &&
                            <>
                                {(testType === TestType.DPAT || testType === TestType.DPATInhouse) &&
                                    <>
                                        {randomizedQuestions.length && [...Array(numQuestions)].map((_, i) => (
                                            <TestQuestion
                                                key={`${pageNumber}_${i}`}
                                                questionPosition={i}
                                                questionIndex={randomizedQuestions[pageNumber - 1][i] - 1}
                                            />
                                        ))}
                                    </>
                                }

                                {testType === TestType.Achiever &&
                                    <>
                                        {section.id === AchieverSectionId.Perception
                                            ? <>
                                                <p>{t('IDS_PAIRS_INSTRUCTIONS')}</p>
                                                <ListGroup>
                                                    {[...Array(numQuestions)].map((_, i) => (
                                                        <TestQuestion
                                                            key={`${pageNumber}_${i}`}
                                                            questionPosition={i}
                                                            questionIndex={(((pageNumber - 1) * questionsPerPage) + i)}
                                                        />
                                                    ))}
                                                </ListGroup>
                                            </>
                                            : <>
                                                {[...Array(numQuestions)].map((_, i) => (
                                                    <TestQuestion
                                                        key={`${pageNumber}_${i}`}
                                                        questionPosition={i}
                                                        questionIndex={reviewMiddleAnswers && middleAnswers
                                                            ? (middleAnswers[pageNumber + i - 1])
                                                            : (((pageNumber - 1) * questionsPerPage) + i)}
                                                    />
                                                ))}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </div>

            <div className="main-titlebar main-titlebar-bottom bg-lightest-ocean py-3 d-flex justify-content-center">
                {!((applicantSessionDto?.progress.progressId ?? 0) === ApplicantTestProgress.Complete) &&
                    <TestButtons
                        goToPage={goToPage}
                        inProgress={inProgress}
                        showFinishModal={showFinishModal}
                    />
                }
            </div>

            {testSession &&
                <Modal
                    scrollable={true}
                    show={showConfirmFinish}
                    onHide={() => setShowConfirmFinish(false)}
                    centered={true}
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>{t('IDS_CONFIRM')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowConfirmFinish(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        {middleAnswers?.length
                            ? t('IDS_IEP_TOO_MANY_MAYBES', { 0: middleAnswers.length })
                            : t('IDS_REVIEW_OR_SUBMIT')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="silver"
                            onClick={() => {
                                setShowConfirmFinish(false);
                                goToPage(1);
                            }}
                        >
                            {t('IDS_REVIEW')}
                        </Button>

                        {!!middleAnswers?.length &&
                            <Button
                                variant="silver"
                                onClick={() => {
                                    setShowConfirmFinish(false);
                                    dispatch(setReviewMiddleAnswers(true));
                                    goToPage(1);
                                }}
                            >
                                {t('IDS_REVIEW_MIDDLE')}
                            </Button>
                        }

                        <Button
                            variant="dark-ocean"
                            className="mw-8rem"
                            disabled={middleAnswers !== null && middleAnswers.length > 0}
                            onClick={() => {
                                setShowConfirmFinish(false);
                                submitSection();
                            }}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_SUBMIT')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}