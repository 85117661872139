import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, InputGroup, Modal, Row, Tab } from 'react-bootstrap';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import { BsAlertDismissible } from '../../../app/components/BsAlertDismissible';
import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';
import LoadingButton from '../../../app/components/LoadingButton';
import {
    MultiTextInput, stringToMultiTextInputOptions
} from '../../../app/components/MultiTextInput';
import { ModalRecordMode } from '../../../app/enums/modalRecordMode';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { TblClientLanguage } from '../../../app/models/dbTables/tblClientLanguage';
import {
    ClientPositionRequest, formDataToClientPositionRequest
} from '../../../app/models/requestHelpers/clientPositionRequest';
import { ClientPositionDto } from '../../../app/models/responseHelpers/clientPositionDto';
import {
    ClientPositionQuestionDto
} from '../../../app/models/responseHelpers/clientPositionQuestionDto';
import { StringKeyObject } from '../../../app/models/stringKeyObject';
import { TextValuePair } from '../../../app/models/textValuePair';
import { useAppSelector } from '../../../app/store/storeHooks';
import { padArray, RegExps } from '../../../app/utility/util';
import { locales, parseValidationMsg } from '../../../i18n';
import ClientPositionQuestions from './ClientPositionQuestions';

interface Props {
    mode: ModalRecordMode;
    showModal: boolean;
    positionId: number;
    setShowModal: (show: boolean) => void;
    changeCount: number;
    setChangeCount: (count: number) => void;
}

export default function ModalPosition({ showModal, setShowModal, mode, positionId, changeCount, setChangeCount }: Props) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const defaultLang = clientSession?.vwClient?.defaultLanguage ?? '';
    const [profiles, setProfiles] = useState<TextValuePair[]>([]);
    const [departments, setDepartments] = useState<TextValuePair[]>([]);
    const [loading, setLoading] = useState(false);
    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object({
            positionNumber: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_JOB_ID'] }),
            title: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_JOB_TITLE'] }),
            description: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_JOB_DESC'] }),
            departmentId: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_DEPARTMENT'] }),
            location: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_LOCATION'] }),
            city: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CITY'] }),
            state: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_STATE'] })
        }))
    }),
        { formState: { isValid, isSubmitting, isDirty, errors }, register, handleSubmit, control, reset, setValue } = formMethods;

    useEffect(() => {
        const fetchPosition = async () => {
            try {
                let formData = {};

                if (showModal && clientId) {
                    setLoading(true);
                    let positionProfiles = await agent.PositionProfile.getAllPositionProfiles({ clientId });
                    let profileOptions = positionProfiles.map(profile => { return { text: profile.name, value: `${profile.id}` }; });
                    setProfiles(profileOptions);

                    let departments = await agent.ClientPosition.getAllClientDepartments();
                    let departmentOptions = departments.map(dept => { return { text: dept.department, value: `${dept.id}` }; });
                    setDepartments(departmentOptions);

                    let position: ClientPositionDto | null = null;
                    let defaultQuestionFields: StringKeyObject<StringKeyObject<number | string | boolean>[]> = {};
                    let questionFields: StringKeyObject<StringKeyObject<string | boolean>[]> = {};
                
                    if (positionId) {
                        position = await agent.ClientPosition.getClientPosition({ clientId, positionId });
                    }
                    
                    let clientDefaultQuestions = await agent.ClientPosition.getAllClientDefaultQuestions({ clientId, languageCode: 'all' });
                    let questions: ClientPositionQuestionDto[] = position?.questions ?? [];
                    let clientPositionDefaultQuestions = questions.filter(x => x.default);
                
                    clientDefaultQuestions.forEach(item => {
                        let key = `positionDefaultQuestions-${item.languageCode}`;
                        let idx = item.order ? item.order - 1 : 0;
                        if (!defaultQuestionFields[key]) {
                            defaultQuestionFields[key] = [];
                        }
                        defaultQuestionFields[key][idx] = {
                            questionId: item.questionId,
                            question: item.question,
                            checked: clientPositionDefaultQuestions.some(x => x.questionId === item.questionId) || positionId === 0
                        };
                    });
                
                    Object.keys(locales).forEach(lang => {
                        let key = `positionDefaultQuestions-${lang}`;
                        if (!defaultQuestionFields[key]) {
                            defaultQuestionFields[key] = [];
                        }
                    });
                
                    let clientPositionQuestions = questions.filter(x => !x.default);
                    clientPositionQuestions.forEach(item => {
                        let key = `positionQuestions-${item.languageCode}`;
                        let idx = item.order;
                        if (!questionFields[key]) {
                            questionFields[key] = [];
                        }
                        questionFields[key][idx] = {
                            question: item.question,
                            active: item.active,
                            typeId: `${item.typeId}`,
                            literalA: item.literalA,
                            literalB: item.literalB,
                            literalC: item.literalC,
                            literalD: item.literalD,
                            literalE: item.literalE
                        };
                    });
                
                    let emptyQuestionField = {
                        question: '',
                        active: false,
                        typeId: '2',
                        literalA: '',
                        literalB: '',
                        literalC: '',
                        literalD: '',
                        literalE: ''
                    };
                    Object.keys(locales).forEach(lang => {
                        let key = `positionQuestions-${lang}`;
                        if (!questionFields[key]) {
                            questionFields[key] = [];
                        }
                        if (questionFields[key].length < 6) {
                            questionFields[key] = padArray<StringKeyObject<any>>(questionFields[key], 6, emptyQuestionField);
                        }
                    });
                
                    formData = {
                        'positionId': `${positionId}`,
                        'clientId': `${clientId}`,
                        'positionNumber': position?.positionNumber ?? '',
                        'active': position?.active ?? true,
                        'positionProfileId': `${position?.profile?.id ?? ''}`,
                        'title': position?.title ?? '',
                        'email': stringToMultiTextInputOptions(position?.emailOverride ?? ''),
                        'description': position?.description ?? '',
                        'departmentId': `${position?.departmentId ?? ''}`,
                        'location': position?.location ?? '',
                        'city': position?.city ?? '',
                        'state': position?.state ?? '',
                        'requirement': position?.requirement ?? '',
                        ...defaultQuestionFields,
                        ...questionFields
                    };
                }

                reset(formData);
            } catch (error) {
                console.log(error);
                setShowModal(false);
            } finally {
                setLoading(false);
            }
        }
        fetchPosition();
    }, [reset, showModal, setShowModal, mode, clientId, positionId, setValue]);

    async function submitForm(data: FieldValues) {
        try {
            let clientPositionRequest: ClientPositionRequest = formDataToClientPositionRequest(data);

            if (mode === ModalRecordMode.Edit) {
                await agent.ClientPosition.updateClientPosition(clientPositionRequest);
            } else if (mode === ModalRecordMode.Add) {
                await agent.ClientPosition.addClientPosition(clientPositionRequest);
            }

            setShowModal(false);
            setChangeCount(changeCount + 1);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    let defaultQuestionsTabs: BsTabs = {};
    clientSession?.languageBC.forEach(({ languageCode }: TblClientLanguage) => {
        defaultQuestionsTabs[languageCode] = {
            linkText: languageCode,
            paneComponent: (
                <ClientPositionQuestions languageCode={languageCode} />)
        };
    });

    if (loading)
        return <LoadingComponent />
    else
        return (
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalPosition"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <FormProvider {...formMethods}>
                    <Modal.Header>
                        <Modal.Title>{t(mode === ModalRecordMode.Add ? 'IDS_ADD_POSITION' : 'IDS_VIEW_POSITIONS')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                    </Modal.Header>
                    <Modal.Body className="bg-light-silver">
                        <Form id="formPosition" onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                            <Tab.Container defaultActiveKey="initialSetup">
                                <BsScrollableTabs
                                    navTabsClassName="nav-tabs-alt"
                                    tabs={{
                                        'initialSetup': {
                                            linkText: 'IDS_INITIAL_SETUP',
                                            paneComponent: (<></>)
                                        },
                                        'defaultQuestions': {
                                            linkText: 'IDS_DEFAULT_APP_QUESTIONS',
                                            paneComponent: (<></>)
                                        }
                                    }}
                                />
                                <div className="border border-light-eggplant border-2 bg-white p-4">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="initialSetup" key="initialSetupTabPane">
                                            <Row className="g-4">
                                                <Col xs={12} lg={6}>
                                                    <InputGroup className="flex-nowrap">
                                                        <FloatingLabel
                                                            controlId="positionNumber"
                                                            className="small has-tooltip flex-fill"
                                                            label={
                                                                <>
                                                                    <span>{t('IDS_JOB_ID')}</span>
                                                                    <HelpTooltipIcon
                                                                        tooltipId="tooltipJobId"
                                                                        tooltipText={t('IDS_HELP_CLIENTPOSITIONJOBID_MESSAGE')}
                                                                        tooltipTitle={t('IDS_HELP_CLIENTPOSITIONJOBID_TITLE')}
                                                                    />
                                                                </>
                                                            }
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={10}
                                                                required
                                                                {...register('positionNumber')}
                                                                isInvalid={!!errors.positionNumber}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {parseValidationMsg(errors?.positionNumber?.message, t)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                        <FloatingLabel
                                                            controlId="active"
                                                            className="small label-flex"
                                                            onClick={(e) => {
                                                                if (e.target instanceof Element && !e.target.matches('input')) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            label={
                                                                <>
                                                                    <span>{t('IDS_ACTIVE')}</span>
                                                                    <HelpTooltipIcon
                                                                        tooltipId="tooltipActive"
                                                                        tooltipText={t('IDS_HELP_CLIENTPOSITIONACTIVE_MESSAGE')}
                                                                        tooltipTitle={t('IDS_HELP_CLIENTPOSITIONACTIVE_TITLE')}
                                                                    />
                                                                </>
                                                            }
                                                        >
                                                            <Form.Check
                                                                type="switch"
                                                                className="form-control text-center"
                                                                style={{ width: '5.4rem' }}
                                                                {...register('active', {})}
                                                            />
                                                        </FloatingLabel>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="profile"
                                                        className="small"
                                                        label={
                                                            <>
                                                                <span>{t('IDS_PROFILE')}</span>
                                                                <HelpTooltipIcon
                                                                    tooltipId="tooltipProfile"
                                                                    tooltipText={t('IDS_HELP_CLIENTPOSITIONPROFILE_MESSAGE')}
                                                                    tooltipTitle={t('IDS_HELP_CLIENTPOSITIONPROFILE_TITLE')}
                                                                />
                                                            </>
                                                        }
                                                    >
                                                        <Form.Select {...register('positionProfileId')}>
                                                            <option value=""></option>
                                                            {profiles && profiles.map(({ text, value }) => (
                                                                <option key={value} value={value}>{text}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="title"
                                                        className="small"
                                                        label={t('IDS_JOB_TITLE')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            maxLength={25}
                                                            required
                                                            {...register('title')}
                                                            isInvalid={!!errors.title}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.title?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="email"
                                                        className="small"
                                                        label={
                                                            <>
                                                                <span>{t('IDS_EMAIL_OVERRIDE')}</span>
                                                                <HelpTooltipIcon
                                                                    tooltipId="tooltipEmailOverride"
                                                                    tooltipText={t('IDS_HELP_CLIENTPOSITIONEMAILOVERRIDE_MESSAGE')}
                                                                    tooltipTitle={t('IDS_HELP_CLIENTPOSITIONEMAILOVERRIDE_TITLE')}
                                                                />
                                                            </>
                                                        }
                                                    >
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <MultiTextInput
                                                                    maxItems={3}
                                                                    inputValidationMsg={t('IDS_IEP_REQUIRED', { 0: t('IDS_VALID_UNIQUE_EMAIL') })}
                                                                    inputRegExp={RegExps.email}
                                                                    inputId="email"
                                                                    {...field}
                                                                />
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12}>
                                                    <FloatingLabel
                                                        controlId="description"
                                                        className="small form-floating-textarea"
                                                        label={t('IDS_JOB_DESC')}
                                                    >
                                                        <Form.Control
                                                            as="textarea"
                                                            style={{ height: '100px' }}
                                                            required
                                                            {...register('description')}
                                                            isInvalid={!!errors.description}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.description?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="departmentId"
                                                        className="small"
                                                        label={t('IDS_DEPARTMENT')}>
                                                        <Form.Select
                                                            required
                                                            {...register('departmentId')}
                                                            isInvalid={!!errors.departmentId}
                                                        >
                                                            <option value=""></option>
                                                            {departments && departments.map(({ text, value }) => (
                                                                <option key={value} value={value}>{text}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.departmentId?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="location"
                                                        className="small"
                                                        label={t('IDS_LOCATION')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            maxLength={50}
                                                            required
                                                            {...register('location')}
                                                            isInvalid={!!errors.location}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.location?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="city"
                                                        className="small"
                                                        label={t('IDS_CITY')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            maxLength={50}
                                                            required
                                                            {...register('city')}
                                                            isInvalid={!!errors.city}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.city?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <FloatingLabel
                                                        controlId="state"
                                                        className="small"
                                                        label={t('IDS_STATE')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            maxLength={2}
                                                            required
                                                            {...register('state')}
                                                            isInvalid={!!errors.state}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.state?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12}>
                                                    <FloatingLabel
                                                        controlId="requirement"
                                                        className="small form-floating-textarea"
                                                        label={t('IDS_REQUIREMENTS')}
                                                    >
                                                        <Form.Control
                                                            as="textarea"
                                                            style={{ height: '100px' }}
                                                            {...register('requirement')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="defaultQuestions" key="defaultQuestionsTabPane">
                                            <BsAlertDismissible
                                                btnText={t('IDS_INSTRUCTIONS')}
                                                message={t('IDS_HELP_CLIENTPOSITIONDEFAULTQUESTIONS_MESSAGE')}
                                                headerText={t('IDS_INSTRUCTIONS')}
                                                variant="dark-ocean"
                                            />
                                            <Tab.Container {...(defaultLang ? { defaultActiveKey: defaultLang } : {})}>
                                                <BsScrollableTabs tabs={defaultQuestionsTabs} navTabsClassName="nav-tabs-alt" />
                                                <div className="border border-light-eggplant border-2 bg-white p-4">
                                                    <Tab.Content>
                                                        {Object.keys(defaultQuestionsTabs).map((key) => (
                                                            <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                                                {defaultQuestionsTabs[key].paneComponent}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="silver" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                        <LoadingButton
                            form="formPosition"
                            type="submit"
                            variant="dark-ocean"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || !isDirty || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t(mode === ModalRecordMode.Add ? 'IDS_ADD' : 'IDS_UPDATE')}
                        </LoadingButton>
                    </Modal.Footer>
                </FormProvider>
            </Modal>
        );
}