export interface ApplicantEeoDataDto {
    countryCode: string;
    gender: string;
    race: string;
    ethnicOrigin: string;
    disability: string;
    disabilityDetails: string;
    dateOfBirth: string;
    positionAppliedFor: string;
    age: string;
    maritalStatus: string;
    sexualOrientation: string;
    religionOrBelief: string;
}

export const NOT_SPECIFIED = 'Not Specified';
export const PREFER_NOT_TO_SAY = 'I prefer not to say';
export const OTHER = 'Other';

export const OTHER_ASIAN_BG = 'Other Asian background';
export const OTHER_BLACK_BG = 'Other Black background';
export const OTHER_MIXED_BG = 'Other Mixed background';
export const OTHER_WHITE_BG = 'Other White background';