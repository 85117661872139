import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Form, Table } from 'react-bootstrap';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import colorVars from '../../../app/layout/styles/_colors.module.scss';
import { useAppSelector } from '../../../app/store/storeHooks';
import ApplicantDefaultsCard from './dnd/ApplicantDefaultsCard';
import { PropsSetupTabs } from './shared/setupTabsProps';

export default function TabApplicantDefaults({ setFormState }: PropsSetupTabs) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientBo = clientSession?.vwClient;
    const formMethods = useForm({
        mode: 'all'
    }),
        { reset, formState: { isDirty, isSubmitting, isSubmitSuccessful }, handleSubmit, getValues, register, control } = formMethods;
    const { fields, move } = useFieldArray({ control, name: 'applicantDefaults' });

    useEffect(() => {
        setFormState({ isValid: true, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset]);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                if (clientBo) {
                    let applicantDefaults: { name: string, text: string, order: number, onByDefault: boolean }[] = [];
                    applicantDefaults.push({ name: 'Email', text: t('IDS_EMAIL_LOGON_INFORMATION'), order: 0, onByDefault: clientBo.applicantDefaultEmail });
                    applicantDefaults.push({ name: 'Achiever', text: t('IDS_PERSONALITY_ASSESSMENT'), order: clientBo.applicantDefaultAchieverOrder, onByDefault: clientBo.applicantDefaultAchiever });
                    applicantDefaults.push({ name: 'Application', text: t('IDS_APPLICATION'), order: clientBo.applicantDefaultApplicationOrder, onByDefault: clientBo.applicantDefaultApplication });
                    applicantDefaults.push({ name: 'Aptitudetest', text: t('IDS_APTITUDE_TEST'), order: clientBo.applicantDefaultAptitudetestOrder, onByDefault: clientBo.applicantDefaultAptitudeTest });
                    applicantDefaults.push({ name: 'DpatInhouse', text: t('IDS_APTITUDE_INHOUSE'), order: clientBo.applicantDefaultDpatInhouseOrder, onByDefault: clientBo.applicantDefaultDpatInhouse });
                    applicantDefaults.sort((a, b) => (a.order > b.order) ? 1 : -1)

                    reset({ applicantDefaults });
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [reset, clientBo, t]);
    
    const handleDrag: OnDragEndResponder = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <Form id="form-applicantDefaults" className="needs-validation" noValidate>
                <small>{t('IDS_APP_DEFAULTS_P1')}</small>
                <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive mt-3">
                    <Table striped borderless hover variant="dark-eggplant" className="mb-0 table-cell-nowrap">
                        <thead>
                            <tr className="d-flex">
                                <th className="text-center" style={{ width: '7.5rem' }}>{t('IDS_DEFAULT_ORDER')}</th>
                                <th className="flex-fill">{t('IDS_AVAILABLE_ITEMS')}</th>
                                <th className="text-center" style={{ width: '12rem' }}>{t('IDS_TURNED_ON_BY_DEFAULT')}</th>
                            </tr>
                        </thead>
                        <tbody style={{borderBottom: `1px solid ${colorVars.darkeggplant}`}}>
                            <tr className="d-flex">
                                <td style={{ width: '7.5rem' }}>
                                    {t('IDS_NA')}
                                </td>
                                <td className="flex-fill">
                                    {getValues(`applicantDefaults[0].text`)}
                                </td>
                                <td className="text-center" style={{ width: '12rem' }}>
                                    <Form.Check
                                        type="switch"
                                        {...register(`applicantDefaults[0].onByDefault`, {})}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <DragDropContext onDragEnd={handleDrag}>
                            <Droppable droppableId="applicantDefaults">
                                {(provided, snapshot) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef} className={snapshot.isDraggingOver ? 'hide-indexes' : ''}>
                                        {fields.map((item, index) => {
                                            return index > 0
                                                ? (
                                                    <Draggable
                                                        key={`applicantDefaults[${index}]`}
                                                        draggableId={`item-${index}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <ApplicantDefaultsCard
                                                                provided={provided}
                                                                item={item}
                                                                index={index}
                                                            />
                                                        )}
                                                    </Draggable>
                                                )
                                                : (null);
                                        })}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </div>
            </Form>
        </FormProvider>
    );
}