import { TFunction } from 'i18next';
import { Country } from 'react-phone-number-input';

import { getLangOrDefault } from '../../i18n';
import { AuthType } from '../enums/authType';
import { FilterMode } from '../enums/filterMode';
import { SettingNames } from '../enums/settingNames';
import { UserType } from '../enums/userType';
import { SettingDto } from '../models/responseHelpers/settingDto';

export const localeStringDefaultOptions: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short', month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit'
};
export const localeStringDefaultOptionsDateOnly: Intl.DateTimeFormatOptions = {
    month: 'numeric', day: 'numeric', year: 'numeric'
};

export const strMinDate = '0001-01-01T00:00:00';

export function localDate(strUtcDate: string | null, dateOnly: boolean = false) {
    if (strUtcDate) {
        if (dateOnly) {
            return new Date(strUtcDate).toISOString().split('T')[0];
        } else {
            return new Date(strUtcDate).toLocaleString(getLangOrDefault(), localeStringDefaultOptions);
        }
    }
    return '';
}

export const sleep = () => new Promise(resolve => setTimeout(resolve, 2000));

export const RegExps = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    digits: /^[0-9]+$/,
    phone: /^([(][0-9]{3}[)]|[0-9]{3})[-\s]?[0-9]{3}[-]?[0-9]{4}$/,
    phoneFR: /^0[1-9][.-]?[0-9]{2}[.-]?[0-9]{2}[.-]?[0-9]{2}[.-]?[0-9]{2}$/,
    phoneGeneric: /^[0-9 ()+-]{1,25}$/,
    state: /^[a-zA-Z]{2}$/,
    date: {
        DDMMYYYY: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
    }
};

export const pathFromHost = () => {
    return window.location.hostname.includes('apply') ? AuthType.Applicant : AuthType.Client;
};

export const pathFromUserType = (userType: UserType | undefined | null) => {
    if (userType === undefined || userType === null) {
        return AuthType.Applicant;
    } else if (userType === UserType.Admin) {
        return AuthType.Admin;
    } else if (userType === UserType.Client) {
        return AuthType.Client;
    } else {
        return AuthType.Applicant;
    }
};

export const stringToBool = (str: string) => str.toLowerCase() === 'true';

export function padArray<T>(arr: Array<T>, len: number, fill: T) {
    return arr.concat(Array(len).fill(fill)).slice(0,len);
}

export const TimeSpanMinVal = -922337203685477;

export function dateTimeIsMinVal(strDate: string | null | undefined) {
    if (!strDate) {
        return true;
    }
    let minDate = new Date();
    minDate.setFullYear(1, 0, 1);
    minDate.setHours(0, 0, 0, 0);

    let date = new Date(strDate);
    if (isNaN(date.getTime())) {
        return true;
    }

    return date.getTime() === minDate.getTime();
}
function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}
export function convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

export const Validate = {
    Email: (email: string, t: TFunction) => {
        let regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.length > 0) {
            if (email.match(regExp)) {
                return true;
            } else if (email.toLowerCase() === 'na' || email.toLowerCase() === t('IDS_NA').toLowerCase()) {
                return true;
            }
        }
        return false;
    }
}

export function truncateString(str: string, maxChars: number) {
    return (str && str.length > maxChars) ? str.substring(0, maxChars) + '...' : str;
};

export function base64ToObjectUrl(base64: string, type: string) {
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let uint8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }
    let blob = new Blob([uint8Array], { type });
    return window.URL.createObjectURL(blob);
}

export function downloadPdf(base64: string, filename: string) {
    let objectUrl = base64ToObjectUrl(base64, 'application/pdf');
    let a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none;');
    a.href = objectUrl;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    a.remove();
}

/**
 * Downloads a CSV file with the provided name and provided data.
 * @param base64 The byte array for the data to be inside of the CSV file.
 * @param filename The name of the file to be downloaded.
 */
export function downloadCsv(base64: string, filename: string) {
    let objectUrl = base64ToObjectUrl(base64, 'text/csv');
    let a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none;');
    a.href = objectUrl;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    a.remove();
}

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function setFilterModeClasses(filterMode: FilterMode) {
    switch (filterMode) {
        case FilterMode.DarkContrast:
            document.documentElement.classList.remove(FilterMode.HighContrast, FilterMode.Monochrome);
            document.documentElement.classList.add(FilterMode.DarkContrast);
            break;
        case FilterMode.Monochrome:
            document.documentElement.classList.remove(FilterMode.DarkContrast, FilterMode.HighContrast);
            document.documentElement.classList.add(FilterMode.Monochrome);
            break;
        case FilterMode.HighContrast:
            document.documentElement.classList.remove(FilterMode.DarkContrast, FilterMode.Monochrome);
            document.documentElement.classList.add(FilterMode.HighContrast);
            break;
        default:
            document.documentElement.classList.remove(FilterMode.DarkContrast, FilterMode.HighContrast, FilterMode.Monochrome);
    }
}

export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
}

export function dateRangeValid(start: string, end: string) {
    if (!start || !end) {
        return false;
    }

    const dtStart = new Date(start);
    const dtEnd = new Date(end);

    if (!isValidDate(dtStart) || !isValidDate(dtEnd)) {
        return false;
    }

    return dtEnd < dtStart;
}

function getDefaultForType(type: 'string' | 'boolean' | 'number'): string | boolean | number {
    switch (type) {
        case 'number': return 0;
        case 'boolean': return false;
        default: return '';
    }
}
export function getAppSettingVal(appSettings: SettingDto[], settingName: SettingNames, type: 'string' | 'boolean' | 'number'): string | boolean | number | null {
    if (appSettings?.length) {
        let appSetting = appSettings.find(x => x.settingName === settingName);

        if (appSetting) {
            try {
                switch (type) {
                    case 'string': return appSetting.settingValue;
                    case 'number': return Number(appSetting.settingValue);
                    case 'boolean': return stringToBool(appSetting.settingValue);
                }
            } catch {
                return getDefaultForType(type);
            }
        }
    }
    return getDefaultForType(type);
}
/**
 * @source https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
 */
export const convertHexToRGBA = (hexCode: string, opacity: number = 1) => {  
    let hex = hexCode.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;   
    }

    return `rgba(${r},${g},${b},${opacity})`;
};

export const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec faucibus molestie nisl, non egestas diam consequat nec. Nullam elementum, risus vel dictum accumsan, neque nibh sagittis urna, vel molestie odio tortor non lorem. Suspendisse potenti. Vestibulum ullamcorper diam et felis venenatis tincidunt. Quisque consectetur orci vitae est dictum, vel efficitur justo rutrum. Donec non condimentum ante, et ultrices ante. Aenean ut lorem eleifend, lobortis odio auctor, pellentesque lectus. Sed scelerisque dui ut massa dictum dapibus. Vivamus dictum leo et orci pulvinar, a posuere arcu maximus. Nam id feugiat nulla. Aenean turpis tortor, placerat vel porta ac, malesuada vel enim. Nam et erat ipsum. Sed quis tempor erat, sit amet aliquam tellus. Vestibulum dictum finibus mi, ut sollicitudin lacus tristique at. Cras risus sapien, congue quis orci eget, feugiat vulputate felis.';

type CountryOption = 'XX' | '🌐' | '|' | '...' | '…' | Country;
export const defaultCountryOptionsOrder: CountryOption[] = ['US', 'CA', 'GB', 'FR', 'DE', 'NL', '|', '...'];
export const defaultFlagUrl = '/images/flags/{XX}.svg';