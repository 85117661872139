import { useState } from 'react';
import { Alert, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    faExchangeAlt, faKey, faMobileScreen, faSignOutAlt, faTriangleExclamation, faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { signOut } from '../../app/store/accountSlice';
import { clearClient } from '../../app/store/clientSlice';
import ModalEditMFAPhone from '../../features/common/ModalEditMFAPhone';
import { BsModalConfirm } from '../components/BsModal';
import { UserType } from '../enums/userType';
import { getMaintenanceState } from '../models/responseHelpers/maintenanceDto';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';
import { clearTestSession } from '../store/testSlice';
import { getAppSettingVal, pathFromUserType } from '../utility/util';
import { SettingNames } from '../enums/settingNames';

export default function UserMenu() {
    const { t } = useTranslation();
    const { pathname }  = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, maintenanceInfo } = useAppSelector(state => state.account);
    const { testSession } = useAppSelector(state => state.test);
    const { clientSession } = useAppSelector(state => state.clientSession);
    const appSettings = useAppSelector(state => state.account.appSettings);
    const mfaEnabled = getAppSettingVal(appSettings, SettingNames.EnableMFA, 'boolean') as boolean;
    const isInTest = (pathname.toLowerCase().includes('achiever') || pathname.toLowerCase().includes('dpat')) && testSession !== null && testSession.pageNumber > 0;
    const [showConfirmLogoff, setShowConfirmLogoff] = useState(false);
    const [showEditMFA, setShowEditMFA] = useState(false);
    const maintState = getMaintenanceState(maintenanceInfo);

    return (
        <>
            <Dropdown className="dropdown-expand">
                <Dropdown.Toggle
                    variant="outline-eggplant"
                    id="userMenuButton"
                    className="rounded-circle caret-off m-0 p-0 lh-1"
                    title={t('IDS_MENU')}
                >
                    <span className="fa-layers fa-fw fa-2x">
                        <FontAwesomeIcon icon={faUserCircle} />
                        {maintState.inDisplayRange &&
                            <span
                                className="fa-layers-counter fa-layers-counter-warning fw-bold"
                                style={{ transform: 'scale(.375)', right: '-0.33rem', top: '-0.33rem' }}
                            >
                                !
                            </span>
                        }
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu renderOnMount={true} align="end">
                    <div className="text-eggplant py-0 text-nowrap dropdown-header">
                        {user?.username ?? ''}
                    </div>
                    <Dropdown.Item
                        as="button"
                        type="button"
                        className={`text-decoration-underline d-flex align-items-center ${(isInTest ? 'disabled' : '')}`}
                        disabled={isInTest}
                        onClick={() => setShowConfirmLogoff(true)}
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" fixedWidth />{t('IDS_LOGOFF')}
                    </Dropdown.Item>
                    <Dropdown.Item
                        as="button"
                        type="button"
                        className={`text-decoration-underline d-flex align-items-center ${(isInTest ? 'disabled' : '')}`}
                        disabled={isInTest}
                        onClick={() => navigate(`/Auth${pathFromUserType(user?.userType)}/ChangePassword`)}
                    >
                        <FontAwesomeIcon icon={faKey} className="me-2" fixedWidth />{t('IDS_CHANGE_PASSWORD')}
                    </Dropdown.Item>
                    {mfaEnabled &&
                        <Dropdown.Item
                            as="button"
                            type="button"
                            className={`text-decoration-underline d-flex align-items-center ${(isInTest ? 'disabled' : '')}`}
                            disabled={isInTest}
                            onClick={() => setShowEditMFA(true)}
                        >
                            <FontAwesomeIcon icon={faMobileScreen} className="me-2" fixedWidth />{t('IDS_EDIT_MFA_PHONE')}
                        </Dropdown.Item>
                    }
                    {user && user.userType === UserType.Client &&
                        <>
                            <Dropdown.Divider className="text-light-eggplant" />
                            <div className="text-eggplant py-0 text-nowrap dropdown-header">
                                {`${t('IDS_ACCOUNT')} ${clientSession?.vwClient.clientNumber}:`}
                            </div>
                            <div className="text-eggplant py-0 text-nowrap fst-italic dropdown-header">
                                {clientSession?.vwClient.name}
                            </div>
                            {user.casObject?.casAccountBC != null && user.casObject.casAccountBC.length > 1 &&
                                <Dropdown.Item
                                    as="button"
                                    type="button"
                                    className="text-decoration-underline d-flex align-items-center"
                                    onClick={() => navigate('/AuthClient/SelectClient')}
                                >
                                    <FontAwesomeIcon icon={faExchangeAlt} className="me-2" fixedWidth />{t('IDS_CHANGE_ACCOUNT')}
                                </Dropdown.Item>
                            }
                        </>
                    }
                    {maintState.inDisplayRange &&
                        <Alert variant="warning" className="d-flex align-items-center max-w-fit-content m-0 p-2">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" size="lg" />
                            <div className="smaller fw-bold" dangerouslySetInnerHTML={{ __html: maintState.parsedMsg }}></div>
                        </Alert>
                    }

                </Dropdown.Menu>
            </Dropdown>
            <BsModalConfirm
                title={t('IDS_CONFIRM_LOGOUT')}
                message={t('IDS_CONFIRM_LOGOUT_MSG')}
                showModal={showConfirmLogoff}
                setShowModal={setShowConfirmLogoff}
                confirmCallback={() => {
                    if (user?.userType === UserType.Client) {
                        dispatch(clearClient());
                    } else if (user?.userType === UserType.Applicant) {
                        dispatch(clearTestSession());
                    }
                    dispatch(signOut());
                }}
            />
            {mfaEnabled &&
                <ModalEditMFAPhone showModal={showEditMFA} setShowModal={setShowEditMFA} />
            }
        </>
    );
}
