import { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Modal, Table } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faCheckCircle, faClone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import { BsAlertDismissible } from '../../../app/components/BsAlertDismissible';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import LoadingButton from '../../../app/components/LoadingButton';
import RangeSlider from '../../../app/components/RangeSlider';
import { ModalRecordMode } from '../../../app/enums/modalRecordMode';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { TblPositionProfile } from '../../../app/models/dbTables/tblPositionProfile';
import { PositionProfileRequest } from '../../../app/models/requestHelpers/positionProfileRequest';
import { useAppSelector } from '../../../app/store/storeHooks';
import { transType } from '../../../i18next';

interface Props {
    mode: ModalRecordMode;
    showModal: boolean;
    positionProfileId: number;
    setShowModal: (show: boolean) => void;
    setPositionProfileId: (id: number) => void;
    setModalProfileMode: (mode: ModalRecordMode) => void;
    changeCount: number;
    setChangeCount: (count: number) => void;
}

export default function ModalPositionProfile({ showModal, setShowModal, mode, positionProfileId, setModalProfileMode, setPositionProfileId, changeCount, setChangeCount }: Props) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const [loading, setLoading] = useState(false);
    const { formState: { isValid, isSubmitting, isDirty }, register, handleSubmit, control, reset, setValue } = useForm<PositionProfileRequest>({
        mode: 'all',
        resolver: yupResolver(yup.object({
            name: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_PROFILE_NAME'] })
        }))
    });

    const aptitudeItems = [
        { id: 'a1', i18nKey: 'IDS_MENTAL_ALERTNESS' },
        { id: 'a2', i18nKey: 'IDS_BUSINESS_TERMS' },
        { id: 'a3', i18nKey: 'IDS_MEMORY_RECALL' },
        { id: 'a4', i18nKey: 'IDS_VOCABULARY' },
        { id: 'a5', i18nKey: 'IDS_PERCEPTION' },
        { id: 'a6', i18nKey: 'IDS_MECHANICAL_INTEREST' },
        { id: 'd1', i18nKey: 'IDS_ENERGY' },
        { id: 'd2', i18nKey: 'IDS_CHARACTER_STRENGTH' },
        { id: 'd3', i18nKey: 'IDS_WORK_HABITS' },
        { id: 'd4', i18nKey: 'IDS_COMMUNICATION' },
        { id: 'd5', i18nKey: 'IDS_EMOTIONAL_MAT' },
        { id: 'd6', i18nKey: 'IDS_DOMINANCE' },
        { id: 'd7', i18nKey: 'IDS_COMPETITIVENESS' },
        { id: 'd8', i18nKey: 'IDS_MENTAL_TOUGHNESS' },
        { id: 'd9', i18nKey: 'IDS_OPTIMISIM' },
        { id: 'd10', i18nKey: 'IDS_WORK_MOTIVATION' },
        { id: 'v1', i18nKey: 'IDS_DISTORTION' },
        { id: 'v2', i18nKey: 'IDS_EQUIVOCATION' }
    ];

    useEffect(() => {
        const fetchPositionProfile = async () => {
            try {
                if (clientId) {
                    let positionProfile: TblPositionProfile | null = null;
                    if (showModal && positionProfileId) {
                        setLoading(true);
                        let gridParams: BsGridFetchDataParams<TblPositionProfile> = {
                            pageSize: 1,
                            pageIndex: 0,
                            sortBy: [],
                            filters: [{ id: 'id', value: { vals: [`${positionProfileId}`], condition: 'equals' } }]
                        };
                        let positionProfileQuery = await agent.PositionProfile.getAllPositionProfilesForGrid(gridParams, { clientId });
                        if (positionProfileQuery?.profiles) {
                            positionProfile = positionProfileQuery.profiles[0];
                        }
                    }

                    if (mode === ModalRecordMode.Clone) {
                        setValue('name', '');
                        setValue('positionProfileId', 0);
                    } else {
                        reset({
                            'positionProfileId': positionProfileId,
                            'clientId': clientId,
                            'name': positionProfile?.name ?? '',
                            'a1': positionProfile?.a1 ?? '00',
                            'a2': positionProfile?.a2 ?? '00',
                            'a3': positionProfile?.a3 ?? '00',
                            'a4': positionProfile?.a4 ?? '00',
                            'a5': positionProfile?.a5 ?? '00',
                            'a6': positionProfile?.a6 ?? '00',
                            'd1': positionProfile?.d1 ?? '00',
                            'd2': positionProfile?.d2 ?? '00',
                            'd3': positionProfile?.d3 ?? '00',
                            'd4': positionProfile?.d4 ?? '00',
                            'd5': positionProfile?.d5 ?? '00',
                            'd6': positionProfile?.d6 ?? '00',
                            'd7': positionProfile?.d7 ?? '00',
                            'd8': positionProfile?.d8 ?? '00',
                            'd9': positionProfile?.d9 ?? '00',
                            'd10': positionProfile?.d10 ?? '00',
                            'v1': positionProfile?.v1 ?? '00',
                            'v2': positionProfile?.v2 ?? '00',
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                setShowModal(false);
            } finally {
                setLoading(false);
            }
        }
        fetchPositionProfile();
    }, [reset, showModal, setShowModal, mode, clientId, positionProfileId, setValue]);

    async function submitForm(data: PositionProfileRequest) {
        try {
            if (mode === ModalRecordMode.Edit) {
                await agent.PositionProfile.updatePositionProfile(data);
            } else if (mode === ModalRecordMode.Add || mode === ModalRecordMode.Clone) {
                await agent.PositionProfile.addPositionProfile(data);
            }

            setShowModal(false);
            setChangeCount(changeCount + 1);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    if (loading)
        return <LoadingComponent />
    else
        return (
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalProfile"
                centered={true}
                backdrop="static"
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>{t(mode === ModalRecordMode.Add || mode === ModalRecordMode.Clone ? 'IDS_ADD_PROFILE' : 'IDS_PROFILE')}</Modal.Title>
                    <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Form id="formPositionProfiles" onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                        <BsAlertDismissible
                            btnText={t('IDS_INSTRUCTIONS')}
                            message={t(mode === ModalRecordMode.View ? 'IDS_POS_PROFILE_INSTRUCT_3' : 'IDS_POS_PROFILE_INSTRUCT_2')}
                            headerText={t('IDS_INSTRUCTIONS')}
                            variant="dark-ocean"
                        />

                        <FloatingLabel
                            controlId="profileName"
                            className="small"
                            label={t('IDS_PROFILE_NAME')}
                        >
                            <Form.Control
                                type="text"
                                readOnly={mode === ModalRecordMode.View}
                                required
                                {...register('name')}
                            />
                        </FloatingLabel>

                        <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive mt-3">
                            <Table striped borderless hover variant="dark-eggplant" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>{t('IDS_APTITUDE')}</th>
                                        <th colSpan={2} className="text-center">{t('IDS_STANINE')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aptitudeItems.map((item) => (
                                        <tr key={item.id}>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                {t(item.i18nKey as keyof transType)}
                                            </td>
                                            <td>
                                                <Controller
                                                    name={item.id as keyof PositionProfileRequest}
                                                    control={control}
                                                    render={({ field }) =>
                                                        <RangeSlider
                                                            max={9}
                                                            min={0}
                                                            step={1}
                                                            vals={(field?.value as string).split('').map(Number) ?? []}
                                                            disabled={mode === ModalRecordMode.View}
                                                            {...field}
                                                            ref={null}
                                                            ctlOnChange={(vals: number[]) => {
                                                                field.onChange(vals.join(''));
                                                            }} />
                                                    }
                                                />
                                            </td>
                                            <td className="text-center">
                                                <Button
                                                    variant="link"
                                                    className="link-dark-ocean py-0 fs-inherit"
                                                    {...(mode === ModalRecordMode.View
                                                        ? { disabled: true }
                                                        : { onClick: () => setValue((item.id as keyof PositionProfileRequest), '00', { shouldTouch: true }) }
                                                    )}
                                                >
                                                    {t('IDS_CLEAR')}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    {(mode === ModalRecordMode.Edit || mode === ModalRecordMode.View) &&
                                        <tr>
                                            <td colSpan={3} className="pt-4">
                                                <Button
                                                    variant="link"
                                                    className="link-dark-ocean"
                                                    onClick={() => {
                                                        setPositionProfileId(0);
                                                        setModalProfileMode(ModalRecordMode.Clone);
                                                    }}
                                                >
                                                    {t('IDS_COPY')}<FontAwesomeIcon icon={faClone} className="ms-2" />
                                                </Button>
                                            </td>
                                        </tr>
                                    }
                                </tfoot>
                            </Table>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="silver" onClick={() => setShowModal(false)}>
                        <FontAwesomeIcon icon={faTimes} className="me-2" />{t(mode === ModalRecordMode.View ? 'IDS_CLOSE' : 'IDS_CANCEL')}
                    </Button>
                    {mode !== ModalRecordMode.View &&
                        <LoadingButton
                            form="formPositionProfiles"
                            type="submit"
                            variant="dark-ocean"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || !isDirty || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t(mode === ModalRecordMode.Add || mode === ModalRecordMode.Clone ? 'IDS_ADD' : 'IDS_UPDATE')}
                        </LoadingButton>
                    }
                </Modal.Footer>
            </Modal>
        );
}