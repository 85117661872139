import { QuestionType } from '../../../enums/testQuestionType';
import { AchieverSectionId } from '../../../enums/testSections';
import { SectionBase } from '../sectionBase';
import { AchieverQuestion } from './achieverQuestion';

export interface AchieverSection extends SectionBase<AchieverSectionId, AchieverQuestion> { }

const getResponses = (section: AchieverSection) => {
    let chResp: string[] = new Array(section.questions.length);
    let nResp = 0;

    for (let cQuest of section.questions) {
        switch (cQuest.questionType) {
            case QuestionType.MultipleChoice:
            case QuestionType.YesNoMaybe:
                chResp[nResp] = String.fromCharCode(cQuest.response + 0x30);
                break;
            case QuestionType.YesNo:
                chResp[nResp] = (cQuest.response === 0) ? 'N' : 'Y';
                break;
        }
        nResp++;
    }
    return chResp.join('');
}

export const achieverSectionMethods = {
    getResponses
};