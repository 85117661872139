import { FieldValues } from 'react-hook-form';

import { locales } from '../../../i18n';

export interface ClientSetupDefaultQuestionsRequest {
    languageCode: string;
    defaultQuestions: DefaultQuestionRequest[];
}

export interface DefaultQuestionRequest {
    typeId: number;
    question: string;
    literalA: string;
    literalB: string;
    literalC: string;
    literalD: string;
    literalE: string;
    defaultQuestionId: number;
}

export function formDataToClientSetupDefaultQuestionsRequest(formData: FieldValues) {
    const supportedLanguages = Object.keys(locales);
    let defaultQuestionsRequests: ClientSetupDefaultQuestionsRequest[] = [];

    for (let lang of supportedLanguages) {
        if (formData[`defaultQuestions-${lang}`]) {
            defaultQuestionsRequests.push({
                languageCode: lang,
                defaultQuestions: formData[`defaultQuestions-${lang}`]
            });
        }
    }

    return defaultQuestionsRequests;
}