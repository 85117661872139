import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';

import { FieldValuesAppEEOData } from '../../../app/models/fieldValueTypes';
import { PREFER_NOT_TO_SAY, OTHER, OTHER_ASIAN_BG, OTHER_BLACK_BG, OTHER_MIXED_BG, OTHER_WHITE_BG } from '../../../app/models/responseHelpers/applicantEeoDataDto';
import { useAppSelector } from '../../../app/store/storeHooks';
import { PropsApplicationTabs } from './shared/applicationTabsProps';

const TITLE = 'Equal Opportunities Monitoring Form';
const DISCLAIMER_P1 = 'Reynolds and Reynolds Ltd selects candidates solely on the basis of merit; it is committed ' +
    'to equality of opportunity for all job applicants regardless of age, disability, gender reassignment, marriage ' +
    '& civil partnership, pregnancy & maternity, race, religion or belief, sex and sexual orientation.';
const DISCLAIMER_P2 = 'The data gathered will help us to monitor the effectiveness of our equal opportunities policies ' +
    'and procedures. It should be completed anonymously and will be held and processed separately from your application.';
const DISCLAIMER_P3 = 'This information will have no impact on the final recruitment decision.';

const ages = ['16-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60+', PREFER_NOT_TO_SAY];
const genders = ['Male', 'Female', 'Non-binary', 'Intersex', 'Transgender', PREFER_NOT_TO_SAY];
const maritalStatuses = ['Married', 'In a registered civil partnership', 'Not married / in a civil partnership', 'Separated', 'Divorced', 'Widowed', PREFER_NOT_TO_SAY];

const ethnicGroups = [
    { key: 'asian', label: 'Asian / Asian British', otherLabel: OTHER_ASIAN_BG, options: ['Bangladeshi', 'Chinese', 'Indian', 'Pakistani', 'Any Other Asian Background'] },
    { key: 'black', label: 'Black / Black British', otherLabel: OTHER_BLACK_BG, options: ['African', 'Caribbean', 'Any Other Black background'] },
    { key: 'mixed', label: 'Mixed Ethnic Group', otherLabel: OTHER_MIXED_BG, options: ['White & Asian', 'White & Black African', 'White & Black Caribbean', 'Any Other Mixed Background'] },
    { key: 'white', label: 'White', otherLabel: OTHER_WHITE_BG, options: ['British', 'Irish', 'Scottish', 'Northern Irish', 'Welsh', 'English', 'Any Other White Background'] }
];
const ethnicGroupsOthers = [OTHER_ASIAN_BG, OTHER_BLACK_BG, OTHER_MIXED_BG, OTHER_WHITE_BG];

const sexualOrientations = ['Bisexual', 'Homosexual / Gay / Lesbian', 'Heterosexual', PREFER_NOT_TO_SAY];
const religions = ['Buddhist', 'Christian', 'Hindu', 'Jewish', 'Muslim', 'No religion', 'Sikh', PREFER_NOT_TO_SAY];

export default function TabUKDMData({ setFormState }: PropsApplicationTabs<FieldValuesAppEEOData>) {
    const {
        applicant: { applicantId }, eeoData
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));

    const formMethods = useForm<FieldValuesAppEEOData>({
        mode: 'all',
        defaultValues: {
            applicantId,
            countryCode: 'en-GB',
            gender: genders.includes(eeoData?.gender || PREFER_NOT_TO_SAY) ? (eeoData?.gender || PREFER_NOT_TO_SAY) : OTHER,
            ethnicOrigin: eeoData?.ethnicOrigin ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin)) ? eeoData?.ethnicOrigin : OTHER) : PREFER_NOT_TO_SAY,
            sexualOrientation: sexualOrientations.includes(eeoData?.sexualOrientation || PREFER_NOT_TO_SAY) ? (eeoData?.sexualOrientation || PREFER_NOT_TO_SAY) : OTHER,
            religionOrBelief: religions.includes(eeoData?.religionOrBelief || PREFER_NOT_TO_SAY) ? (eeoData?.religionOrBelief || PREFER_NOT_TO_SAY) : OTHER,
            disability: eeoData?.disability || PREFER_NOT_TO_SAY,
            disabilityDetails: eeoData?.disabilityDetails || '',
            dateOfBirth: eeoData?.dateOfBirth || '',
            positionAppliedFor: eeoData?.positionAppliedFor || '',
            age: eeoData?.age || PREFER_NOT_TO_SAY,
            maritalStatus: eeoData?.maritalStatus || PREFER_NOT_TO_SAY,
            genderOther: genders.includes(eeoData?.gender || PREFER_NOT_TO_SAY) ? '' : eeoData?.gender,
            ethnicOriginOther: eeoData?.ethnicOrigin
                ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin)) || ethnicGroupsOthers.some(x => eeoData.ethnicOrigin.startsWith(x))
                    ? ''
                    : eeoData?.ethnicOrigin)
                : '',
            ethnicOriginOther_asian: eeoData?.ethnicOrigin
                ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin))
                    ? ''
                    : (eeoData.ethnicOrigin.startsWith(OTHER_ASIAN_BG) ? eeoData?.ethnicOrigin : ''))
                : '',
            ethnicOriginOther_black: eeoData?.ethnicOrigin
                ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin))
                    ? ''
                    : (eeoData.ethnicOrigin.startsWith(OTHER_BLACK_BG) ? eeoData?.ethnicOrigin : ''))
                : '',
            ethnicOriginOther_mixed: eeoData?.ethnicOrigin
                ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin))
                    ? ''
                    : (eeoData.ethnicOrigin.startsWith(OTHER_MIXED_BG) ? eeoData?.ethnicOrigin : ''))
                : '',
            ethnicOriginOther_white: eeoData?.ethnicOrigin
                ? (ethnicGroups.some(x => x.options.includes(eeoData?.ethnicOrigin))
                    ? ''
                    : (eeoData.ethnicOrigin.startsWith(OTHER_WHITE_BG) ? eeoData?.ethnicOrigin : ''))
                : '',
            sexualOrientationOther: sexualOrientations.includes(eeoData?.sexualOrientation || PREFER_NOT_TO_SAY) ? '' : eeoData?.sexualOrientation,
            religionOrBeliefOther: religions.includes(eeoData?.religionOrBelief || PREFER_NOT_TO_SAY) ? '' : eeoData?.religionOrBelief
        }
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful }, handleSubmit, getValues, register, watch } = formMethods;

    const watchGender = watch('gender') as string;
    const watchEthnicOrigin = watch('ethnicOrigin') as string;
    const watchSexualOrientation = watch('sexualOrientation') as string;
    const watchReligionOrBelief = watch('religionOrBelief') as string;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    return (
        <div>
            <Form id="form-ukdmData" className="needs-validation" noValidate>
                <h4 className="mb-3 fw-bold">{TITLE}</h4>
                <p className="small">{DISCLAIMER_P1}</p>
                <p className="small">{DISCLAIMER_P2}</p>
                <p className="small fw-bold">{DISCLAIMER_P3}</p>

                <Form.Group as={Row} className="mb-3" controlId="inputUkdmDataPosition">
                    <Form.Label column="sm" xs="auto">
                        Position Applied For
                    </Form.Label>
                    <Col>
                        <Form.Control
                            className="form-control-xs"
                            maxLength={25}
                            {...register('positionAppliedFor')}
                        />
                    </Col>
                </Form.Group>

                <p className="small">In each section listed below, please choose one option by checking the appropriate box.</p>

                <Row className="mb-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Age</legend>
                            {
                                ages.map((age, ageIdx) => (
                                    <Form.Check
                                        inline
                                        className="check-group-item"
                                        key={ageIdx}
                                        id={`ukdmDataAge_${ageIdx}`}
                                        type="radio"
                                        label={age}
                                        {...register('age')}
                                        value={age}
                                    />
                                ))
                            }
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Disability</legend>
                            <p className="small">
                                The Equality Act 2010 defines a disability as a physical or mental impairment that has a substantial and long-term adverse effect on an individual's ability to carry out normal day-to-day activities.
                            </p>
                            <Form.Label className="small" htmlFor="divUkdmDataDisability">
                                Do you consider that you have a disability?
                            </Form.Label>
                            <div id="divUkdmDataDisability">
                                {
                                    ['Yes', 'No', PREFER_NOT_TO_SAY].map((text, idx) => (
                                        <Form.Check
                                            inline
                                            className="check-group-item"
                                            key={idx}
                                            id={`ukdmDataDisability_${idx}`}
                                            type="radio"
                                            label={text}
                                            {...register('disability')}
                                            value={text}
                                        />
                                    ))
                                }
                            </div>
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mb-3 g-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Gender</legend>
                            {
                                genders.map((text, idx) => (
                                    <Form.Check
                                        inline
                                        className="check-group-item"
                                        key={idx}
                                        id={`ukdmDataGender_${idx}`}
                                        type="radio"
                                        label={text}
                                        {...register('gender')}
                                        value={text}
                                    />
                                ))
                            }
                            <div className="d-flex">
                                <Form.Check
                                    inline
                                    className="check-group-item text-nowrap"
                                    id="ukdmDataGender_other"
                                    type="radio"
                                    label="Other (please specify):"
                                    {...register('gender')}
                                    value={OTHER}
                                />
                                <Form.Control
                                    className="form-control-xs w-100"
                                    maxLength={25}
                                    readOnly={watchGender !== OTHER}
                                    {...register('genderOther')}
                                />
                            </div>

                        </fieldset>
                    </Col>
                </Row>


                <Row className="mb-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Marital or Civil Partnership Status</legend>
                            {
                                maritalStatuses.map((maritalStatus, maritalStatusIdx) => (
                                    <Form.Check
                                        inline
                                        className="check-group-item"
                                        key={maritalStatusIdx}
                                        id={`ukdmDataAge_${maritalStatusIdx}`}
                                        type="radio"
                                        label={maritalStatus}
                                        {...register('maritalStatus')}
                                        value={maritalStatus}
                                    />
                                ))
                            }
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Ethnic Group</legend>
                            {ethnicGroups.map((group) => (
                                <div key={group.key} className="mb-3">
                                    <Form.Label className="smallish fst-italic fw-bold" htmlFor={`divUkdmDataEthnicOrigin_${group.key}`}>{group.label}</Form.Label>
                                    <div id={`divUkdmDataEthnicOrigin_${group.key}`}>
                                        {group.options.map((option, optionIdx) => (
                                            <Form.Check
                                                inline
                                                className="check-group-item"
                                                key={optionIdx}
                                                id={`ukdmDataEthnicOrigin_${group.key}_${optionIdx}`}
                                                type="radio"
                                                label={option}
                                                {...register('ethnicOrigin')}
                                                value={option}
                                            />
                                        ))}
                                        <div className="d-flex">
                                            <Form.Check
                                                inline
                                                className="check-group-item text-nowrap"
                                                id={`ukdmDataEthnicOrigin_${group.key}_other`}
                                                type="radio"
                                                label={`${group.otherLabel} (please specify):`}
                                                {...register('ethnicOrigin')}
                                                value={`${OTHER}-${group.key}`}
                                            />
                                            <Form.Control
                                                className="form-control-xs w-100"
                                                maxLength={25}
                                                readOnly={watchEthnicOrigin !== `${OTHER}-${group.key}`}
                                                {...register(`ethnicOriginOther_${group.key}` as keyof FieldValuesAppEEOData)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex">
                                <Form.Check
                                    inline
                                    className="check-group-item text-nowrap"
                                    id={`ukdmDataEthnicOrigin_other`}
                                    type="radio"
                                    label="Other ethnic group (please specify):"
                                    {...register('ethnicOrigin')}
                                    value={OTHER}
                                />
                                <Form.Control
                                    className="form-control-xs w-100"
                                    maxLength={25}
                                    readOnly={watchEthnicOrigin !== OTHER}
                                    {...register('ethnicOriginOther')}
                                />
                            </div>
                            <Form.Check
                                inline
                                className="check-group-item"
                                id="ukdmDataEthnicOrigin_preferNotToSay"
                                type="radio"
                                label={PREFER_NOT_TO_SAY}
                                {...register('ethnicOrigin')}
                                value={PREFER_NOT_TO_SAY}
                            />
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mb-3 g-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Sexual Orientation</legend>
                            {
                                sexualOrientations.map((text, idx) => (
                                    <Form.Check
                                        inline
                                        className="check-group-item"
                                        key={idx}
                                        id={`ukdmDataSexualOrientation_${idx}`}
                                        type="radio"
                                        label={text}
                                        {...register('sexualOrientation')}
                                        value={text}
                                    />
                                ))
                            }
                            <div className="d-flex">
                                <Form.Check
                                    inline
                                    className="check-group-item text-nowrap"
                                    id={`ukdmDataSexualOrientation_other`}
                                    type="radio"
                                    label="Other (please specify):"
                                    {...register('sexualOrientation')}
                                    value={OTHER}
                                />
                                <Form.Control
                                    className="form-control-xs w-100"
                                    maxLength={25}
                                    readOnly={watchSexualOrientation !== OTHER}
                                    {...register('sexualOrientationOther')}
                                />
                            </div>
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mb-3 g-3">
                    <Col>
                        <fieldset className="border rounded border-2 border-light-eggplant px-2 pb-2">
                            <legend className="w-auto small fw-bold mb-0 float-none">Religion or Belief</legend>
                            {
                                religions.map((text, idx) => (
                                    <Form.Check
                                        inline
                                        className="check-group-item"
                                        key={idx}
                                        id={`ukdmDataReligionOrBelief_${idx}`}
                                        type="radio"
                                        label={text}
                                        {...register('religionOrBelief')}
                                        value={text}
                                    />
                                ))
                            }
                            <div className="d-flex">
                                <Form.Check
                                    inline
                                    className="check-group-item text-nowrap"
                                    id={`ukdmDataReligionOrBelief_other`}
                                    type="radio"
                                    label="Other (please specify):"
                                    {...register('religionOrBelief')}
                                    value={OTHER}
                                />
                                <Form.Control
                                    className="form-control-xs w-100"
                                    maxLength={25}
                                    readOnly={watchReligionOrBelief !== OTHER}
                                    {...register('religionOrBeliefOther')}
                                />
                            </div>
                        </fieldset>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}