import { useCallback, useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useSearchParams } from 'react-router-dom';

import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import ChangePasswordPage from './ChangePasswordPage';

export default function SetPasswordWrapper() {
    const mounted = useRef(false);
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get('token');
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [username, setUsername] = useState('');

    const validateToken = useCallback(async () => {
        if (!executeRecaptcha) return;
        try {
            if (mounted.current && !username) {
                let uname = '';
                if (token) {
                    const recaptchaToken = await executeRecaptcha('checkPasswordResetToken');
                    sessionStorage.setItem('reCaptchaToken', recaptchaToken);
                    uname = await agent.UserAccount.checkPasswordResetToken({ token });
                    if (!uname) {
                        navigate('/AuthApplicant');
                    }
                } else {
                    navigate('/AuthApplicant');
                }

                if (mounted.current) {
                    setUsername(uname);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [executeRecaptcha, navigate, token, username]);

    useEffect(() => {
        mounted.current = true;
        validateToken();

        return () => { mounted.current = false; };
    }, [validateToken]);

    if (!username || !executeRecaptcha)
        return <LoadingComponent />
    else
        return (<ChangePasswordPage isSetPassword={true} setPasswordUsername={username} />);
}