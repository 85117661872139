import { Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { faFileAlt, faListAlt, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppSelector } from '../../../app/store/storeHooks';
import { getTitle } from '../../../i18n';

export default function HomePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { clientSession } = useAppSelector(state => state.clientSession);

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_HOME')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_HOME')} - ${clientSession?.vwClient.name}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <p className="small">{t('IDS_CLIENT_ADMIN_P1')}</p>
                <p className="small mb-3">{t('IDS_CLIENT_ADMIN_P1_2')}</p>
                <Row xs={1} md={3} className="g-4">
                    {clientSession?.updateClientSetup &&
                        <Col>
                            <Card className="h-100 small" onClick={() => navigate('/Client/Setup')}>
                                <Card.Header className="bg-dark-ocean text-white text-center">
                                    <FontAwesomeIcon icon={faSlidersH} className="me-2" />{t('IDS_SETUP')}
                                </Card.Header>
                                <Card.Body className="p-4">
                                    <Card.Text className="lh-lg">{t('IDS_CLIENT_ADMIN_P2')}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    <Col>
                        <Card className="h-100 small text-decoration-none" onClick={() => navigate('/Client/Applicants')}>
                            <Card.Header className="bg-dark-ocean text-white text-center">
                                <FontAwesomeIcon icon={faFileAlt} className="me-2" />{t('IDS_APPLICANTS')}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Card.Text className="lh-lg">{t('IDS_CLIENT_ADMIN_P3')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {clientSession?.maintainPositions &&
                        <Col>
                            <Card className="h-100 small text-decoration-none" onClick={() => navigate('/Client/Positions')}>
                                <Card.Header className="bg-dark-ocean text-white text-center">
                                    <FontAwesomeIcon icon={faListAlt} className="me-2" />{t('IDS_POSITIONS')}
                                </Card.Header>
                                <Card.Body className="p-4">
                                    <Card.Text className="lh-lg">{t('IDS_CLIENT_ADMIN_P4')}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                </Row>
            </div>
        </>
    );
}