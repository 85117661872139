import { EduType } from "../../enums/eduType";
import { ApplicantEduDto } from "../responseHelpers/applicantEduDto";

export interface ApplicantEduRequest {
    applicantId: number;
    edus: ApplicantEdu[];
}

export interface ApplicantEdu {
    type: number;
    attended: string;
    schoolName: string;
    degree: string;
    major: string;
    gpa: string;
    notApplicable: boolean;
    clientIndex: number;
}

function getEmptyEdu(clientIndex: number): ApplicantEdu {
    let type: number;
    if (clientIndex === 0) {
        type = EduType.HighSchool;
    } else if (clientIndex === 3) {
        type = EduType.Other;
    } else {
        type = EduType.College;
    }

    return {
        type,
        attended: '',
        schoolName: '',
        degree: '',
        major: '',
        gpa: '',
        notApplicable: false,
        clientIndex,
    };
}

function eduDtoToEduReq(edu: ApplicantEduDto, clientIndex: number): ApplicantEdu {
    return {
        type: edu.type,
        attended: edu.attended,
        schoolName: edu.schoolName,
        degree: edu.degree,
        major: edu.major,
        gpa: edu.gpa,
        notApplicable: edu.notApplicable,
        clientIndex,
    };
}

export function getPaddedEdus(edus: ApplicantEduDto[]): ApplicantEdu[] {
    if (!Array.isArray(edus) || edus.length === 0) {
        return [getEmptyEdu(0), getEmptyEdu(1), getEmptyEdu(2), getEmptyEdu(3)];
    } else {
        let retArr: ApplicantEdu[] = [];
        for (let i = 0; i < 4; i++) {
            retArr.push(edus.length > i ? eduDtoToEduReq(edus[i], i) : getEmptyEdu(i));
        }
        return retArr;
    }
}