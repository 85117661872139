import i18next from 'i18next';
import { cloneDeep } from 'lodash';

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getLangOrDefault, getTz } from '../../i18n';
import agent from '../api/agent';
import { ApplicantTestProgress } from '../enums/applicantTestProgress';
import { UserType } from '../enums/userType';
import { ApplicantSessionDto, currentProgressId, getProgressKey } from '../models/applicantSession';
import { FieldValuesAppEEOData, FieldValuesLogin } from '../models/fieldValueTypes';
import { ApplicantEduRequest } from '../models/requestHelpers/applicantEduRequest';
import { ApplicantExpRequest } from '../models/requestHelpers/applicantExpRequest';
import { ApplicantInfoRequest } from '../models/requestHelpers/applicantInfoRequest';
import { applicantProgressDtoToRequest } from '../models/requestHelpers/applicantProgressRequest';
import { ApplicantQuestionRequest } from '../models/requestHelpers/applicantQuestionRequest';
import { ApplicantRefRequest } from '../models/requestHelpers/applicantRefRequest';
import { ApplicantEduDto } from '../models/responseHelpers/applicantEduDto';
import { NOT_SPECIFIED, OTHER, OTHER_ASIAN_BG, OTHER_BLACK_BG, OTHER_MIXED_BG, OTHER_WHITE_BG, PREFER_NOT_TO_SAY } from '../models/responseHelpers/applicantEeoDataDto';
import { ApplicantExpDto } from '../models/responseHelpers/applicantExpDto';
import { ApplicantQuesDto } from '../models/responseHelpers/applicantQuesDto';
import { ApplicantRefDto } from '../models/responseHelpers/applicantRefDto';
import { MaintenanceDto } from '../models/responseHelpers/maintenanceDto';
import { UserDto } from '../models/responseHelpers/userDto';
import { achieverSectionMethods } from '../models/test/achiever/achieverSection';
import { dpatSectionMethods } from '../models/test/dpat/dpatSection';
import { TimeSpan } from '../models/test/timeSpan';
import History from '../utility/History';
import { pathFromUserType, TimeSpanMinVal } from '../utility/util';

import type { RootState } from './configureStore';
import { SettingDto } from '../models/responseHelpers/settingDto';
interface AccountState {
    user: UserDto | null;
    maintenanceInfo: MaintenanceDto | null;
    appSettings: SettingDto[];
    status: string;
}

const initialState: AccountState = {
    user: null,
    maintenanceInfo: null,
    appSettings: [],
    status: 'idle'
}

export const signInUser = createAsyncThunk<UserDto, FieldValuesLogin>(
    'account/signInUser',
    async (data, thunkAPI) => {
        try {
            let userType = data.userType as UserType;
            if (userType !== UserType.Client && userType !== UserType.Applicant && userType !== UserType.Admin) {
                throw new Error('UserType not valid.');
            }

            sessionStorage.removeItem('user');
            sessionStorage.removeItem('casObject');
            sessionStorage.removeItem('applicantSessionDto');
            
            const user = await agent.UserAccount.login(data);
            if (userType === UserType.Client) {
                sessionStorage.setItem('casObject', JSON.stringify(user.casObject));
            } else if (userType === UserType.Applicant) {
                sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const fetchCurrentUser = createAsyncThunk<UserDto>(
    'account/fetchCurrentUser',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setUser(JSON.parse(sessionStorage.getItem('user')!)));
        try {
            let rootState = thunkAPI.getState() as RootState;
            let { twoFactorAuthVerified, forcePasswordReset, forceLanguageSelect } = cloneDeep(rootState.account.user || {} as UserDto);

            const currentUser = await agent.UserAccount.currentUser();
            let user = { ...currentUser, twoFactorAuthVerified, forcePasswordReset, forceLanguageSelect };
            sessionStorage.setItem('user', JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    },
    {
        condition: () => {
            if (!sessionStorage.getItem('user')) return false;
        }
    }
);

export const fetchMaintenanceInfo = createAsyncThunk<MaintenanceDto>(
    'account/fetchMaintenanceInfo',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setMaintInfo(JSON.parse(sessionStorage.getItem('maintDto')!)));
        try {
            const maintenanceDto = await agent.Maintenance.getMaintenanceInfo();
            sessionStorage.setItem('maintDto', JSON.stringify(maintenanceDto));
            return maintenanceDto;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const fetchAppSettings = createAsyncThunk<SettingDto[]>(
    'account/fetchAppSettings',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setAppSettings(JSON.parse(sessionStorage.getItem('settingDtos')!)));
        try {
            const settingDtos = await agent.AppSettings.getAppSettings();
            sessionStorage.setItem('settingDtos', JSON.stringify(settingDtos));
            return settingDtos;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantInfo = createAsyncThunk<UserDto, ApplicantInfoRequest>(
    'account/addApplicantInfo',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            await agent.ApplicantInfo.addApplicantInfo(data);

            if (user.applicantSessionDto) {
                user.applicantSessionDto.applicantInfo.firstName = data.firstName;
                user.applicantSessionDto.applicantInfo.lastName = data.lastName;
                user.applicantSessionDto.applicant.positionTitle = data.positionTitle;
                user.applicantSessionDto.applicantInfo.address1 = data.address1;
                user.applicantSessionDto.applicantInfo.address2 = data.address2;
                user.applicantSessionDto.applicantInfo.city = data.city;
                user.applicantSessionDto.applicantInfo.state = data.state;
                user.applicantSessionDto.applicantInfo.zip = data.zip;
                user.applicantSessionDto.applicantInfo.dayPhone = data.dayPhone;
                user.applicantSessionDto.applicantInfo.eveningPhone = data.eveningPhone;
                user.applicantSessionDto.applicantInfo.mobilePhone = data.mobilePhone;
                user.applicantSessionDto.applicantInfo.email = data.email;
                user.applicantSessionDto.applicantInfo.referrer = data.referrer;
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantExperience = createAsyncThunk<UserDto, ApplicantExpRequest>(
    'account/addApplicantExperience',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            await agent.ApplicantExp.addApplicantExperience(data);

            if (user.applicantSessionDto) {
                let exps: ApplicantExpDto[] = [];
                for (let exp of data.experiences) {
                    exps.push({
                        applicantId: data.applicantId,
                        companyName: exp.companyName,
                        title: exp.jobTitle,
                        stillEmployed: exp.stillEmployed,
                        city: exp.city,
                        state: exp.state,
                        start: exp.startDate ? (new Date(exp.startDate)).toISOString() : '',
                        end: exp.endDate ? (new Date(exp.endDate)).toISOString() : '',
                        comment: exp.comment,
                        description: exp.description,
                        salaryStart: exp.salaryStart,
                        salaryEnd: exp.salaryEnd,
                        notApplicable: exp.noPreviousExp
                    });
                }
                user.applicantSessionDto.experiences = exps;
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantEducation = createAsyncThunk<UserDto, ApplicantEduRequest>(
    'account/addApplicantEducation',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            await agent.ApplicantEdu.addApplicantEdu(data);

            if (user.applicantSessionDto) {
                let edus: ApplicantEduDto[] = [];
                for (let edu of data.edus) {
                    edus.push({
                        applicantId: data.applicantId,
                        type: edu.type,
                        attended: edu.attended,
                        schoolName: edu.schoolName,
                        degree: edu.degree,
                        major: edu.major,
                        gpa: edu.gpa,
                        notApplicable: edu.notApplicable
                    });
                }
                user.applicantSessionDto.education = edus;
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantReference = createAsyncThunk<UserDto, ApplicantRefRequest>(
    'account/addApplicantReference',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            await agent.ApplicantRef.addApplicantReference(data);

            if (user.applicantSessionDto) {
                let refs: ApplicantRefDto[] = [];
                for (let ref of data.references) {
                    refs.push({
                        applicantId: data.applicantId,
                        fullName: ref.fullName,
                        company: ref.company,
                        title: ref.title,
                        phone: ref.phone,
                        email: ref.email
                    });
                }
                user.applicantSessionDto.references = refs;
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantEEOData = createAsyncThunk<UserDto, FieldValuesAppEEOData>(
    'account/addApplicantEEOData',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            if (data.gender === OTHER && data.genderOther) {
                data.gender = data.genderOther;
            }
            if (data.religionOrBelief === OTHER && data.religionOrBeliefOther) {
                data.religionOrBelief = data.religionOrBeliefOther;
            }
            if (data.sexualOrientation === OTHER && data.sexualOrientationOther) {
                data.sexualOrientation = data.sexualOrientationOther;
            }
            if (data.ethnicOrigin === `${OTHER}-asian`) {
                data.ethnicOrigin = `${OTHER_ASIAN_BG}: ${data.ethnicOriginOther_asian}`;
            } else if (data.ethnicOrigin === `${OTHER}-black`) {
                data.ethnicOrigin = `${OTHER_BLACK_BG}: ${data.ethnicOriginOther_black}`;
            } else if (data.ethnicOrigin === `${OTHER}-mixed`) {
                data.ethnicOrigin = `${OTHER_MIXED_BG}: ${data.ethnicOriginOther_mixed}`;
            } else if (data.ethnicOrigin === `${OTHER}-white`) {
                data.ethnicOrigin = `${OTHER_WHITE_BG}: ${data.ethnicOriginOther_white}`;
            } else if (data.ethnicOrigin === OTHER && data.ethnicOriginOther) {
                data.ethnicOrigin = data.ethnicOriginOther;
            }

            await agent.ApplicantEEOData.addApplicantEEOData(data);

            if (user.applicantSessionDto) {
                user.applicantSessionDto.eeoData = {
                    countryCode: data.countryCode,
                    gender: data.gender,
                    race: data.race || NOT_SPECIFIED,
                    ethnicOrigin: data.ethnicOrigin || PREFER_NOT_TO_SAY,
                    disability: data.disability || PREFER_NOT_TO_SAY,
                    disabilityDetails: data.disabilityDetails || '',
                    dateOfBirth: data.dateOfBirth || '',
                    positionAppliedFor: data.positionAppliedFor || '',
                    age: data.age || PREFER_NOT_TO_SAY,
                    maritalStatus: data.maritalStatus || PREFER_NOT_TO_SAY,
                    sexualOrientation: data.sexualOrientation || PREFER_NOT_TO_SAY,
                    religionOrBelief: data.religionOrBelief || PREFER_NOT_TO_SAY
                };
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const addApplicantQuestions = createAsyncThunk<UserDto, ApplicantQuestionRequest>(
    'account/addApplicantQuestions',
    async (data, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);

            await agent.ApplicantQuestion.addApplicantQuestions(data);

            if (user.applicantSessionDto) {
                let questions: ApplicantQuesDto[] = [];
                for (let question of data.applicantQuestions) {
                    questions.push({
                        applicantId: data.applicantId,
                        positionId: data.positionId,
                        answer: question.answer,
                        question: question.question,
                        typeId: question.typeId
                    });
                }
                user.applicantSessionDto.questions = questions;
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const updateApplicantProgress = createAsyncThunk<UserDto, { updateDb: boolean, timeRemaining: number, hasStopped: boolean }>(
    'account/updateApplicantProgress',
    async ({ updateDb, timeRemaining, hasStopped }, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);
            if (user.applicantSessionDto && user.applicantSessionDto.progress && rootState.test.testSession) {
                user.applicantSessionDto.progress.currentPage = rootState.test.testSession.pageNumber;
                if (rootState.test.testSession.sectionId !== undefined) {
                    user.applicantSessionDto.progress.sectionId = rootState.test.testSession.sectionId;
                }
                user.applicantSessionDto.progress.response = getAchieverResponses(rootState);
                user.applicantSessionDto.progress.dpatResponse = getDPATResponses(rootState);
                user.applicantSessionDto.progress.dpatInhouseResponse = getDPATInhResponses(rootState);

                if (user.applicantSessionDto.progress.lastPage < user.applicantSessionDto.progress.currentPage) {
                    user.applicantSessionDto.progress.lastPage = user.applicantSessionDto.progress.currentPage;
                }

                user.applicantSessionDto.progress.remainingTimeValue = timeRemaining;
                let timeElapsed = rootState.test.testSession.isTimed
                    ? TimeSpan.fromTime(0, (rootState.test.testSession?.timeToComplete ?? 0), 0).totalMilliseconds - timeRemaining
                    : TimeSpanMinVal;
                user.applicantSessionDto.progress.clientTimeElapsedValue = timeElapsed;
                user.applicantSessionDto.progress.serverTimeElapsedValue = timeElapsed;

                if (hasStopped) {
                    if (user.applicantSessionDto.progress.progressId >= 7 && user.applicantSessionDto.progress.progressId <= 9) {
                        user.applicantSessionDto.progress.dpatStopCount = user.applicantSessionDto.progress.dpatStopCount + 1;
                    } else if (user.applicantSessionDto.progress.progressId >= 11 && user.applicantSessionDto.progress.progressId <= 13) {
                        user.applicantSessionDto.progress.dpatInhouseStopCount = user.applicantSessionDto.progress.dpatInhouseStopCount + 1;
                    }
                }
                
                if (updateDb) {
                    let updateRequest = applicantProgressDtoToRequest(user.applicantSessionDto.progress, user.applicantSessionDto.clientId,
                        user.applicantSessionDto.applicant.positionId, false, hasStopped);

                    await fetch(`${((window as any).REACT_APP_API_URL as string)}applicantTest/updateApplicantProgress`, {
                        keepalive: true,
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': `Bearer ${user.token}`,
                            'UserLanguage': getLangOrDefault(),
                            'UserTimeZone': getTz(),
                            'UserBrowserAgent': navigator.userAgent,
                            'ClientHostName': window.location.hostname
                        },
                        body: JSON.stringify(updateRequest)
                    });
                }
            }
            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const applicantSessionCompletedSection = createAsyncThunk<UserDto>(
    'account/applicantSessionCompletedSection',
    async (_, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);
            if (user.applicantSessionDto) {
                let dtNow = new Date().toISOString();
                let { progressId } = user.applicantSessionDto.progress;
                if (progressId === ApplicantTestProgress.Application)
                    user.applicantSessionDto.progress.appCompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.Personality)
                    user.applicantSessionDto.progress.test1CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MentalAlertness)
                    user.applicantSessionDto.progress.test2CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.BusinessTerms)
                    user.applicantSessionDto.progress.test3CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.Vocabulary)
                    user.applicantSessionDto.progress.test4CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.Perception)
                    user.applicantSessionDto.progress.test5CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MechanicalInterest)
                    user.applicantSessionDto.progress.test6CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.AlphaSequence)
                    user.applicantSessionDto.progress.dpat1CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.GraphicSequence)
                    user.applicantSessionDto.progress.dpat2CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MathWordProblems)
                    user.applicantSessionDto.progress.dpat3CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.AlphaSequence2)
                    user.applicantSessionDto.progress.dpatInhouse1CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.GraphicSequence2)
                    user.applicantSessionDto.progress.dpatInhouse2CompletedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MathWordProblems2)
                    user.applicantSessionDto.progress.dpatInhouse3CompletedDate = dtNow;
                
                if (progressId !== ApplicantTestProgress.Application &&
                    progressId !== ApplicantTestProgress.MechanicalInterest &&
                    progressId !== ApplicantTestProgress.MathWordProblems &&
                    progressId !== ApplicantTestProgress.MathWordProblems2) {
                    user.applicantSessionDto.progress.progressId++;
                    user.applicantSessionDto.progress.sectionId++;
                    user.applicantSessionDto.progress.progress = getProgressKey(user.applicantSessionDto.progress.progressId);
                } else {
                    let curProgressId = currentProgressId(user.applicantSessionDto.applicant, user.applicantSessionDto.progress);
                    user.applicantSessionDto.progress.progressId = curProgressId;
                    user.applicantSessionDto.progress.sectionId = 0;
                    user.applicantSessionDto.progress.progress = getProgressKey(user.applicantSessionDto.progress.progressId);
                    user.applicantSessionDto.isInApplication = curProgressId === ApplicantTestProgress.Application;
                    user.applicantSessionDto.isInAchiever = curProgressId === ApplicantTestProgress.Personality;
                    user.applicantSessionDto.isInAptitudeTest = curProgressId === ApplicantTestProgress.AlphaSequence;
                    user.applicantSessionDto.isInDPATInhouse = curProgressId === ApplicantTestProgress.AlphaSequence2;
                }

                user.applicantSessionDto.progress.currentPage = 1;
                user.applicantSessionDto.progress.lastPage = 1;
                user.applicantSessionDto.progress.remainingTimeValue = TimeSpanMinVal;
                user.applicantSessionDto.progress.clientTimeElapsedValue = TimeSpanMinVal;
                user.applicantSessionDto.progress.serverTimeElapsedValue = TimeSpanMinVal;
                user.applicantSessionDto.randomizedQuestions = [];
                user.applicantSessionDto.randomizedAnswers = [];
            }
            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const updateAndGetApplicantProgress = createAsyncThunk<UserDto,{ transCompleted: boolean, oldProgressId?: number, oldLastPage?: number, oldSectionId?: number, oldTimeRemaining?: number, oldClientTime?: number, oldServerTime?: number }>(
    'account/updateAndGetApplicantProgress',
    async ({ transCompleted, oldProgressId, oldLastPage, oldSectionId, oldTimeRemaining, oldClientTime, oldServerTime }, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);
            let { progress, applicant: { applicantId, clientId, positionId, clientIsRecType, clientDeleteOnTransfer } }
                = user.applicantSessionDto || {} as ApplicantSessionDto;

            let applicantProgressRequest = applicantProgressDtoToRequest(progress, clientId, positionId, false,
                false, oldProgressId, oldLastPage, oldSectionId, oldTimeRemaining, oldClientTime, oldServerTime);
            let updatedProgress = await agent.ApplicantTest.updateAndGetApplicantProgress(applicantProgressRequest);
            user.applicantSessionDto!.progress = updatedProgress.item1;
            let emailTo = updatedProgress.item2;

            let emailed = await agent.ApplicantTest.completeApplicationProgress({ applicantId, emailTo, applicationOnly: false });
            let canApplicantBeDeleted = await agent.Applicant.canApplicantBeDeleted({ applicantId });
            
            if (transCompleted && emailed && clientDeleteOnTransfer && clientIsRecType && canApplicantBeDeleted) {
                await agent.ClientApplicant.removeApplicant({ applicantId });
            } else {
                let message = i18next.t('translation:IDS_ERROR_APP_NOT_REMOVED', {
                    0: String(emailed),
                    1: String(transCompleted),
                    2: String(clientDeleteOnTransfer),
                    3: String(clientIsRecType),
                    4: String(canApplicantBeDeleted)
                });
                await agent.Applicant.insertApplicantEvent({ applicantId, message, eventType: 'INFO' });
            }

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);


export const checkLockForApplicant = createAsyncThunk<UserDto, boolean>(
    'account/checkLockForApplicant',
    async (insertEvent, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let user = cloneDeep(rootState.account.user || {} as UserDto);
            let { applicant: { applicantId } } = user.applicantSessionDto || ({} as ApplicantSessionDto);

            let lockedDto = await agent.ClientApplicant.checkLockForApplicant({ applicantId, insertEvent });
            user.applicantSessionDto!.applicant.locked = lockedDto.dpatLocked || lockedDto.dpatInhouseLocked;

            sessionStorage.setItem('applicantSessionDto', JSON.stringify(user.applicantSessionDto));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        signOut: (state) => {
            const loginPath = `/Auth${pathFromUserType(state.user?.userType)}`;
            state.user = null;
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('casObject');
            sessionStorage.removeItem('applicantSessionDto');

            if (typeof History?.navigate === 'function') History.navigate(loginPath, { replace: true });
        },
        setUser: (state, action: { payload: UserDto }) => {
            let userType: UserType = action.payload.userType;
            if (userType !== UserType.Client && userType !== UserType.Applicant && userType !== UserType.Admin) {
                throw new Error('UserType not valid.');
            }

            let claims = JSON.parse(window.atob(action.payload.token.split('.')[1]));
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

            if (userType === UserType.Client) {
                let casObject = JSON.parse(sessionStorage.getItem('casObject')!);
                state.user = {...action.payload, casObject, roles: typeof(roles) === 'string' ? [roles] : roles};
            } else if (userType === UserType.Applicant) {
                if (action.payload.applicantSessionDto) {
                    state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles};
                } else {
                    let applicantSessionDto = JSON.parse(sessionStorage.getItem('applicantSessionDto')!);
                    state.user = {...action.payload, applicantSessionDto, roles: typeof(roles) === 'string' ? [roles] : roles};
                }
            }
            else {
                state.user = { ...action.payload, roles: typeof (roles) === 'string' ? [roles] : roles };
            }

            sessionStorage.setItem('user', JSON.stringify(state.user));
        },
        setMaintInfo: (state, action: { payload: MaintenanceDto }) => {
            state.maintenanceInfo = { ...action.payload };
            sessionStorage.setItem('maintDto', JSON.stringify(state.maintenanceInfo));
        },
        setAppSettings: (state, action: { payload: SettingDto[] }) => {
            state.appSettings = action.payload ? [...action.payload] : [];
            sessionStorage.setItem('settingDtos', JSON.stringify(state.appSettings));
        },
        applicantSessionStartedSection: (state) => {
            if (state.user && state.user.applicantSessionDto) {
                state.user.applicantSessionDto.randomizedQuestions = [];
                state.user.applicantSessionDto.randomizedAnswers = [];

                let dtNow = new Date().toISOString();
                let { progressId } = state.user.applicantSessionDto.progress;
                if (progressId === ApplicantTestProgress.Personality)
                    state.user.applicantSessionDto.progress.test1StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MentalAlertness)
                    state.user.applicantSessionDto.progress.test2StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.BusinessTerms)
                    state.user.applicantSessionDto.progress.test3StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.Vocabulary)
                    state.user.applicantSessionDto.progress.test4StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.Perception)
                    state.user.applicantSessionDto.progress.test5StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MechanicalInterest)
                    state.user.applicantSessionDto.progress.test6StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.AlphaSequence)
                    state.user.applicantSessionDto.progress.dpat1StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.GraphicSequence)
                    state.user.applicantSessionDto.progress.dpat2StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MathWordProblems)
                    state.user.applicantSessionDto.progress.dpat3StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.AlphaSequence2)
                    state.user.applicantSessionDto.progress.dpatInhouse1StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.GraphicSequence2)
                    state.user.applicantSessionDto.progress.dpatInhouse2StartedDate = dtNow;
                else if (progressId === ApplicantTestProgress.MathWordProblems2)
                    state.user.applicantSessionDto.progress.dpatInhouse3StartedDate = dtNow;

                sessionStorage.setItem('applicantSessionDto', JSON.stringify(state.user.applicantSessionDto));
            }
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            const loginPath = `/Auth${pathFromUserType(state.user?.userType)}`;
            state.user = null;
            sessionStorage.removeItem('user');
            if (typeof History?.navigate === 'function') History.navigate(loginPath, { replace: true});
        });
        builder.addCase(signInUser.rejected, (_, action) => {
            throw action.payload;
        });
        builder.addCase(checkLockForApplicant.fulfilled, (state, action) => {
            let claims = JSON.parse(window.atob(action.payload.token.split('.')[1]));
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

            let applicantSessionDto = JSON.parse(sessionStorage.getItem('applicantSessionDto')!);
            state.user = { ...action.payload, applicantSessionDto, roles: typeof (roles) === 'string' ? [roles] : roles };
            state.status = 'idle';
        });
        builder.addCase(fetchMaintenanceInfo.fulfilled, (state, action) => {
            state.maintenanceInfo = { ...action.payload };
            state.status = 'idle';
        });
        builder.addCase(fetchAppSettings.fulfilled, (state, action) => {
            state.appSettings = [...action.payload];
            state.status = 'idle';
        });
        builder.addMatcher(
            isAnyOf(signInUser.fulfilled,
                fetchCurrentUser.fulfilled,
                updateApplicantProgress.fulfilled,
                applicantSessionCompletedSection.fulfilled,
                updateAndGetApplicantProgress.fulfilled,
                addApplicantInfo.fulfilled,
                addApplicantExperience.fulfilled,
                addApplicantEducation.fulfilled,
                addApplicantReference.fulfilled,
                addApplicantEEOData.fulfilled,
                addApplicantQuestions.fulfilled),
            (state, action) => {
                let userType: UserType = action.payload.userType;
                if (userType !== UserType.Client && userType !== UserType.Applicant && userType !== UserType.Admin) {
                    throw new Error('UserType not valid.');
                }

                let claims = JSON.parse(window.atob(action.payload.token.split('.')[1]));
                let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                if (userType === UserType.Client) {
                    let casObject = JSON.parse(sessionStorage.getItem('casObject')!);
                    state.user = { ...action.payload, casObject, roles: typeof (roles) === 'string' ? [roles] : roles };
                } else if (userType === UserType.Applicant) {
                    let applicantSessionDto = JSON.parse(sessionStorage.getItem('applicantSessionDto')!);
                    state.user = { ...action.payload, applicantSessionDto, roles: typeof (roles) === 'string' ? [roles] : roles };
                }
                else {
                    state.user = { ...action.payload, roles: typeof (roles) === 'string' ? [roles] : roles };
                }
                state.status = 'idle';
            });
        builder.addMatcher(
            isAnyOf(signInUser.pending,
                fetchCurrentUser.pending,
                fetchMaintenanceInfo.pending,
                fetchAppSettings.pending,
                updateApplicantProgress.pending,
                applicantSessionCompletedSection.pending,
                updateAndGetApplicantProgress.pending,
                checkLockForApplicant.pending,
                addApplicantInfo.pending,
                addApplicantExperience.pending,
                addApplicantEducation.pending,
                addApplicantReference.pending,
                addApplicantEEOData.pending,
                addApplicantQuestions.pending),
            (state) => {
                state.status = 'pending';
            });
        builder.addMatcher(
            isAnyOf(fetchMaintenanceInfo.rejected,
                fetchAppSettings.rejected,
                updateApplicantProgress.rejected,
                applicantSessionCompletedSection.rejected,
                updateAndGetApplicantProgress.rejected,
                checkLockForApplicant.rejected,
                addApplicantInfo.rejected,
                addApplicantExperience.rejected,
                addApplicantEducation.rejected,
                addApplicantReference.rejected,
                addApplicantEEOData.rejected,
                addApplicantQuestions.rejected),
            (state, action) => {
                state.status = 'idle';
                throw action.payload;
            });
    })
});

const getAchieverResponses = (state: RootState) => {
    let strAllResponses = '';
    if (state.test.testSession?.achieverTest) {
        state.test.testSession.achieverTest.sections.forEach(x => strAllResponses += achieverSectionMethods.getResponses(x));
    }
    return strAllResponses;
};
const getDPATResponses = (state: RootState) => {
    let strAllResponses = '';
    if (state.test.testSession?.dpatTest) {
        state.test.testSession.dpatTest.sections.forEach(x => strAllResponses += dpatSectionMethods.getResponses(x));
    }
    return strAllResponses;
};
const getDPATInhResponses = (state: RootState) => {
    let strAllResponses = '';
    if (state.test.testSession?.dpatInhTest) {
        state.test.testSession.dpatInhTest.sections.forEach(x => strAllResponses += dpatSectionMethods.getResponses(x));
    }
    return strAllResponses;
};

export const { signOut, setUser, setMaintInfo, setAppSettings, applicantSessionStartedSection } = accountSlice.actions;
export default accountSlice.reducer;