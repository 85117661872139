import { FieldValues } from 'react-hook-form';

import { VwClient } from '../dbViews/vwClient';

export interface ClientApplicantDefaultsRequest {
    id: number;
    clientId: number;
    application: boolean;
    achiever: boolean;
    aptitudeTest: boolean;
    aptitudeInhouse: boolean;
    email: boolean;
    applicationOrder: number;
    achieverOrder: number;
    aptitudeTestOrder: number;
    aptitudeInhouseOrder: number;
}

export function formDataToClientApplicantDefaultsRequest(vwClient: VwClient, formData: FieldValues) {
    let clientApplicantDefaults: ClientApplicantDefaultsRequest = {
        id: vwClient.applicantDefaultId,
        clientId: vwClient.id,
        application: vwClient.applicantDefaultApplication,
        achiever: vwClient.applicantDefaultAchiever,
        aptitudeTest: vwClient.applicantDefaultAptitudeTest,
        aptitudeInhouse: vwClient.applicantDefaultDpatInhouse,
        email: vwClient.applicantDefaultEmail,
        applicationOrder: vwClient.applicantDefaultApplicationOrder,
        achieverOrder: vwClient.applicantDefaultAchieverOrder,
        aptitudeTestOrder: vwClient.applicantDefaultAptitudetestOrder,
        aptitudeInhouseOrder: vwClient.applicantDefaultDpatInhouseOrder
    };

    for (let i = 0; i < formData['applicantDefaults'].length; i++) {
        let f = formData['applicantDefaults'][i];
        if (f.name === 'Email') {
            clientApplicantDefaults.email = f.onByDefault;
        } else if (f.name === 'Achiever') {
            clientApplicantDefaults.achiever = f.onByDefault;
            clientApplicantDefaults.achieverOrder = i;
        } else if (f.name === 'Application') {
            clientApplicantDefaults.application = f.onByDefault;
            clientApplicantDefaults.applicationOrder = i;
        } else if (f.name === 'Aptitudetest') {
            clientApplicantDefaults.aptitudeTest = f.onByDefault;
            clientApplicantDefaults.aptitudeTestOrder = i;
        } else if (f.name === 'DpatInhouse') {
            clientApplicantDefaults.aptitudeInhouse = f.onByDefault;
            clientApplicantDefaults.aptitudeInhouseOrder = i;
        }
    }

    return clientApplicantDefaults;
}