import { ChangeEvent, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { faAngleDoubleDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import LoadingButton from '../../app/components/LoadingButton';
import { CCasCompanyBo } from '../../app/models/clientAppSecurityWebService/CCasCompanyBo';
import { FieldValuesSelectClient } from '../../app/models/fieldValueTypes';
import { selectClient } from '../../app/store/clientSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { getTitle, parseValidationMsg } from '../../i18n';
import ModalClientLookup from '../client/home/ModalClientLookup';

export default function SelectClientPage() {
    const { t } = useTranslation();
    const { user } = useAppSelector(state => state.account);
    const { clientSession } = useAppSelector(state => state.clientSession);
    const [showModalClientLookup, setShowModalClientLookup] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasMultipleCompanies = user?.casObject?.casCompanyBC && user.casObject.casCompanyBC.length > 1;

    const formMethods = useForm<FieldValuesSelectClient>({
        mode: 'all',
        resolver: yupResolver(
            yup.object({
                clientNumber: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_ACCOUNT'] }),
                ...(hasMultipleCompanies && { selectCompany: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_COMPANY'] }) })
            }))
    }),
        { formState: { isValid, isSubmitting, errors }, handleSubmit, control, resetField, getValues, register } = formMethods;

    let sortedCompanies: Array<CCasCompanyBo> = [];
    if (hasMultipleCompanies) {
        sortedCompanies = [...(user!.casObject!.casCompanyBC!)].sort((a, b) => (a.name! > b.name!) ? 1 : -1);
    }

    async function submitForm(data: FieldValuesSelectClient) {
        try {
            await dispatch(selectClient(data.clientNumber));
            navigate('/Client');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_SELECT_ACCOUNT')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                <h1 className="mb-4 fw-bold">{t('IDS_SELECT_ACCOUNT')}</h1>
                {!clientSession &&
                    <div className="help-text mb-4 smaller">{t('IDS_SELECT_ACCOUNT_INSTRU')}</div>
                }
                <FormProvider {...formMethods} >
                    <Form onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                        {hasMultipleCompanies &&
                            <FloatingLabel
                                controlId="selectCompany"
                                className="small mb-3"
                                label={t('IDS_COMPANY')}
                            >
                                <Controller
                                    name="selectCompany"
                                    defaultValue={""}
                                    control={control}
                                    render={({ field: { value, onChange: ctlOnChange }, fieldState }) =>
                                        <Form.Select
                                            value={value || undefined}
                                            required
                                            isInvalid={!!fieldState.error}
                                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                resetField('clientNumber');
                                                ctlOnChange(e.target.value);
                                            }}
                                        >
                                            <option value=""></option>
                                            {(sortedCompanies).map((option: CCasCompanyBo) => (
                                                <option key={option.companyID!} value={option.companyID!}>
                                                    {option.name!}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.selectCompany?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        }
                        <InputGroup className="flex-nowrap mb-3">
                            <FloatingLabel
                                controlId="inputClientNumber"
                                className="small flex-fill"
                                label={t('IDS_ACCOUNT')}
                            >
                                <Form.Control
                                    type="text"
                                    required
                                    maxLength={7}
                                    {...register('clientNumber')}
                                    isInvalid={!!errors.clientNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.clientNumber?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <InputGroup.Text className="px-2" style={{ height: 'calc(3.5rem + 2px)' }}>
                                <Button
                                    variant="dark-ocean"
                                    className="d-flex align-items-center justify-content-center rounded-circle lh-1 m-0 p-1"
                                    disabled={hasMultipleCompanies && !getValues('selectCompany')}
                                    onClick={() => setShowModalClientLookup(true)}
                                    title={t('IDS_SELECT_ACCOUNT')}
                                >
                                    <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth size="xs" />
                                </Button>
                            </InputGroup.Text>
                        </InputGroup>
                        <LoadingButton
                            variant="dark-ocean"
                            type="submit"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_SELECT_ACCOUNT_BTN')}
                        </LoadingButton>
                    </Form>
                    <ModalClientLookup showModal={showModalClientLookup} setShowModal={setShowModalClientLookup} />
                </FormProvider>
            </div>
        </>
    );
}