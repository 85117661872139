import { forwardRef, KeyboardEventHandler, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { components, GroupBase, InputActionMeta, InputProps, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select/dist/declarations/src/Select';

import { BsModalAlert } from './BsModal';

interface CustomControllerRenderProps extends ControllerRenderProps {
    inputId?: string;
    inputRegExp?: RegExp;
    inputValidationMsg?: string;
    maxItems: number;
    required?: boolean;
}

export interface Option {
    label: string;
    value: string;
}

const createOption = (label: string): Option => ({
    label,
    value: label
});


export function stringToMultiTextInputOptions(strOpts: string) {
    let options: Option[] = [];
    if (strOpts) {
        let splitOpts = strOpts.split(',');
        for (let splitOpt of splitOpts) {
            if (splitOpt) {
                options.push({ label: splitOpt, value: splitOpt });
            }
        }
    }
    return options;
}

export const MultiTextInput = forwardRef<Select<Option, true, GroupBase<Option>>, CustomControllerRenderProps>((props, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const { t } = useTranslation();

    const handleChange = (val: OnChangeValue<Option, true>) => {
        props.onChange([...val]);
    };
    const handleInputChange = (inputValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta?.action !== 'input-blur' && actionMeta?.action !== 'menu-close') {
            setInputValue(inputValue);
        }
    };
    const validateAndSetValue = () => {
        if (props.value?.some((el: Option) => el.label.toLowerCase() === inputValue.toLowerCase())) {
            setAlertMsg(t('IDS_VALUE_ALREADY_EXISTS'))
            setShowAlert(true);
        } else if (props.inputRegExp && !inputValue.match(props.inputRegExp)) {
            setAlertMsg(props.inputValidationMsg ?? t('IDS_ENTERED_VALUE_NOT_VALID'))
            setShowAlert(true);
        } else {
            props.onChange([...(props.value || []), createOption(inputValue)]);
            setInputValue('');
        }
    };
    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (!inputValue) return;
        if (e.key === 'Enter') {
            e.preventDefault();
            validateAndSetValue();
        }
    };

    const Input = (inputProps: InputProps<Option, true>) => {
        return <components.Input {...inputProps} readOnly {...(props.inputId ? { id: props.inputId } : {})} />;
    };

    return (
        <>
            <CreatableSelect
                {...props}
                ref={ref}
                classNamePrefix="react-select"
                components={(props.value && props.value.length >= props.maxItems) ? { DropdownIndicator: null, Input } : { DropdownIndicator: null }}
                inputValue={inputValue}
                isClearable={false}
                isMulti
                menuIsOpen={false}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                backspaceRemovesValue={false}
                className={`multi-text ${(props.required ?? false) ? 'multi-text-reqd' : ''} ${(props.required ?? false) && (!props.value || props.value.length < 1) ? 'multi-text-invalid' : ''}`}
            />
            <Button
                className="multi-text-add link-dark-ocean"
                variant="link"
                disabled={(props.value && props.value.length >= props.maxItems) || !inputValue}
                size="sm"
                onClick={validateAndSetValue}
            >
                {`${t('IDS_ADD')} +`}
            </Button>
            <BsModalAlert title={t('IDS_VALIDATION_ERROR')} message={alertMsg} showModal={showAlert} setShowModal={setShowAlert} />
        </>
    );
})