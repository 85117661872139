import { FieldValues, UseFormGetValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';

export interface SetupTabsFormState {
    isValid: boolean;
    isDirty: boolean;
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    handleSubmit: UseFormHandleSubmit<FieldValues> | null;
    getValues?: UseFormGetValues<FieldValues> | null;
    reset: UseFormReset<FieldValues> | null;
}

export const setupTabsDefaultState: SetupTabsFormState = {
    isValid: true,
    isDirty: false,
    isSubmitting: false,
    isSubmitSuccessful: false,
    handleSubmit: null,
    getValues: null,
    reset: null
};