
import { forwardRef, useImperativeHandle } from 'react';
import { useTimer } from 'react-timer-hook';

import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TimeSpan } from '../../../app/models/test/timeSpan';
import { useAppSelector } from '../../../app/store/storeHooks';
import { TimeSpanMinVal } from '../../../app/utility/util';

export interface TimerHandle {
    getRemaining: () => number;
}
interface TimerProps {
    submitSection: () => void;
}

export const TestTimer = forwardRef(({ submitSection }: TimerProps, ref: React.Ref<TimerHandle>) => {
    const remainingTime = useAppSelector(state => state.account.user?.applicantSessionDto?.progress.remainingTimeValue) ?? TimeSpanMinVal;
    const timeToComplete = useAppSelector(state => state.test.testSession?.timeToComplete) ?? 0;

    const remaining = remainingTime !== TimeSpanMinVal
        ? remainingTime
        : TimeSpan.fromTime(0, timeToComplete, 0).totalMilliseconds;
    const time = new Date();
    time.setSeconds(time.getSeconds() + (remaining / 1000));
    const { seconds, minutes,  } =
        useTimer({
            expiryTimestamp: time,
            onExpire: submitSection
        });
    
    useImperativeHandle(ref, () => ({
        getRemaining: () => {
            return TimeSpan.fromTime(0, minutes, seconds).totalMilliseconds;
        },
    }));

    return (
        <div>
            <div className="d-flex">
                <span className={`fs-5 d-flex align-items-center ${minutes === 0 && seconds <= 30 ? 'text-cherry' : ''}`}>
                    <FontAwesomeIcon
                        icon={faStopwatch}
                        className={`me-2 ${minutes === 0 && seconds <= 30 ? 'text-cherry' : 'text-dark-ocean'}`}
                        size="lg"
                    />
                    <span className="font-monospace">
                        {`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}
                    </span>
                </span>
            </div>
        </div>
    );
});