import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../api/agent';
import { CasFeature } from '../enums/casFeature';
import { ClientSession } from '../models/clientSession';
import { TblClientLanguage } from '../models/dbTables/tblClientLanguage';
import { VwClient } from '../models/dbViews/vwClient';
import type { RootState } from './configureStore';

interface ClientState {
    clientSession: ClientSession | null;
    status: string;
}

const initialState: ClientState = {
    clientSession: null,
    status: 'idle'
}

function buildClientSession(rootState: RootState, vwClient: VwClient, languageBC: TblClientLanguage[]): ClientSession {
    let clientSession: ClientSession =  {
        vwClient,
        achiever: false,
        dpat: false,
        dpatInhouse: false,
        viewResults: false,
        maintainPositions: false,
        updateClientSetup: false,
        userName: rootState.account.user!.username,
        languageBC
    };

    if (rootState.account.user?.casObject?.casFeatureBC) {
        for (let i = 0; i < rootState.account.user.casObject.casFeatureBC.length; i++) {
            if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.AptitudeTest) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.dpat = true;
                        break;
                    }
                }
            }
            else if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.AssessmentTest) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.achiever = true;
                        break;
                    }
                }
            }
            else if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.InhouseAptitudeTest) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.dpatInhouse = true;
                        break;
                    }
                }
            }
            else if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.ViewResults) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.viewResults = true;
                        break;
                    }
                }
            }
            else if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.MaintainPositions) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.maintainPositions = true;
                        break;
                    }
                }
            }
            else if (rootState.account.user.casObject.casFeatureBC[i].feature === CasFeature.UpdateClientSetup) {
                for (let j = 0; j < rootState.account.user.casObject.casFeatureBC[i].accountBC!.length; j++) {
                    if (rootState.account.user.casObject.casFeatureBC[i].accountBC![j].accountNumber === vwClient.clientNumber) {
                        clientSession.updateClientSetup = true;
                        break;
                    }
                }
            }
        }
    }

    return clientSession;
}

export const selectClient = createAsyncThunk<ClientSession, string>(
    'client/selectClient',
    async (clientNumber, thunkAPI) => {
        try {
            const vwClient = await agent.Client.getByClientNumber({ clientNumber });
            const languageBc = await agent.ClientLanguage.getClientLanguages({ clientId: vwClient.id });
            const state = thunkAPI.getState() as RootState;
            let clientSession = buildClientSession(state, vwClient, languageBc);
            sessionStorage.setItem('clientSession', JSON.stringify(clientSession));
            return clientSession;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        clearClient: (state) => {
            state.clientSession = null;
            sessionStorage.removeItem('clientSession');
        },
        setClient: (state, action) => {
            state.clientSession = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(selectClient.pending, (state) => {
            state.status = 'pendingFetchClient';
        });
        builder.addCase(selectClient.fulfilled, (state, action) => {
            state.clientSession = action.payload;
            state.status = 'idle';
        });
        builder.addCase(selectClient.rejected, (state, action) => {
            state.status = 'idle';
            throw action.payload;
        });
    })
});

export const { setClient, clearClient } = clientSlice.actions;
export default clientSlice.reducer;