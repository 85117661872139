import { ApplicantQuesDto } from '../responseHelpers/applicantQuesDto';
import { ClientPositionQuestionDto } from '../responseHelpers/clientPositionQuestionDto';

export interface ApplicantQuestionRequest {
    applicantId: number;
    positionId: number;
    applicantQuestions: ApplicantQuestion[];
}

export interface ApplicantQuestion {
    answer: string;
    question: string;
    typeId: number;
}

export function getQuestions(applicantQuestions: ApplicantQuesDto[], clientQuestions: ClientPositionQuestionDto[]): ApplicantQuestion[] {
    let questions: ApplicantQuestion[] = [];
    
    for (let clientQuestion of clientQuestions) {
        questions.push({
            answer: '',
            question: clientQuestion.question,
            typeId: clientQuestion.typeId
        });
    }

    if (Array.isArray(applicantQuestions) && applicantQuestions.length > 0) {
        for (let applicantQuestion of applicantQuestions) {
            let question = questions.find(x => x.question === applicantQuestion.question);
            if (question) {
                question.answer = applicantQuestion.answer;
            }
        }
    }
    return questions;
}