import { QuestionType } from '../../../enums/testQuestionType';
import { AchieverResult } from '../../requestHelpers/achieverResultsRequest';
import { AchieverSection } from './achieverSection';
import { AchieverTest } from './achieverTest';

interface stDIMENSIONDATA {
    strName: string;
    strCode: string;
    strLoDesc: string;
    strHiDesc: string;
    nStanBreaks: number[];
    nStanine: number;
    nRawScore: number;
}

export function GetAchieverResultsFromXML(cTest: AchieverTest): AchieverResult[] {
    let xmlDoc = ScoreTest(cTest);
    let cNodes = xmlDoc.getElementsByTagName('Dimension');
    let cBC: AchieverResult[] = [];
    for (let i = 0; i < cNodes.length; i++) {
        let cCurrentNode = cNodes[i].firstChild as HTMLElement;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let code = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling!.nextSibling!.nextSibling as HTMLElement;
        let strStanine = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let strRawScore = cCurrentNode.innerText;

        let stanine = strStanine.length > 0 ? Number(strStanine) : 0;
        let rawScore = strRawScore.length > 0 ? Number(strRawScore) : 0;

        cBC.push({ code, stanine, rawScore });
    }

    return cBC;
}

function ScoreTest(cTest: AchieverTest): XMLDocument {
    let m_stDim: stDIMENSIONDATA[] = [
        { strName: 'Mental Alertness',      strCode: 'A1',  strLoDesc: 'Slow to Learn',             strHiDesc: 'Fast to Learn',             nStanBreaks: [9, 17, 20, 24, 27, 30, 32, 34, 36], nStanine: 0, nRawScore: 0 },
        { strName: 'Business Terms',        strCode: 'A2',  strLoDesc: 'Poor Business Vocabulary',  strHiDesc: 'Knows Business Language',   nStanBreaks: [9,  3,  4,  5,  6,  7,  8,  9, 10], nStanine: 0, nRawScore: 0 },
        { strName: 'Memory Recall',         strCode: 'A3',  strLoDesc: 'Ignores Current Events',    strHiDesc: 'Knows Current Events',      nStanBreaks: [9,  6,  7,  8,  9, 10, 11,  0, 12], nStanine: 0, nRawScore: 0 },
        { strName: 'Vocabulary',            strCode: 'A4',  strLoDesc: 'Low Word Knowledge',        strHiDesc: 'High Word Knowledge',       nStanBreaks: [9, 24, 28, 32, 35, 38, 41, 43, 45], nStanine: 0, nRawScore: 0 },
        { strName: 'Perception',            strCode: 'A5',  strLoDesc: 'Low Scanning Accuracy',     strHiDesc: 'High Scanning Accuracy',    nStanBreaks: [9,  9, 11, 13, 15, 17, 18, 20, 21], nStanine: 0, nRawScore: 0 },
        { strName: 'Mechanical Interest',   strCode: 'A6',  strLoDesc: 'Low Mechanical Interest',   strHiDesc: 'High Mechanical Interest',  nStanBreaks: [9,  3,  5,  6,  9, 11, 13, 14, 16], nStanine: 0, nRawScore: 0 },
        { strName: 'Energy',                strCode: 'D1',  strLoDesc: 'Restless',                  strHiDesc: 'Calm',                      nStanBreaks: [9,  4,  6,  7,  8, 10, 12, 13, 14], nStanine: 0, nRawScore: 0 },
        { strName: 'Character Strength',    strCode: 'D2',  strLoDesc: 'Situational Morality',      strHiDesc: 'Traditional Morality',      nStanBreaks: [1,  0,  1,  2,  3,  4,  6,  7,  9], nStanine: 0, nRawScore: 0 },
        { strName: 'Work Habits',           strCode: 'D3',  strLoDesc: 'Disorganized',              strHiDesc: 'Planful',                   nStanBreaks: [9,  3,  4,  6,  8, 10, 12, 13, 14], nStanine: 0, nRawScore: 0 },
        { strName: 'Communication',         strCode: 'D4',  strLoDesc: 'Detached',                  strHiDesc: 'Gregarious',                nStanBreaks: [9,  4,  6,  8, 10, 12, 13, 14, 16], nStanine: 0, nRawScore: 0 },
        { strName: 'Emotional Maturity',    strCode: 'D5',  strLoDesc: 'Impatient',                 strHiDesc: 'Tolerant',                  nStanBreaks: [9,  3,  4,  5,  7,  9, 11, 13, 15], nStanine: 0, nRawScore: 0 },
        { strName: 'Dominance',             strCode: 'D6',  strLoDesc: 'Submissive',                strHiDesc: 'Assertive',                 nStanBreaks: [1,  1,  2,  3,  5,  6,  8, 10, 11], nStanine: 0, nRawScore: 0 },
        { strName: 'Competitiveness',       strCode: 'D7',  strLoDesc: 'Friendship Oriented',       strHiDesc: 'Competition Oriented',      nStanBreaks: [1,  4,  5,  6,  8, 10, 12, 14, 15], nStanine: 0, nRawScore: 0 },
        { strName: 'Mental Toughness',      strCode: 'D8',  strLoDesc: 'Tender Minded',             strHiDesc: 'Tough Minded',              nStanBreaks: [1,  1,  2,  3,  4,  6,  8,  9, 11], nStanine: 0, nRawScore: 0 },
        { strName: 'Optimism',              strCode: 'D9',  strLoDesc: 'Naive',                     strHiDesc: 'Cynical',                   nStanBreaks: [9,  0,  1,  2,  3,  4,  6,  8, 10], nStanine: 0, nRawScore: 0 },
        { strName: 'Work Motivation',       strCode: 'D10', strLoDesc: 'Security Motivated',        strHiDesc: 'Recognition Motivated',     nStanBreaks: [9,  2,  4,  5,  6,  8,  9, 10, 11], nStanine: 0, nRawScore: 0 },
        { strName: 'Distortion',            strCode: 'V1',  strLoDesc: 'Very Frank Answer',         strHiDesc: 'Exaggerates Own Honesty',   nStanBreaks: [9,  0,  0,  2,  4,  6,  8, 10, 12], nStanine: 0, nRawScore: 0 },
        { strName: 'Equivocation',          strCode: 'V2',  strLoDesc: 'Chose Given Alternatives',  strHiDesc: 'Chose Middle Answers',      nStanBreaks: [9,  1,  2,  3,  5,  6,  7,  0,  0], nStanine: 0, nRawScore: 0 }
    ];
    
    // NOTE: Achiever scoring was updated as part of PMG 560588 (PMR 1453464). Keeping old scoring commented-out
    // here since REC doesn't seem too sure on new scoring and may want to revert back.

    // let m_stDim: stDIMENSIONDATA[] = [
    //     { strName: 'Mental Alertness',      strCode: 'A1',  strLoDesc: 'Slow to Learn',             strHiDesc: 'Fast to Learn',             nStanBreaks: [9, 17, 21, 24, 27, 31, 33, 34, 36], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Business Terms',        strCode: 'A2',  strLoDesc: 'Poor Business Vocabulary',  strHiDesc: 'Knows Business Language',   nStanBreaks: [9,  3,  4,  5,  6,  7,  8,  9, 10], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Memory Recall',         strCode: 'A3',  strLoDesc: 'Ignores Current Events',    strHiDesc: 'Knows Current Events',      nStanBreaks: [9,  4,  5,  6,  7,  8,  9, 11,  0], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Vocabulary',            strCode: 'A4',  strLoDesc: 'Low Word Knowledge',        strHiDesc: 'High Word Knowledge',       nStanBreaks: [9, 25, 29, 32, 34, 38, 40, 44, 45], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Perception',            strCode: 'A5',  strLoDesc: 'Low Scanning Accuracy',     strHiDesc: 'High Scanning Accuracy',    nStanBreaks: [9,  7,  9, 11, 13, 15, 17, 19, 21], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Mechanical Interest',   strCode: 'A6',  strLoDesc: 'Low Mechanical Interest',   strHiDesc: 'High Mechanical Interest',  nStanBreaks: [9,  3,  4,  5,  6,  8, 11, 12, 13], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Energy',                strCode: 'D1',  strLoDesc: 'Restless',                  strHiDesc: 'Calm',                      nStanBreaks: [9,  3,  5,  6,  7,  9, 11, 12, 14], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Character Strength',    strCode: 'D2',  strLoDesc: 'Situational Morality',      strHiDesc: 'Traditional Morality',      nStanBreaks: [1,  0,  1,  2,  4,  7,  8,  9, 10], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Work Habits',           strCode: 'D3',  strLoDesc: 'Disorganized',              strHiDesc: 'Planful',                   nStanBreaks: [9,  4,  6,  8,  9, 11, 12, 13, 14], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Communication',         strCode: 'D4',  strLoDesc: 'Detached',                  strHiDesc: 'Gregarious',                nStanBreaks: [9,  0,  3,  5,  7,  9, 11, 12, 14], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Emotional Maturity',    strCode: 'D5',  strLoDesc: 'Impatient',                 strHiDesc: 'Tolerant',                  nStanBreaks: [9,  2,  3,  4,  0,  6,  8,  9, 11], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Dominance',             strCode: 'D6',  strLoDesc: 'Submissive',                strHiDesc: 'Assertive',                 nStanBreaks: [1,  0,  3,  4,  7, 10, 11, 12, 13], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Competitiveness',       strCode: 'D7',  strLoDesc: 'Friendship Oriented',       strHiDesc: 'Competition Oriented',      nStanBreaks: [1,  4,  5,  7,  9, 11, 12, 13, 14], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Mental Toughness',      strCode: 'D8',  strLoDesc: 'Tender Minded',             strHiDesc: 'Tough Minded',              nStanBreaks: [1,  0,  3,  5,  6,  8,  9, 10, 12], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Optimism',              strCode: 'D9',  strLoDesc: 'Naive',                     strHiDesc: 'Cynical',                   nStanBreaks: [9,  2,  3,  4,  5,  7,  9, 10, 11], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Work Motivation',       strCode: 'D10', strLoDesc: 'Security Motivated',        strHiDesc: 'Recognition Motivated',     nStanBreaks: [9,  0,  2,  3,  4,  6,  7,  8,  9], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Distortion',            strCode: 'V1',  strLoDesc: 'Very Frank Answer',         strHiDesc: 'Exaggerates Own Honesty',   nStanBreaks: [9,  0,  0,  1,  2,  3,  6,  7,  8], nStanine: 0, nRawScore: 0 },
    //     { strName: 'Equivocation',          strCode: 'V2',  strLoDesc: 'Chose Given Alternatives',  strHiDesc: 'Chose Middle Answers',      nStanBreaks: [9,  2,  3,  4,  5,  6,  7,  8,  9], nStanine: 0, nRawScore: 0 }
    // ];

    for (let cSection of cTest.sections) {
        ComputeRawScores(cSection, m_stDim);
    }

    ComputeStanines(m_stDim);
    return BuildResults(m_stDim);
}

function ComputeRawScores(cSection: AchieverSection, m_stDim: stDIMENSIONDATA[]) {
    let htScores: Record<number, number> = {};
    for (let cQues of cSection.questions) {
        for (let cDimension in cQues.correctAnswers) {
            let nKey: number = Number(cDimension);
            let nCorrectAns: number = cQues.correctAnswers[nKey];
            let nScore: number = 0;
            if (htScores[nKey] != null) {
                nScore = htScores[nKey];
            }

            switch (cQues.questionType) {
                case QuestionType.MultipleChoice:
                    // --------------------------------------------------------
                    //     Score 1 for each correct response
                    // 
                    if (cQues.response === nCorrectAns) {
                        nScore++;
                    }
                    break;
                case QuestionType.YesNo:
                    // --------------------------------------------------------
                    //     Add 1 for all possible YES answers,
                    //     Subtract 1 for each incorrect response
                    // 
                    if (nCorrectAns === 1) {
                        nScore++;
                    }
                    if (nCorrectAns !== cQues.response) {
                        nScore--;
                    }
                    break;
                case QuestionType.YesNoMaybe:
                    // --------------------------------------------------------
                    //     Score 2 for each correct answer, 
                    //     Score 1 for each ambiguous 'maybe' answer
                    // 
                    if (cQues.response === 2 || cQues.response === 0) {
                        nScore++;
                    } else if (cQues.response === nCorrectAns) {
                        nScore = (nScore + 2);
                    }
                    break;
            }

            htScores[nKey] = nScore;
        }
    }

    // --------------------------------------------------------------------
    //     Save the computed scores for this section 
    // 
    for (let cDim in htScores) {
        // -----------------------------------------------------------------
        //     Adjust the key by one to index into the zero-based array
        //     of scoring dimensions
        // 
        m_stDim[Number(cDim) - 1].nRawScore = htScores[cDim];
    }

}

// -----------------------------------------------------------------------
//     ComputeStanines()
// 
//         Loop through all the Achiever Dimensions and compute the 
//         stanine scores for each.  All test sections must have computed
//         raw scores before calling this function.
// 
//         Based on the first element in the stanine breaks array, we
//         reverse the stanine level assigned by subtracting the array
//         index from 10.  See the Stanine Breaks note above for further
//         explanation of why this needs to be done.
// 
function ComputeStanines(m_stDim: stDIMENSIONDATA[]) {
    for (let nDim = 0; nDim < m_stDim.length; nDim++) {
        let nSelected = m_stDim[nDim].nStanBreaks[0];
        for (let nNdx = 1; nNdx <= 8; nNdx++) {
            if (m_stDim[nDim].nRawScore < m_stDim[nDim].nStanBreaks[nNdx]) {
                if (nSelected === 9) {
                    nSelected = nNdx;
                } else if (nSelected === 1) {
                    nSelected = (10 - nNdx);
                }

                break;
            }

        }

        m_stDim[nDim].nStanine = nSelected;
    }

}

function BuildResults(m_stDim: stDIMENSIONDATA[]): XMLDocument {
    let xDoc = document.implementation.createDocument(null, 'Achiever');
    for (let stDim of m_stDim) {
        xDoc.documentElement.appendChild(DimentionChild(xDoc, stDim));
    }

    return xDoc;
}

function DimentionChild(cXml: XMLDocument, stDim: stDIMENSIONDATA): HTMLElement {
    let cDim = cXml.createElement('Dimension');
    let cName = cXml.createElement('Name');
    cName.innerText = stDim.strName;
    cDim.appendChild(cName);
    let cCode = cXml.createElement('Code');
    cCode.innerText = stDim.strCode;
    cDim.appendChild(cCode);
    let cLowDesc = cXml.createElement('LowDesc');
    cLowDesc.innerText = stDim.strLoDesc;
    cDim.appendChild(cLowDesc);
    let cHighDesc = cXml.createElement('HighDesc');
    cHighDesc.innerText = stDim.strHiDesc;
    cDim.appendChild(cHighDesc);
    let cStanine = cXml.createElement('Stanine');
    cStanine.innerText = `${stDim.nStanine}`;
    cDim.appendChild(cStanine);
    let cRawScore = cXml.createElement('RawScore');
    cRawScore.innerText = `${stDim.nRawScore}`;
    cDim.appendChild(cRawScore);
    return cDim;
}