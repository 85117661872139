import { ChangeEvent, KeyboardEvent } from 'react';
import { Form } from 'react-bootstrap';
import { FilterProps } from 'react-table';

export default function TextColumnFilter<T extends object>({ column: { setFilter, render } }: FilterProps<T>) {
    return (
        <Form.Control
            type="text"
            className="form-control-xs w-100"
            onChange={(e: ChangeEvent<HTMLInputElement>) => { if (!e.target.value) setFilter('') }}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' && e.currentTarget.value) {
                    e.preventDefault();
                    setFilter({ vals: [e.currentTarget.value], condition: 'includes' });
                }
            }}
            title={render('Header')?.toString() + ' filter'}
        />
    );
}