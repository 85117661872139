import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../app/api/agent';
import CustomCloseBtn from '../../app/components/CustomCloseBtn';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { localDate } from '../../app/utility/util';
import { SecureApplicantDto } from '../../app/models/responseHelpers/secureApplicantDto';
import { BsModalAlert } from '../../app/components/BsModal';

interface Props {
    showModal: boolean;
    applicantId: number;
    setShowModal: (show: boolean) => void;
    changeCount: number;
    setChangeCount: (count: number) => void;
}

export default function ModalSecureApplicant({ showModal, setShowModal, applicantId, changeCount, setChangeCount }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalAlertState, setModalAlertState] = useState<{ show: boolean, msg: string }>({ show: false, msg: '' });
    const formMethods = useForm({
        mode: 'all'
    }),
        { register, reset, control, getValues } = formMethods;
    const { fields: testFields } = useFieldArray({ control, name: 'tests' });
    
    const resetLastTest = async () => {
        await agent.ClientApplicant.resetApplicantLastTest({ applicantId });
        setChangeCount(changeCount + 1);
        setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: 'Last test reset ' }) });
    }

    const resetAllTests = async () => {
        await agent.ClientApplicant.resetApplicantAllTests({ applicantId });
        setChangeCount(changeCount + 1);
        setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: 'All tests reset ' }) });
    }

    const resetApplicant = async () => {
        await agent.ClientApplicant.resetApplicant({ applicantId });
        setChangeCount(changeCount + 1);
        setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: 'Applicant reset ' }) });
    }

    useEffect(() => {
        const fetchApplicant = async () => {
            try
            {
                let formData = {};

                if (showModal) {
                    setLoading(true);

                    let applicantDto: SecureApplicantDto | null = null;
                    if (applicantId) {
                        applicantDto = await agent.Admin.getSecureApplicantById({ applicantId });
                    }

                    formData = {
                        'fullName': `${applicantDto?.applicant.firstName ?? ''} ${applicantDto?.applicant.lastName ?? ''}`,
                        'lastLogin': localDate(applicantDto?.applicant.lastLogin ?? ''),
                        'deletedDate': localDate(applicantDto?.applicant.deletedDate ?? ''),
                        'userAgentList': `${applicantDto?.userAgentList ?? ''}`,
                        'tests': applicantDto?.tests
                    };
                }
                reset(formData)
            } catch (error) {
                console.log(error);
                setShowModal(false);
            } finally {
                setLoading(false);
            }
        }
        fetchApplicant();
    }, [showModal, setShowModal, applicantId, reset, changeCount])

    if (loading)
        return <LoadingComponent />
    else
        return (
            <>
                <Modal
                    scrollable={true}
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    id="modalSecureApplicant"
                    centered={true}
                    backdrop="static"
                    size="xl"
                >
                    <Modal.Header>
                        <Modal.Title>{t('IDS_APPLICANT')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                    </Modal.Header>
                    <Modal.Body className="bg-light-silver">
                        <Form id="formSecureApplicant" className="needs-validation" noValidate>
                            <Row className="g-4">
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="applicantName"
                                        className="small form-floating-plaintext"
                                        label={t('IDS_FULL_NAME')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('fullName')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="userAgentList"
                                        className="small form-floating-plaintext"
                                        label={t('IDS_BROWSERS')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('userAgentList')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="lastLogin"
                                        className="small form-floating-plaintext"
                                        label={t('IDS_LAST_LOGIN')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('lastLogin')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="deletedDate"
                                        className="small form-floating-plaintext"
                                        label={t('IDS_DELETED_ON')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('deletedDate')}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <hr className="text-light-eggplant" />
                            <Row className="g-4">
                                {testFields && testFields.length > 0 &&
                                    <Col xs={12}>
                                        <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive">
                                            <Table striped borderless
                                                id="tblTests"
                                                size="sm"
                                                variant="dark-eggplant"
                                                className="mb-0 table-cell-nowrap"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t('IDS_TEST')}</th>
                                                        <th className="text-center">{t('IDS_TIME_REMAINING')}</th>
                                                        <th className="text-center">{t('IDS_TIME_ELAPSED')}</th>
                                                        <th className="text-center">{t('IDS_STOP_COUNT')}</th>
                                                        <th className="text-center">{t('IDS_STARTED')}</th>
                                                        <th className="text-center">{t('IDS_COMPLETED_ON')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {testFields.map((item, i) => (
                                                            <tr key={item.id}>
                                                                <td>{getValues(`tests[${i}].description`)}</td>
                                                                <td className="text-center">{getValues(`tests[${i}].remainingTime`)}</td>
                                                                <td className="text-center">{getValues(`tests[${i}].clientTimeElapsed`)}</td>
                                                                <td className="text-center">{getValues(`tests[${i}].stopCount`)}</td>
                                                                <td className="text-center">{localDate(getValues(`tests[${i}].startedDate`) ?? '')}</td>
                                                                <td className="text-center">{localDate(getValues(`tests[${i}].completedDate`) ?? '')}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                }
                                <Col xs={12}>
                                    <Button
                                        variant="link"
                                        size="sm"
                                        className="small text-nowrap link-dark-ocean"
                                        onClick={resetLastTest}
                                    >
                                        {t('IDS_RESET_LAST_TEST')}
                                    </Button>
                                    <span className="ms-2 me-3 text-light-eggplant">|</span>
                                    <Button
                                        variant="link"
                                        size="sm"
                                        className="small text-nowrap link-dark-ocean"
                                        onClick={resetAllTests}
                                    >
                                        {t('IDS_RESET_ALL_TESTS')}
                                    </Button>
                                    <span className="ms-2 me-3 text-light-eggplant">|</span>
                                    <Button
                                        variant="link"
                                        size="sm"
                                        className="small text-nowrap link-dark-ocean"
                                        onClick={resetApplicant}
                                    >
                                        {t('IDS_RESET_APPLICANT')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="silver" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                    </Modal.Footer>
                    <BsModalAlert
                        title={t('IDS_SUCCESS')}
                        message={modalAlertState.msg}
                        showModal={modalAlertState.show}
                        setShowModal={(show) => setModalAlertState(prevState => ({ show, msg: prevState.msg }))}
                    />
                </Modal>
            </>
        );
}