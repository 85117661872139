export default function RecaptchaBranding() {
    return (
        <div className="mb-2 pt-2 smaller line-height-100">
            <span>This site is protected by reCAPTCHA and the Google </span>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <span> and </span>
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            <span> apply.</span>
        </div>
    );
}