import { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import agent from '../../../app/api/agent';
import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import LoadingButton from '../../../app/components/LoadingButton';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import {
    formDataToClientApplicantDefaultsRequest
} from '../../../app/models/requestHelpers/clientApplicantDefaultRequest';
import {
    formDataToClientSetupDefaultQuestionsRequest
} from '../../../app/models/requestHelpers/clientSetupDefaultQuestionsRequest';
import {
    formDataToUpdateClientRequest
} from '../../../app/models/requestHelpers/updateClientRequest';
import { StringKeyObject } from '../../../app/models/stringKeyObject';
import { setClient } from '../../../app/store/clientSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { usePrompt } from '../../../app/utility/blockerPrompt';
import { getTitle, locales } from '../../../i18n';
import { setupTabsDefaultState, SetupTabsFormState } from './shared/setupTabsFormState';
import TabApplicantDefaults from './TabApplicantDefaults';
import TabDefaultQuestions from './TabDefaultQuestions';
import TabInitialSetup from './TabInitialSetup';
import TabPositionProfiles from './TabPositionProfiles';
import TabValidLanguages from './TabValidLanguages';

export default function SetupPage() {
    const { t } = useTranslation();
    const { clientSession, status } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id;
    const clientLangs = clientSession?.languageBC;
    const [formStateInitialSetup, setFormStateInitialSetup] = useState<SetupTabsFormState>({ ...setupTabsDefaultState });
    const [formStateDefaultQuestions, setFormStateDefaultQuestions] = useState<SetupTabsFormState>({ ...setupTabsDefaultState });
    const [formStateApplicantDefaults, setFormStateApplicantDefaults] = useState<SetupTabsFormState>({ ...setupTabsDefaultState });
    const [formStateValidLanguages, setFormStateValidLanguages] = useState<SetupTabsFormState>({ ...setupTabsDefaultState });
    const dispatch = useAppDispatch();
    
    const clientSetupTabs: BsTabs = {
        'initialSetup': {
            linkText: 'IDS_INITIAL_SETUP',
            isInvalid: !status.includes('pending') && !formStateInitialSetup.isValid,
            paneComponent: <TabInitialSetup setFormState={setFormStateInitialSetup} />
        },
        'defaultQuestions': {
            linkText: 'IDS_DEFAULT_APP_QUESTIONS',
            isInvalid: !status.includes('pending') && !formStateDefaultQuestions.isValid,
            paneComponent: <TabDefaultQuestions setFormState={setFormStateDefaultQuestions} />
        },
        'positionProfiles': {
            linkText: 'IDS_POSITION_PROFILES',
            paneComponent: <TabPositionProfiles />
        },
        'applicantDefaults': {
            linkText: 'IDS_APPLICANT_DEFAULTS',
            isInvalid: !status.includes('pending') && !formStateApplicantDefaults.isValid,
            paneComponent: <TabApplicantDefaults setFormState={setFormStateApplicantDefaults} />
        },
        'validLanguages': {
            linkText: 'IDS_VALID_LANGUAGES',
            isInvalid: !status.includes('pending') && !formStateValidLanguages.isValid,
            paneComponent: <TabValidLanguages setFormState={setFormStateValidLanguages} />
        }
    };
    
    usePrompt(t('IDS_CONFIRM_LEAVE'),
        formStateInitialSetup.isDirty || formStateDefaultQuestions.isDirty ||
        formStateApplicantDefaults.isDirty || formStateValidLanguages.isDirty);
    
    async function submitFormInitialSetup(data: FieldValues) {
        try {
            if (clientSession && clientSession.vwClient) {
                let defaultLang = clientSession.vwClient.defaultLanguage || '';
                if (formStateValidLanguages.getValues) {
                    let newDefaultLang = formStateValidLanguages.getValues('defaultLanguage');
                    if (newDefaultLang && newDefaultLang !== defaultLang) {
                        defaultLang = newDefaultLang;
                        dispatch(setClient({
                            ...clientSession,
                            vwClient: {
                                ...clientSession.vwClient,
                                defaultLanguage: defaultLang
                            }
                        }));
                    }
                }
                let updateClientRequest = formDataToUpdateClientRequest(clientSession.vwClient, data, defaultLang);
                await agent.Client.updateClient(updateClientRequest);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormDefaultQuestions(data: FieldValues) {
        try {
            if (clientId) {
                let languageQuestions = formDataToClientSetupDefaultQuestionsRequest(data);
                await agent.ClientPosition.updateClientDefaultQuestion(languageQuestions, { clientId });
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormValidLanguages(data: FieldValues) {
        try {
            if (clientId && clientLangs) {
                let updatedClientLangs = [...clientLangs];
                for (const languageCode of Object.keys(locales)) {
                    if (clientLangs.some(x => x.languageCode === languageCode) && data[`isSelected-${languageCode}`] === false) {
                        await agent.ClientLanguage.removeClientLanguage({ clientId, languageCode });
                        updatedClientLangs = updatedClientLangs.filter((val, idx, arr) => val.languageCode !== languageCode);
                    } else if (!clientLangs.some(x => x.languageCode === languageCode) && data[`isSelected-${languageCode}`] === true) {
                        let newClientLanguageId = await agent.ClientLanguage.addClientLanguage({ clientId, languageCode });
                        updatedClientLangs.push({ id: newClientLanguageId, clientId, languageCode });
                    }
                }
                
                dispatch(setClient({
                    ...clientSession,
                    languageBC: updatedClientLangs
                }));
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormApplicantDefaults(data: FieldValues) {
        try {
            if (clientId && clientSession && clientSession.vwClient) {
                let updateClientAppDefaultsRequest = formDataToClientApplicantDefaultsRequest(clientSession.vwClient, data);
                await agent.Client.updateClientAppDefaults(updateClientAppDefaultsRequest);

                if (updateClientAppDefaultsRequest) {
                    dispatch(setClient({
                        ...clientSession,
                        vwClient: {
                            ...clientSession.vwClient,
                            applicantDefaultEmail: updateClientAppDefaultsRequest.email,
                            applicantDefaultAchiever: updateClientAppDefaultsRequest.achiever,
                            applicantDefaultAchieverOrder: updateClientAppDefaultsRequest.achieverOrder,
                            applicantDefaultApplication: updateClientAppDefaultsRequest.application,
                            applicantDefaultApplicationOrder: updateClientAppDefaultsRequest.applicationOrder,
                            applicantDefaultAptitudeTest: updateClientAppDefaultsRequest.aptitudeTest,
                            applicantDefaultAptitudetestOrder: updateClientAppDefaultsRequest.aptitudeTestOrder,
                            applicantDefaultDpatInhouse: updateClientAppDefaultsRequest.aptitudeInhouse,
                            applicantDefaultDpatInhouseOrder: updateClientAppDefaultsRequest.aptitudeInhouseOrder
                        }
                    }));
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function handleSaveChanges() {
        let defaultLangChanged = formStateValidLanguages.isDirty
            && formStateValidLanguages.getValues
            && formStateValidLanguages.getValues('defaultLanguage')
            && formStateValidLanguages.getValues('defaultLanguage') !== clientSession?.vwClient.defaultLanguage;

        let anyLangsAddedOrRemoved = formStateValidLanguages.isDirty
            && formStateValidLanguages.getValues
            && languagesAddedOrRemoved(formStateValidLanguages.getValues()) > 0;
        
        if (formStateInitialSetup.isValid && formStateInitialSetup.handleSubmit && (formStateInitialSetup.isDirty || defaultLangChanged)) {
            await formStateInitialSetup.handleSubmit(submitFormInitialSetup)()
                .then(() => {
                    if (!defaultLangChanged) {
                        resetAfterSubmit(formStateInitialSetup);
                    } else if (!anyLangsAddedOrRemoved) {
                        resetAfterSubmit(formStateValidLanguages);
                    }
                });
        }

        if (formStateDefaultQuestions.isValid && formStateDefaultQuestions.handleSubmit && formStateDefaultQuestions.isDirty) {
            await formStateDefaultQuestions.handleSubmit(submitFormDefaultQuestions)()
                .then(() => resetAfterSubmit(formStateDefaultQuestions));
        }

        if (formStateApplicantDefaults.isValid && formStateApplicantDefaults.handleSubmit && formStateApplicantDefaults.isDirty) {
            await formStateApplicantDefaults.handleSubmit(submitFormApplicantDefaults)()
                .then(() => resetAfterSubmit(formStateApplicantDefaults));
        }

        if (formStateValidLanguages.isValid && formStateValidLanguages.handleSubmit && formStateValidLanguages.isDirty && anyLangsAddedOrRemoved) {
            await formStateValidLanguages.handleSubmit(submitFormValidLanguages)()
                .then(() => resetAfterSubmit(formStateValidLanguages));
        }

    }

    function resetAfterSubmit(formState: SetupTabsFormState) {
        if (formState.reset && formState.getValues) {
            formState.reset(formState.getValues());
        }
    }

    function languagesAddedOrRemoved(fields: StringKeyObject<any>) {
        let changeCount = 0;
        for (const langCode of Object.keys(locales)) {
            if (clientLangs &&
                ((clientLangs.some(x => x.languageCode === langCode) && fields[`isSelected-${langCode}`] === false) ||
                    (!clientLangs.some(x => x.languageCode === langCode) && fields[`isSelected-${langCode}`] === true))) {
                changeCount++;
            }
        }
        return changeCount;
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_CLIENT_SETUP')}</title>
            </Helmet>
            <Tab.Container defaultActiveKey="initialSetup">
                <div className="main-titlebar main-titlebar-tabs bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="fs-5 line-height-100">{`${t('IDS_CLIENT_SETUP')} - ${clientSession?.vwClient.name}`}</div>
                        <LoadingButton
                            type="button"
                            variant="dark-ocean"
                            className="text-nowrap"
                            onClick={handleSaveChanges}
                            disabled={!(
                                (formStateInitialSetup.isValid && formStateDefaultQuestions.isValid &&
                                formStateApplicantDefaults.isValid && formStateValidLanguages.isValid) &&
                                (formStateInitialSetup.isDirty || formStateDefaultQuestions.isDirty ||
                                formStateApplicantDefaults.isDirty || formStateValidLanguages.isDirty)
                            )}
                            loading={(
                                formStateInitialSetup.isSubmitting ||
                                formStateDefaultQuestions.isSubmitting ||
                                formStateApplicantDefaults.isSubmitting ||
                                formStateValidLanguages.isSubmitting
                            )}
                        >
                            {t('IDS_SAVE_CHANGES')}
                        </LoadingButton>
                    </div>
                    <BsScrollableTabs tabs={clientSetupTabs} />
                </div>
                <div className="main-content bg-light-silver">
                    <LoadingComponentControlled
                        delay={10}
                        positionClass="position-fixed"
                        show={(
                            formStateInitialSetup.isSubmitting ||
                            formStateDefaultQuestions.isSubmitting ||
                            formStateApplicantDefaults.isSubmitting ||
                            formStateValidLanguages.isSubmitting ||
                            status.includes('pending')
                        )} />
                    <Tab.Content>
                        {Object.keys(clientSetupTabs).map((key) => (
                            <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                {clientSetupTabs[key].paneComponent}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </div>
                <div className="main-titlebar main-titlebar-bottom bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-end mb-3">
                        <LoadingButton
                            type="button"
                            variant="dark-ocean"
                            className="text-nowrap"
                            onClick={handleSaveChanges}
                            disabled={!(
                                (formStateInitialSetup.isValid && formStateDefaultQuestions.isValid &&
                                formStateApplicantDefaults.isValid && formStateValidLanguages.isValid) &&
                                (formStateInitialSetup.isDirty || formStateDefaultQuestions.isDirty ||
                                formStateApplicantDefaults.isDirty || formStateValidLanguages.isDirty)
                            )}
                            loading={(
                                formStateInitialSetup.isSubmitting ||
                                formStateDefaultQuestions.isSubmitting ||
                                formStateApplicantDefaults.isSubmitting ||
                                formStateValidLanguages.isSubmitting
                            )}
                        >
                            {t('IDS_SAVE_CHANGES')}
                        </LoadingButton>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
}