import { ChangeEvent, KeyboardEvent } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../app/store/storeHooks';
import { RegExps } from '../../../app/utility/util';

interface Props {
    pageInputValue: number;
    setPageInputValue: (val: number) => void;
    goToPage: (pageNum: number) => void;
}

export default function TestPagingInput({ pageInputValue, setPageInputValue, goToPage }: Props) {
    const { t } = useTranslation();
    const totalPages = useAppSelector(state => Math.ceil(state.test.testSession!.totalQuestions / state.test.testSession!.questionsPerPage));

    return (
        <>
            <Form.Control
                id="inputPage"
                size="sm"
                type="text"
                maxLength={2}
                style={{ width: '3rem' }}
                value={pageInputValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '' || e.target.value.match(RegExps.digits)) {
                        setPageInputValue(Number(e.target.value));
                    };
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && pageInputValue) {
                        e.preventDefault();
                        const curVal = pageInputValue;
                        goToPage(curVal < 1 ? 1 : (curVal > totalPages ? totalPages : curVal));
                    }
                }}
            />
            <small className="text-nowrap">{`${t('IDS_OF')} ${totalPages}`}</small>
            <Button
                variant="silver"
                size="sm"
                className="me-2"
                onClick={() => goToPage(pageInputValue < 1
                    ? 1
                    : (pageInputValue > totalPages ? totalPages : pageInputValue))
                }
            >
                {t('IDS_GO')}
            </Button>
        </>
    );
}