import { useState } from 'react';
import { Button, Tab } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import agent from '../../../app/api/agent';
import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import LoadingButton from '../../../app/components/LoadingButton';
import { ApplicantTestProgress } from '../../../app/enums/applicantTestProgress';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import {
    FieldValuesAppEdu, FieldValuesAppEEOData, FieldValuesAppExp, FieldValuesAppQues,
    FieldValuesAppRefs
} from '../../../app/models/fieldValueTypes';
import { ApplicantInfoRequest } from '../../../app/models/requestHelpers/applicantInfoRequest';
import {
    applicantProgressDtoToRequest
} from '../../../app/models/requestHelpers/applicantProgressRequest';
import {
    addApplicantEducation, addApplicantEEOData, addApplicantExperience, addApplicantInfo,
    addApplicantQuestions, addApplicantReference, applicantSessionCompletedSection
} from '../../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { setPageNumber } from '../../../app/store/testSlice';
import { usePrompt } from '../../../app/utility/blockerPrompt';
import { getLangOrDefault, getTitle, localeCode } from '../../../i18n';
import {
    ApplicationTabsFormState, getApplicationTabsDefaultState
} from './shared/applicationTabsFormState';
import TabApplicantInfo from './TabApplicantInfo';
import TabEducation from './TabEducation';
import TabEEOData from './TabEEOData';
import TabQuestionnaire from './TabQuestionnaire';
import TabReferences from './TabReferences';
import TabUKDMData from './TabUKDMData';
import TabWorkExperience from './TabWorkExperience';

enum ApplicationTabs {
    ApplicantInfo = 'applicantInfo',
    WorkExperience = 'workExperience',
    Education = 'education',
    References = 'references',
    EeoData = 'eeoData',
    Questions = 'questions'
};

export default function ApplicationPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const langIsEnGb = getLangOrDefault() === localeCode.enGB;
    const {
        applicant: { applicantId, clientName, positionId }, progress, client: { references, requestEeoInfo, isRecType }, clientPosition: { questions }
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const { status } = useAppSelector((state) => state.account);
    const [activeTab, setActiveTab] = useState(ApplicationTabs.ApplicantInfo);

    const [formStateApplicantInfo, setFormStateApplicantInfo] = useState<
        ApplicationTabsFormState<ApplicantInfoRequest>
    >(getApplicationTabsDefaultState<ApplicantInfoRequest>());

    const [formStateWorkExperience, setFormStateWorkExperience] = useState<
        ApplicationTabsFormState<FieldValuesAppExp>
    >(getApplicationTabsDefaultState<FieldValuesAppExp>());

    const [formStateEducation, setFormStateEducation] = useState<
        ApplicationTabsFormState<FieldValuesAppEdu>
    >(getApplicationTabsDefaultState<FieldValuesAppEdu>());

    const [formStateReferences, setFormStateReferences] = useState<
        ApplicationTabsFormState<FieldValuesAppRefs>
    >(getApplicationTabsDefaultState<FieldValuesAppRefs>());
    
    const [formStateEeoData, setFormStateEeoData] = useState<
        ApplicationTabsFormState<FieldValuesAppEEOData>
    >(getApplicationTabsDefaultState<FieldValuesAppEEOData>());
    
    const [formStateQuestions, setFormStateQuestions] = useState<
        ApplicationTabsFormState<FieldValuesAppQues>
    >(getApplicationTabsDefaultState<FieldValuesAppQues>());

    usePrompt(t('IDS_CONFIRM_LEAVE'),
        formStateApplicantInfo.isDirty ||
        formStateWorkExperience.isDirty ||
        formStateEducation.isDirty ||
        (references > 0 && formStateReferences.isDirty) ||
        (isRecType && requestEeoInfo && formStateEeoData.isDirty) ||
        (questions.length > 0 && formStateQuestions.isDirty)
    );

    const clientSetupTabs: BsTabs = {
        [ApplicationTabs.ApplicantInfo]: {
            linkText: 'IDS_APPLICANT_INFO',
            isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.ApplicantInfo && !formStateApplicantInfo.isValid,
            paneComponent: <TabApplicantInfo setFormState={setFormStateApplicantInfo} />,
            disable: activeTab !== ApplicationTabs.ApplicantInfo
        },
        [ApplicationTabs.WorkExperience]: {
            linkText: 'IDS_WORK_EXPERIENCE',
            isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.WorkExperience && !formStateWorkExperience.isValid,
            paneComponent: <TabWorkExperience setFormState={setFormStateWorkExperience} />,
            disable: activeTab !== ApplicationTabs.WorkExperience
        },
        [ApplicationTabs.Education]: {
            linkText: 'IDS_EDUCATION',
            isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.Education && !formStateEducation.isValid,
            paneComponent: <TabEducation setFormState={setFormStateEducation} />,
            disable: activeTab !== ApplicationTabs.Education
        },
        ...(references > 0 ? {
            [ApplicationTabs.References]: {
                linkText: 'IDS_REFERENCES',
                isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.References && !formStateReferences.isValid,
                paneComponent: <TabReferences setFormState={setFormStateReferences} />,
                disable: activeTab !== ApplicationTabs.References
            }
        } : {}),
        ...(isRecType && requestEeoInfo ? {
            [ApplicationTabs.EeoData]: {
                linkText: langIsEnGb ? 'EOM Form' : 'EEO Data',
                isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.EeoData && !formStateEeoData.isValid,
                paneComponent: langIsEnGb ? <TabUKDMData setFormState={setFormStateEeoData} /> : <TabEEOData setFormState={setFormStateEeoData} />,
                disable: activeTab !== ApplicationTabs.EeoData
            }
        } : {}),
        ...(questions.length > 0 ? {
            [ApplicationTabs.Questions]: {
                linkText: 'IDS_QUESTIONNAIRE',
                isInvalid: !status.includes('pending') && activeTab === ApplicationTabs.Questions && !formStateQuestions.isValid,
                paneComponent: <TabQuestionnaire setFormState={setFormStateQuestions} />,
                disable: activeTab !== ApplicationTabs.Questions
            }
        } : {})
    };

    async function submitFormApplicantInfo(data: ApplicantInfoRequest) {
        try {
            await dispatch(addApplicantInfo(data));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormWorkExperience(data: FieldValuesAppExp) {
        try {
            await dispatch(addApplicantExperience({ ...data, applicantId }));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormEducation(data: FieldValuesAppEdu) {
        try {
            await dispatch(addApplicantEducation({ ...data, applicantId }));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormReferences(data: FieldValuesAppRefs) {
        try {
            await dispatch(addApplicantReference({ ...data, applicantId }));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormEEOData(data: FieldValuesAppEEOData) {
        try {
            await dispatch(addApplicantEEOData(data));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function submitFormQuestions(data: FieldValuesAppQues) {
        try {
            await dispatch(addApplicantQuestions({ ...data, applicantId, positionId }));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    function resetAfterSubmit<T extends FieldValues>(formState: ApplicationTabsFormState<T>) {
        if (formState.reset && formState.getValues) {
            formState.reset(formState.getValues());
        }
    }

    function handleSetActiveTab(tab: ApplicationTabs) {
        setActiveTab(tab);
        window.scrollTo({ top: 0, behavior: 'auto' });
        setTimeout(() => document.querySelector('.main-titlebar-tabs .nav-link.active')!.scrollIntoView({ behavior: 'auto', block: 'nearest' }), 100);
    }

    async function handleSaveChanges() {
        let applicationFinished = false;
        if (activeTab === ApplicationTabs.ApplicantInfo && formStateApplicantInfo.isValid && formStateApplicantInfo.handleSubmit) {
            await formStateApplicantInfo.handleSubmit(submitFormApplicantInfo)()
                .then(() => {
                    resetAfterSubmit<ApplicantInfoRequest>(formStateApplicantInfo);
                    handleSetActiveTab(ApplicationTabs.WorkExperience);
                });
        } else if (activeTab === ApplicationTabs.WorkExperience && formStateWorkExperience.isValid && formStateWorkExperience.handleSubmit) {
            await formStateWorkExperience.handleSubmit(submitFormWorkExperience)()
                .then(() => {
                    resetAfterSubmit<FieldValuesAppExp>(formStateWorkExperience);
                    handleSetActiveTab(ApplicationTabs.Education);
                });
        } else if (activeTab === ApplicationTabs.Education && formStateEducation.isValid && formStateEducation.handleSubmit) {
            await formStateEducation.handleSubmit(submitFormEducation)()
                .then(() => {
                    resetAfterSubmit<FieldValuesAppEdu>(formStateEducation);
                    if (references > 0) {
                        handleSetActiveTab(ApplicationTabs.References);
                    } else if (isRecType && requestEeoInfo) {
                        handleSetActiveTab(ApplicationTabs.EeoData);
                    } else if (questions.length > 0) {
                        handleSetActiveTab(ApplicationTabs.Questions);
                    } else {
                        applicationFinished = true;
                    }
                });
        } else if (activeTab === ApplicationTabs.References && references > 0 && formStateReferences.isValid && formStateReferences.handleSubmit) {
            await formStateReferences.handleSubmit(submitFormReferences)()
                .then(() => {
                    resetAfterSubmit<FieldValuesAppRefs>(formStateReferences);
                    if (isRecType && requestEeoInfo) {
                        handleSetActiveTab(ApplicationTabs.EeoData);
                    } else if (questions.length > 0) {
                        handleSetActiveTab(ApplicationTabs.Questions);
                    } else {
                        applicationFinished = true;
                    }
                });
        } else if (activeTab === ApplicationTabs.EeoData && isRecType && requestEeoInfo && formStateEeoData.isValid && formStateEeoData.handleSubmit) {
            await formStateEeoData.handleSubmit(submitFormEEOData)()
                .then(() => {
                    resetAfterSubmit<FieldValuesAppEEOData>(formStateEeoData);
                    if (questions.length > 0) {
                        handleSetActiveTab(ApplicationTabs.Questions);
                    } else {
                        applicationFinished = true;
                    }
                });
        } else if (activeTab === ApplicationTabs.Questions && questions.length > 0 && formStateQuestions.isValid && formStateQuestions.handleSubmit) {
            await formStateQuestions.handleSubmit(submitFormQuestions)()
                .then(() => {
                    resetAfterSubmit<FieldValuesAppQues>(formStateQuestions);
                    applicationFinished = true;
                });
        }

        if (progress.progressId === ApplicantTestProgress.Application && applicationFinished) {
            let { applicantSessionDto: updatedAppSessDto = {} as ApplicantSessionDto } = await dispatch(applicantSessionCompletedSection()).unwrap();

            let progressUpdateReq = applicantProgressDtoToRequest(updatedAppSessDto.progress, updatedAppSessDto.clientId, updatedAppSessDto.applicant.positionId, true, false);
            await agent.ApplicantTest.updateApplicantProgress(progressUpdateReq);

            if (updatedAppSessDto.progress.progressId === ApplicantTestProgress.Personality) {
                await dispatch(setPageNumber(0));
                navigate('/Applicant/Achiever');
            }
            else if (updatedAppSessDto.progress.progressId === ApplicantTestProgress.AlphaSequence2) {
                await dispatch(setPageNumber(0));
                navigate('/Applicant/DPATInhouse');
            }
            else if (updatedAppSessDto.progress.progressId === ApplicantTestProgress.AlphaSequence) {
                await dispatch(setPageNumber(0));
                navigate('/Applicant/DPAT');
            } else {
                if (updatedAppSessDto.progress.progressId === ApplicantTestProgress.Complete) {
                    await agent.ApplicantTest.completeApplicationProgress({ applicantId, emailTo: '', applicationOnly: true });
                }
                navigate('/Applicant');
            }
        }
    }

    function handleBack() {
        if (activeTab === ApplicationTabs.WorkExperience) {
            handleSetActiveTab(ApplicationTabs.ApplicantInfo);
        } else if (activeTab === ApplicationTabs.Education) {
            handleSetActiveTab(ApplicationTabs.WorkExperience);
        } else if (activeTab === ApplicationTabs.References) {
            handleSetActiveTab(ApplicationTabs.Education);
        } else if (activeTab === ApplicationTabs.EeoData) {
            handleSetActiveTab(
                references > 0
                    ? ApplicationTabs.References
                    : ApplicationTabs.Education
            );
        } else if (activeTab === ApplicationTabs.Questions) {
            handleSetActiveTab(
                (isRecType && requestEeoInfo)
                    ? ApplicationTabs.EeoData
                    : (references > 0
                        ? ApplicationTabs.References
                        : ApplicationTabs.Education)
            );
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_APPLICATION')}</title>
            </Helmet>
            <Tab.Container defaultActiveKey={ApplicationTabs.ApplicantInfo} activeKey={activeTab}>
                <div className="main-titlebar main-titlebar-tabs bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="fs-5 line-height-100">{`${t('IDS_APPLICATION')} - ${clientName}`}</div>
                    </div>
                    <BsScrollableTabs tabs={clientSetupTabs} />
                </div>
                <div className="main-content bg-light-silver">
                    <LoadingComponentControlled
                        delay={10}
                        positionClass="position-fixed"
                        show={(
                            formStateApplicantInfo.isSubmitting ||
                            formStateWorkExperience.isSubmitting ||
                            formStateEducation.isSubmitting ||
                            (references > 0 && formStateReferences.isSubmitting) ||
                            (isRecType && requestEeoInfo && formStateEeoData.isSubmitting) ||
                            (questions.length > 0 && formStateQuestions.isSubmitting)
                        )}
                    />
                    <Tab.Content>
                        {Object.keys(clientSetupTabs).map((key) => (
                            <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                {clientSetupTabs[key].paneComponent}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </div>
                <div className="main-titlebar main-titlebar-bottom bg-lightest-ocean pt-3">
                    <div className="d-flex align-items-center justify-content-end mb-3">
                        <div className="text-nowrap">
                            <Button
                                variant="dark-ocean"
                                className="text-nowrap me-2"
                                onClick={handleBack}
                                disabled={activeTab === ApplicationTabs.ApplicantInfo}
                            >
                                {t('IDS_BACK')}
                            </Button>
                            <LoadingButton
                                type="button"
                                variant="dark-ocean"
                                className="text-nowrap"
                                onClick={handleSaveChanges}
                                disabled={
                                    (activeTab === ApplicationTabs.ApplicantInfo && !formStateApplicantInfo.isValid) ||
                                    (activeTab === ApplicationTabs.WorkExperience && !formStateWorkExperience.isValid) ||
                                    (activeTab === ApplicationTabs.Education && !formStateEducation.isValid) ||
                                    (activeTab === ApplicationTabs.References && references > 0 && !formStateReferences.isValid) ||
                                    (activeTab === ApplicationTabs.EeoData && isRecType && requestEeoInfo && !formStateEeoData.isValid) ||
                                    (activeTab === ApplicationTabs.Questions && questions.length > 0 && !formStateQuestions.isValid)
                                }
                                loading={(
                                    formStateApplicantInfo.isSubmitting ||
                                    formStateWorkExperience.isSubmitting ||
                                    formStateEducation.isSubmitting ||
                                    (references > 0 && formStateReferences.isSubmitting) ||
                                    (isRecType && requestEeoInfo && formStateEeoData.isSubmitting) ||
                                    (questions.length > 0 && formStateQuestions.isSubmitting)
                                )}
                            >
                                {t('IDS_SAVE_AND_CONTINUE')}
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
}