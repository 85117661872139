import { useState } from 'react';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import QuestionBadge from '../../../app/components/QuestionBadge';
import { QuestionType } from '../../../app/enums/testQuestionType';
import { AchieverSectionId, DPATSectionId } from '../../../app/enums/testSections';
import { TestType } from '../../../app/enums/testType';
import { TestSession } from '../../../app/models/test/testSession';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { setQuestionResponse } from '../../../app/store/testSlice';

interface Props {
    questionIndex: number;
    questionPosition: number;
}

const letterMap = ['A', 'B', 'C', 'D', 'E'];

export default function TestQuestion({ questionIndex, questionPosition }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { testSession } = useAppSelector(state => state.test);
    const { totalQuestions, pageNumber, questionsPerPage } = testSession || {} as TestSession;
    const { testType = TestType.Achiever, section, randomizedAnswers } = testSession || {} as TestSession;
    const question = section?.questions[questionIndex];

    const [responseState, setResponseState] = useState(question?.response ?? 0);

    const questionNumber = questionIndex + 1;
    const isDpatOrDpatInh = (testType === TestType.DPAT || testType === TestType.DPATInhouse);

    const setResponse = async (questionIndex: number, response: number) => {
        await dispatch(setQuestionResponse({ questionIndex, response }));
    }

    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResponseState(Number(e.target.value));
        setResponse(Number(e.target.dataset['questionIndex']), Number(e.target.value));
    }

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResponseState(Number(e.target.checked));
        setResponse(Number(e.target.dataset['questionIndex']), Number(e.target.checked));
    }

    const resetRadio = (e: React.MouseEvent<HTMLButtonElement>) => {
        setResponseState(0);
        setResponse(Number((e.target as HTMLButtonElement).dataset['questionIndex']), 0);
    }

    if (!testSession)
        return null;
    else if (!question) {
        return null;
    }
    else if (testType === TestType.Achiever && section!.id === AchieverSectionId.Perception)
        return (
            <Form.Check.Label className="list-group-item d-flex">
                <div className="me-2 font-monospace text-end" style={{ minWidth: '2rem' }}>{`${questionNumber}.`}</div>
                <div style={{ minWidth: '2rem' }}>
                    <Form.Check.Input
                        type="checkbox"
                        id={`${questionIndex}`}
                        name={`${questionIndex}`}
                        onChange={handleChangeCheckbox}
                        checked={responseState === 1}
                        data-question-index={questionIndex}
                    />
                </div>
                <div className="mw-8rem">{question.question.split('\t')[0]}</div>
                <div>{question.question.split('\t')[1]}</div>
            </Form.Check.Label>
        );
    else
        return (
            <Row
                className="gy-3 gx-5 mb-5"
                {...(!(isDpatOrDpatInh && (section!.id === DPATSectionId.AlphaSequence || section!.id === DPATSectionId.GraphicSequence))
                    ? { xs: 1, lg: 2 }
                    : {})
                }
            >
                <Col
                    {...((isDpatOrDpatInh && section!.id === DPATSectionId.AlphaSequence)
                        ? { xs: 12, md: 4, lg: 4, xl: 3, xxl: 2 }
                        : (isDpatOrDpatInh && section!.id === DPATSectionId.GraphicSequence)
                            ? { xs: 12, md: 4, lg: 4, xl: 3, xxl: 3 }
                            : {})
                    }
                >
                    <h6 className="d-flex align-items-center">
                        <QuestionBadge className="small" questionNumber={(questionPosition + 1) + ((pageNumber - 1) * questionsPerPage)} totalQuestions={totalQuestions} />
                        <div className="text-dark-ocean">{t('IDS_QUESTION')}</div>
                    </h6>
                    {('image' in question && question.question === 'image')
                        ? <img src={`data:image/gif;base64,${question.image}`} alt="" />
                        : <div className="py-2">{question.question}</div>
                    }
                </Col>
                <Col>
                    <h6 className="text-dark-ocean">{'Answer'}</h6>
                    {question.questionType === QuestionType.YesNoMaybe &&
                        <ListGroup>
                            {[...Array(3)].map((_, i) => (
                                <Form.Check.Label key={i} className="list-group-item">
                                    <Form.Check.Input
                                        type="radio"
                                        id={`${questionIndex}_${i + 1}`}
                                        name={`Group${questionIndex}`}
                                        onChange={handleChangeRadio}
                                        checked={responseState === (i + 1)}
                                        value={i + 1}
                                        className="me-2"
                                        data-question-index={questionIndex}
                                    />
                                    {question.answers[i]}
                                </Form.Check.Label>
                            ))}
                        </ListGroup>
                    }

                    {question.questionType === QuestionType.MultipleChoice &&
                        <div className={isDpatOrDpatInh ? 'd-flex gap-3 justify-content-between align-items-center' : ''}>
                            {!!question.answers.length &&
                                <ListGroup
                                    {...(isDpatOrDpatInh ?
                                        { horizontal: (section!.id === DPATSectionId.MathWordProblems ? 'xxl' : 'md'), className: 'flex-fill' }
                                        : {})
                                    }
                                >
                                    {(isDpatOrDpatInh
                                        ? <>
                                            {randomizedAnswers[questionIndex].map((answerIdx, i) => (
                                                <Form.Check.Label
                                                    key={i}
                                                    className={`list-group-item flex-fill ${(section!.id === DPATSectionId.MathWordProblems ? 'text-xxl-center' : 'text-md-center')}`}
                                                >
                                                    <Form.Check.Input
                                                        type="radio"
                                                        id={`${questionIndex}_${i}`}
                                                        checked={responseState === answerIdx}
                                                        value={answerIdx}
                                                        onChange={handleChangeRadio}
                                                        name={`Group${questionIndex}`}
                                                        className="me-2"
                                                        data-question-index={questionIndex}
                                                    />
                                                    {question.answers[answerIdx - 1]}
                                                </Form.Check.Label>
                                            ))}
                                        </>
                                        : <>
                                            {question.answers.map((answer, i) => (
                                                <Form.Check.Label
                                                    key={i}
                                                    className="list-group-item flex-fill"
                                                >
                                                    <Form.Check.Input
                                                        type="radio"
                                                        id={`${questionIndex}_${i}`}
                                                        checked={responseState === (i + 1)}
                                                        value={i + 1}
                                                        onChange={handleChangeRadio}
                                                        name={`Group${questionIndex}`}
                                                        className="me-2"
                                                        data-question-index={questionIndex}
                                                    />
                                                    {answer}
                                                </Form.Check.Label>
                                            ))}
                                        </>
                                    )}
                                </ListGroup>
                            }

                            {('imageAnswers' in question && !!question.imageAnswers.length) &&
                                <div className="d-grid gap-3 w-100 justify-content-between" style={{ gridTemplateColumns: 'repeat(auto-fit, 51px)' }}>
                                    {randomizedAnswers[questionIndex].map((answerIdx, i) => (
                                        <div key={i} className="d-flex">
                                            <Form.Check.Label className="text-center">
                                                <img src={`data:image/gif;base64,${question.imageAnswers[answerIdx - 1]}`} alt="" />
                                                <div>
                                                    <Form.Check.Input
                                                        type="radio"
                                                        id={`${questionIndex}_${i}`}
                                                        checked={responseState === answerIdx}
                                                        value={answerIdx}
                                                        onChange={handleChangeRadio}
                                                        name={`Group${questionIndex}`}
                                                        className="me-2"
                                                        data-question-index={questionIndex}
                                                    />
                                                    {letterMap[i]}
                                                </div>
                                            </Form.Check.Label>
                                        </div>
                                    ))}
                                    <div className="d-flex invisible" style={{ width: '51px' }}></div>
                                </div>
                            }

                            {isDpatOrDpatInh &&
                                <div>
                                    <Button
                                        variant="silver"
                                        size="sm"
                                        data-question-index={questionIndex}
                                        onClick={resetRadio}
                                    >
                                        {t('IDS_CLEAR')}
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </Col>
            </Row>
        );
}