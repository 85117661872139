import { useContext } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import HelpTooltipIcon from '../../app/components/HelpTooltipIcon';
import LoadingButton from '../../app/components/LoadingButton';
import { UserType } from '../../app/enums/userType';
import { FieldValuesChangePassword } from '../../app/models/fieldValueTypes';
import { setUser, signInUser, signOut } from '../../app/store/accountSlice';
import { clearClient } from '../../app/store/clientSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { clearTestSession, initializeTestSession } from '../../app/store/testSlice';
import { RouteAuthCtx } from '../../app/utility/routeAuthCtx';
import { getTitle, parseValidationMsg } from '../../i18n';

interface Props {
    isSetPassword: boolean;
    setPasswordUsername?: string;
}

export default function ChangePasswordPage({ isSetPassword, setPasswordUsername = '' }: Props) {
    const authCtx = useContext(RouteAuthCtx);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get('token');
    const dispatch = useAppDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { user } = useAppSelector(state => state.account);

    const { formState: { isValid, isSubmitting, errors }, handleSubmit, register } = useForm<FieldValuesChangePassword>({
        mode: 'all',
        resolver: yupResolver(yup.object({
            currentPassword: user?.userType === UserType.Client
                ? yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_OLD_PASSWORD'] })
                : yup.string(),
            newPassword: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_NEW_PASSWORD'] }),
            newPasswordConfirm: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CONFIRM_NEW_PASSWORD'] })
                .oneOf([yup.ref('newPassword')], 'IDS_PASSWORD_EQUAL_CONFIRM')
        }))
    });

    async function submitForm(data: FieldValuesChangePassword) {
        try {
            if (isSetPassword && token) {
                if (!executeRecaptcha) return;
                const recaptchaToken = await executeRecaptcha('setApplicantPassword');
                sessionStorage.setItem('reCaptchaToken', recaptchaToken);
                const passwordChanged = await agent.UserAccount.setApplicantPassword({ ...data, token });

                if (passwordChanged) {
                    const recaptchaToken2 = await executeRecaptcha('login');
                    sessionStorage.setItem('reCaptchaToken', recaptchaToken2);
                    await dispatch(signInUser({ username: setPasswordUsername, password: data.newPassword, userType: authCtx.userType }));

                    navigate(`/Auth${authCtx.userTypePath}/Verify`);
                }
            } else {
                const passwordChanged = await agent.UserAccount.changePassword(data);

                if (passwordChanged) {
                    if (user?.forcePasswordReset) {
                        dispatch(setUser({ ...user, forcePasswordReset: false }));
                    }

                    if (authCtx.userType === UserType.Applicant) {
                        await dispatch(initializeTestSession());
                    }
                    navigate(`/${authCtx.userTypePath}`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCancelClick = () => {
        if (user?.forcePasswordReset) {
            if (user?.userType === UserType.Client) {
                dispatch(clearClient());
            } else if (user?.userType === UserType.Applicant) {
                dispatch(clearTestSession());
            }
            dispatch(signOut());
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_CHANGE_PASSWORD')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                <h1 className="mb-4 fw-bold">{t('IDS_CHANGE_PASSWORD')}</h1>
                <Form onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                    {/* Hidden username input added only for auto-complete/password manager purposes, as suggested by Chrome Dev Tools */}
                    <FloatingLabel
                        controlId="inputHiddenUsername"
                        className={`small mb-3 ${!isSetPassword ? 'd-none' : ''}`}
                        label={t('IDS_USERNAME')}
                    >
                        <Form.Control
                            type="text"
                            maxLength={50}
                            className="form-control"
                            autoComplete="username"
                            value={isSetPassword ? setPasswordUsername : user?.username}
                            hidden={!isSetPassword}
                            readOnly
                        />
                    </FloatingLabel>
                    {user?.userType === UserType.Client
                        ? (
                            <FloatingLabel
                                controlId="currentPassword"
                                className="small mb-3"
                                label={t('IDS_OLD_PASSWORD')}
                            >
                                <Form.Control
                                    type="password"
                                    maxLength={50}
                                    autoComplete="current-password"
                                    required
                                    {...register('currentPassword')}
                                    isInvalid={!!errors.currentPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.currentPassword?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        )
                        : (
                            <input
                                type="hidden"
                                {...register('currentPassword')}
                            />
                        )
                    }
                    <FloatingLabel
                        controlId="newPassword"
                        className="small mb-3 has-tooltip"
                        label={
                            <>
                                <span>{t('IDS_NEW_PASSWORD')}</span>
                                <HelpTooltipIcon
                                    tooltipId="tooltipPassRequirements"
                                    textAlignLeft={true}
                                    tooltipText={`${t('IDS_REQUIREMENTS')}\n${t('IDS_PASSWORD_REQUIREMENTS')}`}
                                    tooltipTitle={t('IDS_REQUIREMENTS')}
                                />
                            </>
                        }
                    >
                        <Form.Control
                            type="password"
                            maxLength={50}
                            autoComplete="new-password"
                            required
                            {...register('newPassword')}
                            isInvalid={!!errors.newPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.newPassword?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="newPasswordConfirm"
                        className="small mb-3"
                        label={t('IDS_CONFIRM_NEW_PASSWORD')}
                    >
                        <Form.Control
                            type="password"
                            maxLength={50}
                            autoComplete="new-password"
                            required
                            {...register('newPasswordConfirm')}
                            isInvalid={!!errors.newPasswordConfirm}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.newPasswordConfirm?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <div className="d-flex gap-2">
                        {!isSetPassword &&
                            <Button variant="silver" className="mw-8rem" onClick={handleCancelClick}>
                                <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                            </Button>
                        }
                        <LoadingButton
                            variant="dark-ocean"
                            type="submit"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_CHANGE')}
                        </LoadingButton>
                    </div>
                </Form>
            </div>
        </>
    );
}