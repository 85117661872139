import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import QuestionBadge from '../../../app/components/QuestionBadge';
import { ClientPosQuestionType } from '../../../app/enums/clientPosQuestionType';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppQues } from '../../../app/models/fieldValueTypes';
import { getQuestions } from '../../../app/models/requestHelpers/applicantQuestionRequest';
import {
    ClientPositionQuestionDto
} from '../../../app/models/responseHelpers/clientPositionQuestionDto';
import { useAppSelector } from '../../../app/store/storeHooks';
import { parseValidationMsg } from '../../../i18n';
import { PropsApplicationTabs } from './shared/applicationTabsProps';

export default function TabQuestionnaire({ setFormState }: PropsApplicationTabs<FieldValuesAppQues>) {
    const { t } = useTranslation();
    const {
        questions: applicantQuestions, clientPosition: { questions: clientQuestions }
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const [pageIdx, setPageIdx] = useState(0);
    const clientQuestionsActive = clientQuestions.filter(x => x.active);

    const formMethods = useForm<FieldValuesAppQues>({
        mode: 'all',
        defaultValues: { applicantQuestions: getQuestions(applicantQuestions, clientQuestionsActive) },
        resolver: yupResolver(yup.object({
            applicantQuestions: yup.array().of(
                yup.object().shape({
                    answer: yup.string().nullable().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_QUESTION'] }),
                })
            )
        }))
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors }, handleSubmit, getValues, register } = formMethods;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    return (
        <>
            <div>
                <Form id="form-questionnaire" className="needs-validation" noValidate>
                    {clientQuestionsActive.map((ques, idx) => {
                        if (idx !== pageIdx)
                            return <input type="hidden" key={idx} {...register(`applicantQuestions.${idx}.answer`)} />
                        else if (ques.typeId === ClientPosQuestionType.Default || ques.typeId === ClientPosQuestionType.YesNo)
                            return (<Form.Group key={idx} className="mb-3">
                                <Form.Label className="small label-required" htmlFor={`div-applicantQuestions.${idx}.answer`}>
                                    <QuestionBadge questionNumber={idx + 1} totalQuestions={clientQuestionsActive.length} />
                                    {ques.question}
                                </Form.Label>
                                <div id={`div-applicantQuestions.${idx}.answer`}>
                                    {[t('IDS_YES'), t('IDS_NO')].map((text, textIdx) => (
                                        <Form.Check
                                            className="check-group-item"
                                            key={textIdx}
                                            id={`applicantQuestions.${idx}.answer.${textIdx}`}
                                            type="radio"
                                            label={text}
                                            {...register(`applicantQuestions.${idx}.answer`)}
                                            value={text}
                                            isInvalid={!!errors?.applicantQuestions?.[idx]?.answer}
                                        />
                                    ))}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.applicantQuestions?.[idx]?.answer?.message, t)}
                                </Form.Control.Feedback>
                            </Form.Group>);
                        else if (ques.typeId === ClientPosQuestionType.IAgree)
                            return (<Form.Group key={idx} className="mb-3">
                                <Form.Label className="small label-required" htmlFor={`div-applicantQuestions.${idx}.answer`}>
                                    <QuestionBadge questionNumber={idx + 1} totalQuestions={clientQuestionsActive.length} />
                                    {ques.question}
                                </Form.Label>
                                <div id={`div-applicantQuestions.${idx}.answer`}>
                                    {[t('IDS_AGREE'), t('IDS_DISAGREE')].map((text, textIdx) => (
                                        <Form.Check
                                            className="check-group-item"
                                            key={textIdx}
                                            id={`applicantQuestions.${idx}.answer.${textIdx}`}
                                            type="radio"
                                            label={text}
                                            {...register(`applicantQuestions.${idx}.answer`)}
                                            value={text}
                                            isInvalid={!!errors?.applicantQuestions?.[idx]?.answer}
                                        />
                                    ))}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.applicantQuestions?.[idx]?.answer?.message, t)}
                                </Form.Control.Feedback>
                            </Form.Group>);
                        else if (ques.typeId === ClientPosQuestionType.Ranking)
                            return (<Form.Group key={idx} className="mb-3">
                                <Form.Label className="small label-required" htmlFor={`div-applicantQuestions.${idx}.answer`}>
                                    <QuestionBadge questionNumber={idx + 1} totalQuestions={clientQuestionsActive.length} />
                                    {ques.question}
                                </Form.Label>
                                <div id={`div-applicantQuestions.${idx}.answer`}>
                                    {[...Array(9)].map((_, i) => (
                                        <Form.Check
                                            className="check-group-item"
                                            key={i}
                                            id={`applicantQuestions.${idx}.answer.${i}`}
                                            type="radio"
                                            label={`${i + 1}`}
                                            {...register(`applicantQuestions.${idx}.answer`)}
                                            value={`${i + 1}`}
                                            isInvalid={!!errors?.applicantQuestions?.[idx]?.answer}
                                        />
                                    ))}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.applicantQuestions?.[idx]?.answer?.message, t)}
                                </Form.Control.Feedback>
                            </Form.Group>);
                        else if (ques.typeId === ClientPosQuestionType.FillInTheBlank)
                            return (<Form.Group key={idx} className="mb-3" controlId={`applicantQuestions.${idx}.answer`}>
                                <Form.Label className="small label-required">
                                    <QuestionBadge questionNumber={idx + 1} totalQuestions={clientQuestionsActive.length} />
                                    {ques.question}
                                </Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    maxLength={100}
                                    {...register(`applicantQuestions.${idx}.answer`)}
                                    isInvalid={!!errors?.applicantQuestions?.[idx]?.answer}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.applicantQuestions?.[idx]?.answer?.message, t)}
                                </Form.Control.Feedback>
                            </Form.Group>);
                        else if (ques.typeId === ClientPosQuestionType.MultipleChoice)
                            return (<Form.Group key={idx} className="mb-3">
                                <Form.Label className="small label-required" htmlFor={`div-applicantQuestions.${idx}.answer`}>
                                    <QuestionBadge questionNumber={idx + 1} totalQuestions={clientQuestionsActive.length} />
                                    {ques.question}
                                </Form.Label>
                                <div id={`div-applicantQuestions.${idx}.answer`}>
                                    {['A', 'B', 'C', 'D', 'E'].map((letter, letterIdx) => (
                                        (ques[`literal${letter}` as keyof ClientPositionQuestionDto] as string)?.length > 0 ?
                                            <Form.Check
                                                className="check-group-item"
                                                key={letterIdx}
                                                id={`applicantQuestions.${idx}.answer.${letterIdx}`}
                                                type="radio"
                                                label={ques[`literal${letter}` as keyof ClientPositionQuestionDto] as string}
                                                {...register(`applicantQuestions.${idx}.answer`)}
                                                value={ques[`literal${letter}` as keyof ClientPositionQuestionDto] as string}
                                                isInvalid={!!errors?.applicantQuestions?.[idx]?.answer}
                                            />
                                            : null
                                    ))}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.applicantQuestions?.[idx]?.answer?.message, t)}
                                </Form.Control.Feedback>
                            </Form.Group>);
                        else
                            return null;

                    })}
                </Form>
            </div>

            {clientQuestionsActive.length > 1 &&
                <div className="d-flex justify-content-center">
                    <div className="d-flex mb-3 justify-content-between">
                        <Button
                            size="sm"
                            variant="silver"
                            className="me-2"
                            disabled={pageIdx <= 0}
                            onClick={() => setPageIdx((prevPage) => prevPage - 1)}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} className="small me-2 d-none-xs" fixedWidth />
                            {t('IDS_PREV_QUESTION')}
                        </Button>
                        {pageIdx < (clientQuestionsActive.length - 1) &&
                            <Button
                                size="sm"
                                variant="silver"
                                className="ms-2"
                                onClick={() => setPageIdx((prevPage) => prevPage + 1)}
                            >
                                {t('IDS_NEXT_QUESTION')}
                                <FontAwesomeIcon icon={faAngleRight} className="small ms-2 d-none-xs" fixedWidth />
                            </Button>
                        }
                    </div>
                </div>
            }
        </>
    );
}