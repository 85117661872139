import './app/layout/styles/bootstrap_overrides.scss';
import './app/layout/styles/styles.scss';
import './i18n';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { NavigateSetter } from './app/components/NavigateSetter';
import App from './app/layout/App';
import { store } from './app/store/configureStore';
import ScrollToTop from './app/utility/scrollToTop';
import { getLangOrDefaultReCaptcha } from './i18n';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey={(window as any).REACT_APP_RECAPTCHA_KEY as string}
        language={getLangOrDefaultReCaptcha()}
    >
        <HelmetProvider>
            <BrowserRouter>
                <NavigateSetter />
                <ScrollToTop />
                <Suspense fallback={null}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </Suspense>
            </BrowserRouter>
        </HelmetProvider>
    </GoogleReCaptchaProvider>
);