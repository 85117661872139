import React, { SyntheticEvent, useContext, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl, isPossiblePhoneNumber, parsePhoneNumber} from 'react-phone-number-input';
import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import LoadingButton from '../../app/components/LoadingButton';
import { UserType } from '../../app/enums/userType';
import { FieldValuesTwoFactorAuth } from '../../app/models/fieldValueTypes';
import { UserDto } from '../../app/models/responseHelpers/userDto';
import { setUser } from '../../app/store/accountSlice';
import { selectClient } from '../../app/store/clientSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { initializeTestSession } from '../../app/store/testSlice';
import { RouteAuthCtx } from '../../app/utility/routeAuthCtx';
import { RegExps, defaultCountryOptionsOrder, defaultFlagUrl } from '../../app/utility/util';
import { getTitle, parseValidationMsg } from '../../i18n';

function maskPhone(unmaskedPhoneUnformatted: string) {
    let strRet = '';
    if (unmaskedPhoneUnformatted) {
        let unmaskedPhone = formatPhoneNumberIntl(unmaskedPhoneUnformatted);
        let numIndexes = [];
        for (let i = 0; i < unmaskedPhone.length; i++) {
            if (unmaskedPhone[i] !== ' ' && !isNaN(Number(unmaskedPhone[i]))) {
                numIndexes.push(i);
            }
        }
        for (let i = 0; i < unmaskedPhone.length; i++) {
            if (unmaskedPhone[i] !== ' ' && !isNaN(Number(unmaskedPhone[i])) && i > numIndexes[1] && i < numIndexes[numIndexes.length - 2]) {
                strRet += '*';
            } else {
                strRet += unmaskedPhone[i];
            }
        }
    }
    return strRet;
}

const InputComponent = React.forwardRef<HTMLInputElement>((props, ref) => {
    return (
        <input
            id="phoneNumber"
            type="tel"
            maxLength={25}
            inputMode="tel"
            required
            ref={ref}
            {...props}
            style={{ border: 'none', appearance: 'none', boxShadow: 'none', outline: 'none' }}
        />
    );
});

export default function VerifyPage() {
    const { t } = useTranslation();
    const authCtx = useContext(RouteAuthCtx);
    const { user } = useAppSelector(state => state.account);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [step, setStep] = useState(user?.twoFactorAuthPhone ? 1 : 0);

    const { formState: { isValid, isSubmitting, errors }, handleSubmit, register, setValue, control } = useForm<FieldValuesTwoFactorAuth>({
        mode: 'all',
        defaultValues: {
            authCode: '',
            phoneNumber: user?.twoFactorAuthPhone ?? '',
            updatePhoneOnly: false,
            countryCode: ''
        },
        resolver: yupResolver(yup.object(
            step === 2
                ? { authCode: yup.string()
                    .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_AUTHENTICATION_CODE'] })
                    .matches(RegExps.digits, { message: 'IDS_VALIDATION_DIGITS'})
                    .min(6, { resKeys: ['IDS_VALIDATION_EXACT_LENGTH', '6']})
                    .max(6, { resKeys: ['IDS_VALIDATION_EXACT_LENGTH', '6']}) }
                : (step === 1
                    ? { }
                    : { phoneNumber: yup.string().nullable()
                        .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_PHONE'] })
                        .test('test-name', { resKeys: ['IDS_IEP_INVALID', 'IDS_PHONE'] }, (value) => isPossiblePhoneNumber(value ?? ''))
                    })
        ))
    });

    async function savePhoneRequestCodeSubmitForm(data: FieldValuesTwoFactorAuth) {
        try {
            var phone = parsePhoneNumber(data.phoneNumber);
            data.countryCode = phone?.countryCallingCode ?? '';

            const twoFactorAuthPhone = await agent.UserAccount.upsertUserTwoFactorAuth(data);
            if (!user?.twoFactorAuthPhone) {
                dispatch(setUser({ ...user, twoFactorAuthPhone } as UserDto));
            }

            setStep(2);
        } catch (error) {
            console.log(error);
        }
    }

    async function verifyCodeSubmitForm(data: FieldValuesTwoFactorAuth) {
        try {
            await agent.UserAccount.verifyTwoFactorAuthToken(data);

            dispatch(setUser({ ...user, twoFactorAuthVerified: true } as UserDto));

            if (authCtx.userType === UserType.Client) {
                if (user?.forcePasswordReset) {
                    navigate('/AuthClient/ChangePassword');
                } else if (user?.casObject?.casAccountBC) {
                    if (user.casObject.casAccountBC.length === 1) {
                        await dispatch(selectClient(user.casObject.casAccountBC[0].accountNumber!));
                        navigate('/Client');
                    } else if (user.casObject.casAccountBC.length > 1) {
                        navigate('/AuthClient/SelectClient');
                    }
                } else {
                    navigate(`/${authCtx.userTypePath}`);
                }
            } else if (authCtx.userType === UserType.Applicant) {
                if (user?.forceLanguageSelect) {
                    navigate('/AuthApplicant/SelectLanguage');
                } else if (user?.forcePasswordReset) {
                    navigate('/AuthApplicant/ChangePassword');
                } else {
                    await dispatch(initializeTestSession());
                    navigate(`/${authCtx.userTypePath}`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    switch (step) {
        default:
            return (
                <>
                    <Helmet>
                        <title>{getTitle(t, 'IDS_VERIFY')}</title>
                    </Helmet>
                    <div className="main-content-login d-flex justify-content-center flex-column">
                        <h1 className="mb-4 fw-bold">{t('IDS_TWO_FACTOR_AUTHENTICATION')}</h1>
                        <div className="help-text mb-4 smaller">
                            <Trans i18nKey="IDS_2FA_INSTRUCT_ADD_PHONE" />
                        </div>
                        <Form onSubmit={handleSubmit(savePhoneRequestCodeSubmitForm)} className="needs-validation" noValidate>
                            <FloatingLabel
                                controlId="inputAddPhoneNumber"
                                className={`small mb-3 ${!!errors.phoneNumber ? 'phone-invalid' : ''}`}
                                label={t('IDS_PHONE')}
                            >
                                <PhoneInputWithCountrySelect
                                    name="phoneNumber"
                                    inputComponent={InputComponent}
                                    control={control as any}
                                    international
                                    countryOptionsOrder={defaultCountryOptionsOrder}
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    flagUrl={defaultFlagUrl}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.phoneNumber?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <LoadingButton
                                variant="dark-ocean"
                                type="submit"
                                className="mw-8rem"
                                loading={isSubmitting}
                                disabled={!isValid || isSubmitting}
                            >
                                <FontAwesomeIcon icon={faSignInAlt} className="me-2" />{t('IDS_SAVE_AND_REQUEST_CODE')}
                            </LoadingButton>
                        </Form>
                    </div>
                </>
            );

        case 1:
            return (
                <>
                    <Helmet>
                        <title>{getTitle(t, 'IDS_VERIFY')}</title>
                    </Helmet>
                    <div className="main-content-login d-flex justify-content-center flex-column">
                        <h1 className="mb-4 fw-bold">{t('IDS_TWO_FACTOR_AUTHENTICATION')}</h1>
                        <div className="help-text mb-4 smaller">{t('IDS_2FA_INSTRUCT_SEND_CODE')}</div>
                        <Form onSubmit={handleSubmit(savePhoneRequestCodeSubmitForm)} className="needs-validation" noValidate>
                            <FloatingLabel
                                controlId="inputPhoneNumber"
                                className="small mb-3"
                                label={t('IDS_PHONE')}
                            >
                                <Form.Control
                                    type="text"
                                    name="maskedPhone"
                                    required
                                    readOnly
                                    value={maskPhone(user?.twoFactorAuthPhone ?? '')}
                                />
                            </FloatingLabel>
                            <LoadingButton
                                variant="dark-ocean"
                                type="submit"
                                className="mw-8rem"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                            >
                                <FontAwesomeIcon icon={faSignInAlt} className="me-2" />{t('IDS_REQUEST_CODE')}
                            </LoadingButton>
                        </Form>
                    </div>
                </>
            );
    
        case 2:
            return (
                <>
                    <Helmet>
                        <title>{getTitle(t, 'IDS_VERIFY')}</title>
                    </Helmet>
                    <div className="main-content-login d-flex justify-content-center flex-column">
                        <h1 className="mb-4 fw-bold">{t('IDS_TWO_FACTOR_AUTHENTICATION')}</h1>
                        <div className="help-text mb-4 smaller">{t('IDS_2FA_INSTRUCT_VERIFY_CODE')}</div>
                        <Form onSubmit={handleSubmit(verifyCodeSubmitForm)} className="needs-validation" noValidate>
                            <FloatingLabel
                                controlId="inputAuthCode"
                                className="small mb-3"
                                label={t('IDS_AUTHENTICATION_CODE')}
                            >
                                <Form.Control
                                    type="text"
                                    inputMode="numeric"
                                    autoComplete="one-time-code"
                                    required
                                    maxLength={6}
                                    {...register('authCode', {
                                        onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                            e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]+/g, '');
                                            setValue('authCode', e.currentTarget.value);
                                        }
                                    })}
                                    isInvalid={!!errors.authCode}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.authCode?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <div className="d-flex gap-2">
                                <Button variant="silver" className="mw-8rem" onClick={() => setStep(1)}>
                                    <FontAwesomeIcon icon={faSignInAlt} className="me-2" />{t('IDS_BACK')}
                                </Button>
                                <LoadingButton
                                    variant="dark-ocean"
                                    type="submit"
                                    className="mw-8rem"
                                    loading={isSubmitting}
                                    disabled={!isValid || isSubmitting}
                                >
                                    <FontAwesomeIcon icon={faSignInAlt} className="me-2" />{t('IDS_VERIFY')}
                                </LoadingButton>
                            </div>
                        </Form>
                    </div>
                </>
            );
    }
}