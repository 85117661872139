import { useContext } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectCallback } from '@restart/ui/esm/types';

import { setUser, signOut } from '../../app/store/accountSlice';
import { setLocale } from '../../app/store/localeSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { initializeTestSession } from '../../app/store/testSlice';
import { RouteAuthCtx } from '../../app/utility/routeAuthCtx';
import { getTitle, localeCode, locales } from '../../i18n';
import agent from '../../app/api/agent';
import { cloneDeep } from 'lodash';

export default function SelectLanguagePage() {
    const authCtx = useContext(RouteAuthCtx);
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.account);
    const clientId: number = user?.applicantSessionDto?.applicant?.clientId ?? 0;
    const positionId: number = user?.applicantSessionDto?.applicant?.positionId ?? 0;
    const clientLangs: string[] = user?.applicantSessionDto?.client?.languageCodes ?? [];
    const clientLanguageOptions = Object.fromEntries(Object.entries(locales).filter(([key, value]) => clientLangs.some(x => x === key)));

    const saveLocale: SelectCallback = async (eventKey, e) => {
        e.preventDefault();
        const selectedLocale = eventKey as localeCode;
        
        dispatch(setLocale(selectedLocale));
        i18n.changeLanguage(selectedLocale);

        if (user?.forceLanguageSelect) {
            let clonedUserDto = cloneDeep(user);
            clonedUserDto.forceLanguageSelect = false;

            if (clonedUserDto.applicantSessionDto) {
                let questionsAndNotesForLang = await agent.UserAccount.getQuestionsAndNotesForLang({ clientId, positionId });
                clonedUserDto.applicantSessionDto.client.clientNotesForLanguage = questionsAndNotesForLang.item1;
                clonedUserDto.applicantSessionDto.clientPosition = questionsAndNotesForLang.item2;
            }

            dispatch(setUser(clonedUserDto));
        }

        if (user?.forcePasswordReset) {
            navigate('/AuthApplicant/ChangePassword');
        } else {
            await dispatch(initializeTestSession());
            navigate(`/${authCtx.userTypePath}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_HOME')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                <h1 className="mb-4 fw-bold">{t('IDS_CHOOSE_YOUR_LANGUAGE')}</h1>
                <div className="help-text mb-4 smaller">{t('IDS_CHOOSE_VALID_LANGUAGE')}</div>
                <form>
                    {clientLanguageOptions &&
                        <ListGroup onSelect={saveLocale} className="mb-3">
                            {Object.keys(clientLanguageOptions).map((key, i) => (
                                <ListGroup.Item key={i} eventKey={key} action>
                                    <span className={`fi ${clientLanguageOptions[key].flagClass} me-2`}></span>{`${clientLanguageOptions[key].country} - ${clientLanguageOptions[key].language}`}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    }
                    <div className="d-flex gap-2">
                        <Button variant="silver" className="mw-8rem" onClick={() => dispatch(signOut())}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}