
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FilterProps } from 'react-table';

import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalPositionLookupAlt } from '../../../features/client/applicants/ModalPositionLookup';
import { useTranslation } from 'react-i18next';

export default function PositionColumnFilter<T extends object>({ column: { setFilter, render } }: FilterProps<T>) {
    const { t } = useTranslation();
    const [showModalPositionLookup, setShowModalPositionLookup] = useState(false);
    const [inputValue, setInputValue] = useState('');

    return (
        <>
            <InputGroup className="flex-nowrap input-group-xs w-100">
                <Form.Control
                    type="text"
                    className="w-100"
                    value={inputValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                        if (!e.target.value) {
                            setFilter('');
                        }
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter' && e.currentTarget.value) {
                            e.preventDefault();
                            setFilter({ vals: [e.currentTarget.value], condition: 'includes' });
                        }
                    }}
                    title={render('Header')?.toString() + ' filter'}
                />
                <InputGroup.Text className="px-2">
                    <Button
                        size="sm"
                        variant="dark-ocean"
                        className="d-flex align-items-center justify-content-center rounded-circle m-0 p-0-5"
                        onClick={() => setShowModalPositionLookup(true)}
                        title={t('IDS_SELECT_A_POSITION')}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth size="xs" />
                    </Button>
                </InputGroup.Text>
            </InputGroup>

            <ModalPositionLookupAlt
                showModal={showModalPositionLookup}
                setShowModal={setShowModalPositionLookup}
                setPositionText={setInputValue}
                setPositionFilter={setFilter}
            />
        </>
    );
}