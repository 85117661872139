import { useCallback, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { SelectCallback } from '@restart/ui/esm/types';

import { getLangOrDefault, localeCode, locales } from '../../i18n';
import { setLocale } from '../store/localeSlice';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';

export default function LocaleMenu() {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector(state => state.locale);
    const { i18n, t } = useTranslation();

    const saveLocale: SelectCallback = (eventKey, e) => {
        const selectedLocale = eventKey as localeCode;
        dispatch(setLocale(selectedLocale));
    };

    const initLocale = useCallback(async () => {
        if (locale && locale !== getLangOrDefault()) {
            i18n.changeLanguage(locale);
        }
    }, [i18n, locale]);

    useEffect(() => {
        initLocale();
    }, [initLocale]);

    return (
        <Dropdown onSelect={saveLocale}>
            <Dropdown.Toggle variant="outline-eggplant" id="languageMenuButton">
                <span className={`fi ${locales[locale].flagClass} me-2`}></span>{`${locales[locale].country} - ${locales[locale].language}`}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" aria-labelledby="languageMenuButton">
                <Dropdown.Header className="text-charcoal fw-bold py-0">{t('IDS_CHOOSE_YOUR_LANGUAGE')}</Dropdown.Header>
                <Dropdown.Divider className="mx-3" />
                {Object.keys(locales).map((key, i) => (
                    <li key={i}>
                        <Dropdown.Item eventKey={key} className={key === locale ? 'active' : ''}>
                            <span className={`fi ${locales[key as localeCode].flagClass} me-2`}></span>{`${locales[key as localeCode].country} - ${locales[key as localeCode].language}`}
                        </Dropdown.Item>
                    </li>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
