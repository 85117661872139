export enum AchieverSectionId {
    Personality,
    MentalAcuity,
    BusinessTerms,
    Vocabulary,
    Perception,
    MechInterest
}
export enum DPATSectionId {
    AlphaSequence,
    GraphicSequence,
    MathWordProblems
}