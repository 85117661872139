import { useEffect, useState } from 'react';
import { Button, InputGroup, Modal, Tab, Table } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
    faAngleDoubleDown, faCheckCircle, faTimes, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import { BsModalAlert, BsModalConfirm } from '../../../app/components/BsModal';
import BsScrollableTabs from '../../../app/components/BsScrollableTabs';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';
import LoadingButton from '../../../app/components/LoadingButton';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import {
    TblApplicantAchieverResult
} from '../../../app/models/dbTables/tblApplicantAchieverResult';
import { formDataToApplicantRequest } from '../../../app/models/requestHelpers/applicantRequest';
import {
    applicantWithProgressDtoToFormData
} from '../../../app/models/responseHelpers/applicantWithProgressDto';
import { useAppSelector } from '../../../app/store/storeHooks';
import { dateTimeIsMinVal, getAppSettingVal, localDate, Validate } from '../../../app/utility/util';
import { parseValidationMsg } from '../../../i18n';
import AssessApplicant from './AssessApplicant';
import ApplicantItemsContainer from './dnd/ApplicantItemsContainer';
import ModalPositionLookup from './ModalPositionLookup';
import { ApplicantTestProgress } from '../../../app/enums/applicantTestProgress';
import { SettingNames } from '../../../app/enums/settingNames';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    applicantId: number;
    changeCount: number;
    setChangeCount: (count: number) => void;
}

export default function ModalApplicantEdit({ showModal, setShowModal, applicantId, changeCount, setChangeCount }: Props) {
    const { t } = useTranslation();
    const appSettings = useAppSelector(state => state.account.appSettings);
    const mfaEnabled = getAppSettingVal(appSettings, SettingNames.EnableMFA, 'boolean') as boolean;
    const { clientSession } = useAppSelector(state => state.clientSession);
    const vwClient = clientSession?.vwClient;
    const isRecType = clientSession?.vwClient.isRecType ?? false;
    const [showModalPositionLookup, setShowModalPositionLookup] = useState(false);
    const [showModalConfirmLogonInfo, setShowModalConfirmLogonInfo] = useState(false);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalAlertState, setModalAlertState] = useState<{ show: boolean, msg: string }>({ show: false, msg: '' });
    const [positionId, setPositionId] = useState(0);
    const [assessmentResults, setAssessmentResults] = useState<TblApplicantAchieverResult[]>([]);
    const [canPushTest, setCanPushTest] = useState(false);
    const [canPushDpat, setCanPushDpat] = useState(false);
    const [canPushDpatInh, setCanPushDpatInh] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [moreTestsToGive, setMoreTestsToGive] = useState(false);
    const [progressId, setProgressId] = useState(0);

    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object({
            username: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_USERNAME'] }),
            firstName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_FIRST_NAME'] }),
            lastName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_LAST_NAME'] }),
            positionTitle: isRecType ? yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_POSITION'] }) : yup.string()
        }))
    }),
        { formState: { isValid, isSubmitting, isDirty, errors }, reset, handleSubmit, register, getValues, watch, control } = formMethods;
    const watchEmail = watch('email') as string;
    const { fields: unlockLogFields } = useFieldArray({ control, name: 'unlockLog' });
    const { fields: progressFields } = useFieldArray({ control, name: 'progressItems' });

    async function submitForm(data: FieldValues) {
        try {
            if (vwClient) {
                let updateApplicant = formDataToApplicantRequest(vwClient, data);
                await agent.ClientApplicant.updateApplicant(updateApplicant);
                
                setShowModal(false);
                setChangeCount(changeCount + 1);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const resetPass = async () => {
        let username = getValues('username');
        let newPass = await agent.ClientApplicant.resetPassword({ username });

        if (newPass) {
            setModalAlertState({ show: true, msg: t('IDS_IEP_PASSWORD_RESET', { 0: newPass, interpolation: { escapeValue: false } }) });
        }
    };

    const emailLogonInfo = async () => {
        setShowModalConfirmLogonInfo(false);
        let [email, username, firstName, lastName] = getValues(['email', 'username', 'firstName', 'lastName']);
        if (Validate.Email(email, t)) {
            await agent.ClientApplicant.resetPasswordAndEmailInfo({ applicantId, email, username, firstName, lastName });
            setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: 'Email sent' }) });
        }
    };

    const resetMFAPhone = async () => {
        let username = getValues('username');
        await agent.ClientApplicant.resetMFAPhone({ username });

        setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: t('IDS_MFA_RESET'), interpolation: { escapeValue: false } }) });
    };

    const unlockApplicant = async () => {
        await agent.ClientApplicant.unlockApplicant({ applicantId });
        setModalAlertState({ show: true, msg: t('IDS_IEP_SUCC_SUCCESSFULLY', { 0: 'Applicant unlocked' }) });
    };

    const pushTest = async () => {
        await agent.Rec.pushAchieverResultsToREC({ applicantId });
        setModalAlertState({ show: true, msg: 'Successfully sent to REC.' });
        setCanPushTest(false);
    };
    const pushDpat = async () => {
        await agent.Rec.pushDPATResultsToREC({ applicantId, inhouse: false });
        setModalAlertState({ show: true, msg: 'Successfully sent to REC.' });
        setCanPushDpat(false);
    };
    const pushDpatInh = async () => {
        await agent.Rec.pushDPATResultsToREC({ applicantId, inhouse: true });
        setModalAlertState({ show: true, msg: 'Successfully sent to REC.' });
        setCanPushDpatInh(false);
    };

    useEffect(() => {
        let isMounted = true;
        const fetchSetupData = async () => {
            try {
                if (isMounted && clientSession && showModal) {
                    setLoading(true);
                    let applicantWithProgress = await agent.Applicant.withProgress({ id: applicantId });
                    if (!isMounted) return;
                    let formData = applicantWithProgressDtoToFormData(applicantWithProgress, clientSession, t);

                    setIsDeleted(applicantWithProgress.applicant.isDeleted);
                    setProgressId(applicantWithProgress.progress.progressId);
                    setMoreTestsToGive(applicantWithProgress.applicant.testOnly ||
                        (!applicantWithProgress.applicant.achiever && clientSession.vwClient.achiever) ||
                        (!applicantWithProgress.applicant.dpat && clientSession.vwClient.dpat) ||
                        (!applicantWithProgress.applicant.dpatInhouse && clientSession.vwClient.dpatInhouse));
                    setPositionId(applicantWithProgress.applicant.positionId);

                    if (clientSession.vwClient.isRecType) {
                        let fAchieverAvail = false;
                        let fDPATAvail = false;
                        let fDPATInhAvail = false;

                        let testsAvailableForRec = await agent.Rec.getApplicantTestsAvailableForRec({ applicantId });
                        if (!isMounted) return;

                        if (testsAvailableForRec) {
                            if (testsAvailableForRec['achieverAvail'] !== undefined) {
                                fAchieverAvail = testsAvailableForRec['achieverAvail'];
                            }
                            if (testsAvailableForRec['DPATAvail'] !== undefined) {
                                fDPATAvail = testsAvailableForRec['DPATAvail'];
                            }
                            if (testsAvailableForRec['DPATInhAvail'] !== undefined) {
                                fDPATInhAvail = testsAvailableForRec['DPATInhAvail'];
                            }
                        }
                        if (!applicantWithProgress.applicant.achieverSent && applicantWithProgress.applicant.achiever &&
                            !dateTimeIsMinVal(applicantWithProgress.progress.test6CompletedDate) && fAchieverAvail) {
                            setCanPushTest(true);
                        }
                        if (!applicantWithProgress.applicant.dpatSent && applicantWithProgress.applicant.dpat &&
                            !dateTimeIsMinVal(applicantWithProgress.progress.dpat3CompletedDate) && fDPATAvail) {
                            setCanPushDpat(true);
                        }
                        if (!applicantWithProgress.applicant.dpatInhouseSent && applicantWithProgress.applicant.dpatInhouse &&
                            !dateTimeIsMinVal(applicantWithProgress.progress.dpatInhouse3CompletedDate) && fDPATInhAvail) {
                            setCanPushDpatInh(true);
                        }
                    }

                    let results = await agent.AchieverResults.getResults({ applicantId });
                    if (!isMounted) return;
                    setAssessmentResults(results);

                    reset(formData);
                }
            } catch (error) {
                console.log(error);
                setShowModal(false);
            } finally {
                if (isMounted) setLoading(false);
            }
        }

        fetchSetupData();

        return () => { isMounted = false; };
    }, [reset, showModal, setShowModal, applicantId, clientSession, t]);

    return (
        <>
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalAddApplicant"
                backdrop="static"
                size="xl"
            >
                <FormProvider {...formMethods}>
                    <LoadingComponentControlled
                        delay={10}
                        positionClass="position-fixed"
                        show={loading}
                    />
                    <Modal.Header>
                        <Modal.Title>{t('IDS_VIEW_APPLICANTS')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                    </Modal.Header>
                    <Modal.Body className="bg-light-silver">
                        <Form id="formApplicantEdit" onSubmit={handleSubmit(submitForm)}>
                            <Tab.Container defaultActiveKey="applicantDetails">
                                <BsScrollableTabs
                                    navTabsClassName="nav-tabs-alt"
                                    tabs={{
                                        'applicantDetails': {
                                            linkText: 'IDS_DETAILS',
                                            paneComponent: (<></>)
                                        },
                                        ...(assessmentResults?.length > 0 && Number(getValues('positionId')) > 0 && clientSession?.viewResults
                                            ? {
                                                'applicantAssessment': {
                                                    linkText: 'IDS_ASSESSMENT',
                                                    paneComponent: (<></>)
                                                }
                                            }
                                            : {}
                                        )
                                    }}
                                />
                                <div className="border border-light-eggplant border-2 bg-white p-4">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="applicantDetails" key="applicantDetailsTabPane">
                                            <Row className="g-4" xs={1} lg={2} xl={3}>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="firstName"
                                                        className="small"
                                                        label={t('IDS_FIRST_NAME')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            readOnly={isDeleted || (progressId === ApplicantTestProgress.Complete && !moreTestsToGive)}
                                                            {...register('firstName')}
                                                            isInvalid={!!errors.firstName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.firstName?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="lastName"
                                                        className="small"
                                                        label={t('IDS_LAST_NAME')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            readOnly={isDeleted || (progressId === ApplicantTestProgress.Complete && !moreTestsToGive)}
                                                            {...register('lastName')}
                                                            isInvalid={!!errors.lastName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {parseValidationMsg(errors?.lastName?.message, t)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="username"
                                                        className="small"
                                                        label={t('IDS_USERNAME')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            readOnly={getValues('lastLogin') && getValues('lastLogin').length}
                                                            {...register('username')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="email"
                                                        className="small flex-fill"
                                                        label={t('IDS_EMAIL_ADDRESS')}
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            readOnly={isDeleted || (progressId === ApplicantTestProgress.Complete && !moreTestsToGive)}
                                                            {...register('email')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="flex-nowrap">
                                                        <FloatingLabel
                                                            controlId="positionTitle"
                                                            className="small flex-fill"
                                                            label={t('IDS_POSITION')}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                required={isRecType}
                                                                readOnly={isDeleted}
                                                                {...register('positionTitle')}
                                                                isInvalid={isRecType && !!errors.positionTitle}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {isRecType && parseValidationMsg(errors?.positionTitle?.message, t)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                        <InputGroup.Text className="px-2" style={{ height: 'calc(3.5rem + 2px)' }}>
                                                            <Button
                                                                variant="dark-ocean"
                                                                className="d-flex align-items-center justify-content-center rounded-circle lh-1 m-0 p-1"
                                                                onClick={() => setShowModalPositionLookup(true)}
                                                                disabled={isDeleted}
                                                                title={t('IDS_SELECT_A_POSITION')}
                                                            >
                                                                <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth size="xs" />
                                                            </Button>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="lastLogin"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_LAST_LOGIN')}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('lastLogin')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <hr className="text-light-eggplant" />
                                            <Row className="g-4" xs={1} lg={2} xl={3}>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="addedBy"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_ADDED_BY')}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('addedBy')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="lastModifiedBy"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_LAST_MODIFIED')}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('lastModifiedBy')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <hr className="text-light-eggplant" />
                                            <Row className={`g-4 ${getValues('dpatAuditVisible') && getValues('dpatAuditVisible') === true ? '' : 'd-none'}`}>
                                                <Col xs={12} lg={8}>
                                                    <FloatingLabel
                                                        controlId="dpatTime"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_ELAPSED_TIME')}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('dpatTime')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <FloatingLabel
                                                        controlId="dpatStops"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_RESTARTS')}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('dpatStops')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row className={`g-4 ${getValues('dpatInhouseAuditVisible') && getValues('dpatInhouseAuditVisible') === true ? '' : 'd-none'}`}>
                                                <Col xs={12} lg={8}>
                                                    <FloatingLabel
                                                        controlId="dpatInhouseTime"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={`${t('IDS_ELAPSED_TIME')} ${t('IDS_INH')}`}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('dpatInhouseTime')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <FloatingLabel
                                                        controlId="dpatInhouseStops"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={`${t('IDS_RESTARTS')} ${t('IDS_INH')}`}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            {...register('dpatInhouseStops')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <hr className="text-light-eggplant" />
                                            <Row className={`g-4 ${getValues('locked') && getValues('locked') === true ? '' : 'd-none'}`}>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="lockedStatus"
                                                        className="small flex-fill form-floating-plaintext"
                                                        label={t('IDS_STATUS')}
                                                    >
                                                        <Form.Control
                                                            className="text-cherry"
                                                            readOnly
                                                            value={t('IDS_LOCKED_OUT')}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs="auto" className="d-flex">
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        className="small text-nowrap link-dark-ocean"
                                                        disabled={!(getValues('locked') && getValues('locked') === true)}
                                                        onClick={unlockApplicant}
                                                    >
                                                        {t('IDS_UNLOCK_APP')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <hr className="text-light-eggplant" />
                                            <Row className="g-4">
                                                <Col xs={12} lg={6}>
                                                    <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive">
                                                        <Table striped borderless
                                                            id="tblProgress"
                                                            size="sm"
                                                            variant="dark-eggplant"
                                                            className="mb-0 table-cell-nowrap"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>{t('IDS_PROGRESS')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {progressFields.map((item, i) => (
                                                                    <tr key={item.id}>
                                                                        <td className="pe-4">{getValues(`progressItems[${i}].text`)}</td>
                                                                        <td>{getValues(`progressItems[${i}].status`)}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive">
                                                        <Table striped borderless
                                                            size="sm"
                                                            variant="dark-eggplant"
                                                            className="mb-0 table-cell-nowrap"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>{t('IDS_UNLOCK_LOG')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {unlockLogFields && unlockLogFields.length > 0
                                                                    ? (unlockLogFields.map((item, i) => (
                                                                        <tr key={item.id}>
                                                                            <td className="pe-4">{getValues(`unlockLog[${i}].username`)}</td>
                                                                            <td>{localDate(getValues(`unlockLog[${i}].unlockedDate`))}</td>
                                                                        </tr>))
                                                                    )
                                                                    : (
                                                                        <tr>
                                                                            <td colSpan={2} className="text-center">No data available</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <ApplicantItemsContainer showAssignedAndViewCols={true} />

                                            <div className="small mt-3">
                                                <Button
                                                    variant="link"
                                                    className="text-nowrap link-dark-ocean fs-inherit px-0"
                                                    onClick={resetPass}
                                                    disabled={isDeleted}
                                                >
                                                    {t('IDS_RESET_PASSWORD')}
                                                </Button>
                                                <HelpTooltipIcon
                                                    tooltipId="tooltipBtnResetPassword"
                                                    tooltipText="Resets user's password and shows new password in popup."
                                                    tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                                />
                                                <span className="ms-2 me-3 text-light-eggplant">|</span>
                                                <Button
                                                    variant="link"
                                                    className="text-nowrap link-dark-ocean fs-inherit px-0"
                                                    disabled={!watchEmail || watchEmail.length <= 0 || !Validate.Email(watchEmail, t)}
                                                    onClick={() => setShowModalConfirmLogonInfo(true)}
                                                >
                                                    {t('IDS_EMAIL_LOGON_INFO')}
                                                </Button>
                                                <HelpTooltipIcon
                                                    tooltipId="tooltipBtnEmailLogonInfo"
                                                    tooltipText="Resets user's password and mails user a tokenized password reset link."
                                                    tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                                />
                                                {mfaEnabled &&
                                                    <>
                                                        <span className="ms-2 me-3 text-light-eggplant">|</span>
                                                        <Button
                                                            variant="link"
                                                            className="text-nowrap link-dark-ocean fs-inherit px-0"
                                                            onClick={resetMFAPhone}
                                                        >
                                                            {t('IDS_RESET_MFA_PHONE')}
                                                        </Button>
                                                        <HelpTooltipIcon
                                                            tooltipId="tooltipBtnResetMFAPhone"
                                                            tooltipText="Resets user's MFA phone number, allowing them to re-enter it the next time they log in."
                                                            tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                                        />
                                                    </>
                                                }
                                                {canPushTest &&
                                                    <>
                                                        <span className="ms-2 me-3 text-light-eggplant">|</span>
                                                        <Button
                                                            variant="link"
                                                            className="text-nowrap link-dark-ocean fs-inherit me-2 px-0"
                                                            onClick={pushTest}
                                                        >
                                                            {'Push Assessment'}
                                                        </Button>
                                                    </>
                                                }
                                                {canPushDpat &&
                                                    <>
                                                        <span className="ms-2 me-3 text-light-eggplant">|</span>
                                                        <Button
                                                            variant="link"
                                                            className="text-nowrap link-dark-ocean fs-inherit me-2 px-0"
                                                            onClick={pushDpat}
                                                        >
                                                            {'Push Aptitude'}
                                                        </Button>
                                                    </>
                                                }
                                                {canPushDpatInh &&
                                                    <>
                                                        <span className="ms-2 me-3 text-light-eggplant">|</span>
                                                        <Button
                                                            variant="link"
                                                            className="text-nowrap link-dark-ocean fs-inherit me-2 px-0"
                                                            onClick={pushDpatInh}
                                                        >
                                                            {'Push Inhouse Aptitude'}
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Tab.Pane>
                                        {applicantId > 0 && positionId > 0 && assessmentResults?.length > 0 && Number(getValues('positionId')) > 0 && clientSession?.viewResults &&
                                            <Tab.Pane eventKey="applicantAssessment" key="applicantAssessmentTabPane" className="position-relative">
                                                <AssessApplicant
                                                    applicantId={applicantId}
                                                    assessmentResults={assessmentResults}
                                                    initialPositionId={positionId}
                                                />
                                            </Tab.Pane>
                                        }
                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="silver" className="me-auto" onClick={() => setShowModalConfirmDelete(true)}>
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2" />{t('IDS_DELETE')}
                        </Button>
                        <Button variant="silver" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                        <LoadingButton
                            form="formApplicantEdit"
                            type="submit"
                            variant="dark-ocean"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || !isDirty || isSubmitting || isDeleted}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_UPDATE')}
                        </LoadingButton>
                    </Modal.Footer>
                    <ModalPositionLookup
                        showModal={showModalPositionLookup}
                        setShowModal={setShowModalPositionLookup}
                    />
                    <BsModalConfirm
                        title={t('IDS_CONFIRM')}
                        message={t('IDS_WARN_RESET_PASSWORD')}
                        showModal={showModalConfirmLogonInfo}
                        setShowModal={setShowModalConfirmLogonInfo}
                        confirmCallback={emailLogonInfo}
                    />
                    <BsModalConfirm
                        title={t('IDS_CONFIRM')}
                        message={t('IDS_WARN_DELETE_APPLICANT')}
                        showModal={showModalConfirmDelete}
                        setShowModal={setShowModalConfirmDelete}
                        confirmCallback={async () => {
                            if (applicantId) {
                                await agent.ClientApplicant.removeApplicant({ applicantId });
                                setShowModalConfirmDelete(false);
                                setShowModal(false);
                                setChangeCount(changeCount + 1);
                            }
                        }}
                    />
                    <BsModalAlert
                        title={t('IDS_SUCCESS')}
                        message={modalAlertState.msg}
                        showModal={modalAlertState.show}
                        setShowModal={(show) => setModalAlertState(prevState => ({ show, msg: prevState.msg }))}
                    />
                </FormProvider>
            </Modal>
        </>
    );
}