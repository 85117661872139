import { ChangeEvent, useEffect, useState } from 'react';
import {
    Button, Col, FloatingLabel, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../../app/api/agent';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import {
    TblApplicantAchieverResult
} from '../../../app/models/dbTables/tblApplicantAchieverResult';
import { JobFitDto } from '../../../app/models/responseHelpers/jobFitDto';
import { PositionProfileDto } from '../../../app/models/responseHelpers/positionProfileDto';
import { TextValuePair } from '../../../app/models/textValuePair';
import { getAchieverPdfWithPositionAsync } from '../../../app/store/pdfDocumentSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import PerformJobFitCalc from '../../../app/utility/jobFit';
import AssessApplicantTableRow from './AssessApplicantTableRow';

interface Props {
    applicantId: number;
    assessmentResults: TblApplicantAchieverResult[];
    initialPositionId: number;
}

export default function AssessApplicant({ applicantId, assessmentResults, initialPositionId }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? 0;
    const [loading, setLoading] = useState(false);
    const [positionId, setPositionId] = useState(initialPositionId);
    const [positionOptions, setPositionOptions] = useState<TextValuePair[]>([]);
    const [profile, setProfile] = useState<PositionProfileDto | null>(null);
    const [otherJobFits, setOtherJobFits] = useState<JobFitDto[]>([]);

    useEffect(() => {
        let isMounted = true;
        const fetchSetupData = async () => {
            try {
                if (isMounted && clientId > 0 && positionId > 0 && applicantId > 0) {
                    setLoading(true);
                    let positions = await agent.ClientPosition.getClientPositionForJobFit({ clientId });
                    if (!isMounted) return;

                    let positionOpts: TextValuePair[] = positions.map(pos => {
                        let posProfile = pos?.profile ?? null;
                        let posJobFitCalc = PerformJobFitCalc(assessmentResults, posProfile);
                        let posJobFitRating = `${posJobFitCalc / 2}%`;
                        if (pos.positionId === positionId) {
                            setProfile(posProfile);
                        }
                        return { text: `${pos.title} - ${posJobFitRating}`, value: `${pos.positionId}` };
                    }).sort((a, b) => (a.text > b.text) ? 1 : -1);

                    setPositionOptions(positionOpts);

                    let jobFits = await agent.ClientPosition.getTopFivePositions({ clientId, numResults: 5, applicantId });
                    if (!isMounted) return;
                    setOtherJobFits(jobFits);
                }
            } catch (error) {
                console.log(error);
            } finally {
                if (isMounted) setLoading(false);
            }
        }

        fetchSetupData();

        return () => { isMounted = false; };
    }, [assessmentResults, clientId, positionId, applicantId]);

    return (
        <>
            <LoadingComponentControlled delay={0} show={loading} />
            <Row className="g-4">
                <Col xs={12} lg={6}>
                    <InputGroup className="flex-nowrap">
                        <FloatingLabel
                            controlId="currentPosition"
                            className="small flex-fill"
                            label={`${t('IDS_CURRENT_POSITION')} - ${t('IDS_JOB_FIT_RATING')}`}
                        >
                            <Form.Select
                                value={positionId}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setPositionId(Number(e.target.value));
                                }}
                            >
                                {positionOptions && positionOptions.map(({ text, value }) => (
                                    <option key={value} value={value}>{text}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                        <InputGroup.Text className="px-2" style={{ height: 'calc(3.5rem + 2px)' }}>
                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id="tooltipUpdatedResults">
                                        {t('IDS_VIEW_UPDATED_ASSESSMENT_RESULTS')}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="link"
                                    className="link-dark-ocean m-0 p-1"
                                    onClick={async () => {
                                        await dispatch(getAchieverPdfWithPositionAsync({ applicantId, positionId }));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                </Button>
                            </OverlayTrigger>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="border border-light-eggplant border-1 bg-white small rounded table-responsive">
                        <Table striped borderless
                            id="tblOtherJobFits"
                            size="sm"
                            variant="dark-eggplant"
                            className="mb-0 table-cell-nowrap"
                        >
                            <thead>
                                <tr>
                                    <th colSpan={2}>{t('IDS_OTHER_POSITION_JF')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {otherJobFits && otherJobFits.length > 0
                                    ? (otherJobFits.map((item) => (
                                        <tr key={item.positionTitle}>
                                            <td className="pe-4">{item.positionTitle}</td>
                                            <td className="text-end font-monospace">{item.jobFitPercentage}</td>
                                        </tr>))
                                    )
                                    : (
                                        <tr>
                                            <td colSpan={2} className="text-center">No data available</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>

            <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive mt-3">
                <Table
                    striped borderless
                    size="sm"
                    variant="dark-eggplant"
                    className="mb-0 table-cell-nowrap table-inner-cells-bordered"
                >
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={13}>{t('IDS_MENTAL_APTITUDES')}</th>
                        </tr>
                        <tr>
                            <th className="text-center">{t('IDS_APTITUDE')}</th>
                            <th className="text-center">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center" colSpan={9}>{t('IDS_STANINE')}</th>
                            <th className="text-center ps-3">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center">{t('IDS_RAW_SCORE')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A1')}
                            profileRange={profile?.a1 ?? '00'}
                            i18nKeyAptitude="IDS_MENTAL_ALERTNESS"
                            i18nKeyDescLow="IDS_SLOW_TO_LEARN"
                            i18nKeyDescHigh="IDS_QUICK_TO_LEARN"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A2')}
                            profileRange={profile?.a2 ?? '00'}
                            i18nKeyAptitude="IDS_BUSINESS_TERMS"
                            i18nKeyDescLow="IDS_LOW_BUSINESS"
                            i18nKeyDescHigh="IDS_HIGH_BUSINESS"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A3')}
                            profileRange={profile?.a3 ?? '00'}
                            i18nKeyAptitude="IDS_MEMORY_RECALL"
                            i18nKeyDescLow="IDS_LOW_MEMORY"
                            i18nKeyDescHigh="IDS_HIGH_MEMORY"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A4')}
                            profileRange={profile?.a4 ?? '00'}
                            i18nKeyAptitude="IDS_VOCABULARY"
                            i18nKeyDescLow="IDS_LOW_VOCABULARY"
                            i18nKeyDescHigh="IDS_HIGH_VOCABULARY"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A5')}
                            profileRange={profile?.a5 ?? '00'}
                            i18nKeyAptitude="IDS_PERCEPTION"
                            i18nKeyDescLow="IDS_LOW_PERCEPTION"
                            i18nKeyDescHigh="IDS_HIGH_PERCEPTION"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'A6')}
                            profileRange={profile?.a6 ?? '00'}
                            i18nKeyAptitude="IDS_MECHANICAL_INTEREST"
                            i18nKeyDescLow="IDS_LOW_MECHANICAL"
                            i18nKeyDescHigh="IDS_HIGH_MECHANICAL"
                        />
                    </tbody>
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={13}>{t('IDS_PERS_DIMENSIONS')}</th>
                        </tr>
                        <tr>
                            <th className="text-center">{t('IDS_APTITUDE')}</th>
                            <th className="text-center">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center" colSpan={9}>{t('IDS_STANINE')}</th>
                            <th className="text-center ps-3">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center">{t('IDS_RAW_SCORE')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D1')}
                            profileRange={profile?.d1 ?? '00'}
                            i18nKeyAptitude="IDS_ENERGY"
                            i18nKeyDescLow="IDS_RESTLESS"
                            i18nKeyDescHigh="IDS_CALM"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D2')}
                            profileRange={profile?.d2 ?? '00'}
                            i18nKeyAptitude="IDS_CHARACTER_STRENGTH"
                            i18nKeyDescLow="IDS_LOW_CHAR"
                            i18nKeyDescHigh="IDS_HIGH_CHAR"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D3')}
                            profileRange={profile?.d3 ?? '00'}
                            i18nKeyAptitude="IDS_WORK_HABITS"
                            i18nKeyDescLow="IDS_LOW_WORK"
                            i18nKeyDescHigh="IDS_HIGH_WORK"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D4')}
                            profileRange={profile?.d4 ?? '00'}
                            i18nKeyAptitude="IDS_COMMUNICATION"
                            i18nKeyDescLow="IDS_LOW_COMMUN"
                            i18nKeyDescHigh="IDS_HIGH_COMMUN"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D5')}
                            profileRange={profile?.d5 ?? '00'}
                            i18nKeyAptitude="IDS_EMOTIONAL_MAT"
                            i18nKeyDescLow="IDS_LOW_EMOTIONAL"
                            i18nKeyDescHigh="IDS_HIGH_EMOTIONAL"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D6')}
                            profileRange={profile?.d6 ?? '00'}
                            i18nKeyAptitude="IDS_DOMINANCE"
                            i18nKeyDescLow="IDS_LOW_DOMINANCE"
                            i18nKeyDescHigh="IDS_HIGH_DOMINANCE"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D7')}
                            profileRange={profile?.d7 ?? '00'}
                            i18nKeyAptitude="IDS_COMPETITIVENESS"
                            i18nKeyDescLow="IDS_LOW_COMPETITIVE"
                            i18nKeyDescHigh="IDS_HIGH_COMPETITIVE"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D8')}
                            profileRange={profile?.d8 ?? '00'}
                            i18nKeyAptitude="IDS_MENTAL_TOUGHNESS"
                            i18nKeyDescLow="IDS_LOW_MENTAL_TOUGH"
                            i18nKeyDescHigh="IDS_HIGH_MENTAL_TOUGH"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D9')}
                            profileRange={profile?.d9 ?? '00'}
                            i18nKeyAptitude="IDS_OPTIMISIM"
                            i18nKeyDescLow="IDS_LOW_OPTIMISIM"
                            i18nKeyDescHigh="IDS_HIGH_OPTIMISIM"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'D10')}
                            profileRange={profile?.d10 ?? '00'}
                            i18nKeyAptitude="IDS_WORK_MOTIVATION"
                            i18nKeyDescLow="IDS_LOW_MOTIVATION"
                            i18nKeyDescHigh="IDS_HIGH_MOTIVATION"
                        />
                    </tbody>
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={13}>{t('IDS_VALIDITY_SCALES')}</th>
                        </tr>
                        <tr>
                            <th className="text-center">{t('IDS_APTITUDE')}</th>
                            <th className="text-center">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center" colSpan={9}>{t('IDS_STANINE')}</th>
                            <th className="text-center ps-3">{t('IDS_DESCRIPTION')}</th>
                            <th className="text-center">{t('IDS_RAW_SCORE')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'V1')}
                            profileRange={profile?.v1 ?? '00'}
                            i18nKeyAptitude="IDS_DISTORTION"
                            i18nKeyDescLow="IDS_LOW_DISTORTION"
                            i18nKeyDescHigh="IDS_HIGH_DISTORTION"
                        />
                        <AssessApplicantTableRow
                            achieverResult={assessmentResults.find(x => x.code.trim() === 'V2')}
                            profileRange={profile?.v2 ?? '00'}
                            i18nKeyAptitude="IDS_EQUIVOCATION"
                            i18nKeyDescLow="IDS_LOW_EQUIVOCATION"
                            i18nKeyDescHigh="IDS_HIGH_EQUIVOCATION"
                        />
                    </tbody>
                </Table>
            </div>
        </>
    );
}