import { FieldValues } from 'react-hook-form';

import { locales } from '../../../i18n';
import { ClientPositionDefaultQuestionsRequest } from './clientPositionDefaultQuestionsRequest';
import { ClientPositionQuestionRequest } from './clientPositionQuestionRequest';

export interface ClientPositionRequest {
    positionId: number;
    positionNumber: string;
    active: boolean;
    departmentId: number;
    clientId: number;
    title: string;
    email: string;
    location: string;
    city: string;
    state: string;
    description: string;
    requirement: string;
    positionProfileId: number | null;
    defaultQuestionIds: ClientPositionDefaultQuestionsRequest[];
    positionQuestions: ClientPositionQuestionRequest[];
}

export function formDataToClientPositionRequest(formData: FieldValues) {
    // Convert Email Override array to comma-separated string
    let emailOverride = '';
    if (Array.isArray(formData.email) && formData.email.length > 0) {
        emailOverride = formData.email.map(x => x.value).join(',');
    }

    // Client Position Default Questions & Client Position Questions
    const supportedLanguages = Object.keys(locales);
    let defaultQuestions: ClientPositionDefaultQuestionsRequest[] = [];
    let positionQuestions: ClientPositionQuestionRequest[] = [];

    for (let lang of supportedLanguages) {
        if (formData[`positionDefaultQuestions-${lang}`]) {
            let defaultQuestionIds: number[] = [];
            for (let i = 0; i < formData[`positionDefaultQuestions-${lang}`].length; i++) {
                if (formData[`positionDefaultQuestions-${lang}`][i].checked) {
                    defaultQuestionIds.push(formData[`positionDefaultQuestions-${lang}`][i].questionId);
                }
            }
            defaultQuestions.push({
                languageCode: lang,
                defaultQuestionIds
            });
        }

        if (formData[`positionQuestions-${lang}`]) {
            for (let i = 0; i < formData[`positionQuestions-${lang}`].length; i++) {
                if (formData[`positionQuestions-${lang}`][i].question) {
                    positionQuestions.push({
                        languageCode: lang,
                        ...formData[`positionQuestions-${lang}`][i]
                    });
                }
            }
        }
    }

    let clientPositionRequest: ClientPositionRequest = {
        positionId: formData.positionId,
        positionNumber: formData.positionNumber,
        active: formData.active,
        departmentId: formData.departmentId,
        clientId: formData.clientId,
        title: formData.title,
        email: emailOverride,
        location: formData.location,
        city: formData.city,
        state: formData.state,
        description: formData.description,
        requirement: formData.requirement,
        positionProfileId: formData.positionProfileId,
        defaultQuestionIds: defaultQuestions,
        positionQuestions
    }

    return clientPositionRequest;
}