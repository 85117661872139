import { TblApplicantAchieverResult } from '../models/dbTables/tblApplicantAchieverResult';
import { PositionProfileDto } from '../models/responseHelpers/positionProfileDto';

export default function PerformJobFitCalc(achieverResults: TblApplicantAchieverResult[], positionProfile: PositionProfileDto | null | undefined): number {
    let jobFitScore: number = 0;

    for (let achieverResult of achieverResults) {
        let code = achieverResult.code.trim();
        let strProfile = positionProfile ? (positionProfile[code.toLowerCase() as keyof PositionProfileDto] as string) : '';
        let nLowerBenchmark: number = strProfile.length === 2 ? Number(strProfile[0]) : 0;
        let nUpperBenchmark: number = strProfile.length === 2 ? Number(strProfile[1]) : 0;
        let fWithinBenchmark: boolean = false;

        if (nLowerBenchmark === 0 && nUpperBenchmark === 0) {
            fWithinBenchmark = true;
        } else if (Number(achieverResult.stanine) >= nLowerBenchmark && Number(achieverResult.stanine) <= nUpperBenchmark) {
            fWithinBenchmark = true;
        }

        //  Mental Alertness - 40 points for being within or above the benchmark
        //   30 points for being 1 lower than the benchmark
        //   20 points for being 2 lower than the benchmark
        if (code === "A1") {
            if (Number(achieverResult.stanine) >= nLowerBenchmark) {
                jobFitScore += 40;
            } else if (achieverResult.stanine === (nLowerBenchmark - 1)) {
                jobFitScore += 30;
            } else if (achieverResult.stanine === (nLowerBenchmark - 2)) {
                jobFitScore += 20;
            }
        }

        //  10 points for being within the benchmark for
        //    Business Terms
        //    Memory Recall
        //    Vocabulary
        //    Mechanical Interest
        //    Work Habits
        //    Communication
        //    Dominance
        //    Competitiveness
        //    Mental Toughness
        //    Optimism
        //    Work Motivation
        if (['A2', 'A3', 'A4', 'A6', 'D3', 'D4', 'D6', 'D7', 'D8', 'D9', 'D10'].includes(code) && fWithinBenchmark) {
            jobFitScore += 10;
        }

        //  20 points for being within the benchmark for
        //    Perception
        if (code === "A5" && fWithinBenchmark) {
            jobFitScore += 20;
        }

        //  For Energy, Character Strength, and Emotional Maturity 
        //  subtract 10 points if score is 1 or 2
        //  Add 10 points if within the benchmark
        if (['D1', 'D2', 'D5'].includes(code)) {
            if (achieverResult.stanine === 1 || achieverResult.stanine === 2) {
                jobFitScore -= 10;
            } else if (fWithinBenchmark) {
                jobFitScore += 10;
            }
        }

    }

    return jobFitScore;
}