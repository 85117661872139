import { QuestionBase } from '../questionBase';

export interface DPATQuestion extends QuestionBase {
    imageAnswers: string[];
    image: string;
}

export const dpatParseCorrectAnswers = (strAns: string): Record<number, number> => {
    let htAns: Record<number, number> = {};
    let strParts: string[] = strAns.split(':');
    strParts.forEach(x => htAns[Number(x.substring(0, 2))] = Number(x.substring(3, 5)));
    return htAns;
}