import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomCloseBtn from "../../app/components/CustomCloseBtn";

export default function TermsAndConditions() {
    const [showModalTerms, setShowModalTerms] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-2 pt-2 smaller line-height-100">
                {t('IDS_AGREEMENT') + ' '}
                <Button variant="link"
                    className="p-0 border-none fs-inherit"
                    onClick={() => setShowModalTerms(true)}
                >
                    {t('IDS_TERMS_AND_CONDITIONS')}
                </Button>
            </div>

            <Modal
                scrollable={true}
                show={showModalTerms}
                onHide={() => setShowModalTerms(false)}
                id="modalTermsConditions"
                centered={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>{t('IDS_TERMS_AND_CONDITIONS')}</Modal.Title>
                    <CustomCloseBtn hideFcn={() => setShowModalTerms(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-bold smaller">{t('IDS_CLIENT_LOGIN_PARAGRAPH1')}</p>
                    <p className="smaller">{t('IDS_USER_LOGIN_PARAGRAPH1')}</p>
                    <p className="smaller">{t('IDS_USER_LOGIN_PARAGRAPH2')}</p>
                    <p className="smaller">{t('IDS_USER_LOGIN_PARAGRAPH3')}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}