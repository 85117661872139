import { getTrackBackground, Range } from 'react-range';

import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colorVars from '../layout/styles/_colors.module.scss';

interface Props {
    max: number;
    min: number;
    step: number;
    vals: number[];
    disabled: boolean;
    ctlOnChange: (values: number[]) => void;
    ref?: React.Ref<any>;
}

export default function RangeSlider({ max, min, step, vals, disabled, ctlOnChange }: Props) {
    let values = [...vals];
    if (values[0] === 0 && values[1] > 0) {
        values[0] = 1;
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: `calc((3rem * ${max}) + 3rem)`
            }}
        >
            <Range
                values={values}
                step={step}
                min={min}
                max={max}
                disabled={disabled}
                onChange={(curValues) => {
                    if (curValues[0] === 0 && curValues[1] > 0) {
                        ctlOnChange([1, curValues[1]])
                    } else {
                        ctlOnChange(curValues);
                    }
                }}
                renderMark={({ props, index }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            width: '3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            userSelect: 'none',
                            ...(index === values[0] && index === values[1]
                                    ? { borderRadius: '.25rem' }
                                    : index === values[0]
                                        ? { borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }
                                        : index === values[1]
                                            ? { borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem' } 
                                            : {}),
                            backgroundColor: values.length === 2 && (index * step) >= values[0] && (index * step) <= values[1] ? colorVars.ocean : 'transparent'
                        }}
                    >
                        {index > 0 && index}
                    </div>
                )}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '1.5rem',
                            display: 'flex',
                            width: `calc(3rem * ${max})`
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: values.length === 2 ? getTrackBackground({
                                    values,
                                    colors: ['transparent', 'transparent', 'transparent'],
                                    min: min,
                                    max: max
                                }) : 'transparent',
                                alignSelf: 'center'
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props, index }) => (
                    <div
                        {...props}
                        onMouseDown={(e) => {
                            if (index === 0 && values[0] === 0 && values[1] === 0) {
                                e.stopPropagation();
                            }
                        }}
                        style={{
                            ...props.style,
                            height: '1rem',
                            width: '1rem',
                            backgroundColor: 'transparent',
                            left: index === 0 ? '-1rem' : '1rem',
                            display: 'flex',
                            justifyContent: index === 0 ? 'start' : 'end',
                            alignItems: 'center',
                            opacity: values[0] === 0 && values[1] === 0 ? '0' : '1'
                        }}
                        title="Draggable item"
                    >
                        <FontAwesomeIcon icon={faGripVertical} fixedWidth style={{width: '1rem'}} />
                    </div>
                )}
            />
        </div>
    );
}