import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colorVars from '../../../app/layout/styles/_colors.module.scss';
import { BsModalAlert } from '../../../app/components/BsModal';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppEEOData } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { PropsApplicationTabs } from './shared/applicationTabsProps';
import { NOT_SPECIFIED } from '../../../app/models/responseHelpers/applicantEeoDataDto';

export default function TabEEOData({ setFormState }: PropsApplicationTabs<FieldValuesAppEEOData>) {
    const { t } = useTranslation();
    const {
        applicant: { applicantId }, eeoData
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const [showRaceModal, setShowRaceModal] = useState(false);

    const formMethods = useForm<FieldValuesAppEEOData>({
        mode: 'all',
        defaultValues: {
            applicantId,
            countryCode: 'en-US',
            gender: eeoData?.gender ?? NOT_SPECIFIED,
            race: eeoData?.race ?? NOT_SPECIFIED
        }
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful }, handleSubmit, getValues, register } = formMethods;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    return (
        <div>
            <Form id="form-eeoData" className="needs-validation" noValidate>
                <h4 className="mb-4 fw-bold">{t('IDS_EEO_TITLE')}</h4>
                <div className="pb-2 small" dangerouslySetInnerHTML={{ __html: t('IDS_EEO_DISCLAIMER') }} />

                <Row className="mb-4">
                    <Col>
                        <Form.Label className="small" htmlFor="divEeoDataGender">{t('IDS_EEO_GENDER')}</Form.Label>
                        <div id="divEeoDataGender">
                            {
                                ['Male', 'Female', NOT_SPECIFIED].map((text, idx) => (
                                    <Form.Check
                                        className="check-group-item"
                                        key={idx}
                                        id={`eeoDataGender${idx}`}
                                        type="radio"
                                        label={text}
                                        {...register('gender')}
                                        value={text}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <Form.Label className="small" htmlFor="divEeoDataRace">
                            <span>{t('IDS_EEO_RACE')}</span>
                            <span
                                className="tooltip-icon mx-2 fs-initial"
                                onClick={() => setShowRaceModal(true)}
                                title={t('IDS_HELP_RACEDEF_TITLE')}
                            >
                                <FontAwesomeIcon icon={faQuestionCircle} color={colorVars.eggplant} />
                            </span>
                        </Form.Label>
                        <div id="divEeoDataRace">
                            {
                                [
                                    'White (not Hispanic or Latino)',
                                    'Black or African American (not Hispanic or Latino)',
                                    'Hispanic or Latino',
                                    'Asian (not Hispanic or Latino)',
                                    'Native Hawaiian or Other Pacific Islander (not Hispanic or Latino)',
                                    'American Indian or Alaskan Native (not Hispanic or Latino)',
                                    'Two or More Races (not Hispanic or Latino)',
                                    NOT_SPECIFIED
                                ].map((text, idx) => (
                                    <Form.Check
                                        className="check-group-item"
                                        key={idx}
                                        id={`eeoDataRace${idx}`}
                                        type="radio"
                                        label={text}
                                        {...register('race')}
                                        value={text}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Form>
            <BsModalAlert
                title={t('IDS_HELP_RACEDEF_TITLE')}
                message={t('IDS_HELP_RACEDEF_MESSAGE')}
                showModal={showRaceModal}
                setShowModal={setShowRaceModal}
                bodyClass="small"
            />
        </div>
    );
}