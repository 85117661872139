import i18n from '../../../../i18n';
import { QuestionType } from '../../../enums/testQuestionType';
import { AchieverSectionId } from '../../../enums/testSections';
import { TestBase } from '../testBase';
import { achieverParseCorrectAnswers, AchieverQuestion } from './achieverQuestion';
import { AchieverSection } from './achieverSection';

export interface AchieverTest extends TestBase<AchieverSection> {}

const loadSection = (nID: number, eID: AchieverSectionId, strName: string, nNumQuestions: number,
    eDefType: QuestionType, nTime: number, nMaxMiddle: number, resources: Record<string, string>, testTimeMultiplier: number): AchieverSection => {
    let cSection = {
        id: eID,
        name: strName,
        questions: new Array(nNumQuestions),
        timeToComplete: (nTime > 0 && testTimeMultiplier > 1) ? (nTime * testTimeMultiplier) : nTime,
        maxMaybes: nMaxMiddle,
        instructions: ''
    }
    let strResInst = `IDS_S${nID}_INSTRUCTIONS`;

    if (nMaxMiddle !== 0) {
        cSection.instructions = resources[strResInst].replace('{0}', `${nMaxMiddle}`);
    } else {
        cSection.instructions = resources[strResInst];
    }

    if (cSection.timeToComplete > 0) {
        let min = Math.floor(cSection.timeToComplete);
        let sec = Math.floor((cSection.timeToComplete * 60) % 60);

        cSection.instructions = cSection.instructions.replace(
            '{TIME_TO_COMPLETE}',
            (sec > 0
                ? `${min} ${i18n.t('translation:IDS_MINUTES')} ${i18n.t('translation:IDS_AND')} ${sec} ${i18n.t('translation:IDS_SECONDS')}`
                : `${min} ${i18n.t('translation:IDS_MINUTES')}`).toUpperCase()
        );
    }

    for (let nNdx = 1; nNdx <= nNumQuestions; nNdx++) {
        let strResQues = `IDS_S${nID}Q${nNdx}`;
        let strInfo = resources[`${strResQues}Info`];

        let cQues: AchieverQuestion = {
            question: resources[strResQues],
            questionType: eDefType,
            correctAnswers: achieverParseCorrectAnswers(strInfo.substring(3)),
            answers: [],
            response: 0
        };

        let nMaxAns = Number(strInfo.substring(0, 2));
        for (let nAns = 1; nAns <= nMaxAns; nAns++) {
            let strResAns = `IDS_S${nID}Q${nNdx}A${nAns}`;
            cQues.answers.push(resources[strResAns]);
        }

        cSection.questions[nNdx-1] = cQues;
    }

    return cSection;
}

export const initAchiever = (resources: Record<string, string>, testTimeMultiplier: number): AchieverTest => {
    let sections: AchieverSection[] = [];
    sections.push(loadSection(1, AchieverSectionId.Personality, resources['IDS_PERSONALITY'], 76, QuestionType.YesNoMaybe, 0, 7, resources, testTimeMultiplier));
    sections.push(loadSection(2, AchieverSectionId.MentalAcuity, resources['IDS_MENTAL'], 40, QuestionType.MultipleChoice, 15, 0, resources, testTimeMultiplier));
    sections.push(loadSection(3, AchieverSectionId.BusinessTerms, resources['IDS_BUSINESS'], 24, QuestionType.MultipleChoice, 5, 0, resources, testTimeMultiplier));
    sections.push(loadSection(4, AchieverSectionId.Vocabulary, resources['IDS_VOCABULARY'], 50, QuestionType.MultipleChoice, 4, 0, resources, testTimeMultiplier));
    sections.push(loadSection(5, AchieverSectionId.Perception, resources['IDS_PERCEPTION'], 95, QuestionType.YesNo, 3, 0, resources, testTimeMultiplier));
    sections.push(loadSection(6, AchieverSectionId.MechInterest, resources['IDS_MECHANICAL'], 18, QuestionType.MultipleChoice, 0, 0, resources, testTimeMultiplier));
    return { name: '', instructions: '',  sections };
}