import { useEffect } from 'react';
import { Alert, Form, Tab } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppExp } from '../../../app/models/fieldValueTypes';
import { getPaddedExps } from '../../../app/models/requestHelpers/applicantExpRequest';
import { useAppSelector } from '../../../app/store/storeHooks';
import { isValidDate, RegExps } from '../../../app/utility/util';
import { getLangOrDefault, localeCode } from '../../../i18n';
import { PropsApplicationTabs } from './shared/applicationTabsProps';
import WorkExperienceCard from './WorkExperienceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function TabApplicantWorkExp({ setFormState }: PropsApplicationTabs<FieldValuesAppExp>) {
    const { t } = useTranslation();
    const { status } = useAppSelector((state) => state.account);
    const {
        experiences, client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const empReqd = client.employment || 0;
    const userLang = getLangOrDefault();

    const formMethods = useForm<FieldValuesAppExp>({
        mode: 'all',
        defaultValues: { experiences: getPaddedExps(experiences) },
        resolver: yupResolver(yup.object({
            experiences: yup.array().of(
                yup.object().shape({
                    clientIndex: yup.number(),
                    noPreviousExp: yup.boolean(),
                    stillEmployed: yup.boolean(),
                    companyName: yup.string().when(['clientIndex', 'noPreviousExp'], {
                        is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_COMPANY_NAME'] })
                    }),
                    jobTitle: yup.string().when(['clientIndex', 'noPreviousExp'], {
                        is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_JOB_TITLE'] })
                    }),
                    city: yup.string().when(['clientIndex', 'noPreviousExp'], {
                        is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CITY'] })
                    }),
                    state: (userLang === localeCode.fr || userLang === localeCode.enGB) 
                        ? yup.string().nullable().notRequired().when(['clientIndex', 'noPreviousExp', 'state'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean, stateVal: string) =>
                                (empReqd > clientIndexVal && !noPrevExpVal && stateVal?.length),
                            then: rule => rule.matches(RegExps.state, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_STATE'] } }),
                        })
                        : yup.string().when(['clientIndex', 'noPreviousExp'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                            then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_STATE'] })
                                .matches(RegExps.state, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_STATE'] } })
                        }),
                    startDate: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                        .when(['clientIndex', 'noPreviousExp', 'endDate'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean, endDateVal: any) =>
                                (!noPrevExpVal && (empReqd > clientIndexVal || isValidDate(endDateVal))),
                            then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_START_DATE'] })
                        })
                        .when(['clientIndex', 'noPreviousExp', 'endDate'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean, endDateVal: any) =>
                                (empReqd > clientIndexVal && !noPrevExpVal && isValidDate(endDateVal)),
                            then: rule => rule.max(yup.ref('endDate'), { resKeys: ['IDS_IEP_INVALID', 'IDS_START_DATE'] })
                        }),
                    endDate: yup.date().nullable().transform(v => (isValidDate(v) ? v : null))
                        .when(['clientIndex', 'noPreviousExp', 'stillEmployed', 'startDate'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean, stillEmployedVal: boolean, startDateVal: any) =>
                                (!noPrevExpVal && !stillEmployedVal && (empReqd > clientIndexVal || isValidDate(startDateVal))),
                            then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_END_DATE'] })
                        })
                        .when(['clientIndex', 'noPreviousExp', 'stillEmployed', 'startDate'], {
                            is: (clientIndexVal: number, noPrevExpVal: boolean, stillEmployedVal: boolean, startDateVal: any) =>
                                (empReqd > clientIndexVal && !noPrevExpVal && !stillEmployedVal && isValidDate(startDateVal)),
                            then: rule => rule.min(yup.ref('startDate'), { resKeys: ['IDS_IEP_INVALID', 'IDS_END_DATE'] })
                        }),
                    comment: yup.string().when(['clientIndex', 'noPreviousExp'], {
                        is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_REASON_FOR_LEAVING'] })
                    }),
                    description: yup.string().when(['clientIndex', 'noPreviousExp'], {
                        is: (clientIndexVal: number, noPrevExpVal: boolean) => (empReqd > clientIndexVal && !noPrevExpVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_POSITION_RESPONSIBILITIES'] })
                    })
                },
                    [
                        ['state', 'state'],
                        ['startDate', 'endDate']
                    ])
            )
        }))
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors }, handleSubmit, getValues, trigger } = formMethods;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    const workExpTabs: BsTabs = {
        'first': {
            linkText: 'IDS_FIRST',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.experiences?.[0],
            paneComponent: <WorkExperienceCard index={0} />
        },
        'second': {
            linkText: 'IDS_SECOND',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.experiences?.[1],
            paneComponent: <WorkExperienceCard index={1} />
        },
        'third': {
            linkText: 'IDS_THIRD',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.experiences?.[2],
            paneComponent: <WorkExperienceCard index={2} />
        }
    };

    return (
        <div>
            <Alert variant="dark-ocean" className="d-flex align-items-center">
                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                <div className="small">{t('IDS_WORK_EXP_INSTRUCT')}</div>
            </Alert>
            <FormProvider {...formMethods} >
                <Form id="form-workExperience" className="needs-validation" noValidate>
                    <Tab.Container defaultActiveKey="first">
                        <BsScrollableTabs tabs={workExpTabs} navTabsClassName="nav-tabs-alt" />
                        <div className="border border-light-eggplant border-2 bg-white p-4">
                            <Tab.Content>
                                {Object.keys(workExpTabs).map((key) => (
                                    <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                        {workExpTabs[key].paneComponent}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </Form>
            </FormProvider>
        </div>
    );
}