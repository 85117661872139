import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import colorVars from '../../../app/layout/styles/_colors.module.scss';
import agent from '../../../app/api/agent';
import { ApplicantTestProgress } from '../../../app/enums/applicantTestProgress';
import { useAppSelector } from '../../../app/store/storeHooks';
import { convertHexToRGBA, dateTimeIsMinVal } from '../../../app/utility/util';
import { transType } from '../../../i18next';

export default function TestProgress() {
    const applicant = useAppSelector(state => state.account.user?.applicantSessionDto?.applicant);
    const { testSession } = useAppSelector(state => state.test);
    const [progressIds, setProgressIds] = useState<number[]>([]);

    useEffect(() => {
        let isMounted = true;
        const initProgress = async () => {
            try {
                if (isMounted && !!applicant?.applicantId) {
                    if (sessionStorage.getItem('progressIds') === null) {
                        let allProgress = await agent.Applicant.allProgress({ id: applicant.applicantId });
                        if (!isMounted) return;
                        let allProgressIds = allProgress.map(x => x.progressId ?? 0);
                        setProgressIds(allProgressIds);
                        sessionStorage.setItem('progressIds', JSON.stringify(allProgressIds));
                    } else {
                        setProgressIds(JSON.parse(sessionStorage.getItem('progressIds')!));
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        initProgress();
        return () => { isMounted = false; };
    }, [applicant?.applicantId]);

    if (testSession && applicant)
        return (
            <div className="d-flex mt-2">
                <ProgressBar className="w-100" style={{ height: '1.75rem' }}>
                    {progressIds.map((progressId, i) => (
                        <TestProgressItem
                            key={i}
                            index={i}
                            progressId={progressId}
                        />
                    ))}
                </ProgressBar>
            </div>
        );
    else return null;
}

interface TestProgressItemProps {
    index: number;
    progressId: number;
}

function TestProgressItem({ index, progressId }: TestProgressItemProps) {
    const { t } = useTranslation();
    const progress = useAppSelector(state => state.account.user?.applicantSessionDto?.progress);
    const isActiveSection = progressId === progress?.progressId;

    const activeVariant = 'dark-eggplant';
    const completeVariant = 'eggplant';
    const incompleteVariant = 'light-eggplant';

    let i18nKey = '';
    let sectionComplete = false;
    if (progressId === ApplicantTestProgress.Application) {
        i18nKey = 'IDS_STATUS_APPLICATION';
        sectionComplete = !dateTimeIsMinVal(progress?.appCompletedDate);
    } else if (progressId === ApplicantTestProgress.Personality) {
        i18nKey = 'IDS_PERSONALITY';
        sectionComplete = !dateTimeIsMinVal(progress?.test1CompletedDate);
    } else if (progressId === ApplicantTestProgress.MentalAlertness) {
        i18nKey = 'IDS_MENTAL_ALERTNESS';
        sectionComplete = !dateTimeIsMinVal(progress?.test2CompletedDate);
    } else if (progressId === ApplicantTestProgress.BusinessTerms) {
        i18nKey = 'IDS_BUSINESS_TERMS';
        sectionComplete = !dateTimeIsMinVal(progress?.test3CompletedDate);
    } else if (progressId === ApplicantTestProgress.Vocabulary) {
        i18nKey = 'IDS_VOCABULARY';
        sectionComplete = !dateTimeIsMinVal(progress?.test4CompletedDate);
    } else if (progressId === ApplicantTestProgress.Perception) {
        i18nKey = 'IDS_PERCEPTION';
        sectionComplete = !dateTimeIsMinVal(progress?.test5CompletedDate);
    } else if (progressId === ApplicantTestProgress.MechanicalInterest) {
        i18nKey = 'IDS_MECHANICAL_INTEREST';
        sectionComplete = !dateTimeIsMinVal(progress?.test6CompletedDate);
    } else if (progressId === ApplicantTestProgress.AlphaSequence) {
        i18nKey = 'IDS_STATUS_ALPHA_SEQUENCE';
        sectionComplete = !dateTimeIsMinVal(progress?.dpat1CompletedDate);
    } else if (progressId === ApplicantTestProgress.GraphicSequence) {
        i18nKey = 'IDS_STATUS_GRAPHIC_SEQUENCE';
        sectionComplete = !dateTimeIsMinVal(progress?.dpat2CompletedDate);
    } else if (progressId === ApplicantTestProgress.MathWordProblems) {
        i18nKey = 'IDS_STATUS_MATH_WORD_PROBLEMS';
        sectionComplete = !dateTimeIsMinVal(progress?.dpat3CompletedDate);
    } else if (progressId === ApplicantTestProgress.AlphaSequence2) {
        i18nKey = 'IDS_STATUS_ALPHA_SEQUENCE';
        sectionComplete = !dateTimeIsMinVal(progress?.dpatInhouse1CompletedDate);
    } else if (progressId === ApplicantTestProgress.GraphicSequence2) {
        i18nKey = 'IDS_STATUS_GRAPHIC_SEQUENCE';
        sectionComplete = !dateTimeIsMinVal(progress?.dpatInhouse2CompletedDate);
    } else if (progressId === ApplicantTestProgress.MathWordProblems2) {
        i18nKey = 'IDS_STATUS_MATH_WORD_PROBLEMS';
        sectionComplete = !dateTimeIsMinVal(progress?.dpatInhouse3CompletedDate);
    } else if (progressId === ApplicantTestProgress.Complete) {
        i18nKey = 'IDS_STATUS_COMPLETE';
        sectionComplete = progress?.progressId === ApplicantTestProgress.Complete;
    }

    return (
        <ProgressBar
            isChild
            striped={sectionComplete}
            key={index}
            variant={
                isActiveSection
                    ? activeVariant
                    : sectionComplete
                    ? completeVariant
                    : incompleteVariant
            }
            className={`flex-fill d-block p-1 ${
                isActiveSection ? 'overflow-visible' : 'text-wrap'
            }`}
            style={{
                borderColor: convertHexToRGBA(colorVars.darkeggplant, .5),
                borderStyle: 'solid',
                borderTopWidth: '1px',
                borderBottomWidth: '1px',
                ...(index === 0
                    ? { borderLeftWidth: '1px', borderRightWidth: '1px' }
                    : { borderLeftWidth: '0', borderRightWidth: '1px' }),
            }}
            title={t(i18nKey as keyof transType)}
            label={
                <div
                    className={`user-select-none ${
                        isActiveSection ? '' : 'd-inline-block text-nowrap'
                    }`}
                >
                    {t(i18nKey as keyof transType)}
                </div>
            }
        />
    );
}