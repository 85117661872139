import { FieldValues } from 'react-hook-form';

export interface ScheduledTaskRequest {
    scheduledTaskId: number;
    taskName: string;
    description: string;
    assemblyName: string;
    className: string;
    argumentsString: string;
    cronInterval: string;
    active: boolean;
    owner: string;
    oneTimeOnly: boolean;
    emailOnSuccess: boolean;
}

export function formDataToScheduledTaskRequest(formData: FieldValues) {
    //Convert arguments array to comma-separated string
    let argumentsList = '';
    if (Array.isArray(formData.argumentsString) && formData.argumentsString.length > 0) {
        argumentsList = formData.argumentsString.map(x => x.value).join(',');
    }

    //Convert owners array to comma-separated string
    let owners = '';
    if (Array.isArray(formData.owner) && formData.owner.length > 0) {
        owners = formData.owner.map(x => x.value).join(',');
    }

    let scheduledTaskRequest: ScheduledTaskRequest = {
        scheduledTaskId: formData.scheduledTaskId,
        taskName: formData.taskName,
        description: formData.description,
        assemblyName: formData.assemblyName,
        className: formData.className,
        argumentsString: argumentsList,
        cronInterval: formData.cronInterval,
        active: formData.active,
        owner: owners,
        oneTimeOnly: formData.oneTimeOnly,
        emailOnSuccess: formData.emailOnSuccess
    }

    return scheduledTaskRequest;
}