import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { transType } from './i18next';

export interface Locale {
    country: string;
    countryCode: string;
    language: string;
    flagClass: string;
}

export enum localeCode {
    enUS = 'en-US',
    enGB = 'en-GB',
    de = 'de',
    nl = 'nl',
    enCA = 'en-CA',
    frCA = 'fr-CA',
    fr = 'fr'
}

export const locales: { [key in localeCode]: Locale } =
{
    [localeCode.enUS]: { country: 'United States', countryCode: 'US', language: 'English', flagClass: 'fi-us' },
    [localeCode.enGB]: { country: 'United Kingdom', countryCode: 'GB', language: 'English', flagClass: 'fi-gb' },
    [localeCode.de]: { country: 'Deutschland', countryCode: 'DE', language: 'Deutsch', flagClass: 'fi-de' },
    [localeCode.nl]: { country: 'Nederland', countryCode: 'NL', language: 'Nederlands', flagClass: 'fi-nl' },
    [localeCode.enCA]: { country: 'Canada', countryCode: 'CA', language: 'English', flagClass: 'fi-ca' },
    [localeCode.frCA]: { country: 'Canada', countryCode: 'CA', language: 'Français', flagClass: 'fi-ca' },
    [localeCode.fr]: { country: 'France', countryCode: 'FR', language: 'Français', flagClass: 'fi-fr' }
};

const supportedLanguages = Object.keys(locales);

export function getInitialLangOrDefault() {
    let resolvedLocale = Intl.DateTimeFormat().resolvedOptions().locale;
    if (resolvedLocale && supportedLanguages.includes(resolvedLocale)) {
        return resolvedLocale as localeCode;
    }
    return localeCode.enUS;
}

export function getLangOrDefault() {
    const localStorageVal = localStorage.getItem('i18nextLng');
    if (localStorageVal && supportedLanguages.includes(localStorageVal)) {
        return localStorageVal as localeCode;
    }
    return localeCode.enUS;
}

export function getLangOrDefaultReCaptcha() {
    let lang = getLangOrDefault();
    return (lang === 'en-US' || lang === 'en-CA') ? 'en' : lang;
}

export function getTz() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function parseValidationMsg(error: any, t: TFunction, doNotEscape: boolean = false) {
    if (error === null) {
        return '';
    } else if (typeof error === 'object') {
        if (error.resKeys.length === 2) {
            return t(error.resKeys[0] as keyof transType, { 0: t(error.resKeys[1] as keyof transType), interpolation: { escapeValue: !doNotEscape }});
        }
        return t(error.resKeys[0] as keyof transType);
    }
    return t(error as keyof transType);
}

export function getTitle(t: TFunction, pageKey: string) {
    return `${t('IDS_TEST_FIRST_HIRING')} - ${t(pageKey as keyof transType)}`;
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: 'translation',
        fallbackLng: localeCode.enUS,
        load: 'currentOnly',
        supportedLngs: supportedLanguages,
        detection: { order: ['localStorage'] },
        partialBundledLanguages: true,
        returnNull: false
        // debug: true,
    });

export default i18n;