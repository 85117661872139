import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import agent from '../../app/api/agent';
import BsGrid from '../../app/components/BsGrid';
import DatePickerColumnFilter from '../../app/components/BsGrid/datePickerColumnFilter';
import SelectColumnFilter from '../../app/components/BsGrid/selectColumnFilter';
import TextColumnFilter from '../../app/components/BsGrid/textColumnFilter';
import { BsModalConfirm } from '../../app/components/BsModal';
import { ModalRecordMode } from '../../app/enums/modalRecordMode';
import { BsGridFetchDataParams } from '../../app/models/bsGridFetchDataParams';
import { TblScheduledTask } from '../../app/models/dbTables/tblScheduledTask';
import { localDate } from '../../app/utility/util';
import { getTitle } from '../../i18n';
import ModalScheduledTask from './ModalScheduledTask';

export default function ScheduledTasksPage() {
    const { t } = useTranslation();
    const [data, setData] = useState<TblScheduledTask[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [scheduledTaskId, setScheduledTaskId] = useState(0);
    const [showModalScheduledTask, setShowModalScheduledTask] = useState(false);
    const [modalScheduledTaskMode, setModalScheduledTaskMode] = useState<ModalRecordMode>(ModalRecordMode.View);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const fetchIdRef = useRef(0);

    let hiddenColIds: string[] = ['id'];

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<TblScheduledTask>) => {
        //Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        //Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current && changeCount >= 0)
        {
            setLoading(true);

            let gridParams = {
                pageSize, pageIndex, sortBy, filters
            };

            agent.Admin.getScheduledTasks(gridParams)
                .then(taskData => {
                    setTotalRecords(taskData.total);
                    setData(taskData.scheduledTasks);
                    setPageCount(Math.ceil(taskData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [changeCount]);

    const showScheduledTaskModal = (mode: ModalRecordMode, scheduledTaskId: number) => {
        setModalScheduledTaskMode(mode);
        setScheduledTaskId(scheduledTaskId);
        setShowModalScheduledTask(true);
    }

    const showConfirmDelete = (scheduledTaskId: number) => {
        setScheduledTaskId(scheduledTaskId);
        setShowModalConfirmDelete(true);
    }

    const columns = useMemo<Column<TblScheduledTask>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: t('IDS_TASK_NAME'),
                accessor: 'name',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<TblScheduledTask>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => showScheduledTaskModal(ModalRecordMode.Edit, row.original.id)}
                        >
                            {row.original.name}
                        </Button>
                    )
                }
            },
            {
                Header: t('IDS_TASK_OWNER'),
                accessor: 'owner',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_ACTIVE'),
                accessor: 'active',
                Filter: (filterProps) => SelectColumnFilter(filterProps, [{ text: t('IDS_YES'), value: 'true' }, { text: t('IDS_NO'), value: 'false' }], 'true', t('IDS_ACTIVE')),
                Cell: ({ row }: CellProps<TblScheduledTask>) => {
                    return <>{row.original.active ? t('IDS_YES') : t('IDS_NO')}</>
                }
            },
            {
                Header: t('IDS_CRON_INTERVAL'),
                accessor: 'cronInterval',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_TASK_LAST_ERROR'),
                accessor: 'lastError',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_TASK_LAST_RUN'),
                id: 'lastRun',
                accessor: (row) => localDate(row.lastRun),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps),
            },
            {
                Header: t('IDS_TASK_NEXT_RUN'),
                id: 'nextRun',
                accessor: (row) => localDate(row.nextRun),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_DELETE')}`,
                id: 'delete',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: CellProps<TblScheduledTask>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 fs-inherit"
                            onClick={() => showConfirmDelete(row.original.id)}
                        >
                            {t('IDS_DELETE')}
                        </Button>
                    );
                }
            }
        ],
        [t]
    );

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_SCHEDULED_TASKS')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_ADMIN')} - ${t('IDS_SCHEDULED_TASKS')}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <BsGrid
                    addRecordFcn={() => showScheduledTaskModal(ModalRecordMode.Add, 0)}
                    columns={columns}
                    data={data}
                    defaultSortBy={{ id: 'name', desc: false }}
                    fetchData={fetchData}
                    hiddenColumnIds={hiddenColIds}
                    i18nKeys={{ itemName: 'IDS_SCHEDULED_TASK', addItem: 'IDS_ADD_SCHEDULED_TASK' }}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                />
                <ModalScheduledTask
                    scheduledTaskId={scheduledTaskId}
                    mode={modalScheduledTaskMode}
                    showModal={showModalScheduledTask}
                    setShowModal={() => setShowModalScheduledTask(false)}
                    changeCount={changeCount}
                    setChangeCount={setChangeCount}
                />
                <BsModalConfirm
                    title={t('IDS_CONFIRM')}
                    message={t('IDS_DELETE_TASK_WARNING')}
                    showModal={showModalConfirmDelete}
                    setShowModal={setShowModalConfirmDelete}
                    confirmCallback={async () => {
                        if (scheduledTaskId) {
                            await agent.Admin.removeScheduledTask({ scheduledTaskId });
                            setShowModalConfirmDelete(false);
                            setChangeCount(prevCount => prevCount + 1);
                        }
                    }}
                />
            </div>
        </>
    );
}