import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import agent from '../../../app/api/agent';
import { BsAlertDismissible } from '../../../app/components/BsAlertDismissible';
import BsGrid from '../../../app/components/BsGrid';
import SelectColumnFilter from '../../../app/components/BsGrid/selectColumnFilter';
import TextColumnFilter from '../../../app/components/BsGrid/textColumnFilter';
import { BsModalConfirm } from '../../../app/components/BsModal';
import { ModalRecordMode } from '../../../app/enums/modalRecordMode';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { TblPositionProfile } from '../../../app/models/dbTables/tblPositionProfile';
import { useAppSelector } from '../../../app/store/storeHooks';
import ModalPositionProfile from './ModalPositionProfile';

export default function TabPositionProfiles() {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const [data, setData] = useState<TblPositionProfile[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showModalProfile, setShowModalProfile] = useState(false);
    const [modalProfileMode, setModalProfileMode] = useState<ModalRecordMode>(ModalRecordMode.View);
    const [positionProfileId, setPositionProfileId] = useState(0);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<TblPositionProfile>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current && changeCount >= 0) {
            setLoading(true);

            let gridParams = {
                pageSize,
                pageIndex,
                sortBy,
                filters
            };

            agent.PositionProfile.getAllPositionProfilesForGrid(gridParams, { clientId })
                .then(positionProfileData => {
                    setTotalRecords(positionProfileData.total);
                    setData(positionProfileData.profiles);
                    setPageCount(Math.ceil(positionProfileData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [clientId, changeCount]);

    const showProfileModal = (mode: ModalRecordMode, profileId: number) => {
        setModalProfileMode(mode);
        setPositionProfileId(profileId);
        setShowModalProfile(true);
    }

    const showConfirmDelete = (profileId: number) => {
        setPositionProfileId(profileId);
        setShowModalConfirmDelete(true);
    }

    const columns = useMemo<Column<TblPositionProfile>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: `${t('IDS_PROFILE_NAME')}`,
                accessor: 'name',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_TYPE')}`,
                accessor: 'clientId',
                disableSortBy: true,
                Filter: (filterProps) => SelectColumnFilter(filterProps, [{text: 'User', value: `${clientId}` }, {text: 'System', value: 'null' }], '', t('IDS_TYPE')),
                Cell: ({ row }: CellProps<TblPositionProfile>) => {
                    return <>{row.original.clientId !== null ? 'User' : 'System'}</>
                }
            },
            {
                Header: `${t('IDS_ACTIONS')}`,
                id: 'actions',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: CellProps<TblPositionProfile>) => {
                    return row.original.clientId !== null ?
                        <>
                            <Button
                                variant="link"
                                className="link-dark-ocean py-0 fs-inherit"
                                onClick={() => showProfileModal(ModalRecordMode.Edit, row.original.id)}
                            >
                                {t('IDS_EDIT')}
                            </Button>
                            <Button
                                variant="link"
                                className="link-dark-ocean py-0 fs-inherit"
                                onClick={() => showConfirmDelete(row.original.id)}
                            >
                                {t('IDS_DELETE')}
                            </Button>
                        </> :
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 fs-inherit"
                            onClick={() => showProfileModal(ModalRecordMode.View, row.original.id)}
                        >
                            {t('IDS_VIEW')}
                        </Button>
                }
            }
        ],
        [t, clientId]
    );

    return (
        <>
            <BsAlertDismissible
                btnText={t('IDS_INSTRUCTIONS')}
                message={t('IDS_POS_PROFILE_INSTRUCT_1')}
                headerText={t('IDS_INSTRUCTIONS')}
                variant="dark-ocean"
            />
            <BsGrid
                addRecordFcn={() => showProfileModal(ModalRecordMode.Add, 0)}
                columns={columns}
                data={data}
                defaultSortBy={{ id: 'name', desc: false }}
                fetchData={fetchData}
                hiddenColumnIds={['id']}
                i18nKeys={{ itemName: 'IDS_PROFILE', addItem: 'IDS_ADD_PROFILE' }}
                loading={loading}
                pageCount={pageCount}
                totalRecords={totalRecords}
            />
            <ModalPositionProfile
                positionProfileId={positionProfileId}
                mode={modalProfileMode}
                showModal={showModalProfile}
                setShowModal={() => setShowModalProfile(false)}
                setModalProfileMode={setModalProfileMode}
                setPositionProfileId={setPositionProfileId}
                changeCount={changeCount}
                setChangeCount={setChangeCount}
            />
            <BsModalConfirm
                title={t('IDS_CONFIRM')}
                message={t('IDS_PROFILE_DEL_WARN')}
                showModal={showModalConfirmDelete}
                setShowModal={setShowModalConfirmDelete}
                confirmCallback={async () => {
                    if (positionProfileId && clientId) {
                        await agent.PositionProfile.deletePositionProfile({ profileId: positionProfileId, clientId });
                        setShowModalConfirmDelete(false);
                        setChangeCount(prevCount => prevCount + 1);
                    }
                }}
            />
        </>
    );
}