import { createSlice } from '@reduxjs/toolkit';

import { getInitialLangOrDefault, localeCode } from '../../i18n';

export interface LocaleState {
    locale: localeCode;
}

const initialState: LocaleState = {
    locale: getInitialLangOrDefault()
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale: (state, action: { payload: localeCode, type: string }) => {
            state.locale = action.payload;
        }
    }
});

export const { setLocale } = localeSlice.actions;
export default localeSlice.reducer;