import { AxiosResponse } from 'axios';

import { BsGridFetchDataParams } from '../models/bsGridFetchDataParams';
import { CsvDocument } from '../models/csvDocument';
import { TblApplicantAchieverResult } from '../models/dbTables/tblApplicantAchieverResult';
import { TblApplicantDpatResult } from '../models/dbTables/tblApplicantDpatResult';
import { TblApplicantEdu } from '../models/dbTables/tblApplicantEdu';
import { TblApplicantExp } from '../models/dbTables/tblApplicantExp';
import { TblApplicantInfo } from '../models/dbTables/tblApplicantInfo';
import { TblApplicantQuestion } from '../models/dbTables/tblApplicantQuestion';
import { TblApplicantReferrer } from '../models/dbTables/tblApplicantReferrer';
import { TblClientDepartment } from '../models/dbTables/tblClientDepartment';
import { TblClientLanguage } from '../models/dbTables/tblClientLanguage';
import { TblClientNote } from '../models/dbTables/tblClientNote';
import { TblPositionProfile } from '../models/dbTables/tblPositionProfile';
import { TblScheduledTask } from '../models/dbTables/tblScheduledTask';
import { VwClient } from '../models/dbViews/vwClient';
import { VwClientApplicantSearch } from '../models/dbViews/vwClientApplicantSearch';
import { VwSecureApplicantSearch } from '../models/dbViews/vwSecureApplicantSearch';
import {
    FieldValuesAppEEOData, FieldValuesChangePassword, FieldValuesLogin, FieldValuesRecoverPassword,
    FieldValuesRecoverUsername, FieldValuesSetPassword, FieldValuesTwoFactorAuth
} from '../models/fieldValueTypes';
import { PdfDocument } from '../models/pdfDocument';
import { AchieverResultsRequest } from '../models/requestHelpers/achieverResultsRequest';
import { ApplicantEduRequest } from '../models/requestHelpers/applicantEduRequest';
import { ApplicantExpRequest } from '../models/requestHelpers/applicantExpRequest';
import { ApplicantInfoRequest } from '../models/requestHelpers/applicantInfoRequest';
import { ApplicantProgressRequest } from '../models/requestHelpers/applicantProgressRequest';
import { ApplicantQuestionRequest } from '../models/requestHelpers/applicantQuestionRequest';
import { ApplicantRefRequest } from '../models/requestHelpers/applicantRefRequest';
import { ApplicantRequest } from '../models/requestHelpers/applicantRequest';
import {
    ClientApplicantDefaultsRequest
} from '../models/requestHelpers/clientApplicantDefaultRequest';
import { ClientPositionRequest } from '../models/requestHelpers/clientPositionRequest';
import {
    ClientSetupDefaultQuestionsRequest
} from '../models/requestHelpers/clientSetupDefaultQuestionsRequest';
import { DPATResultsRequest } from '../models/requestHelpers/dpatResultsRequest';
import { PositionProfileRequest } from '../models/requestHelpers/positionProfileRequest';
import { ScheduledTaskRequest } from '../models/requestHelpers/scheduledTaskRequest';
import { UpdateClientRequest } from '../models/requestHelpers/updateClientRequest';
import { ApplicantAllProgressDto } from '../models/responseHelpers/applicantAllProgressDto';
import { ApplicantDto } from '../models/responseHelpers/applicantDto';
import { ApplicantLockDto } from '../models/responseHelpers/applicantLockDto';
import { ApplicantProgressDto } from '../models/responseHelpers/applicantProgressDto';
import { ApplicantWithProgressDto } from '../models/responseHelpers/applicantWithProgressDto';
import { ClientNoteDto } from '../models/responseHelpers/clientNoteDto';
import { ClientPositionDto } from '../models/responseHelpers/clientPositionDto';
import { ClientPositionQuestionDto } from '../models/responseHelpers/clientPositionQuestionDto';
import { JobFitDto } from '../models/responseHelpers/jobFitDto';
import { MaintenanceDto } from '../models/responseHelpers/maintenanceDto';
import { SearchApplicantDto } from '../models/responseHelpers/searchApplicantDto';
import { SearchPositionDto } from '../models/responseHelpers/searchPositionDto';
import { SearchPositionProfileDto } from '../models/responseHelpers/searchPositionProfileDto';
import { SearchScheduledTaskDto } from '../models/responseHelpers/searchScheduledTaskDto';
import { SearchSecureApplicantDto } from '../models/responseHelpers/searchSecureApplicantDto';
import { SecureApplicantDto } from '../models/responseHelpers/secureApplicantDto';
import { SettingDto } from '../models/responseHelpers/settingDto';
import { UserDto } from '../models/responseHelpers/userDto';
import { StringKeyObject } from '../models/stringKeyObject';
import axiosInstance from './axiosInstance';

const responseBody = (response: AxiosResponse) => {
    return response.data;
}

const requests = {
    get: (url: string, params?: {}) => axiosInstance.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => axiosInstance.post(url, body).then(responseBody),
    postWithBodyAndParams: (url: string, body: {}, params: {}) => axiosInstance.post(url, body, { params }).then(responseBody),
    put: (url: string, body: {}) => axiosInstance.put(url, body).then(responseBody),
    delete: (url: string, params: {}) => axiosInstance.delete(url, { params }).then(responseBody)
};

const TestErrors = {
    get400Error: () => requests.get('buggy/bad-request'),
    get401Error: () => requests.get('buggy/unauthorized'),
    get404Error: () => requests.get('buggy/not-found'),
    get500Error: () => requests.get('buggy/server-error'),
    getValidationError: () => requests.get('buggy/validation-error')
}

const UserAccount = {
    login: (body: FieldValuesLogin): Promise<UserDto> =>
        requests.post('userAccount/login', body),
    currentUser: (): Promise<UserDto> =>
        requests.get('userAccount/currentUser'),
    changePassword: (params: FieldValuesChangePassword): Promise<boolean> =>
        requests.get('userAccount/changePassword', params),
    setApplicantPassword: (params: FieldValuesSetPassword): Promise<boolean> =>
        requests.get('userAccount/setApplicantPassword', params),
    upsertUserTwoFactorAuth: (params: FieldValuesTwoFactorAuth): Promise<string> =>
        requests.get('userAccount/upsertUserTwoFactorAuth', params),
    verifyTwoFactorAuthToken: (params: FieldValuesTwoFactorAuth): Promise<void> =>
        requests.get('userAccount/verifyTwoFactorAuthToken', params),
    recoverApplicantUsername: (params: FieldValuesRecoverUsername): Promise<void> =>
        requests.get('userAccount/recoverApplicantUsername', params),
    recoverApplicantPassword: (params: FieldValuesRecoverPassword): Promise<void> =>
        requests.get('userAccount/recoverApplicantPassword', params),
    checkPasswordResetToken: (params: { token: string }): Promise<string> =>
        requests.get('userAccount/checkPasswordResetToken', params),
    getQuestionsAndNotesForLang: (params: { clientId: number, positionId: number }): Promise<{ item1: ClientNoteDto, item2: ClientPositionDto }> =>
        requests.get('userAccount/getQuestionsAndNotesForLang', params)
};

const Admin = {
    getSecureApplicants: (body: BsGridFetchDataParams<VwSecureApplicantSearch>): Promise<SearchSecureApplicantDto> =>
        requests.post('admin/getSecureApplicants', body),
    getSecureApplicantById: (params: { applicantId: number }): Promise<SecureApplicantDto> =>
        requests.get('admin/getSecureApplicantById', params),
    getScheduledTasks: (body: BsGridFetchDataParams<TblScheduledTask>): Promise<SearchScheduledTaskDto> =>
        requests.post('admin/getScheduledTasks', body),
    removeScheduledTask: (params: { scheduledTaskId: number }): Promise<void> =>
        requests.delete('admin/removeScheduledTask', params),
    getScheduledTaskById: (params: { scheduledTaskId: number }): Promise<TblScheduledTask> =>
        requests.get('admin/getScheduledTaskById', params),
    addScheduledTask: (body: ScheduledTaskRequest): Promise<string> =>
        requests.post('admin/addScheduledTask', body),
    updateScheduledTask: (body: ScheduledTaskRequest): Promise<string> =>
        requests.post('admin/updateScheduledTask', body),
    getBillingByMonth: (params: { month: number, year: number }): Promise<CsvDocument> =>
        requests.get('admin/getReportByMonth', params),
    getBillingByDateRange: (params: { startDate: string | null, endDate: string | null }): Promise<CsvDocument> =>
        requests.get('admin/getReportByDateRange', params),
    getMaintenanceInfo: (): Promise<MaintenanceDto> => 
        requests.get('admin/getMaintenanceInfo'),
    updateMaintenanceInfo: (body: MaintenanceDto): Promise<string> => 
        requests.post('admin/updateMaintenanceInfo', body)
};

const Client = {
    getById: (params: { clientId: number }): Promise<VwClient> =>
        requests.get('client/getById', params),
    getByClientNumber: (params: { clientNumber: string }): Promise<VwClient> =>
        requests.get('client/getByClientNumber', params),
    updateClient: (body: UpdateClientRequest): Promise<void> =>
        requests.post('client/updateClient', body),
    getClientNotes: (params: { clientId: number }): Promise<TblClientNote[]> =>
        requests.get('client/getClientNotes', params),
    updateClientAppDefaults: (body: ClientApplicantDefaultsRequest): Promise<void> =>
        requests.post('client/updateClientAppDefaults', body),
    getNotesByLanguage: (params: { clientId: number }): Promise<ClientNoteDto> =>
        requests.get('client/getNotesByLanguage', params)
};

const ClientPosition = {
    getAllClientDefaultQuestions: (params: { clientId: number, languageCode: string }): Promise<ClientPositionQuestionDto[]> =>
        requests.get('clientPosition/getAllClientDefaultQuestions', params),
    updateClientDefaultQuestion: (body: ClientSetupDefaultQuestionsRequest[], params: { clientId: number }): Promise<void> =>
        requests.postWithBodyAndParams('clientPosition/updateClientDefaultQuestion', body, params),
    searchPositions: (body: BsGridFetchDataParams<ClientPositionDto>, params: { clientId: number | null }): Promise<SearchPositionDto> =>
        requests.postWithBodyAndParams('clientPosition/searchPositions', body, params),
    getClientPosition: (params: { clientId: number, positionId: number }): Promise<ClientPositionDto> =>
        requests.get('clientPosition/getClientPosition', params),
    addClientPosition: (body: ClientPositionRequest): Promise<string> =>
        requests.post('clientPosition/addClientPosition', body),
    updateClientPosition: (body: ClientPositionRequest): Promise<string> =>
        requests.post('clientPosition/updateClientPosition', body),
    removeClientPosition: (params: { clientId: number, positionId: number }): Promise<void> =>
        requests.delete('clientPosition/removeClientPosition', params),
    getAllClientDepartments: (): Promise<TblClientDepartment[]> =>
        requests.get('clientPosition/getAllClientDepartments'),
    getClientPositionDefaultQuestions: (params: { positionId: number, languageCode: string }): Promise<number[]> =>
        requests.get('clientPosition/getClientPositionDefaultQuestions', params),
    getClientPositionQuestionsByLanguage: (params: { positionId: number, clientId: number, languageCode: string }): Promise<ClientPositionQuestionDto[]> =>
        requests.get('clientPosition/getClientPositionQuestionsByLanguage', params),
    getClientPositionForJobFit: (params: { clientId: number, positionTitle?: string }): Promise<ClientPositionDto[]> =>
        requests.get('clientPosition/getClientPositionForJobFit', params),
    getTopFivePositions: (params: { clientId: number, numResults: number, applicantId: number }): Promise<JobFitDto[]> =>
        requests.get('clientPosition/getTopFivePositions', params)
};

const ClientLanguage = {
    getClientLanguages: (params: { clientId: number }): Promise<TblClientLanguage[]> =>
        requests.get('clientLanguage/getClientLanguages', params),
    addClientLanguage: (params: { clientId: number, languageCode: string }): Promise<number> =>
        requests.postWithBodyAndParams('clientLanguage/addClientLanguage', {}, params),
    removeClientLanguage: (params: { clientId: number, languageCode: string }): Promise<void> =>
        requests.delete('clientLanguage/removeClientLanguage', params)
}

const ClientApplicant = {
    getApplicants: (body: BsGridFetchDataParams<VwClientApplicantSearch>, params: { clientId: number | null }): Promise<SearchApplicantDto> =>
        requests.postWithBodyAndParams('clientApplicant/getApplicants', body, params),
    addApplicant: (body: ApplicantRequest): Promise<{ item1: string, item2: string, item3: string }> =>
        requests.post('clientApplicant/addApplicant', body),
    removeApplicant: (params: { applicantId: number }): Promise<void> =>
        requests.postWithBodyAndParams('clientApplicant/removeApplicant', {}, params),
    resetApplicant: (params: { applicantId: number }): Promise<string> => 
        requests.postWithBodyAndParams('clientApplicant/resetApplicant', {}, params),
    resetApplicantAllTests: (params: { applicantId: number }): Promise<string> => 
        requests.postWithBodyAndParams('clientApplicant/resetTest', {}, params),
    resetApplicantLastTest: (params: { applicantId: number }): Promise<string> => 
        requests.postWithBodyAndParams('clientApplicant/resetLastTest', {}, params),
    updateApplicant: (body: ApplicantRequest): Promise<void> =>
        requests.post('clientApplicant/updateApplicant', body),
    resetPassword: (params: { username: string }): Promise<string> =>
        requests.get('clientApplicant/resetPassword', params),
    resetPasswordAndEmailInfo: (params: { applicantId: number, email: string, username: string, firstName: string, lastName: string }): Promise<string> =>
        requests.get('clientApplicant/resetPasswordAndEmailInfo', params),
    unlockApplicant: (params: { applicantId: number }): Promise<number> =>
        requests.get('clientApplicant/unlockApplicant', params),
    checkLockForApplicant: (params: { applicantId: number, insertEvent: boolean }): Promise<ApplicantLockDto> =>
        requests.get('clientApplicant/checkLockForApplicant', params),
    resetMFAPhone: (params: { username: string }): Promise<void> =>
        requests.get('clientApplicant/resetMFAPhone', params)
};

const Applicant = {
    byId: (params: { id: number }): Promise<ApplicantDto> =>
        requests.get('applicant/byId', params),
    byEmail: (params: { email: string }): Promise<ApplicantDto> =>
        requests.get('applicant/byEmail', params),
    byUsername: (params: { username: string, ignoreDeleted: boolean }): Promise<ApplicantDto> =>
        requests.get('applicant/byUsername', params),
    progress: (params: { id: number }): Promise<ApplicantProgressDto> =>
        requests.get('applicant/progress', params),
    withProgress: (params: { id: number }): Promise<ApplicantWithProgressDto> =>
        requests.get('applicant/withProgress', params),
    allProgress: (params: { id: number }): Promise<ApplicantAllProgressDto[]> =>
        requests.get('applicant/allProgress', params),
    insertApplicantEvent: (params: { applicantId: number, message: string, eventType: string }): Promise<void> =>
        requests.postWithBodyAndParams('applicant/insertApplicantEvent', {}, params),
    canApplicantBeDeleted: (params: { applicantId: number }): Promise<boolean> =>
        requests.get('applicant/canApplicantBeDeleted', params)
}

const ApplicantInfo = {
    getApplicantInfo: (params: { applicantId: number }): Promise<TblApplicantInfo> =>
        requests.get('applicantInfo/getApplicantInfo', params),
    addApplicantInfo: (body: ApplicantInfoRequest): Promise<void> =>
        requests.post('applicantInfo/addApplicantInfo', body)
}

const ApplicantExp = {
    getApplicantExperience: (params: { applicantId: number }): Promise<TblApplicantExp[]> =>
        requests.get('applicantExp/getApplicantExperience', params),
    addApplicantExperience: (body: ApplicantExpRequest): Promise<void> =>
        requests.post('applicantExp/addApplicantExperience', body)
}

const ApplicantEdu = {
    getApplicantEducation: (params: { applicantId: number }): Promise<TblApplicantEdu[]> =>
        requests.get('applicantEdu/getApplicantEducation', params),
    addApplicantEdu: (body: ApplicantEduRequest): Promise<void> =>
        requests.post('applicantEdu/addApplicantEdu', body)
}

const ApplicantEEOData = {
    addApplicantEEOData: (params: FieldValuesAppEEOData): Promise<void> =>
        requests.postWithBodyAndParams('applicantEEOData/addApplicantEEOData', {}, params)
}

const ApplicantQuestion = {
    getApplicantQuestions: (params: { applicantId: number, positionId?: number }): Promise<TblApplicantQuestion[]> =>
        requests.get('applicantQuestion/getApplicantQuestions', params),
    addApplicantQuestions: (body: ApplicantQuestionRequest): Promise<void> =>
        requests.post('applicantQuestion/addApplicantQuestions', body)
}

const ApplicantRef = {
    getApplicantReferences: (params: { applicantId: number }): Promise<TblApplicantQuestion[]> =>
        requests.get('applicantRef/getApplicantReferences', params),
    addApplicantReference: (body: ApplicantRefRequest): Promise<void> =>
        requests.post('applicantRef/addApplicantReference', body)
}

const ApplicantTest = {
    updateApplicantAchieverSent: (params: { applicantId: number, achieverSent: boolean }): Promise<void> =>
        requests.postWithBodyAndParams('applicantTest/updateApplicantAchieverSent', {}, params),
    updateApplicantDpatSent: (params: { applicantId: number, achieverSent: boolean }): Promise<void> =>
        requests.postWithBodyAndParams('applicantTest/updateApplicantDpatSent', {}, params),
    updateApplicantDpatInhouseSent: (params: { applicantId: number, achieverSent: boolean }): Promise<void> =>
        requests.postWithBodyAndParams('applicantTest/updateApplicantDpatInhouseSent', {}, params),
    updateApplicantProgress: (body: ApplicantProgressRequest): Promise<void> =>
        requests.post('applicantTest/updateApplicantProgress', body),
    updateApplicantProgressCompleteSection: (body: ApplicantProgressRequest): Promise<void> =>
        requests.post('applicantTest/updateApplicantProgressCompleteSection', body),
    updateApplicantResponse: (params: { applicantId: number, response: string, dpatResponse: string, dpatInhouseResponse: string }): Promise<void> =>
        requests.postWithBodyAndParams('applicantTest/updateApplicantResponse', {}, params),
    updateAndGetApplicantProgress: (body: ApplicantProgressRequest): Promise<{ item1: ApplicantProgressDto, item2: string }> =>
        requests.post('applicantTest/updateAndGetApplicantProgress', body),
    completeApplicationProgress: (params: { applicantId: number, emailTo: string, applicationOnly: boolean }): Promise<boolean> =>
        requests.get('applicantTest/completeApplicationProgress', params)
}

const PositionProfile = {
    getAllPositionProfiles: (params: { clientId: number }): Promise<TblPositionProfile[]> =>
        requests.get('positionProfile/getAllPositionProfiles', params),
    getAllPositionProfilesForGrid: (body: BsGridFetchDataParams<TblPositionProfile>, params: { clientId: number | null }): Promise<SearchPositionProfileDto> =>
        requests.postWithBodyAndParams('positionProfile/getAllPositionProfilesForGrid', body, params),
    addPositionProfile: (body: PositionProfileRequest): Promise<number> =>
        requests.post('positionProfile/addPositionProfile', body),
    updatePositionProfile: (body: PositionProfileRequest): Promise<void> =>
        requests.post('positionProfile/updatePositionProfile', body),
    deletePositionProfile: (params: { profileId: number, clientId: number }): Promise<void> =>
        requests.delete('positionProfile/deletePositionProfile', params)
}

const AchieverResults = {
    addResults: (body: AchieverResultsRequest): Promise<void> =>
        requests.post('achieverResults/addResults', body),
    getResults: (params: { applicantId: number }): Promise<TblApplicantAchieverResult[]> =>
        requests.get('achieverResults/getResults', params),
}

const DPATResults = {
    addResults: (body: DPATResultsRequest): Promise<void> =>
        requests.post('dpatResults/addResults', body),
    getResults: (params: { applicantId: number, inhouse: boolean }): Promise<TblApplicantDpatResult[]> =>
        requests.get('dpatResults/getResults', params),
}

const Rec = {
    getApplicantTestsAvailableForRec: (params: { applicantId: number }): Promise<StringKeyObject<boolean>> =>
        requests.get('rec/getApplicantTestsAvailableForRec', params),
    pushDPATResultsToREC: (params: { applicantId: number, inhouse: boolean }): Promise<void> =>
        requests.get('rec/pushDPATResultsToREC', params),
    pushAchieverResultsToREC: (params: { applicantId: number }): Promise<void> =>
        requests.get('rec/pushAchieverResultsToREC', params)
}

const FactoryTest = {
    createApplicationPdf: (params: { applicantId: number }): Promise<PdfDocument> =>
        requests.get('factoryTest/createApplicationPdf', params),
    createDpatInhousePdf: (params: { applicantId: number }): Promise<PdfDocument> =>
        requests.get('factoryTest/createDpatInhousePdf', params),
    createDpatPdf: (params: { applicantId: number }): Promise<PdfDocument> =>
        requests.get('factoryTest/createDpatPdf', params),
    createAchieverPdf: (params: { applicantId: number }): Promise<PdfDocument> =>
        requests.get('factoryTest/createAchieverPdf', params),
    createAchieverPdfWithPosition: (params: { applicantId: number, positionId: number }): Promise<PdfDocument> =>
        requests.get('factoryTest/createAchieverPdfWithPosition', params)
}

const Referrers = {
    getReferrers: (params: { languageCode: string }): Promise<TblApplicantReferrer[]> =>
        requests.get('referrers/getReferrers', params),
    getApplicantReferrer: (params: { applicantId: number }): Promise<TblApplicantReferrer> =>
        requests.get('referrers/getApplicantReferrer', params)
}

const TestResources = {
    getAchieverResources: (): Promise<Record<string, string>> =>
        requests.get('testResources/getAchieverResources'),
    getDpatResources: (params: { inhouse: boolean }): Promise<{ xml: Record<string, string>, img: Record<string, string> }> =>
        requests.get('testResources/getDpatResources', params)
}

const Maintenance = {
    getMaintenanceMessage: (): Promise<string> =>
        requests.get('maintenance/getMaintenanceMessage'),
    getMaintenanceTimes: (): Promise<MaintenanceDto> => 
        requests.get('maintenance/getMaintenanceTimes'),
    getMaintenanceInfo: (): Promise<MaintenanceDto> => 
        requests.get('maintenance/getMaintenanceInfo')
}

const AppSettings = {
    getAppSettings: (): Promise<SettingDto[]> => 
        requests.get('appSettings/getAppSettings')
}

const agent = {
    AchieverResults,
    Admin,
    Applicant,
    ApplicantEdu,
    ApplicantEEOData,
    ApplicantExp,
    ApplicantInfo,
    ApplicantQuestion,
    ApplicantRef,
    ApplicantTest,
    AppSettings,
    Client,
    ClientApplicant,
    ClientLanguage,
    ClientPosition,
    DPATResults,
    FactoryTest,
    Maintenance,
    PositionProfile,
    Rec,
    Referrers,
    TestErrors,
    TestResources,
    UserAccount
};

export default agent;