import { QuestionType } from '../../../enums/testQuestionType';
import { DPATSectionId } from '../../../enums/testSections';
import { DPATResultsRequest } from '../../requestHelpers/dpatResultsRequest';
import { DPATSection } from './dpatSection';
import { DPATTest } from './dpatTest';

interface stDIMENSIONDATA {
    strName: string;
    dblRawScore: number;
    correct: number;
    incorrect: number;
}

export function GetDPATResultsFromXML(cTest: DPATTest, inhouse: boolean, applicantID: number): DPATResultsRequest {
    let xmlDoc = ScoreTest(cTest);
    let cNodes = xmlDoc.getElementsByTagName('Test');
    let result: DPATResultsRequest = {
        applicantID,
        inhouse,
        dPAT1Correct: 0,
        dPAT1Incorrect: 0,
        dPAT2Correct: 0,
        dPAT2Incorrect: 0,
        dPAT3Correct: 0,
        dPAT3Incorrect: 0,
        dPATTotal: 0,
        grade: ''
    };
    for (let i = 0; i < cNodes.length; i++) {
        let cCurrentNode = cNodes[i].firstChild as HTMLElement;
        let name = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let strRawScore = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let strCorrect = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let strIncorrect = cCurrentNode.innerText;
        cCurrentNode = cCurrentNode.nextSibling as HTMLElement;
        let strGrade = cCurrentNode.innerText;

        let rawScore = strRawScore.length > 0 ? Number(strRawScore) : 0;
        let correct = strCorrect.length > 0 ? Number(strCorrect) : 0;
        let incorrect = strIncorrect.length > 0 ? Number(strIncorrect) : 0;

        if (name === 'Alpha Sequence') {
            result.dPAT1Correct = correct;
            result.dPAT1Incorrect = incorrect;
        } else if (name === 'Graphic Sequence') {
            result.dPAT2Correct = correct;
            result.dPAT2Incorrect = incorrect;
        } else if (name === 'Math Word Problems') {
            result.dPAT3Correct = correct;
            result.dPAT3Incorrect = incorrect;
        } else if (name === 'Overall Score') {
            result.grade = strGrade;
            result.dPATTotal = rawScore;
        }
    }

    return result;
}

function ScoreTest(cTest: DPATTest): XMLDocument {
    let m_stDim: stDIMENSIONDATA[] = [
        { strName: 'Alpha Sequence', dblRawScore: 0, correct: 0, incorrect: 0 },
        { strName: 'Graphic Sequence', dblRawScore: 0, correct: 0, incorrect: 0 },
        { strName: 'Math Word Problems', dblRawScore: 0, correct: 0, incorrect: 0 }
    ];

    for (let cSection of cTest.sections) {
        ComputeRawScores(cSection, m_stDim);
    }

    return BuildResults(m_stDim);
}

function ComputeRawScores(cSection: DPATSection, m_stDim: stDIMENSIONDATA[]) {
    let htScores: Record<number, number> = {};
    for (let cQues of cSection.questions) {
        for (let cDimension in cQues.correctAnswers) {
            let nKey: number = Number(cDimension);
            let nCorrectAns: number = cQues.correctAnswers[nKey];
            let nScore: number = 0;
            if (htScores[nKey] != null) {
                nScore = htScores[nKey];
            }

            switch (cQues.questionType) {
                case QuestionType.MultipleChoice:
                    if (cQues.response === nCorrectAns) {
                        nScore++;
                        switch (cSection.id)
                        {
                            case DPATSectionId.AlphaSequence:
                                m_stDim[0].correct++;
                                break;
                            case DPATSectionId.GraphicSequence:
                                m_stDim[1].correct++;
                                break;
                            case DPATSectionId.MathWordProblems:
                                m_stDim[2].correct++;
                                break;
                        }
                    } else if (cQues.response > 0) {
                        nScore = nScore - (.25);
                        switch (cSection.id)
                        {
                            case DPATSectionId.AlphaSequence:
                                m_stDim[0].incorrect++;
                                break;
                            case DPATSectionId.GraphicSequence:
                                m_stDim[1].incorrect++;
                                break;
                            case DPATSectionId.MathWordProblems:
                                m_stDim[2].incorrect++;
                                break;
                        }
                    }
                    htScores[nKey] = nScore;
                    break;
                case QuestionType.YesNo:
                    htScores[nKey] = nScore;
                    break;
                case QuestionType.YesNoMaybe:
                    htScores[nKey] = nScore;
                    break;
            }
        }

    }

    for (let entry in htScores) { 
        m_stDim[Number(entry) - 1].dblRawScore = htScores[entry];
    }
}

function BuildResults(m_stDim: stDIMENSIONDATA[]): XMLDocument {
    let xDoc = document.implementation.createDocument(null, 'DPAT');
    let ddOverall: stDIMENSIONDATA = { strName: 'Overall Score', dblRawScore: 0, correct: 0, incorrect: 0 };
    let dblRawSum = 0;
    for (let stDim of m_stDim) {
        xDoc.documentElement.appendChild(DimentionChild(xDoc, stDim));
        dblRawSum = dblRawSum + stDim.dblRawScore;
    }
    ddOverall.dblRawScore = dblRawSum;
    xDoc.documentElement.appendChild(DimentionChild(xDoc, ddOverall));

    return xDoc;
}

function DimentionChild(cXml: XMLDocument, stDim: stDIMENSIONDATA): HTMLElement {
    let cTest = cXml.createElement('Test');
    let cName = cXml.createElement('Name');
    cName.innerText = stDim.strName;
    cTest.appendChild(cName);

    let nCorrect = 0;
    let nIncorrect = 0;
    let strGrade = '';

    if (stDim.strName === 'Overall Score') {
        if (stDim.dblRawScore < 37) {
            strGrade = 'D';
        } else if (stDim.dblRawScore < 47) {
            strGrade = 'C';
        } else if (stDim.dblRawScore < 57) {
            strGrade = 'B';
        } else {
            strGrade = 'A';
        }
    } else {
        nCorrect = stDim.correct;
        nIncorrect = stDim.incorrect;
    }
    
    let cRawScore = cXml.createElement('RawScore');
    cRawScore.innerText = `${stDim.dblRawScore}`;
    cTest.appendChild(cRawScore);

    let cCorrect = cXml.createElement('Correct');
    cCorrect.innerText = `${nCorrect}`;
    cTest.appendChild(cCorrect);

    let cIncorrect = cXml.createElement('Incorrect');
    cIncorrect.innerText = `${nIncorrect}`;
    cTest.appendChild(cIncorrect);

    let cGrade = cXml.createElement('Grade');
    cGrade.innerText = strGrade;
    cTest.appendChild(cGrade);

    return cTest;
}