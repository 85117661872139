import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { Navigate, Route, Routes } from 'react-router-dom';

import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdminPage from '../../features/admin/AdminPage';
import MaintenancePage from '../../features/admin/MaintenancePage';
import ScheduledTasksPage from '../../features/admin/ScheduledTasksPage';
import SecureApplicantsPage from '../../features/admin/SecureApplicantsPage';
import SecureBillingPage from '../../features/admin/SecureBillingPage';
import ApplicationPage from '../../features/applicant/application/ApplicationPage';
import HomePageApplicant from '../../features/applicant/home/HomePage';
import TestPage from '../../features/applicant/test/TestPage';
import ApplicantsPage from '../../features/client/applicants/ApplicantsPage';
import HomePageClient from '../../features/client/home/HomePage';
import PositionsPage from '../../features/client/positions/PositionsPage';
import SetupPage from '../../features/client/setup/SetupPage';
import AuthPage from '../../features/login/AuthPage';
import ChangePasswordPage from '../../features/login/ChangePasswordPage';
import ForgotPasswordPage from '../../features/login/ForgotPasswordPage';
import ForgotUsernamePage from '../../features/login/ForgotUsernamePage';
import RequireAuth from '../../features/login/RequireAuth';
import SelectClientPage from '../../features/login/SelectClientPage';
import SelectLanguagePage from '../../features/login/SelectLanguagePage';
import SetPasswordWrapper from '../../features/login/SetPasswordWrapper';
import VerifyPage from '../../features/login/Verify';
import setUpInterceptor from '../api/axiosInterceptor';
import { AccessibilityModal } from '../components/AccessibilityModal';
import { BsModalHandledError } from '../components/BsModal';
import { DocumentModal } from '../components/DocumentModal';
import { FilterMode } from '../enums/filterMode';
import { UserType } from '../enums/userType';
import { UserDto } from '../models/responseHelpers/userDto';
import {
    setAccessibilityFilterMode, setAccessibilityTextSize, setShowAccessibilityModal
} from '../store/accessibilitySlice';
import { fetchAppSettings, fetchCurrentUser, fetchMaintenanceInfo } from '../store/accountSlice';
import { setClient } from '../store/clientSlice';
import { store } from '../store/configureStore';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';
import { setTestSession } from '../store/testSlice';
import {
    authPropsAdmin, authPropsApplicant, authPropsClient, RouteAuthCtx
} from '../utility/routeAuthCtx';
import { getAppSettingVal, pathFromHost, pathFromUserType, setFilterModeClasses } from '../utility/util';
import Footer from './Footer';
import Header from './Header';
import LoadingComponent from './LoadingComponent';
import { SettingNames } from '../enums/settingNames';

setUpInterceptor(store);
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { user, appSettings } = useAppSelector(state => state.account);
    const mfaEnabled = getAppSettingVal(appSettings, SettingNames.EnableMFA, 'boolean') as boolean;
    const dispatch = useAppDispatch();

    const initApp = useCallback(async () => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function (e) {
            dispatch(setAccessibilityFilterMode(e.matches ? FilterMode.DarkContrast : FilterMode.None));
        });

        try {
            let dispatchUser = await dispatch(fetchCurrentUser());
            let userPayload = dispatchUser.payload as UserDto;
            if (userPayload?.userType === UserType.Client && sessionStorage.getItem('clientSession')) {
                dispatch(setClient(JSON.parse(sessionStorage.getItem('clientSession')!)));
            } else if (userPayload?.userType === UserType.Applicant && sessionStorage.getItem('testSession')) {
                dispatch(setTestSession(JSON.parse(sessionStorage.getItem('testSession')!)));
            }
            if (sessionStorage.getItem('filterMode')) {
                dispatch(setAccessibilityFilterMode(sessionStorage.getItem('filterMode') as FilterMode));
            }
            if (sessionStorage.getItem('textSize')) {
                dispatch(setAccessibilityTextSize(sessionStorage.getItem('textSize')!));
            }

            await dispatch(fetchMaintenanceInfo());
            await dispatch(fetchAppSettings());
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    useEffect(() => {
        setFilterModeClasses((sessionStorage.getItem('filterMode') ?? '') as FilterMode);
        let textSize = sessionStorage.getItem('textSize');
        if (textSize && textSize !== '100') {
            document.documentElement.style.fontSize = `${textSize}%`;
        }

        initApp().then(() => setLoading(false));
    }, [initApp]);


    if (loading) return <LoadingComponent />

    return (
        <>
            <Header />
            <div className="main d-flex flex-column">
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={!user ? `/Auth${pathFromHost()}` : `/${pathFromUserType(user.userType)}`} />} />
                    <Route
                        path="*"
                        element={<Navigate to={!user ? `/Auth${pathFromHost()}` : `/${pathFromUserType(user.userType)}`} />} />

                    {/* Client Routes */}
                    <Route
                        path="/AuthClient"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><AuthPage /></RouteAuthCtx.Provider>} />
                    {mfaEnabled &&
                        <Route
                            path="/AuthClient/Verify"
                            element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><VerifyPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    }
                    <Route
                        path="/AuthClient/SelectClient"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><SelectClientPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/AuthClient/ChangePassword"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><ChangePasswordPage isSetPassword={false} /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Client"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><HomePageClient /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Client/Setup"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><SetupPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Client/Applicants"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><ApplicantsPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Client/Positions"
                        element={<RouteAuthCtx.Provider value={authPropsClient}><RequireAuth><PositionsPage /></RequireAuth></RouteAuthCtx.Provider>} />

                    {/* Admin Routes */}
                    <Route
                        path="/AuthAdmin"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><AuthPage /></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Admin"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><RequireAuth><AdminPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Admin/ApplicantSearch"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><RequireAuth><SecureApplicantsPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Admin/ScheduledTasks"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><RequireAuth><ScheduledTasksPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Admin/Billing"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><RequireAuth><SecureBillingPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Admin/Maintenance"
                        element={<RouteAuthCtx.Provider value={authPropsAdmin}><RequireAuth><MaintenancePage /></RequireAuth></RouteAuthCtx.Provider>} />

                    {/* Applicant Routes */}
                    <Route
                        path="/AuthApplicant"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><AuthPage /></RouteAuthCtx.Provider>} />
                    <Route
                        path="/AuthApplicant/ForgotPassword"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><ForgotPasswordPage /></RouteAuthCtx.Provider>} />
                    <Route
                        path="/AuthApplicant/ForgotUsername"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><ForgotUsernamePage /></RouteAuthCtx.Provider>} />
                    {mfaEnabled &&
                        <Route
                            path="/AuthApplicant/Verify"
                            element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><VerifyPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    }
                    <Route
                        path="/AuthApplicant/SetPassword"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><SetPasswordWrapper /></RouteAuthCtx.Provider>} />
                    <Route
                        path="/AuthApplicant/SelectLanguage"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><SelectLanguagePage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/AuthApplicant/ChangePassword"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><ChangePasswordPage isSetPassword={false} /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Applicant"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><HomePageApplicant /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Applicant/Application"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><ApplicationPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Applicant/Achiever"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><TestPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Applicant/DPAT"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><TestPage /></RequireAuth></RouteAuthCtx.Provider>} />
                    <Route
                        path="/Applicant/DPATInhouse"
                        element={<RouteAuthCtx.Provider value={authPropsApplicant}><RequireAuth><TestPage /></RequireAuth></RouteAuthCtx.Provider>} />
                </Routes>
            </div>
            <Footer />
            <BsModalHandledError />
            <DocumentModal />

            <Button
                variant="link"
                onClick={() => dispatch(setShowAccessibilityModal(true))}
                className="btn-accessibility p-0 lh-1 border border-0"
                title={t('IDS_ACCESSIBILITY_OPTIONS')}
            >
                <FontAwesomeIcon
                    icon={faUniversalAccess}
                    fixedWidth
                    size="2x"
                />
            </Button>
            <AccessibilityModal />
        </>
    );
}

export default App;