import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    questionNumber: number;
    totalQuestions: number;
}

export default function QuestionBadge({ questionNumber, totalQuestions, className = '' }: Props) {
    const { t } = useTranslation();

    return (
        <span className={`me-3 align-text-bottom ${className}`}>
            <Badge style={{ minWidth: '4rem' }} bg="eggplant">
                {`${questionNumber} ${t('IDS_OF')} ${totalQuestions}`}
            </Badge>
        </span>
    );
}
