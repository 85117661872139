import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import agent from '../../app/api/agent';
import BsGrid from '../../app/components/BsGrid';
import DatePickerColumnFilter from '../../app/components/BsGrid/datePickerColumnFilter';
import TextColumnFilter from '../../app/components/BsGrid/textColumnFilter';
import { BsGridFetchDataParams } from '../../app/models/bsGridFetchDataParams';
import { VwSecureApplicantSearch } from '../../app/models/dbViews/vwSecureApplicantSearch';

import { localDate } from '../../app/utility/util';
import { getTitle } from '../../i18n';
import ModalSecureApplicant from './ModalSecureApplicant';

export default function SecureApplicantsPage() {
    const { t } = useTranslation();
    const [data, setData] = useState<VwSecureApplicantSearch[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [applicantId, setApplicantId] = useState(0);
    const [showModalSecureApplicant, setShowModalSecureApplicant] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const fetchIdRef = useRef(0);

    let hiddenColIds: string[] = ['id'];

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<VwSecureApplicantSearch>) => {
        //Give this fetch an id
        const fetchId = ++fetchIdRef.current;

        //Only upate the data if this is the latest fetch
        if (fetchId === fetchIdRef.current && changeCount >= 0)
        {
            setLoading(true);

            let gridParams = {
                pageSize, pageIndex, sortBy, filters
            };

            agent.Admin.getSecureApplicants(gridParams)
                .then(appData => {
                    setTotalRecords(appData.total);
                    setData(appData.secureApplicantList)
                    setPageCount(Math.ceil(appData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [changeCount]);

    const showSecureApplicantModal = (applicantId: number) => {
        setApplicantId(applicantId);
        setShowModalSecureApplicant(true);
    }

    const columns = useMemo<Column<VwSecureApplicantSearch>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: t('IDS_USERNAME'),
                accessor: 'username',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwSecureApplicantSearch>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => showSecureApplicantModal(row.original.id)}
                        >
                            {row.original.username}
                        </Button>
                    )
                }
            },
            {
                Header: t('IDS_FIRST_NAME'),
                accessor: 'firstName',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_LAST_NAME'),
                accessor: 'lastName',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_CLIENT_NUMBER'),
                accessor: 'clientNumber',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_CLIENT_NAME'),
                accessor: 'clientName',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_APP_COMPLETED'),
                id: 'applicationCompletedDate',
                accessor: (row) => localDate(row.applicationCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            },
            {
                Header: t('IDS_ASSESS_COMPLETED'),
                id: 'achieverCompletedDate',
                accessor: (row) => localDate(row.achieverCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            },
            {
                Header: t('IDS_APT_COMPLETED'),
                id: 'dpatCompletedDate',
                accessor: (row) => localDate(row.dpatCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            },
            {
                Header: t('IDS_INH_APT_COMPLETED'),
                id: 'dpatInhouseCompletedDate',
                accessor: (row) => localDate(row.dpatInhouseCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            }
        ],
        [t]
    );

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_SEARCH_APPLICANTS')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_ADMIN')} - ${t('IDS_SEARCH_APPLICANTS')}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <BsGrid
                    columns={columns}
                    data={data}
                    defaultSortBy={{ id: 'username', desc: false }}
                    fetchData={fetchData}
                    hiddenColumnIds={hiddenColIds}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                />
                <ModalSecureApplicant
                    showModal={showModalSecureApplicant}
                    setShowModal={() => setShowModalSecureApplicant(false)}
                    applicantId={applicantId}
                    changeCount={changeCount}
                    setChangeCount={setChangeCount}
                />
            </div>
        </>
    );
}