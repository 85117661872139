import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Table, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HelpTooltipIcon from '../../../../app/components/HelpTooltipIcon';

import ApplicantItemsCard from './ApplicantItemsCard';
import colorVars from '../../../../app/layout/styles/_colors.module.scss';
import { ApplicantItem } from '../../../../app/models/applicantItem';
import { useAppSelector } from '../../../../app/store/storeHooks';

export interface Props {
    showAssignedAndViewCols: boolean;
}

export default function ApplicantItemsContainer({ showAssignedAndViewCols }: Props) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const isRecType = clientSession?.vwClient.isRecType ?? false;
    const { control, getValues, register } = useFormContext();
    const { fields, move } = useFieldArray({ control, name: 'applicantItems' });
    const watchApplicantItems = useWatch({
        control,
        name: 'applicantItems',
    }) as ApplicantItem[];

    const handleDrag: OnDragEndResponder = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <div className="border border-dark-eggplant border-1 bg-white small rounded table-responsive mt-3">
            <Table striped borderless hover variant="dark-eggplant" className="mb-0 table-cell-nowrap">
                <thead>
                    <tr className="d-flex">
                        <th className="text-center" style={{ width: '4rem' }}>{t('IDS_ORDER')}</th>
                        <th style={{ width: showAssignedAndViewCols ? '11.5rem' : '100%' }}>{t('IDS_AVAILABLE_ITEMS')}</th>
                        <th className="text-center" style={{ width: '6rem' }}>{t('IDS_TURNED_ON')}</th>
                        {showAssignedAndViewCols &&
                            <>
                                <th className="flex-fill">{t('IDS_ASSIGNED')}</th>
                                <th className="text-center" style={{ width: '4rem' }}>{t('IDS_VIEW')}</th>
                            </>
                        }
                    </tr>
                </thead>
                <DragDropContext onDragEnd={handleDrag}>
                    <Droppable droppableId="applicantItems">
                        {(provided, snapshot) => (
                            <tbody {...provided.droppableProps} ref={provided.innerRef} className={snapshot.isDraggingOver ? 'hide-indexes' : ''}>
                                {fields.map((item, index) => {
                                    return (
                                            <Draggable
                                                key={`applicantItems[${index}]`}
                                                draggableId={`item-${index}`}
                                                index={index}
                                                isDragDisabled={getValues('orderDisabled') === true}
                                            >
                                                {(provided, snapshot) => (
                                                    <ApplicantItemsCard
                                                        showAssignedAndViewCols={showAssignedAndViewCols}
                                                        provided={provided}
                                                        item={item}
                                                        index={index}
                                                        isDragDisabled={getValues('orderDisabled') === true}
                                                    />
                                                )}
                                            </Draggable>
                                        );
                                })}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>

                {isRecType && watchApplicantItems && watchApplicantItems.some(x => (x.name === 'Achiever' || x.name === 'Aptitudetest' || x.name === 'DpatInhouse') && x.turnedOn) &&
                    <tfoot>
                        <tr style={{ borderTop: `1px solid ${colorVars.eggplant}` }}>
                            <td
                                colSpan={showAssignedAndViewCols ? 5 : 3}
                                className="text-center"
                                style={{ backgroundColor: `${colorVars.lighteggplant}` }}
                            >
                                <div className="d-flex justify-content-center">
                                    <Form.Check
                                        type="switch"
                                        className="d-inline-block mb-0"
                                        id="specialAccommodations"
                                        label={t('IDS_SPECIAL_ACCOMMODATIONS')}
                                        style={{ minHeight: 'unset' }}
                                        {...register('specialAccommodations', {})}
                                    />
                                    <HelpTooltipIcon
                                        fontSizeInitial={true}
                                        tooltipId="tooltipSpecialAccommodations"
                                        tooltipText={t('IDS_SPECIAL_ACCOMMODATIONS_HELP')}
                                        tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                }
            </Table>
        </div>
    );
}
