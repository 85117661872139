import { ApplicantTestProgress } from '../enums/applicantTestProgress';
import { AppProgress } from '../enums/appProgress';
import { dateTimeIsMinVal } from '../utility/util';
import { ApplicantDto } from './responseHelpers/applicantDto';
import { ApplicantEduDto } from './responseHelpers/applicantEduDto';
import { ApplicantEeoDataDto } from './responseHelpers/applicantEeoDataDto';
import { ApplicantExpDto } from './responseHelpers/applicantExpDto';
import { ApplicantInfoDto } from './responseHelpers/applicantInfoDto';
import { ApplicantProgressDto } from './responseHelpers/applicantProgressDto';
import { ApplicantQuesDto } from './responseHelpers/applicantQuesDto';
import { ApplicantRefDto } from './responseHelpers/applicantRefDto';
import { ApplicantReferrerDto } from './responseHelpers/applicantReferrerDto';
import { ApplicantUnlockDto } from './responseHelpers/applicantUnlockDto';
import { ClientNoteDto } from './responseHelpers/clientNoteDto';
import { ClientPositionDto } from './responseHelpers/clientPositionDto';

export interface ApplicantSessionDto {
    applicant: ApplicantDto;
    applicationProgress: AppProgress;
    progress: ApplicantProgressDto;
    applicantInfo: ApplicantInfoDto;
    eeoData: ApplicantEeoDataDto;
    referrer: ApplicantReferrerDto;
    clientPosition: ClientPositionDto;
    experiences: ApplicantExpDto[];
    education: ApplicantEduDto[];
    references: ApplicantRefDto[];
    questions: ApplicantQuesDto[];
    unlockLog: ApplicantUnlockDto[];
    visitedInfoPage: boolean;
    sevenMaybes: boolean;
    testInReview: boolean;
    submitted: boolean;
    dpatLockoutExempt: boolean;
    randomizedQuestions: number[][];
    randomizedAnswers: number[][];
    applicantId: number;
    clientId: number;
    username: string;
    gender: string;
    race: string;
    testSection: number;
    isInApplication: boolean;
    isInAchiever: boolean;
    isInAptitudeTest: boolean;
    isInDPATInhouse: boolean;
    currentProgressId: number;
    client: ApplicantClientSessionDto;
}

export interface ApplicantClientSessionDto {
    id: number;
    clientNumber: string;
    name: string;
    openingNotes: string;
    closingNotes: string;
    lockoutText: string;
    references: number;
    employment: number | null;
    education: number;
    emailRequired: boolean;
    referredByRequired: boolean;
    defaultLanguage: string;
    dpatLockoutExempt: boolean;
    requestEeoInfo: boolean;
    isRecType: boolean;
    recTestLocation: string;
    isReynoldsAssociate: boolean | null;
    deleteOnTransfer: boolean | null;
    languageCodes: string[];
    clientNotesForLanguage: ClientNoteDto;
}

export const getProgressKey = (progressId: ApplicantTestProgress) => {
    if (progressId === ApplicantTestProgress.Application) {
        return 'IDS_APPLICATION';
    } else if (progressId === ApplicantTestProgress.Personality) {
        return 'IDS_PERSONALITY';
    } else if (progressId === ApplicantTestProgress.MentalAlertness) {
        return 'IDS_MENTAL_ALERTNESS';
    } else if (progressId === ApplicantTestProgress.BusinessTerms) {
        return 'IDS_BUSINESS_TERMS';
    } else if (progressId === ApplicantTestProgress.Vocabulary) {
        return 'IDS_VOCABULARY';
    } else if (progressId === ApplicantTestProgress.Perception) {
        return 'IDS_PERCEPTION';
    } else if (progressId === ApplicantTestProgress.MechanicalInterest) {
        return 'IDS_MECHANICAL_INTEREST';
    } else if (progressId === ApplicantTestProgress.AlphaSequence || progressId === ApplicantTestProgress.AlphaSequence2) {
        return 'IDS_ALPHA_SEQ';
    } else if (progressId === ApplicantTestProgress.GraphicSequence || progressId === ApplicantTestProgress.GraphicSequence2) {
        return 'IDS_GRAPHIC_SEQ';
    } else if (progressId === ApplicantTestProgress.MathWordProblems || progressId === ApplicantTestProgress.MathWordProblems2) {
        return 'IDS_MATH_WORD_PROBS';
    } else if (progressId === ApplicantTestProgress.Complete) {
        return 'IDS_STATUS_COMPLETE';
    } else {
        return '';
    }
}

export const currentProgressId = (m_cAppBO: ApplicantDto, m_cAppProgBO: ApplicantProgressDto): number => {
    //  -1 or 0    = Application
    //  1 - 6        = Achiever
    //  7 - 9        = Aptitude Test
    //  11-13        = DPAT Inhouse Test
    let fIsInApplication: boolean = (m_cAppProgBO.progressId <= 0);
    let fIsInAchiever: boolean = ((m_cAppProgBO.progressId >= 1) && (m_cAppProgBO.progressId <= 6));
    let fIsInAptitudeTest: boolean = ((m_cAppProgBO.progressId >= 7) && (m_cAppProgBO.progressId <= 9));
    let fIsInDPATInhouse: boolean = ((m_cAppProgBO.progressId >= 11) && (m_cAppProgBO.progressId <= 13));
    let fIsComplete: boolean = (m_cAppProgBO.progressId === 10);
    let fStillNeedToTakeApplication: boolean = (!m_cAppBO.testOnly && dateTimeIsMinVal(m_cAppProgBO.appCompletedDate));
    let fStillNeedToTakeAchiever: boolean = (m_cAppBO.achiever && dateTimeIsMinVal(m_cAppProgBO.test6CompletedDate));
    let fStillNeedToTakeAptitudeTest: boolean = (m_cAppBO.dpat && dateTimeIsMinVal(m_cAppProgBO.dpat3CompletedDate));
    let fStillNeedToTakeDPATInhouse: boolean = (m_cAppBO.dpatInhouse && dateTimeIsMinVal(m_cAppProgBO.dpatInhouse3CompletedDate));
    let nProgressID: number = m_cAppProgBO.progressId;
    if ((!fStillNeedToTakeApplication
        && (!fStillNeedToTakeAchiever
            && (!fStillNeedToTakeAptitudeTest
                && (!fStillNeedToTakeDPATInhouse
                    && !fIsComplete))))) {
        return 10;
    }

    //  Check to see whether the applicant should be in the Application but is not
    if ((fStillNeedToTakeApplication
        && !fIsInApplication)) {
        let fShouldTakeBeforeAchiever: boolean = (!fStillNeedToTakeAchiever
            || (fStillNeedToTakeAchiever
                && (m_cAppBO.applicationOrder < m_cAppBO.achieverOrder)));
        let fShouldTakeBeforeAptitudeTest: boolean = (!fStillNeedToTakeAptitudeTest
            || (fStillNeedToTakeAptitudeTest
                && (m_cAppBO.applicationOrder < m_cAppBO.aptitudeTestOrder)));
        let fShouldTakeBeforeDPATInhouse: boolean = (!fStillNeedToTakeDPATInhouse
            || (fStillNeedToTakeDPATInhouse
                && (m_cAppBO.applicationOrder < m_cAppBO.dpatInhouseOrder)));
        if ((fShouldTakeBeforeAchiever
            && (fShouldTakeBeforeAptitudeTest && fShouldTakeBeforeDPATInhouse))) {
            return 0;
        }

    }

    //  Check to see whether the applicant should be in the Achiever but is not
    if ((fStillNeedToTakeAchiever
        && !fIsInAchiever)) {
        let fShouldTakeBeforeApplication: boolean = (!fStillNeedToTakeApplication
            || (fStillNeedToTakeApplication
                && (m_cAppBO.achieverOrder < m_cAppBO.applicationOrder)));
        let fShouldTakeBeforeAptitudeTest: boolean = (!fStillNeedToTakeAptitudeTest
            || (fStillNeedToTakeAptitudeTest
                && (m_cAppBO.achieverOrder < m_cAppBO.aptitudeTestOrder)));
        let fShouldTakeBeforeDPATInhouse: boolean = (!fStillNeedToTakeDPATInhouse
            || (fStillNeedToTakeDPATInhouse
                && (m_cAppBO.achieverOrder < m_cAppBO.dpatInhouseOrder)));
        if ((fShouldTakeBeforeApplication
            && (fShouldTakeBeforeAptitudeTest && fShouldTakeBeforeDPATInhouse))) {
            return 1;
        }

    }

    //  Check to see whether the applicant should be in the Aptitude Test but is not
    if ((fStillNeedToTakeAptitudeTest
        && !fIsInAptitudeTest)) {
        let fShouldTakeBeforeApplication: boolean = (!fStillNeedToTakeApplication
            || (fStillNeedToTakeApplication
                && (m_cAppBO.aptitudeTestOrder < m_cAppBO.applicationOrder)));
        let fShouldTakeBeforeAchiever: boolean = (!fStillNeedToTakeAchiever
            || (fStillNeedToTakeAchiever
                && (m_cAppBO.aptitudeTestOrder < m_cAppBO.achieverOrder)));
        let fShouldTakeBeforeDPATInhouse: boolean = (!fStillNeedToTakeDPATInhouse
            || (fStillNeedToTakeDPATInhouse
                && (m_cAppBO.aptitudeTestOrder < m_cAppBO.dpatInhouseOrder)));
        if ((fShouldTakeBeforeApplication
            && (fShouldTakeBeforeAchiever && fShouldTakeBeforeDPATInhouse))) {
            return 7;
        }

    }

    //  Check to see whether the applicant should be in the DPAT Inhouse but is not
    if ((fStillNeedToTakeDPATInhouse
        && !fIsInDPATInhouse)) {
        let fShouldTakeBeforeApplication: boolean = (!fStillNeedToTakeApplication
            || (fStillNeedToTakeApplication
                && (m_cAppBO.dpatInhouseOrder < m_cAppBO.applicationOrder)));
        let fShouldTakeBeforeAchiever: boolean = (!fStillNeedToTakeAchiever
            || (fStillNeedToTakeAchiever
                && (m_cAppBO.dpatInhouseOrder < m_cAppBO.achieverOrder)));
        let fShouldTakeBeforeAptitudeTest: boolean = (!fStillNeedToTakeAptitudeTest
            || (fStillNeedToTakeAptitudeTest
                && (m_cAppBO.dpatInhouseOrder < m_cAppBO.aptitudeTestOrder)));
        if ((fShouldTakeBeforeApplication
            && (fShouldTakeBeforeAchiever && fShouldTakeBeforeAptitudeTest))) {
            return 11;
        }

    }

    return nProgressID;
}