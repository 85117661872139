import { ApplicantRefDto } from "../responseHelpers/applicantRefDto";

export interface ApplicantRefRequest {
    applicantId: number;
    references: ApplicantReference[];
}

export interface ApplicantReference {
    fullName: string;
    company: string;
    title: string;
    phone: string;
    email: string;
    clientIndex: number;
}

function getEmptyRef(clientIndex: number): ApplicantReference {
    return {
        fullName: '',
        company: '',
        title: '',
        phone: '',
        email: '',
        clientIndex,
    };
}

function refDtoToRefReq(ref: ApplicantRefDto, clientIndex: number): ApplicantReference {
    return {
        fullName: ref.fullName,
        company: ref.company,
        title: ref.title,
        phone: ref.phone,
        email: ref.email,
        clientIndex,
    };
}

export function getPaddedRefs(refs: ApplicantRefDto[]): ApplicantReference[] {
    if (!Array.isArray(refs) || refs.length === 0) {
        return [getEmptyRef(0), getEmptyRef(1), getEmptyRef(2), getEmptyRef(3)];
    } else {
        let retArr: ApplicantReference[] = [];
        for (let i = 0; i < 4; i++) {
            retArr.push(refs.length > i ? refDtoToRefReq(refs[i], i) : getEmptyRef(i));
        }
        return retArr;
    }
}