import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TestSession } from '../../../app/models/test/testSession';
import { applicantSessionStartedSection } from '../../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { transType } from '../../../i18next';

interface Props {
    inProgress: boolean;
    goToPage: (pageNum: number) => void;
    showFinishModal: () => void;
}

export default function TestButtons({ inProgress, goToPage, showFinishModal }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { user } = useAppSelector(state => state.account);
    const applicantSessionDto = user?.applicantSessionDto;

    const { testSession } = useAppSelector(state => state.test);
    const { 
        totalQuestions, questionsPerPage, pageNumber, reviewMiddleAnswers
    } = testSession || {} as TestSession;

    const totalPages = Math.ceil(totalQuestions / questionsPerPage);
    const i18nKey = questionsPerPage > 1 ? 'PAGE' : (reviewMiddleAnswers ? 'MIDDLE_ANS' : 'QUESTION');

    return (
        <>
            {testSession && applicantSessionDto &&
                <div>
                    {pageNumber === 0 &&
                        <Button
                            variant="dark-ocean"
                            onClick={async () => {
                                await dispatch(applicantSessionStartedSection());
                                goToPage(inProgress ? applicantSessionDto.progress.lastPage : 1);
                            }}
                        >
                            {t(inProgress ? 'IDS_CONTINUE_TEST' : 'IDS_BEGIN_TEST')}
                        </Button>
                    }

                    {pageNumber > 0 &&
                        <>
                            <div className="d-flex mb-3 justify-content-between">
                                <Button
                                    variant="silver"
                                    className="me-2"
                                    disabled={pageNumber < 2}
                                    onClick={() => goToPage(pageNumber - 1)}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} className="small me-2 d-none-xs" fixedWidth />
                                    {t(`IDS_PREV_${i18nKey}` as keyof transType)}
                                </Button>

                                <Button
                                    variant="dark-ocean"
                                    className="ms-2"
                                    disabled={pageNumber > totalPages}
                                    onClick={() => {
                                        if (pageNumber < totalPages) {
                                            goToPage(pageNumber + 1);
                                        } else {
                                            showFinishModal();
                                        }
                                    }}
                                >
                                    {t(`IDS_NEXT_${i18nKey}` as keyof transType)}
                                    <FontAwesomeIcon icon={faAngleRight} className="small ms-2 d-none-xs" fixedWidth />
                                </Button>
                            </div>

                            <div className="d-flex justify-content-between">
                                <Button
                                    variant="link"
                                    size="sm"
                                    className="btn-link-sm-compact"
                                    disabled={pageNumber < 2}
                                    onClick={() => goToPage(1)}
                                >
                                    {t(`IDS_FIRST_${i18nKey}` as keyof transType)}
                                </Button>

                                <Button
                                    variant="link"
                                    size="sm"
                                    className="btn-link-sm-compact"
                                    disabled={pageNumber >= totalPages}
                                    onClick={() => goToPage(totalPages)}
                                >
                                    {t(`IDS_LAST_${i18nKey}` as keyof transType)}
                                </Button>

                                <Button
                                    variant="link"
                                    size="sm"
                                    className="btn-link-sm-compact"
                                    onClick={() => showFinishModal()}
                                >
                                    {t('IDS_FINISH')}
                                </Button>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
}