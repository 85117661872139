export interface ApplicantUnlockDto {
    username: string;
    unlockedDate: string;
    applicantId: number;
    extraRestart: boolean;
    extraTime: boolean;
    unlockLogonDate: string | null;
}

export const getRestarts = (unlockLog: ApplicantUnlockDto[] | null) => {
    let restarts = 1;

    if (unlockLog == null) return restarts;
    
    for (let i = 0; i < unlockLog.length; i++) {
        if (unlockLog[i].extraRestart) {
            restarts++;
        }
    }
    return restarts;
}