import React, { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber, Country } from 'react-phone-number-input';
import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { ApplicantInfoRequest } from '../../../app/models/requestHelpers/applicantInfoRequest';
import { TextValuePair } from '../../../app/models/textValuePair';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { RegExps, defaultCountryOptionsOrder, defaultFlagUrl } from '../../../app/utility/util';
import { getLangOrDefault, parseValidationMsg, locales, localeCode } from '../../../i18n';
import { PropsApplicationTabs } from './shared/applicationTabsProps';

const InputComponent = React.forwardRef<HTMLInputElement>((props, ref) => {
    return (
        <input
            id="phone"
            type="tel"
            maxLength={25}
            inputMode="tel"
            required
            ref={ref}
            {...props}
            style={{ border: 'none', appearance: 'none', boxShadow: 'none', outline: 'none' }}
        />
    );
});

export default function TabApplicantInfo({ setFormState }: PropsApplicationTabs<ApplicantInfoRequest>) {
    const { t } = useTranslation();
    const {
        applicant, applicantInfo, client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const dispatch = useAppDispatch();
    const [referrers, setReferrers] = useState<TextValuePair[]>([]);
    const refByReqd = client.referredByRequired;
    const { locale } = useAppSelector(state => state.locale);
    const userLang = getLangOrDefault();

    const {
        reset,
        formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors },
        handleSubmit,
        register,
        getValues,
        control
    } = useForm<ApplicantInfoRequest>({
        mode: 'all',
        resolver: yupResolver(yup.object().shape({
            address1: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_ADDRESS'] }),
            city: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CITY'] }),
            state: (userLang === localeCode.fr || userLang === localeCode.enGB)
                ? yup.string().nullable().notRequired().when('state', {
                    is: (stateVal: string) => (stateVal?.length),
                    then: rule => rule.matches(RegExps.state, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_STATE'] } }),
                })
                : yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_STATE'] })
                    .matches(RegExps.state, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_STATE'] } }),
            zip: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_POSTAL_CODE'] }),
            dayPhone: yup.string().nullable().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_DAY_PHONE'] })
                .test('test-name', { resKeys: ['IDS_IEP_INVALID', 'IDS_DAY_PHONE'] }, (value) => isPossiblePhoneNumber(value ?? '')),
            eveningPhone: yup.string().nullable().notRequired()
                .when('eveningPhone', {
                    is: (value: string) => value?.length,
                    then: rule => rule.test('test-name', { resKeys: ['IDS_IEP_INVALID', 'IDS_EVENING_PHONE'] }, (value) => isPossiblePhoneNumber(value ?? '')),
                }),
            mobilePhone: yup.string().nullable().notRequired()
                .when('mobilePhone', {
                    is: (value: string) => value?.length,
                    then: rule => rule.test('test-name', { resKeys: ['IDS_IEP_INVALID', 'IDS_MOBILE_PHONE'] }, (value) => isPossiblePhoneNumber(value ?? '')),
                }),
            email: client.emailRequired
                ? yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_EMAIL_ADDRESS'] })
                    .matches(RegExps.email, { message: { resKeys: ['IDS_IEP_REQUIRED', 'IDS_VALID_UNIQUE_EMAIL'] } })
                : yup.string().nullable().notRequired()
                    .when('email', {
                        is: (value: string) => value?.length,
                        then: rule => rule.matches(RegExps.email, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_EMAIL_ADDRESS'] } }),
                    }),
            referrer: refByReqd
                ? yup.number().moreThan(-1, { resKeys: ['IDS_IEP_REQUIRED', 'IDS_REFERRED_BY'] })
                : yup.number()
        },
            [
                ['state', 'state'],
                ['eveningPhone', 'eveningPhone'],
                ['mobilePhone', 'mobilePhone'],
                ['email', 'email']
            ]))
    });

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset]);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                if (applicant && client && applicantInfo) {
                    let referrers = await agent.Referrers.getReferrers({ languageCode: getLangOrDefault() });
                    let referrersOptions = referrers.map(ref => { return { text: ref.referrer, value: `${ref.id}` }; });
                    let emptyReferrerIdx = referrers.findIndex(x => x.referrer === '');
                    setReferrers(referrersOptions);

                    reset({
                        applicantId: applicant.applicantId,
                        clientId: applicant.clientId,
                        clientIsRecType: client.isRecType,
                        firstName: applicantInfo.firstName,
                        lastName: applicantInfo.lastName,
                        positionTitle: applicant.positionTitle,
                        address1: applicantInfo.address1,
                        address2: applicantInfo.address2,
                        city: applicantInfo.city,
                        state: applicantInfo.state,
                        zip: applicantInfo.zip,
                        dayPhone: applicantInfo.dayPhone,
                        eveningPhone: applicantInfo.eveningPhone,
                        mobilePhone: applicantInfo.mobilePhone,
                        email: applicantInfo.email,
                        referrer: (refByReqd && emptyReferrerIdx > -1 && applicantInfo.referrer === referrers[emptyReferrerIdx].id) ? -1 : applicantInfo.referrer
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [dispatch, reset, applicant, client, applicantInfo, refByReqd]);

    return (
        <Form id="form-application" className="needs-validation" noValidate>
            <div>
                <Row xs={1} sm={3} className="mb-4 g-4">
                    <Col>
                        <FloatingLabel
                            controlId="firstName"
                            className="small form-floating-plaintext"
                            label={t('IDS_FIRST_NAME')}
                        >
                            <Form.Control
                                readOnly
                                {...register('firstName')}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="lastName"
                            className="small form-floating-plaintext"
                            label={t('IDS_LAST_NAME')}
                        >
                            <Form.Control
                                readOnly
                                {...register('lastName')}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="positionTitle"
                            className="small form-floating-plaintext"
                            label={t('IDS_POSITION')}
                        >
                            <Form.Control
                                readOnly
                                {...register('positionTitle')}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-4 g-4">
                    <Col xs={12} sm={7}>
                        <FloatingLabel
                            controlId="address1"
                            className="small"
                            label={t('IDS_ADDRESS')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={250}
                                required
                                {...register('address1')}
                                isInvalid={!!errors.address1}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.address1?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm={5}>
                        <FloatingLabel
                            controlId="address2"
                            className="small"
                            label={t('IDS_ADDRESS_LINE_2')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={250}
                                {...register('address2')}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-4 g-4">
                    <Col xs={12} md={userLang !== localeCode.enGB ? 6 : 7} >
                        <FloatingLabel
                            controlId="city"
                            className="small"
                            label={t('IDS_CITY')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={50}
                                required
                                {...register('city')}
                                isInvalid={!!errors.city}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.city?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>

                    {userLang !== localeCode.enGB &&
                        <Col xs={12} sm={6} md={3}>
                            <FloatingLabel
                                controlId="state"
                                className="small"
                                label={t('IDS_STATE')}
                            >
                                <Form.Control
                                    type="text"
                                    maxLength={2}
                                    required={(userLang !== localeCode.fr)}
                                    {...register('state')}
                                    isInvalid={!!errors.state}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {parseValidationMsg(errors?.state?.message, t)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    }
                    
                    <Col xs={12} {...(userLang !== localeCode.enGB) ? { sm: 6, md: 3} : { md: 5}}>
                        <FloatingLabel
                            controlId="zip"
                            className="small"
                            label={t('IDS_POSTAL_CODE')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={12}
                                required
                                {...register('zip')}
                                isInvalid={!!errors.zip}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.zip?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row xs={1} sm={3} className="mb-4 g-4">
                    <Col>
                        <FloatingLabel
                            controlId="dayPhone"
                            className={`small mb-3 form-floating ${!!errors.dayPhone ? 'phone-invalid' : ''}`}
                            label={
                                <>
                                    <span className="phone-required">{t('IDS_DAY_PHONE')}</span>
                                </>
                            }
                        >
                            <PhoneInputWithCountrySelect
                                name="dayPhone"
                                inputComponent={InputComponent}
                                control={control as any}
                                international
                                countryOptionsOrder={defaultCountryOptionsOrder}
                                countryCallingCodeEditable={false}
                                defaultCountry={locales[locale].countryCode as Country}
                                flagUrl={defaultFlagUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.dayPhone?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="eveningPhone"
                            className={`small mb-3 form-floating ${!!errors.eveningPhone ? 'phone-invalid' : ''}`}
                            label={t('IDS_EVENING_PHONE')}
                        >
                            <PhoneInputWithCountrySelect
                                name="eveningPhone"
                                inputComponent={InputComponent}
                                control={control as any}
                                international
                                countryOptionsOrder={defaultCountryOptionsOrder}
                                countryCallingCodeEditable={false}
                                defaultCountry={locales[locale].countryCode as Country}
                                flagUrl={defaultFlagUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.eveningPhone?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="mobilePhone"
                            className={`small mb-3 form-floating ${!!errors.mobilePhone ? 'phone-invalid' : ''}`}
                            label={t('IDS_MOBILE_PHONE')}
                        >
                            <PhoneInputWithCountrySelect
                                name="mobilePhone"
                                inputComponent={InputComponent}
                                control={control as any}
                                international
                                countryOptionsOrder={defaultCountryOptionsOrder}
                                countryCallingCodeEditable={false}
                                defaultCountry={locales[locale].countryCode as Country}
                                flagUrl={defaultFlagUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.mobilePhone?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row xs={1} sm={2} className="g-4">
                    <Col>
                        <FloatingLabel
                            controlId="email"
                            className="small"
                            label={t('IDS_EMAIL_ADDRESS')}
                        >
                            <Form.Control
                                type="text"
                                maxLength={50}
                                required={client.emailRequired}
                                {...register('email')}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.email?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="referrer"
                            className="small"
                            label={t('IDS_REFERRED_BY')}
                        >
                            <Form.Select
                                required={client.referredByRequired}
                                {...register('referrer')}
                                isInvalid={!!errors.referrer}
                            >
                                {referrers && referrers.map(({ text, value }) => (
                                    <option key={value} value={(client.referredByRequired && text === '') ? '-1' : value}>{text}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.referrer?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}