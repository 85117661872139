import { FieldValues } from 'react-hook-form';

import { ApplicantItem } from '../applicantItem';
import { VwClient } from '../dbViews/vwClient';

export interface ApplicantRequest {
    applicantId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    clientNumber: string;
    clientId: number;
    application: boolean;
    achiever: boolean;
    aptitude: boolean;
    aptitudeInhouse: boolean;
    applicationOrder: number;
    achieverOrder: number;
    aptitudeOrder: number;
    dpatInhouseOrder: number;
    positionId: number;
    positionTitle: string;
    emailInformation: boolean;
    languageCode: string;
    isDeleted: boolean;
    addedByUsername?: string;
    isReynoldsAssociate?: boolean;
    recruiterName?: string;
    recruiterEmail?: string;
    companyName?: string;
    resetPassword?: boolean;    
    testTimeMultiplier: number;
}

export function formDataToApplicantRequest(vwClient: VwClient, formData: FieldValues) {
    let applicant: ApplicantRequest = {
        applicantId: Number(formData.id),
        isDeleted: false,
        username: formData.username,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        clientNumber: vwClient.clientNumber,
        clientId: vwClient.id,
        positionId: formData.positionId || '0',
        positionTitle: formData.positionTitle,
        emailInformation: formData.emailInformation,
        languageCode: vwClient.defaultLanguage,
        application: false,
        achiever: false,
        aptitude: false,
        aptitudeInhouse: false,
        applicationOrder: 0,
        achieverOrder: 0,
        aptitudeOrder: 0,
        dpatInhouseOrder: 0,
        testTimeMultiplier: formData.specialAccommodations ? 1.5 : 0.0
    };

    for (let i = 0; i < formData['applicantItems'].length; i++) {
        let f = formData['applicantItems'][i] as ApplicantItem;
        if (f.name === 'Achiever') {
            applicant.achiever = f.turnedOn;
            applicant.achieverOrder = i + 1;
        } else if (f.name === 'Application') {
            applicant.application = f.turnedOn;
            applicant.applicationOrder = i + 1;
        } else if (f.name === 'Aptitudetest') {
            applicant.aptitude = f.turnedOn;
            applicant.aptitudeOrder = i + 1;
        } else if (f.name === 'DpatInhouse') {
            applicant.aptitudeInhouse = f.turnedOn;
            applicant.dpatInhouseOrder = i + 1;
        }
    }

    let itemsCount = formData['applicantItems'].length;
    if (applicant.applicationOrder === 0) applicant.applicationOrder = ++itemsCount;
    if (applicant.achieverOrder === 0) applicant.achieverOrder = ++itemsCount;
    if (applicant.aptitudeOrder === 0) applicant.aptitudeOrder = ++itemsCount;
    if (applicant.dpatInhouseOrder === 0) applicant.dpatInhouseOrder = ++itemsCount;

    return applicant;
}