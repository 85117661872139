import { DraggableProvided } from '@hello-pangea/dnd';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { faFilePdf, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HelpTooltipIcon from '../../../../app/components/HelpTooltipIcon';
import {
    getAchieverPdfAsync, getApplicationPdfAsync, getDpatInhPdfAsync, getDpatPdfAsync
} from '../../../../app/store/pdfDocumentSlice';
import { useAppDispatch } from '../../../../app/store/storeHooks';

export interface CardProps {
    provided: DraggableProvided;
    item: Record<"id", string>;
    index: number;
    showAssignedAndViewCols: boolean;
    isDragDisabled: boolean;
}

export default function ApplicantItemsCard({ provided, item, index, showAssignedAndViewCols, isDragDisabled }: CardProps) {
    const { register, getValues } = useFormContext();
    const dispatch = useAppDispatch();

    return (
        <tr
            className="d-flex"
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}>
            <td style={{ cursor: isDragDisabled ? 'not-allowed' : 'move', width: '4rem' }} {...provided.dragHandleProps} title="Draggable item">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faGripVertical} fixedWidth className={`fs-initial ${isDragDisabled ? 'opacity-50' : ''}`} />
                    <span className="dnd-index-cell-span d-flex align-items-center justify-content-center" style={{ width: '1rem' }}>{index + 1}</span>
                </div>
            </td>
            <td style={{ width: showAssignedAndViewCols ? '11.5rem' : '100%' }}>
                {getValues(`applicantItems[${index}].text`)}
            </td>
            <td className="text-center" style={{ width: '6rem' }}>
                <div className="d-flex justify-content-center">
                    <Form.Check
                        type="switch"
                        disabled={getValues(`applicantItems[${index}].disableToggle`) === true}
                        {...register(`applicantItems[${index}].turnedOn`, {})}
                    />
                    <HelpTooltipIcon
                        fontSizeInitial={true}
                        tooltipId={`tooltip${getValues(`applicantItems[${index}].name`)}`}
                        tooltipText={getValues(`applicantItems[${index}].tooltipText`)}
                        tooltipTitle={getValues(`applicantItems[${index}].tooltipTitle`)}
                    />
                </div>
            </td>
            {showAssignedAndViewCols &&
                <>
                    <td className="flex-fill">
                        {getValues(`applicantItems[${index}].assignedText`)}
                    </td>
                    <td className="text-center" style={{ width: '4rem' }}>
                        {getValues(`applicantItems[${index}].viewVisible`) === true &&
                            <Button
                                variant="link"
                                title={getValues(`applicantItems[${index}].pdfTooltipText`)}
                                className="link-dark-ocean py-0 fs-initial lh-1"
                                disabled={getValues(`applicantItems[${index}].viewEnabled`) === false}
                                onClick={async () => {
                                    let applicantId: number = getValues(`applicantItems[${index}].applicantId`);
                                    let applicantItemName: string = getValues(`applicantItems[${index}].name`);

                                    if (applicantItemName === 'Application') {
                                        await dispatch(getApplicationPdfAsync({ applicantId }));
                                    } else if (applicantItemName === 'Achiever') {
                                        await dispatch(getAchieverPdfAsync({ applicantId }));
                                    } else if (applicantItemName === 'Aptitudetest') {
                                        await dispatch(getDpatPdfAsync({ applicantId }));
                                    } else if (applicantItemName === 'DpatInhouse') {
                                        await dispatch(getDpatInhPdfAsync({ applicantId }));
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} />
                            </Button>
                        }
                    </td>
                </>
            }
        </tr>
    );
}
