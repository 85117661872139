import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../../app/api/agent';
import BsGrid from '../../../app/components/BsGrid';
import DatePickerColumnFilter from '../../../app/components/BsGrid/datePickerColumnFilter';
import PositionColumnFilter from '../../../app/components/BsGrid/positionColumnFilter';
import TextColumnFilter from '../../../app/components/BsGrid/textColumnFilter';
import { BsModalAlert, BsModalConfirm } from '../../../app/components/BsModal';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { VwClientApplicantSearch } from '../../../app/models/dbViews/vwClientApplicantSearch';
import {
    getAchieverPdfAsync, getApplicationPdfAsync, getDpatInhPdfAsync, getDpatPdfAsync
} from '../../../app/store/pdfDocumentSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { localDate } from '../../../app/utility/util';
import { getTitle } from '../../../i18n';
import ModalApplicantAdd from './ModalApplicantAdd';
import ModalApplicantEdit from './ModalApplicantEdit';

export default function ApplicantsPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const viewResults = clientSession?.viewResults ?? false;
    const [data, setData] = useState<VwClientApplicantSearch[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showModalApplicantAdd, setShowModalApplicantAdd] = useState(false);
    const [showModalApplicantEdit, setShowModalApplicantEdit] = useState(false);
    const [applicantId, setApplicantId] = useState(0);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const [modalAlertState, setModalAlertState] = useState<{ show: boolean, msg: string }>({ show: false, msg: '' });
    const fetchIdRef = useRef(0);

    let hiddenColIds: string[] = ['id'];
    if (!clientSession?.achiever) hiddenColIds.push('achieverCompletedDate');
    if (!clientSession?.dpat) hiddenColIds.push('dpatCompletedDate');
    if (!clientSession?.dpatInhouse) hiddenColIds.push('dpatInhouseCompletedDate');

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<VwClientApplicantSearch>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current && changeCount >= 0) {
            setLoading(true);

            let gridParams = {
                pageSize,
                pageIndex,
                sortBy,
                filters
            };

            agent.ClientApplicant.getApplicants(gridParams, { clientId })
                .then(applicantData => {
                    setTotalRecords(applicantData.total);
                    setData(applicantData.applicants);
                    setPageCount(Math.ceil(applicantData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [clientId, changeCount]);

    const showApplicantModal = (applicantId: number) => {
        setApplicantId(applicantId);
        if (applicantId > 0) {
            setShowModalApplicantEdit(true);
        } else {
            setShowModalApplicantAdd(true);
        }
    }

    const showConfirmDelete = (applicantId: number) => {
        setApplicantId(applicantId);
        setShowModalConfirmDelete(true);
    }

    const columns = useMemo<Column<VwClientApplicantSearch>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: t('IDS_LAST_NAME'),
                accessor: 'lastName',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => showApplicantModal(row.original.id)}
                        >
                            {row.original.lastName}
                        </Button>
                    );
                }
            },
            {
                Header: t('IDS_FIRST_NAME'),
                accessor: 'firstName',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_USERNAME'),
                accessor: 'username',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_EMAIL'),
                accessor: 'email',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: t('IDS_POSITION'),
                accessor: 'title',
                Filter: (filterProps) => PositionColumnFilter(filterProps)
            },
            {
                Header: t('IDS_ADDED'),
                id: 'addedDate',
                accessor: (row) => localDate(row.addedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps)
            },
            {
                Header: t('IDS_APP_COMPLETED'),
                id: 'applicationCompletedDate',
                accessor: (row) => localDate(row.applicationCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return row.values.applicationCompletedDate ? (
                        <>
                            {row.values.applicationCompletedDate}
                            {viewResults &&
                                <Button
                                    variant="link"
                                    title={t('IDS_VIEW_APPLICATION')}
                                    className="ms-1 link-dark-ocean py-0 fs-initial lh-1"
                                    onClick={async () => {
                                        await dispatch(getApplicationPdfAsync({ applicantId: row.original.id }));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            }
                        </>
                    ) : (<>{row.original.testOnly ? t('IDS_TEST_ONLY') : ''}</>);
                }
            },
            {
                Header: t('IDS_ASSESS_COMPLETED'),
                id: 'achieverCompletedDate',
                accessor: (row) => localDate(row.achieverCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return row.values.achieverCompletedDate ? (
                        <>
                            {row.values.achieverCompletedDate}
                            {viewResults &&
                                <Button
                                    variant="link"
                                    title={t('IDS_VIEW_ASSESSMENT')}
                                    className="ms-1 link-dark-ocean py-0 fs-initial lh-1"
                                    onClick={async () => {
                                        await dispatch(getAchieverPdfAsync({ applicantId: row.original.id }));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            }
                        </>
                    ) : null;
                }
            },
            {
                Header: t('IDS_APT_COMPLETED'),
                id: 'dpatCompletedDate',
                accessor: (row) => localDate(row.dpatCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return row.values.dpatCompletedDate ? (
                        <>
                            {row.values.dpatCompletedDate}
                            {viewResults &&
                                <Button
                                    variant="link"
                                    title={t('IDS_VIEW_DPAT')}
                                    className="ms-1 link-dark-ocean py-0 fs-initial lh-1"
                                    onClick={async () => {
                                        await dispatch(getDpatPdfAsync({ applicantId: row.original.id }));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            }
                        </>
                    ) : null;
                }
            },
            {
                Header: t('IDS_INH_APT_COMPLETED'),
                id: 'dpatInhouseCompletedDate',
                accessor: (row) => localDate(row.dpatInhouseCompletedDate),
                Filter: (filterProps) => DatePickerColumnFilter(filterProps),
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return row.values.dpatInhouseCompletedDate ? (
                        <>
                            {row.values.dpatInhouseCompletedDate}
                            {viewResults &&
                                <Button
                                    variant="link"
                                    title={t('IDS_VIEW_INH_DPAT')}
                                    className="ms-1 link-dark-ocean py-0 fs-initial lh-1"
                                    onClick={async () => {
                                        await dispatch(getDpatInhPdfAsync({ applicantId: row.original.id }));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            }
                        </>
                    ) : null;
                }
            },
            {
                Header: `${t('IDS_DELETE')}`,
                id: 'delete',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: CellProps<VwClientApplicantSearch>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 fs-inherit"
                            onClick={() => showConfirmDelete(row.original.id)}
                        >
                            {t('IDS_DELETE')}
                        </Button>
                    );
                }
            }
        ],
        [dispatch, t, viewResults]
    );

    return (
        <>
        <Helmet>
            <title>{getTitle(t, 'IDS_SEARCH_APPLICANTS')}</title>
        </Helmet>
        <div className="main-titlebar bg-lightest-ocean py-3">
            <span className="fs-5 line-height-100">{`${t('IDS_APPLICANTS')} - ${clientSession?.vwClient.name}`}</span>
        </div>
        <div className="main-content bg-light-silver">
            <BsGrid
                addRecordFcn={() => showApplicantModal(0)}
                columns={columns}
                data={data}
                defaultSortBy={{ id: 'lastName', desc: false }}
                fetchData={fetchData}
                hiddenColumnIds={hiddenColIds}
                i18nKeys={{ itemName: 'IDS_APPLICANT', addItem: 'IDS_ADD_APPLICANT' }}
                loading={loading}
                pageCount={pageCount}
                totalRecords={totalRecords}
            />
            <ModalApplicantAdd
                showModal={showModalApplicantAdd}
                setShowModal={() => setShowModalApplicantAdd(false)}
                changeCount={changeCount}
                setChangeCount={setChangeCount}
                setModalAlert={setModalAlertState}
            />
            <ModalApplicantEdit
                applicantId={applicantId}
                showModal={showModalApplicantEdit}
                setShowModal={() => setShowModalApplicantEdit(false)}
                changeCount={changeCount}
                setChangeCount={setChangeCount}
            />
            <BsModalConfirm
                title={t('IDS_CONFIRM')}
                message={t('IDS_WARN_DELETE_APPLICANT')}
                showModal={showModalConfirmDelete}
                setShowModal={setShowModalConfirmDelete}
                confirmCallback={async () => {
                    if (applicantId) {
                        await agent.ClientApplicant.removeApplicant({ applicantId });
                        setShowModalConfirmDelete(false);
                        setChangeCount(prevCount => prevCount + 1);
                    }
                }}
            />
            <BsModalAlert
                title={t('IDS_SUCCESS')}
                message={modalAlertState.msg}
                showModal={modalAlertState.show}
                setShowModal={(show) => setModalAlertState(prevState => ({ show, msg: prevState.msg }))}
            />
            </div>
        </>
    );
}