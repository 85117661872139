import { useEffect } from 'react';
import { Form, Tab } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppEdu } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { PropsApplicationTabs } from './shared/applicationTabsProps';
import { getPaddedEdus } from '../../../app/models/requestHelpers/applicantEduRequest';
import EducationCard from './EducationCard';
import { getLangOrDefault } from '../../../i18n';

export default function TabEducation({ setFormState }: PropsApplicationTabs<FieldValuesAppEdu>) {
    const { status } = useAppSelector((state) => state.account);
    const {
        education, client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const eduReqd = client.education;
    const langIsEnGb = getLangOrDefault() === 'en-GB';

    const formMethods = useForm<FieldValuesAppEdu>({
        mode: 'all',
        defaultValues: { edus: getPaddedEdus(education) },
        resolver: yupResolver(yup.object({
            edus: yup.array().of(
                yup.object().shape({
                    clientIndex: yup.number(),
                    notApplicable: yup.boolean(),
                    type: yup.number(),
                    schoolName: yup.string().when(['clientIndex', 'notApplicable'], {
                        is: (clientIndexVal: number, notApplicableVal: boolean) => (clientIndexVal < 2 && eduReqd > clientIndexVal && !notApplicableVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_SCHOOL_NAME'] })
                    }),
                    degree: yup.string().when(['clientIndex', 'notApplicable'], {
                        is: (clientIndexVal: number, notApplicableVal: boolean) => (clientIndexVal < 2 && eduReqd > clientIndexVal && !notApplicableVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_SCHOOL_DEGREE'] })
                    }),
                    gpa: yup.string().when(['clientIndex', 'notApplicable'], {
                        is: (clientIndexVal: number, notApplicableVal: boolean) => (clientIndexVal === 1 && eduReqd > clientIndexVal && !notApplicableVal && !langIsEnGb),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_SCHOOL_GPA'] })
                    })
                })
            )
        }))
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors }, handleSubmit, getValues, trigger } = formMethods;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    const eduTabs: BsTabs = {
        'highSchool': {
            linkText: 'IDS_HIGH_SCHOOL',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.edus?.[0],
            paneComponent: <EducationCard index={0} />
        },
        'college': {
            linkText: 'IDS_COLLEGE',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.edus?.[1],
            paneComponent: <EducationCard index={1} />
        },
        'otherCollege': {
            linkText: 'IDS_OTHER_COLLEGE',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.edus?.[2],
            paneComponent: <EducationCard index={2} />
        },
        'otherEducation': {
            linkText: 'IDS_OTHER_EDUCATION',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.edus?.[3],
            paneComponent: <EducationCard index={3} />
        }
    };

    return (
        <div>
            <FormProvider {...formMethods} >
                <Form id="form-education" className="needs-validation" noValidate>
                    <Tab.Container defaultActiveKey="highSchool">
                        <BsScrollableTabs tabs={eduTabs} navTabsClassName="nav-tabs-alt" />
                        <div className="border border-light-eggplant border-2 bg-white p-4">
                            <Tab.Content>
                                {Object.keys(eduTabs).map((key) => (
                                    <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                        {eduTabs[key].paneComponent}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </Form>
            </FormProvider>
        </div>
    );
}