import { SyntheticEvent } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppEdu } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { getLangOrDefault, parseValidationMsg } from '../../../i18n';

export interface CardProps {
    index: number;
}

export default function EducationCard({ index }: CardProps) {
    const { t } = useTranslation();
    const { client: { education: eduReqd } } =
        useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const { register, setValue, getValues, trigger, watch, formState: { errors } } = useFormContext<FieldValuesAppEdu>();
    const watchNotApplicable = watch(`edus.${index}.notApplicable`) as boolean;
    const langIsEnGb = getLangOrDefault() === 'en-GB';

    return (
        <div>
            <input type="hidden" {...register(`edus.${index}.clientIndex`, { value: index })} />
            <input type="hidden" {...register(`edus.${index}.type`)} />
            {(index === 1 && eduReqd > 1) &&
                <Row className="mb-4 g-4">
                    <Col>
                        <Form.Check
                            id={`edus.${index}.notApplicable`}
                            type="checkbox"
                            label={t('IDS_NOT_APPLICABLE_COLLEGE')}
                            className="small"
                            {...register(`edus.${index}.notApplicable`, {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    if (e.currentTarget.checked) {
                                        let vals = getValues();
                                        setValue(`edus.${index}`, {
                                            clientIndex: vals.edus[index].clientIndex,
                                            type: vals.edus[index].type,
                                            attended: '',
                                            schoolName: '',
                                            degree: '',
                                            major: '',
                                            gpa: '',
                                            notApplicable: true
                                        });
                                    }
                                    trigger();
                                }
                            })}
                        />
                    </Col>
                </Row>
            }
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <FloatingLabel
                        controlId={`edus.${index}.schoolName`}
                        className="small"
                        label={t('IDS_SCHOOL_NAME')}
                    >
                        <Form.Control
                            type="text"
                            required={(index < 2 && eduReqd > index && !watchNotApplicable)}
                            disabled={watchNotApplicable}
                            maxLength={50}
                            {...register(`edus.${index}.schoolName`)}
                            isInvalid={!!errors?.edus?.[index]?.schoolName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.edus?.[index]?.schoolName?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId={`edus.${index}.degree`}
                        className="small"
                        label={t('IDS_SCHOOL_DEGREE')}
                    >
                        <Form.Control
                            type="text"
                            required={(index < 2 && eduReqd > index && !watchNotApplicable)}
                            disabled={watchNotApplicable}
                            maxLength={50}
                            {...register(`edus.${index}.degree`)}
                            isInvalid={!!errors?.edus?.[index]?.degree}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.edus?.[index]?.degree?.message, t, true)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                {(index > 0 && index < 3 && !langIsEnGb) &&
                    <Col>
                        <FloatingLabel
                            controlId={`edus.${index}.gpa`}
                            className="small"
                            label={t('IDS_SCHOOL_GPA')}
                        >
                            <Form.Control
                                type="text"
                                required={(index === 1 && eduReqd > index && !watchNotApplicable)}
                                disabled={watchNotApplicable}
                                maxLength={10}
                                {...register(`edus.${index}.gpa`)}
                                isInvalid={!!errors?.edus?.[index]?.gpa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.edus?.[index]?.gpa?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                }
                {!langIsEnGb &&
                    <Col>
                        <FloatingLabel
                            controlId={`edus.${index}.major`}
                            className="small"
                            label={t('IDS_SCHOOL_MAJOR')}
                        >
                            <Form.Control
                                type="text"
                                disabled={watchNotApplicable}
                                maxLength={50}
                                {...register(`edus.${index}.major`)}
                            />
                        </FloatingLabel>
                    </Col>
                }
                {index > 0 &&
                    <Col>
                        <FloatingLabel
                            controlId={`edus.${index}.attended`}
                            className="small"
                            label={t('IDS_SCHOOL_LAST_YEAR')}
                        >
                            <Form.Control
                                type="text"
                                disabled={watchNotApplicable}
                                maxLength={4}
                                {...register(`edus.${index}.attended`, {
                                    onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]+/g, '');
                                        setValue(`edus.${index}.attended`, e.currentTarget.value);
                                    }
                                })}
                            />
                        </FloatingLabel>
                    </Col>
                }
            </Row>
        </div>
    );
}