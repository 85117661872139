import { useEffect } from 'react';
import { Form, Tab } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import agent from '../../../app/api/agent';
import { BsAlertDismissible } from '../../../app/components/BsAlertDismissible';
import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import { TblClientLanguage } from '../../../app/models/dbTables/tblClientLanguage';
import { StringKeyObject } from '../../../app/models/stringKeyObject';
import { useAppSelector } from '../../../app/store/storeHooks';
import { locales } from '../../../i18n';
import DefaultQuestionsContainer from './dnd/DefaultQuestionsContainer';
import { PropsSetupTabs } from './shared/setupTabsProps';

export default function TabDefaultQuestions({ setFormState }: PropsSetupTabs) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id;
    const defaultLang = clientSession?.vwClient?.defaultLanguage ?? '';
    const formMethods = useForm({
        mode: 'all'
    }),
        { reset, formState: { isDirty, isSubmitting, isSubmitSuccessful }, handleSubmit, getValues } = formMethods;

    useEffect(() => {
        setFormState({ isValid: true, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset]);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                if (clientId) {
                    let clientDefaultQuestions = await agent.ClientPosition.getAllClientDefaultQuestions({ clientId, languageCode: 'all' });

                    let defaultQuestionFields: StringKeyObject<StringKeyObject<string>[]> = {};
                    clientDefaultQuestions.forEach(item => {
                        let key = `defaultQuestions-${item.languageCode}`;
                        let idx = item.order ? item.order - 1 : 0;
                        if (!defaultQuestionFields[key]) {
                            defaultQuestionFields[key] = [];
                        }
                        defaultQuestionFields[key][idx] = {
                            defaultQuestionId: `${item.questionId}`,
                            question: item.question,
                            typeId: `${item.typeId}`,
                            literalA: item.literalA,
                            literalB: item.literalB,
                            literalC: item.literalC,
                            literalD: item.literalD,
                            literalE: item.literalE
                        };
                    });

                    Object.keys(locales).forEach(lang => {
                        let key = `defaultQuestions-${lang}`;
                        if (!defaultQuestionFields[key]) {
                            defaultQuestionFields[key] = [];
                        }
                    });

                    reset({ ...defaultQuestionFields });
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchSetupData();
    }, [reset, clientId]);

    let defaultQuestionsTabs: BsTabs = {};
    clientSession?.languageBC.forEach(({ languageCode }: TblClientLanguage) => {
        defaultQuestionsTabs[languageCode] = {
            linkText: languageCode,
            paneComponent: (
                <DefaultQuestionsContainer languageCode={languageCode} />)
        };
    });

    return (
        <FormProvider {...formMethods} >
            <BsAlertDismissible
                btnText={t('IDS_HELP_CLIENTSETUPDEFAULTQUESTIONS_TITLE')}
                message={t('IDS_HELP_CLIENTSETUPDEFAULTQUESTIONS_MESSAGE')}
                headerText={t('IDS_HELP_CLIENTSETUPDEFAULTQUESTIONS_TITLE')}
                variant="dark-ocean"
            />
            <Form id="form-defaultQuestions" className="needs-validation pt-2" noValidate>
                <Tab.Container {...(defaultLang ? { defaultActiveKey: defaultLang } : {})}>
                    <BsScrollableTabs tabs={defaultQuestionsTabs} navTabsClassName="nav-tabs-alt" />
                    <div className="border border-light-eggplant border-2 bg-white p-4">
                        <Tab.Content>
                            {Object.keys(defaultQuestionsTabs).map((key) => (
                                <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                    {defaultQuestionsTabs[key].paneComponent}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </Form>
        </FormProvider>
    );
}