import { DraggableProvided } from '@hello-pangea/dnd';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface CardProps {
    provided: DraggableProvided;
    item: Record<"id", string>;
    index: number;
}

export default function ApplicantDefaultsCard({ provided, item, index }: CardProps) {
    const { register, getValues } = useFormContext();
    
    return (
        <tr
            className="d-flex"
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}>
                <td style={{ cursor: 'move', width: '7.5rem' }} {...provided.dragHandleProps} title="Draggable item">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faGripVertical} fixedWidth className="fs-initial" />
                        <span className="dnd-index-cell-span d-flex align-items-center justify-content-center" style={{width: '1rem'}}>{index}</span>
                    </div>
                </td>
                <td className="flex-fill">
                    {getValues(`applicantDefaults[${index}].text`)}
                </td>
                <td className="text-center" style={{ width: '12rem' }}>
                    <Form.Check
                        type="switch"
                        {...register(`applicantDefaults[${index}].onByDefault`, {})}
                    />
                </td>
        </tr>
    );
}
