import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../api/agent';
import { HandledError } from '../models/handledError';

export interface HandledErrorState {
    handledError: HandledError | null;
    status: string;
}

const initialState: HandledErrorState = {
    handledError: null,
    status: 'idle'
};

export const get400ErrorAsync = createAsyncThunk<void>(
    'error/get400ErrorAsync',
    async (_, thunkAPI) => {
        try {
            await agent.TestErrors.get400Error();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const get401ErrorAsync = createAsyncThunk<void>(
    'error/get401ErrorAsync',
    async (_, thunkAPI) => {
        try {
            await agent.TestErrors.get401Error();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const get404ErrorAsync = createAsyncThunk<void>(
    'error/get404ErrorAsync',
    async (_, thunkAPI) => {
        try {
            await agent.TestErrors.get404Error();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const get500ErrorAsync = createAsyncThunk<void>(
    'error/get500ErrorAsync',
    async (_, thunkAPI) => {
        try {
            await agent.TestErrors.get500Error();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);
export const getValidationErrorAsync = createAsyncThunk<void>(
    'error/getValidationErrorAsync',
    async (_, thunkAPI) => {
        try {
            await agent.TestErrors.getValidationError();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
);

export const handledErrorSlice = createSlice({
    name: 'handledError',
    initialState,
    reducers: {
        setError: (state, action: { payload: HandledError | null, type: string }) => {
            state.handledError = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(get400ErrorAsync.pending, (state) => {
            state.status = 'pendingGet400Error';
        });
        builder.addCase(get400ErrorAsync.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get400ErrorAsync.rejected, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get401ErrorAsync.pending, (state) => {
            state.status = 'pendingGet401Error';
        });
        builder.addCase(get401ErrorAsync.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get401ErrorAsync.rejected, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get404ErrorAsync.pending, (state) => {
            state.status = 'pendingGet404Error';
        });
        builder.addCase(get404ErrorAsync.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get404ErrorAsync.rejected, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get500ErrorAsync.pending, (state) => {
            state.status = 'pendingGet500Error';
        });
        builder.addCase(get500ErrorAsync.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addCase(get500ErrorAsync.rejected, (state) => {
            state.status = 'idle';
        });
        builder.addCase(getValidationErrorAsync.pending, (state) => {
            state.status = 'pendinGetValidationError';
        });
        builder.addCase(getValidationErrorAsync.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addCase(getValidationErrorAsync.rejected, (state) => {
            state.status = 'idle';
        });
    })
});

export const { setError } = handledErrorSlice.actions;
export default handledErrorSlice.reducer;