import { SyntheticEvent } from 'react';
import { Alert, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppExp } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { getLangOrDefault, localeCode, parseValidationMsg } from '../../../i18n';

export interface CardProps {
    index: number;
}

export default function WorkExperienceCard({ index }: CardProps) {
    const { t } = useTranslation();
    const { client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const empReqd = client.employment || 0;
    const { register, watch, resetField, getValues, setValue, trigger, formState: { errors } } = useFormContext<FieldValuesAppExp>();
    const watchNoPreviousExp = watch(`experiences.${index}.noPreviousExp`) as boolean;
    const watchStillEmployed = watch(`experiences.${index}.stillEmployed`) as boolean;
    const userLang = getLangOrDefault();

    return (
        <div>
            <input type="hidden" {...register(`experiences.${index}.clientIndex`, { value: index })} />
            <Row className="mb-4 g-4">
                <Col xs={12}>
                    <Form.Check
                        id={`experiences.${index}.noPreviousExp`}
                        type="checkbox"
                        label={t(index === 0 ? 'IDS_NOT_APPLICABLE' : 'IDS_NOT_APPLICABLE_2')}
                        className="small"
                        {...register(`experiences.${index}.noPreviousExp`, {
                            onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                if (e.currentTarget.checked) {
                                    let vals = getValues();
                                    for (let i = index; i <= 2; i++) {
                                        setValue(`experiences.${i}`, {
                                            clientIndex: vals.experiences[i].clientIndex,
                                            stillEmployed: false,
                                            companyName: '',
                                            jobTitle: '',
                                            city: '',
                                            state: '',
                                            startDate: '',
                                            endDate: '',
                                            comment: '',
                                            description: '',
                                            salaryStart: '',
                                            salaryEnd: '',
                                            noPreviousExp: true
                                        });
                                    }
                                }
                                trigger();
                            }
                        })}
                    />
                </Col>
            </Row>
            <Row className="mb-4 g-4">
                <Col xs={12} sm={6}>
                    <FloatingLabel
                        controlId={`experiences.${index}.companyName`}
                        className="small"
                        label={t('IDS_COMPANY_NAME')}
                    >
                        <Form.Control
                            type="text"
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            maxLength={50}
                            {...register(`experiences.${index}.companyName`)}
                            isInvalid={!!errors?.experiences?.[index]?.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.companyName?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={12} sm={6}>
                    <FloatingLabel
                        controlId={`experiences.${index}.jobTitle`}
                        className="small"
                        label={t('IDS_JOB_TITLE')}
                    >
                        <Form.Control
                            type="text"
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            maxLength={50}
                            {...register(`experiences.${index}.jobTitle`)}
                            isInvalid={!!errors?.experiences?.[index]?.jobTitle}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.jobTitle?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-4 g-4">
                <Col xs={12} {...(userLang !== localeCode.enGB) ? { sm: 8, md: 9} : {}}>
                    <FloatingLabel
                        controlId={`experiences.${index}.city`}
                        className="small"
                        label={t('IDS_CITY')}
                    >
                        <Form.Control
                            type="text"
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            maxLength={50}
                            {...register(`experiences.${index}.city`)}
                            isInvalid={!!errors?.experiences?.[index]?.city}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.city?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                {userLang !== localeCode.enGB &&
                    <Col xs={12} sm={4} md={3}>
                        <FloatingLabel
                            controlId={`experiences.${index}.state`}
                            className="small"
                            label={t('IDS_STATE')}
                        >
                            <Form.Control
                                type="text"
                                required={(empReqd > index && !watchNoPreviousExp && userLang !== localeCode.fr)}
                                disabled={watchNoPreviousExp}
                                maxLength={2}
                                {...register(`experiences.${index}.state`, {
                                    onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                        e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z]+/g, '').toUpperCase();
                                        setValue(`experiences.${index}.state`, e.currentTarget.value);
                                    }
                                })}
                                isInvalid={!!errors?.experiences?.[index]?.state}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.experiences?.[index]?.state?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                }
            </Row>
            <Row className="mb-4 g-4">
                <Col xs={12} sm={6} md={4} lg={4} xl={5}>
                    <FloatingLabel
                        controlId={`experiences.${index}.startDate`}
                        className="small"
                        label={t('IDS_START_DATE')}
                    >
                        <Form.Control
                            type="date"
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            {...register(`experiences.${index}.startDate`, {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    trigger(`experiences.${index}.endDate`);
                                }
                            })}
                            isInvalid={!!errors?.experiences?.[index]?.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.startDate?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={5}>
                    <FloatingLabel
                        controlId={`experiences.${index}.endDate`}
                        className="small"
                        label={t('IDS_END_DATE')}
                    >
                        <Form.Control
                            type="date"
                            required={(empReqd > index && !watchNoPreviousExp && !watchStillEmployed)}
                            disabled={watchNoPreviousExp || watchStillEmployed}
                            {...register(`experiences.${index}.endDate`, {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    trigger(`experiences.${index}.startDate`);
                                }
                            })}
                            isInvalid={!!errors?.experiences?.[index]?.endDate}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.endDate?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={2} className="d-flex align-items-center still-employed" style={{ height: '3.5rem' }}>
                    <Form.Check
                        id={`experiences.${index}.stillEmployed`}
                        type="checkbox"
                        label={t('IDS_STILL_EMPLOYED')}
                        className="small"
                        disabled={watchNoPreviousExp}
                        {...register(`experiences.${index}.stillEmployed`, {
                            onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                if (e.currentTarget.checked) {
                                    resetField(`experiences.${index}.endDate`);
                                } else {
                                    trigger(`experiences.${index}.endDate`);
                                }
                            }
                        })}
                    />
                </Col>
            </Row>
            <Row className="mb-4 g-4">
                <Col>
                    <FloatingLabel
                        controlId={`experiences.${index}.comment`}
                        className="small"
                        label={t('IDS_REASON_FOR_LEAVING')}
                    >
                        <Form.Control
                            type="text"
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            maxLength={50}
                            {...register(`experiences.${index}.comment`)}
                            isInvalid={!!errors?.experiences?.[index]?.comment}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.comment?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className={`g-4 ${client.isRecType ? 'mb-4' : ''}`}>
                <Col>
                    <FloatingLabel
                        controlId={`experiences.${index}.description`}
                        className={`small form-floating-textarea ${(watchNoPreviousExp ? 'form-floating-textarea-disabled' : '')}`}
                        label={t('IDS_POSITION_RESPONSIBILITIES')}
                    >
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            maxLength={1000}
                            required={(empReqd > index && !watchNoPreviousExp)}
                            disabled={watchNoPreviousExp}
                            {...register(`experiences.${index}.description`, {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    e.currentTarget.value = e.currentTarget.value.replaceAll('\0', '');
                                    setValue(`experiences.${index}.description`, e.currentTarget.value);
                                }
                            })}
                            isInvalid={!!errors?.experiences?.[index]?.description}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.experiences?.[index]?.description?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            {!client.isRecType &&
                <Row className="g-4">
                    <Col xs={12} sm={6} lg={userLang === localeCode.enUS ? 3 : 6}>
                        <FloatingLabel
                            controlId={`experiences.${index}.salaryStart`}
                            className="small"
                            label={t('IDS_START_SALARY')}
                        >
                            <Form.Control
                                type="text"
                                disabled={watchNoPreviousExp}
                                maxLength={10}
                                {...register(`experiences.${index}.salaryStart`)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm={6} lg={userLang === localeCode.enUS ? 3 : 6}>
                        <FloatingLabel
                            controlId={`experiences.${index}.salaryEnd`}
                            className="small"
                            label={t('IDS_END_SALARY')}
                        >
                            <Form.Control
                                type="text"
                                disabled={watchNoPreviousExp}
                                maxLength={10}
                                {...register(`experiences.${index}.salaryEnd`)}
                            />
                        </FloatingLabel>
                    </Col>
                    {userLang === localeCode.enUS &&
                        <Col xs={12} lg={6}>
                            <Alert variant="warning" className="d-flex align-items-center" style={{ paddingTop: '.625rem', paddingBottom: '.625rem' }}>
                                <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                <div className="smaller">{t('IDS_SALARY_NOTE')}</div>
                            </Alert>
                        </Col>
                    }
                </Row>
            }
        </div>
    );
}