import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import agent from '../../../app/api/agent';
import BsGrid from '../../../app/components/BsGrid';
import SelectColumnFilter from '../../../app/components/BsGrid/selectColumnFilter';
import TextColumnFilter from '../../../app/components/BsGrid/textColumnFilter';
import { BsModalConfirm } from '../../../app/components/BsModal';
import { ModalRecordMode } from '../../../app/enums/modalRecordMode';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { TblClientPosition } from '../../../app/models/dbTables/tblClientPosition';
import { useAppSelector } from '../../../app/store/storeHooks';
import { truncateString } from '../../../app/utility/util';
import { getTitle } from '../../../i18n';
import ModalPosition from './ModalPosition';

export default function PositionsPage() {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const [data, setData] = useState<TblClientPosition[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showModalPosition, setShowModalPosition] = useState(false);
    const [modalPositionMode, setModalPositionMode] = useState<ModalRecordMode>(ModalRecordMode.View);
    const [positionId, setPositionId] = useState(0);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<TblClientPosition>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current && changeCount >= 0) {
            setLoading(true);

            let gridParams = {
                pageSize,
                pageIndex,
                sortBy,
                filters
            };

            agent.ClientPosition.searchPositions(gridParams, { clientId })
                .then(positionData => {
                    setTotalRecords(positionData.total);
                    setData(positionData.positions);
                    setPageCount(Math.ceil(positionData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [clientId, changeCount]);

    const showPositionModal = (mode: ModalRecordMode, positionId: number) => {
        setModalPositionMode(mode);
        setPositionId(positionId);
        setShowModalPosition(true);
    }

    const showConfirmDelete = (positionId: number) => {
        setPositionId(positionId);
        setShowModalConfirmDelete(true);
    }

    const columns = useMemo<Column<TblClientPosition>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: `${t('IDS_POSITION_TITLE')}`,
                accessor: 'title',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<TblClientPosition>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => showPositionModal(ModalRecordMode.Edit, row.original.id)}
                        >
                            {row.original.title}
                        </Button>);
                }
            },
            {
                Header: `${t('IDS_ACTIVE')}`,
                accessor: 'active',
                // disableSortBy: true,
                Filter: (filterProps) => SelectColumnFilter(filterProps, [{text: t('IDS_YES'), value: 'true' }, {text: t('IDS_NO'), value: 'false' }], 'true', t('IDS_ACTIVE')),
                Cell: ({ row }: CellProps<TblClientPosition>) => {
                    return <>{row.original.active ? t('IDS_YES') : t('IDS_NO')}</>
                }
            },
            {
                Header: `${t('IDS_JOB_ID')}`,
                accessor: 'positionNumber',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_LOCATION')}`,
                accessor: 'location',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_DESCRIPTION')}`,
                id: 'description',
                accessor: (row) => truncateString(row.description, 40),
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_CITY')}`,
                accessor: 'city',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_STATE')}`,
                accessor: 'state',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: '',
                id: 'delete',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: CellProps<TblClientPosition>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 fs-inherit"
                            onClick={() => showConfirmDelete(row.original.id)}
                        >
                            {t('IDS_DELETE')}
                        </Button>
                    );
                }
            }
        ],
        [t]
    );

    return (
        <>
        <Helmet>
            <title>{getTitle(t, 'IDS_VIEW_POSITIONS')}</title>
        </Helmet>
        <div className="main-titlebar bg-lightest-ocean py-3">
            <span className="fs-5 line-height-100">{`${t('IDS_POSITIONS')} - ${clientSession?.vwClient.name}`}</span>
        </div>
        <div className="main-content bg-light-silver">
            <BsGrid
                addRecordFcn={() => showPositionModal(ModalRecordMode.Add, 0)}
                columns={columns}
                data={data}
                defaultSortBy={{ id: 'title', desc: false }}
                fetchData={fetchData}
                hiddenColumnIds={['id']}
                i18nKeys={{ itemName: 'IDS_POSITION', addItem: 'IDS_ADD_POSITION' }}
                loading={loading}
                pageCount={pageCount}
                totalRecords={totalRecords}
                defaultFilters={[{ id: 'active', value: { vals: [true], condition: 'equals' } }]}
            />
            <ModalPosition
                positionId={positionId}
                mode={modalPositionMode}
                showModal={showModalPosition}
                setShowModal={() => setShowModalPosition(false)}
                changeCount={changeCount}
                setChangeCount={setChangeCount}
            />
            <BsModalConfirm
                title={t('IDS_CONFIRM')}
                message={t('IDS_DELETE_POS_WARNING')}
                showModal={showModalConfirmDelete}
                setShowModal={setShowModalConfirmDelete}
                confirmCallback={async () => {
                    if (positionId && clientId) {
                        setShowModalConfirmDelete(false);
                        await agent.ClientPosition.removeClientPosition({ clientId, positionId });
                        setChangeCount(prevCount => prevCount + 1);
                    }
                }}
            />
            </div>
        </>
    );
}