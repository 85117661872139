export enum ApplicantTestProgress {
    Application = 0,
    Personality = 1,
    MentalAlertness = 2,
    BusinessTerms = 3,
    Vocabulary = 4,
    Perception = 5,
    MechanicalInterest = 6,
    AlphaSequence = 7,
    GraphicSequence = 8,
    MathWordProblems = 9,
    Complete = 10,
    AlphaSequence2 = 11,
    GraphicSequence2 = 12,
    MathWordProblems2 = 13
}