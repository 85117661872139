import { configureStore } from '@reduxjs/toolkit';

import accessibilitySettingsReducer from './accessibilitySlice';
import accountReducer from './accountSlice';
import clientReducer from './clientSlice';
import handledErrorReducer from './handledErrorSlice';
import localeReducer from './localeSlice';
import pdfDocumentReducer from './pdfDocumentSlice';
import testReducer from './testSlice';

export const store = configureStore({
    reducer: {
        handledError: handledErrorReducer,
        locale: localeReducer,
        accessibilitySettings: accessibilitySettingsReducer,
        account: accountReducer,
        clientSession: clientReducer,
        pdfDocument: pdfDocumentReducer,
        test: testReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;