import { DraggableProvided } from '@hello-pangea/dnd';
import { Button, Col, FloatingLabel, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faGripVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colorVars from '../../../../app/layout/styles/_colors.module.scss';

export interface CardProps {
    provided: DraggableProvided;
    item: Record<"id", string>;
    index: number;
    languageCode: string;
    remove: UseFieldArrayRemove;
}

export default function DefaultQuestionsCard({ provided, item, index, languageCode, remove }: CardProps) {
    const { t } = useTranslation();
    const { register, watch } = useFormContext();
    const watchType = watch(`defaultQuestions-${languageCode}[${index}].typeId`);
    
    return (
        <div
            className="mb-3"
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <input type="hidden" {...register(`defaultQuestions-${languageCode}[${index}].defaultQuestionId`)} />
            <InputGroup className="flex-nowrap">
                <InputGroup.Text style={{ cursor: 'move' }} {...provided.dragHandleProps} title="Draggable item">
                    <FontAwesomeIcon icon={faGripVertical} fixedWidth />
                </InputGroup.Text>
                <FloatingLabel
                    controlId={`defaultQuestions-${languageCode}[${index}].question`}
                    className="small flex-fill"
                    label={t('IDS_QUESTION')}
                >
                    <Form.Control
                        type="text"
                        maxLength={4000}
                        {...register(`defaultQuestions-${languageCode}[${index}].question`)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId={`defaultQuestions-${languageCode}[${index}].typeId`}
                    style={{ flex: 'unset', minWidth: 'unset', width: 'unset' }}
                    className="small"
                    label={t('IDS_TYPE')}
                >
                    <Form.Select
                        {...register(`defaultQuestions-${languageCode}[${index}].typeId`)}
                    >
                        <option value="2">I Agree</option>
                        <option value="3">Ranking</option>
                        <option value="4">Fill in the Blank</option>
                        <option value="5">Multiple Choice</option>
                        <option value="6">Yes/No</option>
                    </Form.Select>
                </FloatingLabel>
                <Button
                    variant="outline-eggplant"
                    onClick={() => remove(index)}
                    title={t('IDS_DELETE')}
                >
                    <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                </Button>
            </InputGroup>
            <Row
                xs={1} sm={2} md={3} xl={5} xxl={5}
                style={{
                    display: watchType === '5' ? 'flex' : 'none',
                    border: `1px solid ${colorVars.eggplant}`,
                    borderTopWidth: 0,
                    marginLeft: 'calc(1.5rem + 1em + 1px)',
                    marginRight: 'calc(1.5rem + 1em + 1px)'
                }}
                className="px-3 py-2 g-3 rounded-bottom"
            >
                {['A', 'B', 'C', 'D', 'E'].map(letter => (
                    <Col key={`defaultQuestions-${languageCode}[${index}].literal${letter}`}>
                        <InputGroup size="sm" className="flex-nowrap">
                            <InputGroup.Text id={`defaultQuestions-${languageCode}[${index}].literal${letter}-label`}>
                                {letter}
                            </InputGroup.Text>
                            <FormControl
                                id={`defaultQuestions-${languageCode}[${index}].literal${letter}`}
                                type="text"
                                maxLength={50}
                                aria-label={letter}
                                aria-describedby={`defaultQuestions-${languageCode}[${index}].literal${letter}-label`}
                                {...register(`defaultQuestions-${languageCode}[${index}].literal${letter}`)}
                            />
                        </InputGroup>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
