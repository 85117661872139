import i18n from '../../../../i18n';
import { QuestionType } from '../../../enums/testQuestionType';
import { DPATSectionId } from '../../../enums/testSections';
import { TestBase } from '../testBase';
import { dpatParseCorrectAnswers, DPATQuestion } from './dpatQuestion';
import { DPATSection } from './dpatSection';

export interface DPATTest extends TestBase<DPATSection> { }

const LoadSection = (nID: number, eID: DPATSectionId, strName: string, nNumQuestions: number,
    eDefType: QuestionType, nTime: number, nMaxMiddle: number,
    resources: { xml: Record<string, string>, img: Record<string, string> }, testTimeMultiplier: number): DPATSection => {
    let section1 = {
        id: eID,
        name: strName,
        questions: new Array(nNumQuestions),
        timeToComplete: (nTime > 0 && testTimeMultiplier > 1) ? (nTime * testTimeMultiplier) : nTime,
        maxMaybes: nMaxMiddle,
        instructions: ''
    }

    if (nMaxMiddle !== 0) {
        section1.instructions = resources.xml[`IDS_S${nID}_INSTRUCTIONS`].replace('{0}', `${nMaxMiddle}`);
    } else {
        section1.instructions = resources.xml[`IDS_S${nID}_INSTRUCTIONS`];
    }

    if (section1.timeToComplete > 0) {
        let min = Math.floor(section1.timeToComplete);
        let sec = Math.floor((section1.timeToComplete * 60) % 60);

        section1.instructions = section1.instructions.replace(
            '{TIME_TO_COMPLETE}',
            sec > 0
                ? `${min} ${i18n.t('translation:IDS_MINUTES')} ${i18n.t('translation:IDS_AND')} ${sec} ${i18n.t('translation:IDS_SECONDS')}`
                : `${min} ${i18n.t('translation:IDS_MINUTES')}`
        );
    }

    for (let num1 = 1; num1 <= nNumQuestions; num1++) {
        let text4 = `IDS_S${nID}Q${num1}`;
        let text5 = resources.xml[`${text4}Info`];
        let question1: DPATQuestion = {
            question: resources.xml[text4],
            image: '',
            questionType: eDefType,
            answers: [],
            imageAnswers: [],
            response: 0,
            correctAnswers: dpatParseCorrectAnswers(text5.substring(3))
        };
        if (question1.question === 'image') {
            question1.image = resources.img[`S${nID}_Q${num1}`];
        }
        let num2 = Number(text5.substring(0, 2));
        for (let num3 = 1; num3 <= num2; num3++) {
            let answer = resources.xml[`IDS_S${nID}Q${num1}A${num3}`];
            if (answer === 'image') {
                question1.imageAnswers.push(resources.img[`S${nID}_Q${num1}_A${num3}`]);
            } else {
                question1.answers.push(answer);
            }
        }
        section1.questions[num1-1] = question1;
    }
    return section1;
}

export const initDpat = (resources: { xml: Record<string, string>, img: Record<string, string> }, testTimeMultiplier: number): DPATTest => {
    let sections: DPATSection[] = [];
    sections.push(LoadSection(1, DPATSectionId.AlphaSequence, i18n.t('translation:IDS_ALPHA_SEQ'), 40, QuestionType.MultipleChoice, 15, 0, resources, testTimeMultiplier));
    sections.push(LoadSection(2, DPATSectionId.GraphicSequence, i18n.t('translation:IDS_GRAPHIC_SEQ'), 30, QuestionType.MultipleChoice, 15, 0, resources, testTimeMultiplier));
    sections.push(LoadSection(3, DPATSectionId.MathWordProblems, i18n.t('translation:IDS_MATH_WORD_PROBS'), 25, QuestionType.MultipleChoice, 30, 0, resources, testTimeMultiplier));
    return { name: '', instructions: '',  sections };
}