import { PropsWithChildren } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
    loading: boolean;
    disabled?: boolean;
}

export default function LoadingButton({ children, loading, disabled = false, ...rest }: PropsWithChildren<Props>) {
    const { t } = useTranslation();
    
    return (
        <Button {...rest} disabled={disabled}>
            {loading
                ? <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">{`${t('IDS_LOADING')}...`}</span>
                </>
                : children
            }
        </Button>
    );
}