import { NavigateFunction } from 'react-router-dom';

interface CustomHistory {
    navigate: NavigateFunction | null;
}

const History: CustomHistory = {
    navigate: null
};

export default History;