import { useEffect, useState } from 'react';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import CustomCloseBtn from '../../app/components/CustomCloseBtn';
import HelpTooltipIcon from '../../app/components/HelpTooltipIcon';
import LoadingButton from '../../app/components/LoadingButton';
import { MultiTextInput, stringToMultiTextInputOptions } from '../../app/components/MultiTextInput';
import { ModalRecordMode } from '../../app/enums/modalRecordMode';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { TblScheduledTask } from '../../app/models/dbTables/tblScheduledTask';
import { parseValidationMsg } from '../../i18n';
import { RegExps } from '../../app/utility/util';
import { localDate } from '../../app/utility/util';
import { formDataToScheduledTaskRequest, ScheduledTaskRequest } from '../../app/models/requestHelpers/scheduledTaskRequest';

interface Props{
    mode: ModalRecordMode;
    showModal: boolean;
    scheduledTaskId: number;
    setShowModal: (show: boolean) => void;
    changeCount: number;
    setChangeCount: (count: number) => void;
}

export default function ModalScheduledTask({ showModal, setShowModal, mode, scheduledTaskId, changeCount, setChangeCount }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object({
            taskName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_TASK_NAME'] }),
            assemblyName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_ASSEMBLY_NAME'] }),
            className: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CLASS_NAME'] }),
            cronInterval: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_CRON_INTERVAL'] }),
            owner: yup.array().min(1, { resKeys: ['IDS_IEP_REQUIRED', 'IDS_TASK_OWNER'] })
        }))
    }),
        { formState: { isValid, isSubmitting, isDirty, errors }, register, handleSubmit, control, reset, setValue } = formMethods;

    useEffect(() => {
        const fetchScheduledTask = async () => {
            try {
                let formData = {};

                if (showModal) {
                    setLoading(true);
                    
                    let scheduledTask: TblScheduledTask | null = null;
                    if (scheduledTaskId) {
                        scheduledTask = await agent.Admin.getScheduledTaskById({ scheduledTaskId });
                    }

                    formData = {
                        'scheduledTaskId': `${scheduledTaskId}`,
                        'taskName': `${scheduledTask?.name ?? ''}`,
                        'description': `${scheduledTask?.description ?? ''}`,
                        'assemblyName': `${scheduledTask?.assemblyName ?? ''}`,
                        'className': `${scheduledTask?.className ?? ''}`,
                        'argumentsString': stringToMultiTextInputOptions(scheduledTask?.argumentsString ?? ''),
                        'owner': stringToMultiTextInputOptions(scheduledTask?.owner ?? ''),
                        'cronInterval': `${scheduledTask?.cronInterval ?? ''}`,
                        'active': scheduledTask?.active ?? false,
                        'oneTimeOnly': scheduledTask?.oneTimeOnly ?? false,
                        'emailOnSuccess': scheduledTask?.emailOnSuccess ?? false,
                        'lastRun': localDate(scheduledTask?.lastRun ?? ''),
                        'nextRun': localDate(scheduledTask?.nextRun ?? '')
                    };
                }

                reset(formData);
            } catch (error) {
                console.log(error);
                setShowModal(false);
            } finally {
                setLoading(false);
            }
        }
        fetchScheduledTask();
    }, [reset, showModal, setShowModal, mode, scheduledTaskId, setValue]);

    async function submitForm(data: FieldValues) {
        try {
            let scheduledTaskRequest: ScheduledTaskRequest = formDataToScheduledTaskRequest(data);

            if (mode === ModalRecordMode.Edit) {
                await agent.Admin.updateScheduledTask(scheduledTaskRequest);
            } else if (mode === ModalRecordMode.Add) {
                await agent.Admin.addScheduledTask(scheduledTaskRequest);
            }

            setShowModal(false);
            setChangeCount(changeCount + 1);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    if (loading)
        return <LoadingComponent />
    else
        return (
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalScheduledTask"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <FormProvider {...formMethods}>
                    <Modal.Header>
                        <Modal.Title>{t(mode === ModalRecordMode.Add ? 'IDS_ADD_SCHEDULED_TASK' : 'IDS_EDIT_SCHEDULED_TASK')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                    </Modal.Header>
                    <Modal.Body className="bg-light-silver">
                        <Form id="formScheduledTask" onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                            <Row className="g-4">
                                <Col xs={12} lg={8}>
                                    <InputGroup className="flex-nowrap">
                                        <FloatingLabel
                                            controlId="taskName"
                                            className="small flex-fill"
                                            label={t('IDS_TASK_NAME')}
                                        >
                                            <Form.Control
                                                type="text"
                                                required
                                                {...register('taskName')}
                                                isInvalid={!!errors.taskName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {parseValidationMsg(errors?.taskName?.message, t)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="active"
                                            className="small label-flex"
                                            onClick={(e) => {
                                                if (e.target instanceof Element && !e.target.matches('input')) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            label={t('IDS_ACTIVE')}
                                        >
                                            <Form.Check
                                                type="switch"
                                                className="form-control text-center"
                                                style={{ width: '4.0rem' }}
                                                {...register('active', {})}
                                            />
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <FloatingLabel
                                        controlId="oneTimeOnly"
                                        className="small"
                                        onClick={(e) => {
                                            if (e.target instanceof Element && !e.target.matches('input')) {
                                                e.preventDefault();
                                            }
                                        }}
                                        label={t('IDS_ONE_TIME_ONLY')}
                                    >
                                        <Form.Check
                                            type="switch"
                                            className="form-control text-center"
                                            style={{ width: '7.2rem' }}
                                            {...register('oneTimeOnly', {})}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <FloatingLabel
                                        controlId="emailOnSuccess"
                                        className="small"
                                        onClick={(e) => {
                                            if (e.target instanceof Element && !e.target.matches('input')) {
                                                e.preventDefault();
                                            }
                                        }}
                                        label={t('IDS_EMAIL_ON_SUCCESS')}
                                    >
                                        <Form.Check
                                            type="switch"
                                            className="form-control text-center"
                                            style={{ width: '8.0rem' }}
                                            {...register('emailOnSuccess', {})}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12}>
                                    <FloatingLabel
                                        controlId="description"
                                        className="small"
                                        label={t('IDS_TASK_DESCRIPTION')}
                                    >
                                        <Form.Control
                                            as="textarea"
                                            style={{ height: '80px' }}
                                            {...register('description')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="assemblyName"
                                        className="small"
                                        label={t('IDS_ASSEMBLY_NAME')}
                                    >
                                        <Form.Control
                                            type="text"
                                            required
                                            {...register('assemblyName')}
                                            isInvalid={!!errors.assemblyName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.assemblyName?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="className"
                                        className="small"
                                        label={t('IDS_CLASS_NAME')}
                                    >
                                        <Form.Control
                                            type="text"
                                            required
                                            {...register('className')}
                                            isInvalid={!!errors.className}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.className?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="argumentsString"
                                        className="small"
                                        label={t('IDS_TASK_ARGUMENTS')}
                                    >
                                        <Controller
                                            name="argumentsString"
                                            control={control}
                                            render={({ field }) =>
                                                <MultiTextInput
                                                    maxItems={10}
                                                    inputId="argumentsString"
                                                    {...field}
                                                />
                                            }
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="email"
                                        className="small"
                                        label={
                                            <>
                                                <span>{t('IDS_CRON_INTERVAL')}</span>
                                                <HelpTooltipIcon
                                                    tooltipId="tooltipTaskCron"
                                                    tooltipText={t('IDS_HELP_TASK_CRON_MESSAGE')}
                                                    tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                                />
                                            </>
                                        }
                                    >
                                        <Form.Control
                                            type="text"
                                            required
                                            {...register('cronInterval')}
                                            isInvalid={!!errors.cronInterval}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.cronInterval?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel
                                        controlId="owner"
                                        className="small"
                                        label={
                                            <>
                                                <span>{t('IDS_TASK_OWNER')}</span>
                                                <HelpTooltipIcon
                                                    tooltipId="tooltipTaskOwner"
                                                    tooltipText={t('IDS_HELP_TASK_OWNERS_MESSAGE')}
                                                    tooltipTitle={t('IDS_HELP_GENERIC_TITLE')}
                                                />
                                            </>
                                        }
                                    >
                                        <Controller
                                            name="owner"
                                            control={control}
                                            render={({ field }) =>
                                                <MultiTextInput
                                                    maxItems={5}
                                                    required={true}
                                                    inputValidationMsg={t('IDS_IEP_REQUIRED', { 0: t('IDS_VALID_UNIQUE_EMAIL') })}
                                                    inputRegExp={RegExps.email}
                                                    inputId="owner"
                                                    {...field}
                                                />
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.owner?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <hr className="text-light-eggplant" />
                            <Row className="g-4" xs={1} lg={2} xl={3}>
                                <Col>
                                    <FloatingLabel
                                        controlId="lastRun"
                                        className="small flex-fill form-floating-plaintext"
                                        label={t('IDS_TASK_LAST_RUN')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('lastRun')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="nextRun"
                                        className="small flex-fill form-floating-plaintext"
                                        label={t('IDS_TASK_NEXT_RUN')}
                                    >
                                        <Form.Control
                                            readOnly
                                            {...register('nextRun')}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="silver" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                        <LoadingButton
                            form="formScheduledTask"
                            type="submit"
                            variant="dark-ocean"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || !isDirty || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t(mode === ModalRecordMode.Add ? 'IDS_ADD' : 'IDS_UPDATE')}
                        </LoadingButton>
                    </Modal.Footer>
                </FormProvider>
            </Modal>
        );
}