import { ReactElement } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import colorVars from '../layout/styles/_colors.module.scss';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    tooltipId: string;
    tooltipText: string | ReactElement;
    tooltipTitle: string;
    fontSizeInitial?: boolean;
    textAlignLeft?: boolean;
}

export default function HelpTooltipIcon({ tooltipId, tooltipText, tooltipTitle, fontSizeInitial = false, textAlignLeft = false }: Props) {
    return (
        <OverlayTrigger
            placement="auto"
            overlay={(props) =>
                <Tooltip
                    id={tooltipId}
                    className={`position-absolute ${textAlignLeft ? 'tooltip-text-start' : ''}`}
                    {...props}
                >
                    {tooltipText}
                </Tooltip>
            }
        >
            <Button className={`m-0 p-0 border border-0 bg-transparent tooltip-icon mx-2 ${fontSizeInitial ? 'fs-initial' : ''}`}>
                <FontAwesomeIcon icon={faQuestionCircle} color={colorVars.eggplant} />
                <span className="visually-hidden">{tooltipTitle}</span>
            </Button>
        </OverlayTrigger>
    );
}