import type {Blocker, History, Transition} from 'history';
import { ContextType, RefObject, useCallback, useContext, useEffect } from 'react';
import {
    Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext
} from 'react-router-dom';
import { TimerHandle } from '../../features/applicant/test/TestTimer';
import { updateApplicantProgress } from '../store/accountSlice';
import { useAppDispatch } from '../store/storeHooks';
import { TimeSpanMinVal } from './util';

interface Navigator extends BaseNavigator {
    block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator };

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when = true) {
    const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message: string | ((
    location: Transition['location'],
    action: Transition['action'],
) => string), when = true) {
    const blocker = useCallback(async (tx: Transition) => {
        let response;
        if (typeof message === 'function') {
            response = message(tx.location, tx.action);
            if (typeof response === 'string') {
                response = window.confirm(response);
            }
        } else {
            response = window.confirm(message);
        }
        if (response) {
            tx.retry();
        }
    }, [message]);
    return useBlocker(blocker, when);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function useTestPrompt(message: string | ((
    location: Transition['location'],
    action: Transition['action'],
) => string), timerRef: RefObject<TimerHandle>, when = true) {
    const dispatch = useAppDispatch();

    const blocker = useCallback(async (tx: Transition) => {
        let response;
        if (typeof message === 'function') {
            response = message(tx.location, tx.action);
            if (typeof response === 'string') {
                response = window.confirm(response);
            }
        } else {
            response = window.confirm(message);
        }
        if (response) {
            await dispatch(updateApplicantProgress({
                updateDb: true,
                timeRemaining: timerRef.current ? timerRef.current.getRemaining() : TimeSpanMinVal,
                hasStopped: true
            }));
            tx.retry();
        }
    }, [dispatch, message, timerRef]);
    return useBlocker(blocker, when);
}