
import { AchieverSectionId, DPATSectionId } from '../../enums/testSections';
import { TestType } from '../../enums/testType';
import { getRandomInt } from '../../utility/util';
import { AchieverSection } from './achiever/achieverSection';
import { AchieverTest } from './achiever/achieverTest';
import { DPATSection } from './dpat/dpatSection';
import { DPATTest } from './dpat/dpatTest';

export interface TestSession {
    achieverTest: AchieverTest | null;
    dpatTest: DPATTest | null;
    dpatInhTest: DPATTest | null;
    section?: AchieverSection | DPATSection | null;
    needTimer: boolean;
    reviewMiddleAnswers: boolean;
    testType: TestType;
    sectionId?: AchieverSectionId | DPATSectionId;
    pageNumber: number;
    questionsPerPage: number;
    totalQuestions: number;
    title?: string;
    isTimed: boolean;
    timeToComplete: number;
    instructions: string;
    randomizedQuestions: number[][];
    randomizedAnswers: number[][];
    middleAnswers: number[] | null;
    instructionsDisplayed: boolean;
};

export const createRandomIntArray = (nStart: number, nEnd: number, nTotalQuestions: number, fRandom: boolean): number[] => {
    //  Create an array of the ordered questions that will show up per page
    if (nEnd > nTotalQuestions) {
        nEnd = nTotalQuestions;
    }
    
    let anOrderedQuestions: number[] = new Array(nEnd - nStart + 1);
    let nCounter = 0;
    for (let i = nStart; (i < (nEnd + 1)); i++) {
        anOrderedQuestions[nCounter] = i;
        nCounter++;
    }
    
    if (!fRandom) {
        return anOrderedQuestions;
    }
    
    let anRandomQuestions: number[] = new Array(anOrderedQuestions.length);
    for (let i: number = 0; (i < anRandomQuestions.length); i++) {
        //  ex: if nIndex = 3, get the 3rd Question that hasn't been put into
        //  the random list
        let nIndex: number = getRandomInt(1, (anRandomQuestions.length - i + 1));
        nCounter = 1;
        for (let j = 0; (j < anOrderedQuestions.length); j++) {
            let fExists = false;
            for (let k = 0; (k < anRandomQuestions.length); k++) {
                if (anOrderedQuestions[j] === anRandomQuestions[k]) {
                    fExists = true;
                    break;
                }
            }
            
            if (!fExists) {
                if (nCounter === nIndex) {
                    anRandomQuestions[i] = anOrderedQuestions[j];
                    break;
                } else {
                    nCounter++;
                }
            }
        }
    }
    
    return anRandomQuestions;
}