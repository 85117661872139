import 'react-phone-number-input/style.css';

import React, { useEffect } from 'react';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';
import * as yup from 'yup';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import CustomCloseBtn from '../../app/components/CustomCloseBtn';
import LoadingButton from '../../app/components/LoadingButton';
import { FieldValuesTwoFactorAuth } from '../../app/models/fieldValueTypes';
import { UserDto } from '../../app/models/responseHelpers/userDto';
import { setUser } from '../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
import { defaultCountryOptionsOrder, defaultFlagUrl } from '../../app/utility/util';
import { parseValidationMsg } from '../../i18n';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const InputComponent = React.forwardRef<HTMLInputElement>((props, ref) => {
    return (
        <input
            id="phoneNumber"
            type="tel"
            maxLength={25}
            inputMode="tel"
            required
            ref={ref}
            {...props}
            style={{ border: 'none', appearance: 'none', boxShadow: 'none', outline: 'none' }}
        />
    );
});

export default function ModalEditMFAPhone({ showModal, setShowModal }: Props) {
    const { t } = useTranslation();
    const { user } = useAppSelector(state => state.account);
    const phoneNumber = user?.twoFactorAuthPhone ?? '';
    const dispatch = useAppDispatch();

    const { formState: { isValid, isDirty, isSubmitting, errors }, handleSubmit, reset, control } = useForm<FieldValuesTwoFactorAuth>({
        mode: 'all',
        defaultValues: {
            authCode: '',
            phoneNumber,
            updatePhoneOnly: true
        },
        resolver: yupResolver(yup.object({
            phoneNumber: yup.string().nullable()
                .required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_PHONE'] })
                .test('test-name', { resKeys: ['IDS_IEP_INVALID', 'IDS_PHONE'] }, (value) => isPossiblePhoneNumber(value ?? ''))
        }))
    });

    async function savePhoneSubmitForm(data: FieldValuesTwoFactorAuth) {
        try {
            const twoFactorAuthPhone = await agent.UserAccount.upsertUserTwoFactorAuth(data);
            dispatch(setUser({ ...user, twoFactorAuthPhone } as UserDto));
        } catch (error) {
            console.log(error);
        }
        setShowModal(false);
    }

    useEffect(() => {
        reset({
            authCode: '',
            phoneNumber,
            updatePhoneOnly: true
        });
    }, [showModal, reset, phoneNumber]);

    return (
        <div>
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalAddApplicant"
                centered={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>{t('IDS_EDIT_MFA_PHONE')}</Modal.Title>
                    <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Form id="formEditMFAPhone" onSubmit={handleSubmit(savePhoneSubmitForm)} className="needs-validation" noValidate>
                        <FloatingLabel
                            className={`small ${!!errors.phoneNumber ? 'phone-invalid' : ''}`}
                            label={t('IDS_PHONE')}
                            controlId="phoneNumber"
                        >
                            <PhoneInputWithCountrySelect
                                name="phoneNumber"
                                inputComponent={InputComponent}
                                control={control as any}
                                international
                                countryOptionsOrder={defaultCountryOptionsOrder}
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                flagUrl={defaultFlagUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {parseValidationMsg(errors?.phoneNumber?.message, t)}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="silver" onClick={() => setShowModal(false)}>
                        <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                    </Button>
                    <LoadingButton
                        form="formEditMFAPhone"
                        type="submit"
                        variant="dark-ocean"
                        className="mw-8rem"
                        loading={isSubmitting}
                        disabled={!isValid || !isDirty || isSubmitting}
                    >
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_UPDATE')}
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </div>
    );
}