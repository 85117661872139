import { TFunction } from 'i18next';

import { ApplicantTestProgress } from '../../enums/applicantTestProgress';
import { dateTimeIsMinVal, localDate, strMinDate } from '../../utility/util';
import { ApplicantItem } from '../applicantItem';
import { ClientSession } from '../clientSession';
import { ApplicantDto } from './applicantDto';
import { ApplicantProgressDto } from './applicantProgressDto';

export interface ApplicantWithProgressDto {
    applicant: ApplicantDto;
    progress: ApplicantProgressDto;
}

export function applicantWithProgressDtoToFormData({ applicant, progress }: ApplicantWithProgressDto, clientSession: ClientSession, t: TFunction) {
    let applicantItems: ApplicantItem[] = [];
    applicantItems.push({
        applicantId: applicant.applicantId,
        name: 'Application',
        text: t('IDS_APPLICATION'),
        tooltipText: t('IDS_HELP_CLIENTEDITAPPLICANTTESTONLY_MESSAGE'),
        tooltipTitle: t('IDS_HELP_CLIENTEDITAPPLICANTTESTONLY_TITLE'),
        pdfTooltipText: t('IDS_VIEW_APPLICATION'),
        order: applicant.applicationOrder,
        turnedOn: !applicant.testOnly,
        assignedText: (!dateTimeIsMinVal(applicant.applicationAddedDate)
            ? (!dateTimeIsMinVal(applicant.applicationActivatedDate)
                ? `${t('IDS_ACTIVATED')} ${localDate(applicant.applicationActivatedDate)}`
                : `${t('IDS_ASSIGNED')} ${localDate(applicant.applicationAddedDate)} (${applicant.applicationAddedBy})`)
            : ''),
        viewVisible: progress.progressId !== ApplicantTestProgress.Complete || clientSession.viewResults,
        viewEnabled: !dateTimeIsMinVal(progress.appCompletedDate) && clientSession.viewResults,
        disableToggle: !dateTimeIsMinVal(applicant.applicationActivatedDate)
    });
    if (clientSession.achiever) {
        applicantItems.push({
            applicantId: applicant.applicantId,
            name: 'Achiever',
            text: t('IDS_PERSONALITY_ASSESSMENT'),
            tooltipText: t('IDS_HELP_CLIENTEDITAPPLICANTACHIEVER_MESSAGE'),
            tooltipTitle: t('IDS_HELP_CLIENTEDITAPPLICANTACHIEVER_TITLE'),
            pdfTooltipText: t('IDS_VIEW_ASSESSMENT'),
            order: applicant.achieverOrder,
            turnedOn: applicant.achiever,
            assignedText: (!dateTimeIsMinVal(applicant.applicationAddedDate)
                ? (!dateTimeIsMinVal(applicant.applicationActivatedDate)
                    ? `${t('IDS_ACTIVATED')} ${localDate(applicant.applicationActivatedDate)}`
                    : `${t('IDS_ASSIGNED')} ${localDate(applicant.applicationAddedDate)} (${applicant.applicationAddedBy})`)
                : ''),
            viewVisible: applicant.achiever && clientSession.viewResults && clientSession.achiever,
            viewEnabled: !dateTimeIsMinVal(progress.test6CompletedDate) && clientSession.viewResults,
            disableToggle: !dateTimeIsMinVal(applicant.achieverStartedDate)
        });
    }
    if (clientSession.dpat) {
        applicantItems.push({
            applicantId: applicant.applicantId,
            name: 'Aptitudetest',
            text: t('IDS_APTITUDE_TEST'),
            tooltipText: t('IDS_HELP_CLIENTEDITAPPLICANTDPAT_MESSAGE'),
            tooltipTitle: t('IDS_HELP_CLIENTEDITAPPLICANTDPAT_TITLE'),
            pdfTooltipText: t('IDS_VIEW_DPAT'),
            order: applicant.aptitudeTestOrder,
            turnedOn: applicant.dpat,
            assignedText: (!dateTimeIsMinVal(applicant.applicationAddedDate)
                ? (!dateTimeIsMinVal(applicant.applicationActivatedDate)
                    ? `${t('IDS_ACTIVATED')} ${localDate(applicant.applicationActivatedDate)}`
                    : `${t('IDS_ASSIGNED')} ${localDate(applicant.applicationAddedDate)} (${applicant.applicationAddedBy})`)
                : ''),
            viewVisible: applicant.dpat && clientSession.viewResults && clientSession.dpat,
            viewEnabled: !dateTimeIsMinVal(progress.dpat3CompletedDate) && clientSession.viewResults,
            disableToggle: !dateTimeIsMinVal(applicant.dpatStartedDate)
        });
    }
    if (clientSession.dpatInhouse) {
        applicantItems.push({
            applicantId: applicant.applicantId,
            name: 'DpatInhouse',
            text: t('IDS_APTITUDE_INHOUSE'),
            tooltipText: t('IDS_HELP_CLIENTEDITAPPLICANTDPATINHOUSE_MESSAGE'),
            tooltipTitle: t('IDS_HELP_CLIENTEDITAPPLICANTDPATINHOUSE_TITLE'),
            pdfTooltipText: t('IDS_VIEW_INH_DPAT'),
            order: applicant.dpatInhouseOrder,
            turnedOn: applicant.dpatInhouse,
            assignedText: (!dateTimeIsMinVal(applicant.applicationAddedDate)
                ? (!dateTimeIsMinVal(applicant.applicationActivatedDate)
                    ? `${t('IDS_ACTIVATED')} ${localDate(applicant.applicationActivatedDate)}`
                    : `${t('IDS_ASSIGNED')} ${localDate(applicant.applicationAddedDate)} (${applicant.applicationAddedBy})`)
                : ''),
            viewVisible: applicant.dpatInhouse && clientSession.viewResults && clientSession.dpatInhouse,
            viewEnabled: !dateTimeIsMinVal(progress.dpatInhouse3CompletedDate) && clientSession.viewResults,
            disableToggle: !dateTimeIsMinVal(applicant.dpatInhouseStartedDate)
        });
    }
    applicantItems.sort((a, b) => (a.order > b.order) ? 1 : -1);

    // Progress
    let progressItems: { text: string, status: string }[] = [
        {
            text: t('IDS_APPLICATION'),
            status: !dateTimeIsMinVal(progress.appCompletedDate)
                ? localDate(progress.appCompletedDate)
                : (progress.progressId === ApplicantTestProgress.Application && !applicant.testOnly && !dateTimeIsMinVal(applicant.lastLoginDate))
                    ? t('IDS_IN_PROGRESS')
                    : applicant.testOnly
                        ? t('IDS_TEST_ONLY')
                        : t('IDS_NOT_TAKEN')
        }
    ];
    if (applicant.achiever) {
        progressItems.push(
            {
                text: t('IDS_PERSONALITY'),
                status: !dateTimeIsMinVal(progress.test1CompletedDate)
                    ? localDate(progress.test1CompletedDate)
                    : (!dateTimeIsMinVal(progress.test1StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_MENTAL_ALERTNESS'),
                status: !dateTimeIsMinVal(progress.test2CompletedDate)
                    ? localDate(progress.test2CompletedDate)
                    : (!dateTimeIsMinVal(progress.test2StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_BUSINESS_TERMS'),
                status: !dateTimeIsMinVal(progress.test3CompletedDate)
                    ? localDate(progress.test3CompletedDate)
                    : (!dateTimeIsMinVal(progress.test3StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_VOCABULARY'),
                status: !dateTimeIsMinVal(progress.test4CompletedDate)
                    ? localDate(progress.test4CompletedDate)
                    : (!dateTimeIsMinVal(progress.test4StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_PERCEPTION'),
                status: !dateTimeIsMinVal(progress.test5CompletedDate)
                    ? localDate(progress.test5CompletedDate)
                    : (!dateTimeIsMinVal(progress.test5StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_MECHANICAL_INTEREST'),
                status: !dateTimeIsMinVal(progress.test6CompletedDate)
                    ? localDate(progress.test6CompletedDate)
                    : (!dateTimeIsMinVal(progress.test6StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            }
        );
    }
    if (applicant.dpat) {
        progressItems.push(
            {
                text: t('IDS_ALPHA_SEQ'),
                status: !dateTimeIsMinVal(progress.dpat1CompletedDate)
                    ? localDate(progress.dpat1CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpat1StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_GRAPHIC_SEQ'),
                status: !dateTimeIsMinVal(progress.dpat2CompletedDate)
                    ? localDate(progress.dpat2CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpat2StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_MATH_WORD_PROBS'),
                status: !dateTimeIsMinVal(progress.dpat3CompletedDate)
                    ? localDate(progress.dpat3CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpat3StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            }
        );
    }
    if (applicant.dpatInhouse) {
        progressItems.push(
            {
                text: t('IDS_ALPHA_SEQ'),
                status: !dateTimeIsMinVal(progress.dpatInhouse1CompletedDate)
                    ? localDate(progress.dpatInhouse1CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpatInhouse1StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_GRAPHIC_SEQ'),
                status: !dateTimeIsMinVal(progress.dpatInhouse2CompletedDate)
                    ? localDate(progress.dpatInhouse2CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpatInhouse2StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            },
            {
                text: t('IDS_MATH_WORD_PROBS'),
                status: !dateTimeIsMinVal(progress.dpatInhouse3CompletedDate)
                    ? localDate(progress.dpatInhouse3CompletedDate)
                    : (!dateTimeIsMinVal(progress.dpatInhouse3StartedDate))
                        ? t('IDS_IN_PROGRESS')
                        : t('IDS_NOT_TAKEN')
            }
        );
    }


    // DPAT Audit - time elapsed
    let dpatTime = t('IDS_NA');
    if (progress.dpat1StartedDate && !dateTimeIsMinVal(progress.dpat1StartedDate)) {
        let dtLastDpat = new Date(strMinDate);
        if (progress.dpat3CompletedDate && !dateTimeIsMinVal(progress.dpat3CompletedDate)) {
            dtLastDpat = new Date(progress.dpat3CompletedDate);
        } else {
            dtLastDpat = new Date();
        }

        let seconds = Math.floor((dtLastDpat.getTime() - (new Date(progress.dpat1StartedDate)).getTime()) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        dpatTime = `${hours} ${t('IDS_HOURS')}, ${minutes} ${t('IDS_MINUTES')}, ${t('IDS_AND')} ${seconds} ${t('IDS_SECONDS')}`;

        if (days > 0) {
            dpatTime = `${days} ${t('IDS_DAYS')}, ${dpatTime}`;
        }
    }

    // DPAT Inhouse Audit - time elapsed
    let dpatInhouseTime = t('IDS_NA');
    if (progress.dpatInhouse1StartedDate && !dateTimeIsMinVal(progress.dpatInhouse1StartedDate)) {
        let dtLastDpatInhouse = new Date(strMinDate);
        if (progress.dpatInhouse3CompletedDate && !dateTimeIsMinVal(progress.dpatInhouse3CompletedDate)) {
            dtLastDpatInhouse = new Date(progress.dpatInhouse3CompletedDate);
        } else {
            dtLastDpatInhouse = new Date();
        }

        let seconds = Math.floor((dtLastDpatInhouse.getTime() - (new Date(progress.dpatInhouse1StartedDate)).getTime()) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        dpatInhouseTime = `${hours} ${t('IDS_HOURS')}, ${minutes} ${t('IDS_MINUTES')}, ${t('IDS_AND')} ${seconds} ${t('IDS_SECONDS')}`;

        if (days > 0) {
            dpatInhouseTime = `${days} ${t('IDS_DAYS')}, ${dpatInhouseTime}`;
        }
    }

    return {
        'id': applicant?.applicantId ?? '',
        'firstName': applicant?.firstName ?? '',
        'lastName': applicant?.lastName ?? '',
        'username': applicant?.username ?? '',
        'email': applicant?.email ?? '',
        'positionTitle': applicant?.positionTitle ?? '',
        'positionId': applicant?.positionId ?? '',
        'currentPosition': applicant?.positionId ?? '',
        'lastLogin': (!dateTimeIsMinVal(applicant?.lastLoginDate)
            ? localDate(applicant?.lastLoginDate)
            : t('IDS_NA')),
        'addedBy': `${applicant?.addedBy ?? t('IDS_NA')} ${localDate(applicant?.addedDate)}`,
        'lastModifiedBy': `${applicant.modifiedBy ?? t('IDS_NA')} ${localDate(applicant?.modifiedDate)}`,
        'orderDisabled': (!dateTimeIsMinVal(applicant.applicationActivatedDate)
            || !dateTimeIsMinVal(applicant.achieverActivatedDate)
            || !dateTimeIsMinVal(applicant.dpatActivatedDate)
            || !dateTimeIsMinVal(applicant.dpatInhouseActivatedDate)),
        'fMoreTestsToGive': '',
        'dpatAuditVisible': applicant.dpat,
        'dpatTime': dpatTime,
        'dpatStops': `${progress.dpatStopCount}`,
        'dpatInhouseAuditVisible': applicant.dpatInhouse,
        'dpatInhouseTime': dpatInhouseTime,
        'dpatInhouseStops': `${progress.dpatInhouseStopCount}`,
        'locked': applicant.locked,
        'unlockLog': applicant.unlockLog,
        'specialAccommodations': applicant.testTimeMultiplier > 1.0,
        ...{ applicantItems },
        ...{ progressItems }
    };
}