import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';

import { getLangOrDefault, getTz } from '../../i18n';
import { UserDto } from '../models/responseHelpers/userDto';
import { setUser } from '../store/accountSlice';
import { setError } from '../store/handledErrorSlice';
import axiosInstance from './axiosInstance';

type Handler = {
	request: AxiosRequestConfig;
	resolver(value: AxiosRequestConfig): void;
};

type ConcurrencyManagerInstance = {
	queue: Handler[];
	running: Handler[];
	shiftInitial(): void;
	push(reqHandler: Handler): void;
	shift(): void;
	requestHandler(req: AxiosRequestConfig): Promise<AxiosRequestConfig>;
	responseHandler(res: AxiosResponse): AxiosResponse;
	responseErrorHandler(res: AxiosError): void;
};

const setUpInterceptor = (store: any): ConcurrencyManagerInstance => {
    let instance: ConcurrencyManagerInstance = {
        queue: [],
        running: [],
        shiftInitial: () => {
            setTimeout(() => {
                if (instance.running.length < 1) {
                    instance.shift();
                }
            }, 0);
        },
        push: (reqHandler) => {
            instance.queue.push(reqHandler);
            instance.shiftInitial();
        },
        shift: () => {
            if (instance.queue.length) {
                const queued = instance.queue.shift();

                if (queued) {
                    queued.request.headers!.UserLanguage = getLangOrDefault();
                    queued.request.headers!.UserTimeZone = getTz();
                    queued.request.headers!.UserBrowserAgent = navigator.userAgent;
                    queued.request.headers!.ClientHostName = window.location.hostname;

                    const token = store.getState().account.user?.token;
                    if (token) queued.request.headers!.Authorization = `Bearer ${token}`;

                    const reCaptchaToken = sessionStorage.getItem('reCaptchaToken');
                    if (reCaptchaToken) queued.request.headers!.ReCaptchaToken = reCaptchaToken;

                    queued.resolver(queued.request);
                    instance.running.push(queued);
                }
            }
        },
        // Use as interceptor. Queue outgoing requests
        requestHandler: (req) => {
            return new Promise(resolve => {
                instance.push({ request: req, resolver: resolve });
            });
        },
        // Use as interceptor. Execute queued request upon receiving a response
        responseHandler: (res) => {
            if (res && res.headers && res.headers['renewedtoken']) {
                const user = cloneDeep<UserDto>(store.getState().account.user);
                user.token = res.headers['renewedtoken'];
                store.dispatch(setUser(user));
            }
            instance.running.shift();
            instance.shift();
            return res;
        },
        responseErrorHandler: (res) => {
            if (res.response) {
                const { data, status, statusText } = res.response;

                let redirectToLogout = (status === 401 && res.response.headers && res.response.headers['tokenexpired'] === 'true');
                store.dispatch(setError({ data, status, statusText, redirectToLogout }));

                return Promise.reject(instance.responseHandler(res.response));
            } else {
                store.dispatch(setError({ data: 'IDS_NETWORK_ERROR', status: 0, statusText: '', redirectToLogout: false }));
            }
        }
    };
    // queue concurrent requests
    axiosInstance.interceptors.request.use(
        instance.requestHandler
    );
    axiosInstance.interceptors.response.use(
        instance.responseHandler,
        instance.responseErrorHandler,
    );
    return instance;
};

export default setUpInterceptor;