import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import agent from '../../../app/api/agent';
import BsGrid from '../../../app/components/BsGrid';
import TextColumnFilter from '../../../app/components/BsGrid/textColumnFilter';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { TblClientPosition } from '../../../app/models/dbTables/tblClientPosition';
import { useAppSelector } from '../../../app/store/storeHooks';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

export default function ModalPositionLookup({ showModal, setShowModal }: Props) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const { setValue } = useFormContext();
    const [data, setData] = useState<TblClientPosition[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<TblClientPosition>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
            setLoading(true);

            let gridParams = {
                pageSize,
                pageIndex,
                sortBy,
                filters
            };
            if (!gridParams.filters.some(x => x.id === 'active')) {
                gridParams.filters.push({ id: 'active', value: { vals: [true], condition: 'equals' } });
            }

            agent.ClientPosition.searchPositions(gridParams, { clientId })
                .then(positionData => {
                    setTotalRecords(positionData.total);
                    setData(positionData.positions);
                    setPageCount(Math.ceil(positionData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [clientId]);

    const columns = useMemo<Column<TblClientPosition>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: `${t('IDS_POSITION_TITLE')}`,
                accessor: 'title',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<TblClientPosition>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => {
                                setValue('positionTitle', row.original.title, { shouldValidate: true });
                                setValue('positionId', row.original.id);
                                setShowModal(false);
                            }}
                        >
                            {row.original.title}
                        </Button>);
                }
            },
            {
                Header: `${t('IDS_JOB_ID')}`,
                accessor: 'positionNumber',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_LOCATION')}`,
                accessor: 'location',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_DESCRIPTION')}`,
                accessor: 'description',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_CITY')}`,
                accessor: 'city',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_STATE')}`,
                accessor: 'state',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            }
        ],
        [t, setShowModal, setValue]
    );

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={() => setShowModal(false)}
            id="modalPositionLookup"
            centered={true}
            backdrop="static"
            size="xl"
        >
            <Modal.Header>
                <Modal.Title>{t('IDS_POSITIONS')}</Modal.Title>
                <CustomCloseBtn hideFcn={() => setShowModal(false)} />
            </Modal.Header>
            <Modal.Body>
                <BsGrid
                    columns={columns}
                    data={data}
                    defaultSortBy={{ id: 'title', desc: false }}
                    fetchData={fetchData}
                    hiddenColumnIds={['id']}
                    i18nKeys={{ itemName: 'IDS_POSITION' }}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                />
            </Modal.Body>
        </Modal>
    );
}

interface PropsAlt extends Props {
    setPositionText: (text: string) => void;
    setPositionFilter: (filter: any) => void;
}

export function ModalPositionLookupAlt({ showModal, setShowModal, setPositionText, setPositionFilter }: PropsAlt) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const clientId = clientSession?.vwClient.id ?? null;
    const [data, setData] = useState<TblClientPosition[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<TblClientPosition>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
            setLoading(true);

            let gridParams = {
                pageSize,
                pageIndex,
                sortBy,
                filters
            };
            if (!gridParams.filters.some(x => x.id === 'active')) {
                gridParams.filters.push({ id: 'active', value: { vals: [true], condition: 'equals' } });
            }

            agent.ClientPosition.searchPositions(gridParams, { clientId })
                .then(positionData => {
                    setTotalRecords(positionData.total);
                    setData(positionData.positions);
                    setPageCount(Math.ceil(positionData.total / pageSize));
                })
                .finally(() => setLoading(false));
        }
    }, [clientId]);

    const columns = useMemo<Column<TblClientPosition>[]>(
        () => [
            {
                accessor: 'id',
                disableFilters: true
            },
            {
                Header: `${t('IDS_POSITION_TITLE')}`,
                accessor: 'title',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<TblClientPosition>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 text-nowrap fs-inherit"
                            onClick={() => {
                                setPositionText(row.original.title);
                                setPositionFilter({ vals: [row.original.title], condition: 'includes' });
                                setShowModal(false);
                            }}
                        >
                            {row.original.title}
                        </Button>);
                }
            },
            {
                Header: `${t('IDS_JOB_ID')}`,
                accessor: 'positionNumber',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_LOCATION')}`,
                accessor: 'location',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_DESCRIPTION')}`,
                accessor: 'description',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_CITY')}`,
                accessor: 'city',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_STATE')}`,
                accessor: 'state',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            }
        ],
        [t, setPositionText, setPositionFilter, setShowModal]
    );

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={() => setShowModal(false)}
            id="modalPositionLookup"
            centered={true}
            backdrop="static"
            size="xl"
        >
            <Modal.Header>
                <Modal.Title>{t('IDS_POSITIONS')}</Modal.Title>
                <CustomCloseBtn hideFcn={() => setShowModal(false)} />
            </Modal.Header>
            <Modal.Body>
                <BsGrid
                    columns={columns}
                    data={data}
                    defaultSortBy={{ id: 'title', desc: false }}
                    fetchData={fetchData}
                    hiddenColumnIds={['id']}
                    i18nKeys={{ itemName: 'IDS_POSITION' }}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                />
            </Modal.Body>
        </Modal>
    );
}