import { getLangOrDefault } from '../../../i18n';
import { ApplicantTestProgress } from '../../enums/applicantTestProgress';
import { ApplicantProgressDto } from '../responseHelpers/applicantProgressDto';

export interface ApplicantProgressRequest {
    applicantId: number;
    progressId: number;
    question: number;
    section: number;
    response: string;
    dPATResponse: string;
    dPATInhouseResponse: string;
    startedDate: string | null;
    timeRemaining: number;
    clientTimeElapsed: number;
    serverTimeElapsed: number;
    completeApplication: boolean;
    hasStopped: boolean;
    removeApplicant: boolean;
    removedBy: string;
    clientId: number;
    positionId: number;
    emailTo: string;
    oldProgressId: number;
    oldLastPage: number;
    oldSectionId: number;
    oldTimeRemaining: number;
    oldClientTime: number;
    oldServerTime: number;
    languageCode: string;
}

export const applicantProgressDtoToRequest = (appProgressDto: ApplicantProgressDto, clientId: number,
    positionId: number, completeApplication: boolean, hasStopped: boolean, oldProgressId?: number, oldLastPage?: number,
    oldSectionId?: number, oldTimeRemaining?: number, oldClientTime?: number, oldServerTime?: number) => {
    let startedDate: string | null = null;
    if (appProgressDto.progressId === ApplicantTestProgress.Personality)
        startedDate = appProgressDto.test1StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.MentalAlertness)
        startedDate = appProgressDto.test2StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.BusinessTerms)
        startedDate = appProgressDto.test3StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.Vocabulary)
        startedDate = appProgressDto.test4StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.Perception)
        startedDate = appProgressDto.test5StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.MechanicalInterest)
        startedDate = appProgressDto.test6StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.AlphaSequence)
        startedDate = appProgressDto.dpat1StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.GraphicSequence)
        startedDate = appProgressDto.dpat2StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.MathWordProblems)
        startedDate = appProgressDto.dpat3StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.AlphaSequence2)
        startedDate = appProgressDto.dpatInhouse1StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.GraphicSequence2)
        startedDate = appProgressDto.dpatInhouse2StartedDate;
    else if (appProgressDto.progressId === ApplicantTestProgress.MathWordProblems2)
        startedDate = appProgressDto.dpatInhouse3StartedDate;
        
    let request: ApplicantProgressRequest = {
        applicantId: appProgressDto.applicantId,
        progressId: appProgressDto.progressId,
        question: appProgressDto.lastPage,
        section: appProgressDto.sectionId,
        response: appProgressDto.response,
        startedDate,
        dPATResponse: appProgressDto.dpatResponse,
        dPATInhouseResponse: appProgressDto.dpatInhouseResponse,
        timeRemaining: appProgressDto.remainingTimeValue,
        clientTimeElapsed: appProgressDto.clientTimeElapsedValue,
        serverTimeElapsed: appProgressDto.serverTimeElapsedValue,
        completeApplication,
        hasStopped,
        removeApplicant: false,
        removedBy: 'PROCESS_COMPLETE',
        clientId,
        positionId,
        emailTo: '',
        oldProgressId: oldProgressId ?? appProgressDto.progressId,
        oldLastPage: oldLastPage ?? appProgressDto.lastPage,
        oldSectionId: oldSectionId ?? appProgressDto.sectionId,
        oldTimeRemaining: oldTimeRemaining ?? appProgressDto.remainingTimeValue,
        oldClientTime: oldClientTime ?? appProgressDto.clientTimeElapsedValue,
        oldServerTime: oldServerTime ?? appProgressDto.serverTimeElapsedValue,
        languageCode: getLangOrDefault(),
    };

    return request;
}