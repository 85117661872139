import { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FilterProps } from 'react-table';

import { TextValuePair } from '../../models/textValuePair';

export default function SelectColumnFilter<T extends object>({ column: { setFilter, render } }: FilterProps<T>, options: TextValuePair[], defaultValue: string, title: string) {
    const [selectedValue, setSelectedValue] = useState<string>(defaultValue);
    const { t } = useTranslation();

    return (
        <Form.Select
            className="form-select-xs w-100"
            value={selectedValue}
            style={{ width: '100%' }}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setSelectedValue(e.target.value);
                setFilter(e.target.value ? { vals: [e.target.value], condition: 'equals' } : undefined)
            }}
            title={render('Header')?.toString() + ' filter'}
        >
            <option value="">{t('IDS_ALL')}</option>
            {options.map(option => (
                <option key={option.text} value={option.value}>
                    {option.text}
                </option>
            ))}
        </Form.Select>
    );
}