import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Button } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DefaultQuestionsCard from './DefaultQuestionsCard';

interface Props {
    languageCode: string;
}

export default function DefaultQuestionsContainer({ languageCode }: Props) {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const { fields, append, move, remove } = useFieldArray({ control, name: `defaultQuestions-${languageCode}` });

    const handleDrag: OnDragEndResponder = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId={`defaultQuestions-${languageCode}`}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {fields.map((item, index) => {
                                return (
                                    <Draggable
                                        key={`defaultQuestions-${languageCode}[${index}]`}
                                        draggableId={`item-${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <DefaultQuestionsCard
                                                provided={provided}
                                                item={item}
                                                index={index}
                                                languageCode={languageCode}
                                                remove={remove}
                                            />
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Button
                variant="link"
                className="link-dark-ocean"
                onClick={() => { append({ defaultQuestionId: '0', question: '', typeId: '2' }); }}
            >
                {t('IDS_ADD_QUESTION')}<FontAwesomeIcon icon={faPlus} className="ms-2" />
            </Button>
        </>
    );
}
