import React, {
    EffectCallback, ReactElement, SyntheticEvent, useEffect, useLayoutEffect, useRef, useState
} from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { transType } from '../../i18next';

interface ScrollBtnState {
    disabled: boolean;
    display: string;
}

interface TabsElScrollState {
    parentOffsetWidth: number;
    offsetWidth: number;
    scrollWidth: number;
    scrollLeft: number;
}

interface Props {
    tabs: BsTabs;
    navTabsClassName?: string;
}
export interface BsTabs {
    [key: string]: { linkText: string, isInvalid?: boolean, paneComponent: ReactElement, disable?: boolean }
}

export default function BsScrollableTabs({ tabs, navTabsClassName = '' }: Props) {
    const tabsElRef = useRef<HTMLDivElement>(null);
    const [leftScrollBtnState, setLeftScrollBtnState] = useState<ScrollBtnState>({ disabled: true, display: 'block' });
    const [rightScrollBtnState, setRightScrollBtnState] = useState<ScrollBtnState>({ disabled: false, display: 'block' });
    const [tabsElScrollState, setTabsElScrollState] = useState<TabsElScrollState | null>(null);
    const { t } = useTranslation();

    const handleTabsElScroll = (e: React.UIEvent<HTMLDivElement>) => {
        let el = e.currentTarget;
        if (el && el.parentElement) {
            setTabsElScrollState({
                parentOffsetWidth: el.parentElement.offsetWidth,
                offsetWidth: el.offsetWidth,
                scrollWidth: el.scrollWidth,
                scrollLeft: el.scrollLeft
            });
        }
    }
    
    const handleScrollBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        let navTabs = e.currentTarget.parentElement?.querySelector<HTMLDivElement>('.nav-tabs');

        if (navTabs) {
            if (e.currentTarget.dataset['scrollDir'] === 'left') {
                navTabs.scrollBy({ behavior: 'smooth', left: -100 });
            } else if (e.currentTarget.dataset['scrollDir'] === 'right') {
                navTabs.scrollBy({ behavior: 'smooth', left: 100 });
            }
        }
    };
    
    const handleOnSelect = (eventKey: string | null, e: SyntheticEvent<unknown, Event>) => {
        let tabLink = e.currentTarget as HTMLAnchorElement;
        tabLink.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };
    
    useLayoutEffect(() => {
        if (tabsElRef.current && tabsElRef.current.parentElement) {
            setTabsElScrollState({
                parentOffsetWidth: tabsElRef.current.parentElement.offsetWidth,
                offsetWidth: tabsElRef.current.offsetWidth,
                scrollWidth: tabsElRef.current.scrollWidth,
                scrollLeft: tabsElRef.current.scrollLeft
            });

            let activeTabLink = tabsElRef.current.querySelector('.nav-link.active') as HTMLAnchorElement;
            if (activeTabLink) {
                activeTabLink.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, []);

    useEffect(() => {
        if (tabsElScrollState != null) {
            setLeftScrollBtnState({
                disabled: tabsElScrollState.scrollLeft === 0,
                display: tabsElScrollState.parentOffsetWidth < tabsElScrollState.scrollWidth ? 'block' : 'none'
            });
            setRightScrollBtnState({
                disabled: tabsElScrollState.scrollLeft + tabsElScrollState.offsetWidth === tabsElScrollState.scrollWidth,
                display: tabsElScrollState.parentOffsetWidth < tabsElScrollState.scrollWidth ? 'block' : 'none'
            });
        }
    }, [tabsElScrollState]);

    useEffect((): ReturnType<EffectCallback> => {
        const handleResize = () => {
            if (tabsElRef.current && tabsElRef.current.parentElement) {
                setTabsElScrollState({
                    parentOffsetWidth: tabsElRef.current.parentElement.offsetWidth,
                    offsetWidth: tabsElRef.current.offsetWidth,
                    scrollWidth: tabsElRef.current.scrollWidth,
                    scrollLeft: tabsElRef.current.scrollLeft
                });
            }
        };
        const handleTabClick = (e: MouseEvent) => {
            if (tabsElRef.current && tabsElRef.current.parentElement && e.target && (e.target as Element).matches('a.nav-link')) {
                setTabsElScrollState({
                    parentOffsetWidth: tabsElRef.current.parentElement.offsetWidth,
                    offsetWidth: tabsElRef.current.offsetWidth,
                    scrollWidth: tabsElRef.current.scrollWidth,
                    scrollLeft: tabsElRef.current.scrollLeft
                });

                let activeTabLinks = tabsElRef.current.querySelectorAll<HTMLAnchorElement>('.tab-pane.active .nav-link.active');
                if (activeTabLinks && activeTabLinks.length > 0) {
                    setTimeout(() => activeTabLinks.forEach(el => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })), 500);
                }
            }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleTabClick);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleTabClick);
        };
    });
      
    return (
        <div className="d-flex align-items-center">
            <Button
                variant="transparent"
                className="user-select-none p-1 me-2"
                data-scroll-dir="left"
                onClick={handleScrollBtnClick}
                disabled={leftScrollBtnState.disabled}
                style={{ display: `${leftScrollBtnState.display}` }}
            >
                <FontAwesomeIcon icon={faChevronLeft} className="text-dark-ocean" fixedWidth />
                <span className="visually-hidden">{t('IDS_SCROLL_LEFT')}</span>
            </Button>
            <Nav
                variant="tabs"
                className={navTabsClassName}
                ref={tabsElRef}
                onScroll={handleTabsElScroll}
                onSelect={handleOnSelect}
            >
                {Object.keys(tabs).map((key) => (
                    <Nav.Item key={`${key}NavItem`}>
                        <Nav.Link
                            eventKey={key}
                            className={tabs[key].isInvalid ? 'nav-link-invalid' : ''}
                            disabled={tabs[key].disable ?? false}
                        >
                            {t(tabs[key].linkText as keyof transType)}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <Button
                variant="transparent"
                className="user-select-none p-1 ms-2"
                data-scroll-dir="right"
                onClick={handleScrollBtnClick}
                disabled={rightScrollBtnState.disabled}
                style={{ display: `${rightScrollBtnState.display}` }}
            >
                <FontAwesomeIcon icon={faChevronRight} className="text-dark-ocean" fixedWidth />
                <span className="visually-hidden">{t('IDS_SCROLL_LEFT')}</span>
            </Button>
        </div>
    );
}