import { cloneDeep } from 'lodash';

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import agent from '../api/agent';
import { QuestionType } from '../enums/testQuestionType';
import { AchieverSectionId, DPATSectionId } from '../enums/testSections';
import { TestType } from '../enums/testType';
import {
    AchieverResult, AchieverResultsRequest
} from '../models/requestHelpers/achieverResultsRequest';
import { GetAchieverResultsFromXML } from '../models/test/achiever/achieverScoring';
import { AchieverSection } from '../models/test/achiever/achieverSection';
import { initAchiever } from '../models/test/achiever/achieverTest';
import { GetDPATResultsFromXML } from '../models/test/dpat/dpatScoring';
import { DPATSection } from '../models/test/dpat/dpatSection';
import { initDpat } from '../models/test/dpat/dpatTest';
import { createRandomIntArray, TestSession } from '../models/test/testSession';

import type { RootState } from './configureStore';
interface TestState {
    testSession: TestSession | null;
    status: string;
}

const initialState: TestState = {
    testSession: null,
    status: 'idle'
}

export const initializeTestSession = createAsyncThunk<TestSession>(
    'test/initializeTestSession',
    async (_, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let applicantSession = rootState.account.user?.applicantSessionDto;
            let progressId = applicantSession?.progress.progressId ?? 0;
            let testTimeMultiplier = applicantSession?.applicant?.testTimeMultiplier ?? 0;

            let achieverResources = await agent.TestResources.getAchieverResources();
            let dpatResources = await agent.TestResources.getDpatResources({ inhouse: false });
            let dpatInhResources = await agent.TestResources.getDpatResources({ inhouse: true });

            let achieverTest = initAchiever(achieverResources, testTimeMultiplier);
            let dpatTest = initDpat(dpatResources, testTimeMultiplier);
            let dpatInhTest = initDpat(dpatInhResources, testTimeMultiplier);

            let testSession: TestSession = {
                achieverTest,
                dpatTest,
                dpatInhTest,
                testType: TestType.Achiever,
                needTimer: false,
                reviewMiddleAnswers: false,
                isTimed: false,
                pageNumber: 0,
                questionsPerPage: 1,
                totalQuestions: 0,
                timeToComplete: 0,
                instructions: '',
                randomizedQuestions: [],
                randomizedAnswers: [],
                middleAnswers: [],
                instructionsDisplayed: false
            }

            loadTests(testSession, progressId);

            if (applicantSession?.progress.response) {
                setAchieverResponses(testSession, applicantSession.progress.response);
            }
            if (applicantSession?.progress.dpatResponse) {
                setDPATResponses(testSession, applicantSession.progress.dpatResponse);
            }
            if (applicantSession?.progress.dpatInhouseResponse) {
                setDPATInhouseResponses(testSession, applicantSession.progress.dpatInhouseResponse);
            }

            return testSession;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const setPageNumber = createAsyncThunk<TestSession, number>(
    'test/setPageNumber',
    async (pageNumber, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let testSession = displayQuestions(rootState, pageNumber);
            return testSession;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const checkMiddleAnswers = createAsyncThunk<TestSession>(
    'test/checkMiddleAnswers',
    async (_, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let testSession = cloneDeep(rootState.test.testSession || {} as TestSession);
            if (testSession) {
                let middleAnswers = getMiddleAnswers(rootState);

                if (middleAnswers && middleAnswers.length > 7) {
                    testSession.middleAnswers = middleAnswers;
                } else {
                    testSession.middleAnswers = null;
                }
            }
            return testSession;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const addTestResult = createAsyncThunk<boolean, { fDPAT: boolean, fDPATInhouse: boolean }>(
    'test/addTestResult',
    async ({ fDPAT, fDPATInhouse }, thunkAPI) => {
        try {
            let rootState = thunkAPI.getState() as RootState;
            let fTransCompleted = false;

            if (rootState.test.testSession && rootState.account.user?.applicantSessionDto) {
                let { applicantId } = rootState.account.user.applicantSessionDto;

                if (fDPAT || fDPATInhouse) {
                    let dpatResultsReq = GetDPATResultsFromXML(
                        (fDPATInhouse ? rootState.test.testSession.dpatInhTest : rootState.test.testSession.dpatTest)!,
                        fDPATInhouse,
                        applicantId
                    );

                    await agent.DPATResults.addResults(dpatResultsReq);
                    fTransCompleted = true;
                } else {
                    let cResultsBC: AchieverResult[] = GetAchieverResultsFromXML(rootState.test.testSession.achieverTest!);
                    let achieverResultsReq: AchieverResultsRequest = {
                        applicantID: applicantId,
                        achieverResults: cResultsBC
                    };
                    await agent.AchieverResults.addResults(achieverResultsReq);
                    fTransCompleted = true;
                }
            }

            return fTransCompleted;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        setTestSession: (state, action: { payload: TestSession }) => {
            state.testSession = action.payload;
        },
        clearTestSession: (state) => {
            state = initialState;
            sessionStorage.removeItem('testSession');
            sessionStorage.removeItem('progressIds');
        },
        setTotalQuestions: (state, action: { payload: number }) => {
            if (state.testSession) state.testSession.totalQuestions = action.payload;
        },
        setReviewMiddleAnswers: (state, action: { payload: boolean }) => {
            if (state.testSession) state.testSession.reviewMiddleAnswers = action.payload;
        },
        setQuestionResponse: (state, action: { payload: { questionIndex: number, response: number } }) => {
            if (state.testSession) {
                if (state.testSession.testType === TestType.DPAT && state.testSession.dpatTest && state.testSession.sectionId !== undefined) {
                    state.testSession.dpatTest.sections[state.testSession.sectionId].questions[action.payload.questionIndex].response = action.payload.response;
                } else if (state.testSession.testType === TestType.DPATInhouse && state.testSession.dpatInhTest && state.testSession.sectionId !== undefined) {
                    state.testSession.dpatInhTest.sections[state.testSession.sectionId].questions[action.payload.questionIndex].response = action.payload.response;
                } else if (state.testSession.testType === TestType.Achiever && state.testSession.achieverTest && state.testSession.sectionId !== undefined) {
                    state.testSession.achieverTest.sections[state.testSession.sectionId].questions[action.payload.questionIndex].response = action.payload.response;
                }
            }
        }
    },
    extraReducers: (builder => {
        builder.addCase(initializeTestSession.pending, (state) => {
            state.status = 'pendingInitializeTestSession';
        });
        builder.addCase(setPageNumber.pending, (state) => {
            state.status = 'pendingSetPageNumber';
        });
        builder.addCase(checkMiddleAnswers.pending, (state) => {
            state.status = 'pendingCheckMiddleAnswers';
        });
        builder.addCase(addTestResult.pending, (state) => {
            state.status = 'pendingAddTestResult';
        });
        builder.addCase(addTestResult.fulfilled, (state) => {
            state.status = 'idle';
        });
        builder.addMatcher(isAnyOf(initializeTestSession.fulfilled, setPageNumber.fulfilled, checkMiddleAnswers.fulfilled), (state, action) => {
            sessionStorage.setItem('testSession', JSON.stringify(action.payload));
            state.testSession = action.payload;
            state.status = 'idle';
        });
        builder.addMatcher(isAnyOf(initializeTestSession.rejected, setPageNumber.rejected, checkMiddleAnswers.rejected, addTestResult.rejected), (state, action) => {
            state.status = 'idle';
            throw action.payload;
        });
    })
});

export const {
    setTestSession,
    clearTestSession,
    setTotalQuestions,
    setQuestionResponse,
    setReviewMiddleAnswers
} = testSlice.actions;
export default testSlice.reducer;

const loadTests = (testSession: TestSession, progressId: number) => {
    if (progressId > 6 && progressId < 10) {
        testSession.sectionId = progressId - 7;
        testSession.testType = TestType.DPAT;
        testSession.section = testSession.dpatTest!.sections[testSession.sectionId];
        testSession.questionsPerPage = testSession.section.id === DPATSectionId.AlphaSequence ? 8 : 4;
    } else if (progressId > 10 && progressId < 14) {
        testSession.sectionId = progressId - 11;
        testSession.testType = TestType.DPATInhouse;
        testSession.section = testSession.dpatInhTest!.sections[testSession.sectionId];
        testSession.questionsPerPage = testSession.section.id === DPATSectionId.AlphaSequence ? 8 : 4;
    } else {
        testSession.sectionId = Math.max(Math.min(progressId, 6) - 1, 0);
        testSession.testType = TestType.Achiever;
        testSession.section = testSession.achieverTest!.sections[testSession.sectionId];
        testSession.questionsPerPage = testSession.section.id === AchieverSectionId.Perception ? 5 : 1;
    }

    testSession.totalQuestions = testSession.section.questions.length;
    testSession.title = testSession.section.name;
    testSession.timeToComplete = testSession.section.timeToComplete;
    testSession.instructions = testSession.section.instructions;
    testSession.isTimed = testSession.timeToComplete > 0;
}

const displayQuestions = (rootState: RootState, pageNumber: number) => {
    let applicantSession = rootState.account.user?.applicantSessionDto;
    let progressId = applicantSession?.progress.progressId ?? 0;
    let testSession = cloneDeep(rootState.test.testSession || {} as TestSession);
    testSession.pageNumber = pageNumber;

    if (progressId !== 10) {
        loadTests(testSession, progressId);
    }

    if (pageNumber === 0) {
        return testSession;
    }

    if (testSession.reviewMiddleAnswers) {
        testSession.totalQuestions = testSession.middleAnswers?.length ?? middleAnswerCount(rootState);
    }

    let questionNumber = ((testSession.pageNumber - 1) * testSession.questionsPerPage) + 1;

    if (testSession.testType === TestType.DPAT || testSession.testType === TestType.DPATInhouse) {
        let nStart = questionNumber;
        let nEnd = testSession.questionsPerPage * testSession.pageNumber;
        let nPages = Math.ceil(testSession.totalQuestions / testSession.questionsPerPage);

        if (testSession.randomizedQuestions.length === 0 || testSession.randomizedQuestions.length !== nPages) {
            testSession.randomizedQuestions = new Array(nPages);
        }
        if ((testSession.randomizedQuestions[testSession.pageNumber - 1] == null) ||
            (testSession.randomizedQuestions[testSession.pageNumber - 1].length === 0)) {
            testSession.randomizedQuestions[testSession.pageNumber - 1] = createRandomIntArray(nStart, nEnd, testSession.totalQuestions, true);
        }

        for (let i = 0; i < testSession.questionsPerPage; i++) {
            if (questionNumber > testSession.totalQuestions) {
                break;
            }

            let question = testSession.section!.questions[testSession.randomizedQuestions[testSession.pageNumber - 1][i] - 1];
            let nNumOfAnswers = ('imageAnswers' in question && question.imageAnswers.length)
                ? question.imageAnswers.length
                : question.answers.length;

            if ((testSession.randomizedAnswers.length === 0) || testSession.randomizedAnswers.length !== testSession.totalQuestions) {
                testSession.randomizedAnswers = new Array(testSession.totalQuestions);
            }

            if (((testSession.randomizedAnswers[questionNumber - 1] == null)
                || (testSession.randomizedAnswers[questionNumber - 1].length === 0))) {
                testSession.randomizedAnswers[questionNumber - 1] = createRandomIntArray(1, nNumOfAnswers, nNumOfAnswers, true);
            }
            questionNumber++;
        }
    }
    return testSession;
}

const sectionSetResponses = (section: AchieverSection | DPATSection, responses: string) => {
    if (responses.length !== section.questions.length) {
        throw new Error('Number of responses does not match number of questions');
    }

    let nResp = 0;
    for (let cQuest of section.questions) {
        switch (cQuest.questionType) {
            case QuestionType.MultipleChoice:
                if (!(responses[nResp] >= '0' && responses[nResp] <= '9')) {
                    throw new Error('Invalid response string');
                }
                cQuest.response = Number(responses[nResp]);
                break;
            case QuestionType.YesNoMaybe:
                if (!(responses[nResp] >= '0' && responses[nResp] <= '9')) {
                    throw new Error('Invalid response string');
                }
                cQuest.response = Number(responses[nResp]);
                if (cQuest.response !== 1 && cQuest.response !== 2 && cQuest.response !== 3) {
                    cQuest.response = 0;
                    throw new Error('Invalid response string');
                }
                break;
            case QuestionType.YesNo: {
                switch (responses[nResp]) {
                    case 'Y':
                    case 'y':
                        cQuest.response = 1;
                        break;
                    case 'N':
                    case 'n':
                        cQuest.response = 0;
                        break;
                    default:
                        throw new Error('Invalid response string');
                }
                break;
            }
        }
        nResp++;
    }
    return section;
};

const setAchieverResponses = (testSession: TestSession, allResponses: string) => {
    let strEachSection = '';

    if (testSession?.achieverTest) {
        for (let i = 0; i < testSession.achieverTest.sections.length; i++) {
            testSession.section = testSession.achieverTest.sections[i];

            strEachSection = allResponses.substring(0, testSession.section.questions.length);
            allResponses = allResponses.slice(testSession.section.questions.length);
            if (testSession.section.id === AchieverSectionId.Personality) {
                for (let j = 0; j < testSession.section.questions.length; j++) {
                    let cQuestion = testSession.section.questions[j];
                    cQuestion.response = Number(strEachSection.substring(j, j + 1));
                    testSession.section.questions[j] = cQuestion;
                }
            } else {
                testSession.section = sectionSetResponses(testSession.section, strEachSection) as AchieverSection;
            }

            testSession.achieverTest.sections[i] = testSession.section;
        }
    }
};

const setDPATResponses = (testSession: TestSession, allResponses: string) => {
    let strEachSection = '';

    if (testSession?.dpatTest) {
        for (let i = 0; i < testSession.dpatTest.sections.length; i++) {
            testSession.section = testSession.dpatTest.sections[i];

            strEachSection = allResponses.substring(0, testSession.section.questions.length);
            allResponses = allResponses.slice(testSession.section.questions.length);

            testSession.section = sectionSetResponses(testSession.section, strEachSection) as DPATSection;

            testSession.dpatTest.sections[i] = testSession.section;
        }
    }
};

const setDPATInhouseResponses = (testSession: TestSession, allResponses: string) => {
    let strEachSection = '';

    if (testSession?.dpatInhTest) {
        for (let i = 0; i < testSession.dpatInhTest.sections.length; i++) {
            testSession.section = testSession.dpatInhTest.sections[i];

            strEachSection = allResponses.substring(0, testSession.section.questions.length);
            allResponses = allResponses.slice(testSession.section.questions.length);

            testSession.section = sectionSetResponses(testSession.section, strEachSection) as DPATSection;

            testSession.dpatInhTest.sections[i] = testSession.section;
        }
    }
};

const getMiddleAnswers = (state: RootState) => {
    let cMiddleAnswers = [];
    let currentSectionId = state.account.user?.applicantSessionDto?.progress.sectionId;
    if (currentSectionId !== undefined) {
        let currentSectionQuestions = state.test.testSession?.achieverTest?.sections[currentSectionId]?.questions;

        if (currentSectionQuestions && currentSectionQuestions.length) {
            for (let i = 0; i < currentSectionQuestions.length; i++) {
                let cQuestion = currentSectionQuestions[i];
                if (cQuestion.response === 2 || cQuestion.response === 0) {
                    cMiddleAnswers.push(i);
                }
            }
        }
    }
    return cMiddleAnswers;
};
const middleAnswerCount = (state: RootState) => getMiddleAnswers(state).length;

export const testSelectors = {
    getMiddleAnswers,
    middleAnswerCount
};