import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface BaseLoadingProps {
    message?: string;
}

interface ControlledLoadingProps extends BaseLoadingProps {
    show: boolean;
    positionClass?: string;
    delay: number;
}

const defaultMsg = 'Loading...';

export default function LoadingComponent({ message = defaultMsg }: BaseLoadingProps) {    
    return (
        <Modal
            show={true}
            centered={true}
            backdrop="static"
            backdropClassName="opacity-25"
            dialogClassName="w-max-content mx-auto"
            contentClassName="bg-white-translucent-50 border-0 shadow-none"
        >
            <div className="d-flex flex-column justify-content-center align-items-center h-100 m-4">
                <img src="/images/spinner.png" alt={defaultMsg} className="spinner" />
                <h4 className="mt-2">{message}</h4>
            </div>
        </Modal>
    );
}

export function LoadingComponentControlled({ show, delay, message = defaultMsg, positionClass = 'position-absolute' }: ControlledLoadingProps) {
    const [delayedShow, setDelayedShow] = useState(false);

    useEffect(() => {
        if (show) {
            setDelayedShow(false);
        }
        const timer = setTimeout(() => setDelayedShow(true), delay);

        return () => clearTimeout(timer);
    }, [show, delay]);
    
    return (
        <div
            className={`${positionClass} loading-overlay loading-overlay-controlled inset-0 flex-column justify-content-center align-items-center h-100 w-100 ${(show && delayedShow) ? 'd-flex' : 'd-none'}`}
            style={{zIndex: 999}}
        >
            <div className="d-flex flex-column bg-white-translucent-75 rounded-3 p-4 justify-content-center align-items-center">
                <img src="/images/spinner.png" alt={defaultMsg} className="spinner" />
                <h4 className="mt-2">{message}</h4>
            </div>
        </div>
    );
}