import { SyntheticEvent, useMemo, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faFileInvoiceDollar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import CustomCloseBtn from '../../app/components/CustomCloseBtn';
import LoadingButton from '../../app/components/LoadingButton';
import { BillingType } from '../../app/enums/billingType';
import { FieldValuesBilling } from '../../app/models/fieldValueTypes';
import { downloadCsv, isValidDate } from '../../app/utility/util';
import { getTitle, parseValidationMsg } from '../../i18n';

export default function SecureBillingPage() {
    const { t } = useTranslation();
    const [showNoBilling, setShowNoBilling] = useState(false);

    const newDate = useMemo<Date>(
        () => new Date(), []
    );
    const currentYear = newDate.getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear - index);

    const formMethods = useForm<FieldValuesBilling>({
        mode: 'all',
        defaultValues: {
            billingCheck: BillingType.ByMonth,
            fromDate: '',
            toDate: '',
            month: newDate.getMonth() - 1,
            year: currentYear
        },
        resolver: yupResolver(
            yup.object().shape({
                month: yup.number().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_MONTH'] }),
                year: yup.number().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_YEAR'] }),
                billingCheck: yup.string(),
                fromDate: yup.date().nullable()
                    .transform(v => (isValidDate(v) ? v : null))
                    .when(['billingCheck'], {
                        is: (billingCheckVal: string) =>
                            (billingCheckVal === BillingType.ByDateRange),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_START_DATE'] })
                    })
                    .when(['toDate', 'billingCheck'], {
                        is: (endDateVal: any, billingCheckVal: string) =>
                            (billingCheckVal === BillingType.ByDateRange && isValidDate(endDateVal)),
                        then: rule => rule.max(yup.ref('toDate'), { resKeys: ['IDS_IEP_INVALID', 'IDS_START_DATE'] })
                    }),
                toDate: yup.date().nullable()
                    .transform(v => (isValidDate(v) ? v : null))
                    .when(['billingCheck'], {
                        is: (billingCheckVal: string) =>
                            (billingCheckVal === BillingType.ByDateRange),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_END_DATE'] })
                    })
                    .when(['fromDate', 'billingCheck'], {
                        is: (startDateVal: any, billingCheckVal: string) =>
                            (billingCheckVal === BillingType.ByDateRange && isValidDate(startDateVal)),
                        then: rule => rule.min(yup.ref('fromDate'), { resKeys: ['IDS_IEP_INVALID', 'IDS_END_DATE'] })
                    })
            }, [
                ['fromDate', 'toDate']
            ])
        )
    }),
        { formState: { isValid, isSubmitting, errors }, handleSubmit, trigger, register, getValues } = formMethods;

    /**
     * Calls to the API to get the billing data with the provided input, and then 
     * downloads the file if there was data, otherwise displays an error modal.
     */
    async function submitForm(data: FieldValuesBilling) {
        let csv;
        if (data.billingCheck === BillingType.ByMonth) {
            csv = await agent.Admin.getBillingByMonth({ month: data.month, year: data.year });
        } else {
            csv = await agent.Admin.getBillingByDateRange({ startDate: data.fromDate, endDate: data.toDate });
        }

        if (csv.csvBytes) {
            downloadCsv(csv.csvBytes, csv.csvFileName);
        } else {
            setShowNoBilling(true);
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_GENERATE_BILLING')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_ADMIN')} - ${t('IDS_GENERATE_BILLING')}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <p className="small">{t('IDS_GENERATE_BILLING_DESC')}</p>
                <FormProvider {...formMethods}>
                    <Form id="formBilling" onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                        <Row className="mb-4">
                            <Col lg={2}>
                                <Form.Check
                                    className="check-group-item"
                                    type="radio"
                                    id="byMonth"
                                    label={`${t('IDS_BY_MONTH')}:`}
                                    style={{ fontSize: '1.25rem' }}
                                    {...register('billingCheck', {
                                        onChange: () => trigger()
                                    })}
                                    value="month"
                                />
                            </Col>
                            <Col xs={12} lg={2}>
                                <FloatingLabel
                                    controlId="month"
                                    className="small"
                                    label={t('IDS_MONTH')}
                                >
                                    <Form.Select
                                        required={getValues('billingCheck') === BillingType.ByMonth}
                                        disabled={getValues('billingCheck') !== BillingType.ByMonth}
                                        isInvalid={!!errors?.month}
                                        {...register('month')}
                                    >
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {parseValidationMsg(errors?.month?.message, t)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg={2}>
                                <FloatingLabel
                                    controlId="year"
                                    className="small"
                                    label={t('IDS_YEAR')}
                                >
                                    <Form.Select
                                        required={getValues('billingCheck') === BillingType.ByMonth}
                                        disabled={getValues('billingCheck') !== BillingType.ByMonth}
                                        isInvalid={!!errors?.year}
                                        {...register('year')}
                                    >
                                        {years && years.map((text, value) => (
                                            <option key={value} value={text}>{text}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {parseValidationMsg(errors?.year?.message, t)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={2}>
                                <Form.Check
                                    className="check-group-item"
                                    type="radio"
                                    id="byRange"
                                    label={`${t('IDS_BY_DATE_RANGE')}:`}
                                    style={{ fontSize: '1.25rem' }}
                                    {...register('billingCheck', {
                                        onChange: () => trigger()
                                    })}
                                    value="range"
                                />
                            </Col>
                            <Col xs={12} lg={2}>
                                <FloatingLabel
                                    controlId="fromDate"
                                    className="small"
                                    label={t('IDS_START_DATE')}
                                >
                                    <Form.Control
                                        type="date"
                                        required={getValues('billingCheck') === BillingType.ByDateRange}
                                        disabled={getValues('billingCheck') !== BillingType.ByDateRange}
                                        isInvalid={!!errors?.fromDate}
                                        {...register('fromDate', {
                                            onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                trigger('toDate');
                                            }
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {parseValidationMsg(errors?.fromDate?.message, t)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg={2}>
                                <FloatingLabel
                                    controlId="toDate"
                                    className="small"
                                    label={t('IDS_END_DATE')}
                                >
                                    <Form.Control
                                        type="date"
                                        required={getValues('billingCheck') === BillingType.ByDateRange}
                                        disabled={getValues('billingCheck') !== BillingType.ByDateRange}
                                        isInvalid={!!errors?.toDate}
                                        {...register('toDate', {
                                            onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                                trigger('fromDate');
                                            }
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {parseValidationMsg(errors?.toDate?.message, t)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} lg={3}>
                                <LoadingButton
                                    form='formBilling'
                                    type='submit'
                                    variant='dark-ocean'
                                    className='mw-8rem'
                                    loading={isSubmitting}
                                    disabled={!isValid || isSubmitting}
                                >
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />{t('IDS_GENERATE_BILLING')}
                                </LoadingButton>
                            </Col>
                        </Row>
                    </Form>
                </FormProvider>
            </div>
            <Modal
                scrollable={false}
                show={showNoBilling}
                onHide={() => setShowNoBilling(false)}
                centered={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>{t('IDS_GENERATE_BILLING')}</Modal.Title>
                    <CustomCloseBtn hideFcn={() => setShowNoBilling(false)} />
                </Modal.Header>
                <Modal.Body>
                    {t('IDS_NO_BILLING_FOUND')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="silver" onClick={() => setShowNoBilling(false)}>
                        <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}