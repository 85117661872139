import { localDate } from '../../utility/util';
import { ApplicantExpDto } from '../responseHelpers/applicantExpDto';

export interface ApplicantExpRequest {
    applicantId: number;
    experiences: ApplicantExperience[];
}

export interface ApplicantExperience {
    companyName: string;
    jobTitle: string;
    stillEmployed: boolean;
    city: string;
    state: string;
    startDate: string | null;
    endDate: string | null;
    comment: string;
    description: string;
    salaryStart: string;
    salaryEnd: string;
    noPreviousExp: boolean;
    clientIndex: number;
}

function getEmptyExp(clientIndex: number): ApplicantExperience {
    return {
        companyName: '',
        jobTitle: '',
        stillEmployed: false,
        city: '',
        state: '',
        startDate: '',
        endDate: '',
        comment: '',
        description: '',
        salaryStart: '',
        salaryEnd: '',
        noPreviousExp: false,
        clientIndex,
    };
}

function expDtoToExpReq(exp: ApplicantExpDto, clientIndex: number): ApplicantExperience {
    return {
        companyName: exp.companyName,
        jobTitle: exp.title,
        stillEmployed: exp.stillEmployed,
        city: exp.city,
        state: exp.state,
        startDate: localDate(exp.start, true),
        endDate: localDate(exp.end, true),
        comment: exp.comment,
        description: exp.description,
        salaryStart: exp.salaryStart,
        salaryEnd: exp.salaryEnd,
        noPreviousExp: exp.notApplicable,
        clientIndex,
    };
}

export function getPaddedExps(experiences: ApplicantExpDto[]): ApplicantExperience[] {
    if (!Array.isArray(experiences) || experiences.length === 0) {
        return [getEmptyExp(0), getEmptyExp(1), getEmptyExp(2)];
    } else {
        let retArr: ApplicantExperience[] = [];
        for (let i = 0; i < 3; i++) {
            retArr.push(experiences.length > i ? expDtoToExpReq(experiences[i], i) : getEmptyExp(i));
        }
        return retArr;
    }
}