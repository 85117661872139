import { useTranslation } from 'react-i18next';

import {
    TblApplicantAchieverResult
} from '../../../app/models/dbTables/tblApplicantAchieverResult';
import { transType } from '../../../i18next';

export interface Props {
    achieverResult: TblApplicantAchieverResult | null | undefined;
    profileRange: string;
    i18nKeyAptitude: string;
    i18nKeyDescLow: string;
    i18nKeyDescHigh: string;
}

const oneThroughNine = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function AssessApplicantTableRow({ achieverResult, profileRange, i18nKeyAptitude, i18nKeyDescLow, i18nKeyDescHigh }: Props) {
    const { t } = useTranslation();
    const stanine = achieverResult?.stanine ?? 0;
    const profileLow = profileRange.length === 2 ? Number(profileRange[0]) : 0;
    const profileHigh = profileRange.length === 2 ? Number(profileRange[1]) : 0;

    return (
        <tr>
            <td>{t(i18nKeyAptitude as keyof transType)}</td>
            <td>{t(i18nKeyDescLow as keyof transType)}</td>
            {oneThroughNine.map(num => (
                <td
                    key={num}
                    className={`text-center font-monospace ${((num >= profileLow) && (num <= profileHigh)) ? 'bg-ocean' : ''}`}
                    style={{width: '1.5rem'}}
                >
                    {stanine === num ? '✔' : num}
                </td>
            ))}
            <td className="ps-3">{t(i18nKeyDescHigh as keyof transType)}</td>
            <td className="text-end font-monospace">{achieverResult?.rawScore}</td>
        </tr>
    );
}