import { FieldValues } from 'react-hook-form';

import { locales } from '../../../i18n';
import { stringToBool } from '../../utility/util';
import { VwClient } from '../dbViews/vwClient';
import { ClientLanguageRequest } from './clientLanguageRequest';

export interface UpdateClientRequest {
    clientNumber: string;
    clientName: string;
    clientEmail: string;
    references: number;
    openingNote: string;
    closingNote: string;
    lockoutText: string;
    returnedEmail: string;
    employment: number;
    achiever: boolean;
    dpat: boolean;
    dpatInhouse: boolean;
    education: number;
    emailRequired: boolean;
    referredByRequired: boolean;
    defaultLanguage: string;
    dpatLockoutExempt: boolean;
    requestEeoInfo: boolean;
    onlyLanguageEnUs: boolean;
    notesByLanguage: ClientLanguageRequest[];
}

export function formDataToUpdateClientRequest(vwClient: VwClient, formData: FieldValues, defaultLanguage: string) {
    const supportedLanguages = Object.keys(locales);
    let clientLanguageRequests: ClientLanguageRequest[] = [];
    for (let lang of supportedLanguages) {
        if (lang !== 'en-US') {
            clientLanguageRequests.push({
                languageCode: lang,
                openingNotes: formData[`openingNotes-${lang}`],
                closingNotes: formData[`completionMsg-${lang}`],
                lockoutNotes: formData[`lockoutWarning-${lang}`]
            });
        }
    }

    let strClientEmail = '';
    if (Array.isArray(formData.emailApps) && formData.emailApps.length > 0) {
        strClientEmail = formData.emailApps.map(x => x.value).join(',');
    }

    let updateClientRequest: UpdateClientRequest = {
        clientNumber: formData.clientId,
        clientName: formData.clientName,
        clientEmail: strClientEmail,
        references: Number(formData.requiredReferences),
        openingNote: formData['openingNotes-en-US'],
        closingNote: formData['completionMsg-en-US'],
        lockoutText: formData['lockoutWarning-en-US'],
        returnedEmail: formData.returnedEmail,
        employment: Number(formData.requiredEmpHistory),
        achiever: vwClient.achiever,
        dpat: vwClient.dpat,
        dpatInhouse: vwClient.dpatInhouse,
        education: Number(formData.requiredEducation),
        emailRequired: stringToBool(formData.requireEmailAddress),
        referredByRequired: formData.requireReferredBy !== undefined ? stringToBool(formData.requireReferredBy) : vwClient.referredByRequired,
        defaultLanguage: defaultLanguage,
        dpatLockoutExempt: stringToBool(formData.disableDpatLockout),
        requestEeoInfo: formData.requestEeoInfo !== undefined ? stringToBool(formData.requestEeoInfo) : vwClient.requestEeoInfo,
        onlyLanguageEnUs: false,
        notesByLanguage: clientLanguageRequests
    };

    return updateClientRequest;
}