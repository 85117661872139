import { useEffect } from 'react';
import { Form, Tab } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import BsScrollableTabs, { BsTabs } from '../../../app/components/BsScrollableTabs';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { FieldValuesAppRefs } from '../../../app/models/fieldValueTypes';
import { useAppSelector } from '../../../app/store/storeHooks';
import { PropsApplicationTabs } from './shared/applicationTabsProps';
import ReferenceCard from './ReferenceCard';
import { getPaddedRefs } from '../../../app/models/requestHelpers/applicantRefRequest';
import { RegExps } from '../../../app/utility/util';

export default function TabReferences({ setFormState }: PropsApplicationTabs<FieldValuesAppRefs>) {
    const { status } = useAppSelector((state) => state.account);
    const {
        references, client
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const refsReqd = client.references;

    const formMethods = useForm<FieldValuesAppRefs>({
        mode: 'all',
        defaultValues: { references: getPaddedRefs(references) },
        resolver: yupResolver(yup.object({
            references: yup.array().of(
                yup.object().shape({
                    clientIndex: yup.number(),
                    fullName: yup.string().when('clientIndex', {
                        is: (clientIndexVal: number) => (refsReqd > clientIndexVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_FULL_NAME'] })
                    }),
                    company: yup.string().when('clientIndex', {
                        is: (clientIndexVal: number) => (refsReqd > clientIndexVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_COMPANY'] })
                    }),
                    title: yup.string().when('clientIndex', {
                        is: (clientIndexVal: number) => (refsReqd > clientIndexVal),
                        then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_TITLE'] })
                    }),
                    phone: yup.string()
                        .when('clientIndex', {
                            is: (clientIndexVal: number) => (refsReqd > clientIndexVal),
                            then: rule => rule.required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_PHONE'] })
                                .matches(RegExps.phoneGeneric, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_PHONE'] } }),
                        })
                        .when('phone', {
                            is: (phoneVal: string) => phoneVal?.length,
                            then: rule => rule.matches(RegExps.phoneGeneric, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_PHONE'] } }),
                        }),
                    email: yup.string().when('email', {
                        is: (emailVal: string) => emailVal?.length,
                        then: rule => rule.matches(RegExps.email, { message: { resKeys: ['IDS_IEP_INVALID', 'IDS_EMAIL'] } })
                    })
                },
                    [
                        ['phone', 'phone'],
                        ['email', 'email'],
                    ])
            )
        }))
    }),
        { reset, formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors }, handleSubmit, getValues, trigger } = formMethods;

    useEffect(() => {
        setFormState({ isValid, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset });
    }, [setFormState, isDirty, isSubmitting, isSubmitSuccessful, handleSubmit, getValues, reset, isValid]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    const refTabs: BsTabs = {
        'first': {
            linkText: 'IDS_FIRST',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.references?.[0],
            paneComponent: <ReferenceCard index={0} />
        },
        'second': {
            linkText: 'IDS_SECOND',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.references?.[1],
            paneComponent: <ReferenceCard index={1} />
        },
        'third': {
            linkText: 'IDS_THIRD',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.references?.[2],
            paneComponent: <ReferenceCard index={2} />
        },
        'fourth': {
            linkText: 'IDS_FOURTH',
            isInvalid: !status.includes('pending') && !isValid && !!errors?.references?.[3],
            paneComponent: <ReferenceCard index={3} />
        }
    };

    return (
        <div>
            <FormProvider {...formMethods} >
                <Form id="form-references" className="needs-validation" noValidate>
                    <Tab.Container defaultActiveKey="first">
                        <BsScrollableTabs tabs={refTabs} navTabsClassName="nav-tabs-alt" />
                        <div className="border border-light-eggplant border-2 bg-white p-4">
                            <Tab.Content>
                                {Object.keys(refTabs).map((key) => (
                                    <Tab.Pane eventKey={key} key={`${key}TabPane`}>
                                        {refTabs[key].paneComponent}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </Form>
            </FormProvider>
        </div>
    );
}