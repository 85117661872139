import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-call-us d-flex align-items-baseline bg-dark-eggplant py-3 text-white">
                <span className="font-pt-serif me-2 fs-5 line-height-100">
                    {t('IDS_CALL_US')}
                </span>
                <a href={`tel:${t('IDS_REYREY_PHONE')}`} className="text-white small line-height-100">
                    {t('IDS_REYREY_PHONE')}
                </a>
            </div>
            <div className="footer-copyright d-flex justify-content-between align-items-center py-3">
                <span className="small line-height-100">
                    {t('IDS_COPYRIGHT', {0: new Date().getFullYear().toString()})}
                </span>
                {/* <LegalAndPrivacy /> */}
            </div>
            <div id="recaptchaContainer"></div>
        </footer>
    );
}