import { useEffect, useState } from 'react';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { faAngleDoubleDown, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../../app/api/agent';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import HelpTooltipIcon from '../../../app/components/HelpTooltipIcon';
import LoadingButton from '../../../app/components/LoadingButton';
import { ApplicantItem } from '../../../app/models/applicantItem';
import { formDataToApplicantRequest } from '../../../app/models/requestHelpers/applicantRequest';
import { useAppSelector } from '../../../app/store/storeHooks';
import { parseValidationMsg } from '../../../i18n';
import ApplicantItemsContainer from './dnd/ApplicantItemsContainer';
import ModalPositionLookup from './ModalPositionLookup';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    changeCount: number;
    setChangeCount: (count: number) => void;
    setModalAlert: (alertState: { show: boolean, msg: string }) => void;
}

export default function ModalApplicantAdd({ showModal, setShowModal, changeCount, setChangeCount, setModalAlert }: Props) {
    const { t } = useTranslation();
    const { clientSession } = useAppSelector(state => state.clientSession);
    const vwClient = clientSession?.vwClient;
    const [showModalPositionLookup, setShowModalPositionLookup] = useState(false);
    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object({
            firstName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_FIRST_NAME'] }),
            lastName: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_LAST_NAME'] }),
            email: yup.string().when('emailInformation', {
                is: (val: boolean) => val === true,
                then: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_EMAIL'] })
            }),
            positionTitle: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_POSITION'] })
        }))
    }),
        { formState: { isValid, isSubmitting, isDirty, errors }, reset, handleSubmit, register, watch } = formMethods;
    const watchEmailInformation = watch('emailInformation') as boolean;

    async function submitForm(data: FieldValues) {
        try {
            if (vwClient) {
                let newApplicant = formDataToApplicantRequest(vwClient, data);
                let { item1: username, item2: message, item3: newPass } = await agent.ClientApplicant.addApplicant(newApplicant);

                let msg = t('IDS_IEP_APP_CREATED', {
                    0: username,
                    1: newApplicant.emailInformation
                        ? (message || 'Email sent successfully.')
                        : `${t('IDS_PASSWORD')}: ${newPass}`,
                    interpolation: { escapeValue: false }
                });
                
                setShowModal(false);
                setChangeCount(changeCount + 1);
                setModalAlert({ show: true, msg });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                if (clientSession && vwClient && showModal) {
                    let applicantItems: ApplicantItem[] = [];
                    if (clientSession.dpatInhouse) {
                        applicantItems.push({
                            applicantId: 0,
                            name: 'DpatInhouse',
                            text: t('IDS_APTITUDE_INHOUSE'),
                            tooltipText: t('IDS_HELP_CLIENTADDAPPLICANTDPATINHOUSE_MESSAGE'),
                            tooltipTitle: t('IDS_HELP_CLIENTADDAPPLICANTDPATINHOUSE_TITLE'),
                            pdfTooltipText: t('IDS_VIEW_INH_DPAT'),
                            order: vwClient.applicantDefaultDpatInhouseOrder,
                            turnedOn: vwClient.applicantDefaultDpatInhouse
                        });
                    }
                    if (clientSession.dpat) {
                        applicantItems.push({
                            applicantId: 0,
                            name: 'Aptitudetest',
                            text: t('IDS_APTITUDE_TEST'),
                            tooltipText: t('IDS_HELP_CLIENTADDAPPLICANTDPAT_MESSAGE'),
                            tooltipTitle: t('IDS_HELP_CLIENTADDAPPLICANTDPAT_TITLE'),
                            pdfTooltipText: t('IDS_VIEW_DPAT'),
                            order: vwClient.applicantDefaultAptitudetestOrder,
                            turnedOn: vwClient.applicantDefaultAptitudeTest
                        });
                    }
                    if (clientSession.achiever) {
                        applicantItems.push({
                            applicantId: 0,
                            name: 'Achiever',
                            text: t('IDS_PERSONALITY_ASSESSMENT'),
                            tooltipText: t('IDS_HELP_CLIENTADDAPPLICANTACHIEVER_MESSAGE'),
                            tooltipTitle: t('IDS_HELP_CLIENTADDAPPLICANTACHIEVER_TITLE'),
                            pdfTooltipText: t('IDS_VIEW_ASSESSMENT'),
                            order: vwClient.applicantDefaultAchieverOrder,
                            turnedOn: vwClient.applicantDefaultAchiever
                        });
                    }
                    applicantItems.push({
                        applicantId: 0,
                        name: 'Application',
                        text: t('IDS_APPLICATION'),
                        tooltipText: t('IDS_HELP_CLIENTADDAPPLICANTTESTONLY_MESSAGE'),
                        tooltipTitle: t('IDS_HELP_CLIENTADDAPPLICANTTESTONLY_TITLE'),
                        pdfTooltipText: t('IDS_VIEW_APPLICATION'),
                        order: vwClient.applicantDefaultApplicationOrder,
                        turnedOn: vwClient.applicantDefaultApplication
                    });
                    applicantItems.sort((a, b) => (a.order > b.order) ? 1 : -1);

                    reset({
                        'id': '',
                        'firstName': '',
                        'lastName': '',
                        'username': '',
                        'email': '',
                        'positionTitle': '',
                        'positionId': '',
                        'emailInformation': false,
                        'specialAccommodations': false,
                        ...{ applicantItems }
                    });
                }
            } catch (error) {
                console.log(error);
                setShowModal(false);
            }
        }
        fetchSetupData();
    }, [reset, t, clientSession, vwClient, showModal, setShowModal]);

    return (
        <div>
            <Modal
                scrollable={true}
                show={showModal}
                onHide={() => setShowModal(false)}
                id="modalAddApplicant"
                centered={true}
                backdrop="static"
                size="lg"
            >
                <FormProvider {...formMethods}>
                    <Modal.Header>
                        <Modal.Title>{t('IDS_ADD_APPLICANT')}</Modal.Title>
                        <CustomCloseBtn hideFcn={() => setShowModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="formApplicantAdd" onSubmit={handleSubmit(submitForm)}>
                            <Row className="g-4">
                                <Col xs={12} lg={4}>
                                    <FloatingLabel
                                        controlId="firstName"
                                        className="small"
                                        label={t('IDS_FIRST_NAME')}
                                    >
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            required
                                            {...register('firstName')}
                                            isInvalid={!!errors.firstName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.firstName?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <FloatingLabel
                                        controlId="lastName"
                                        className="small"
                                        label={t('IDS_LAST_NAME')}
                                    >
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            required
                                            {...register('lastName')}
                                            isInvalid={!!errors.lastName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {parseValidationMsg(errors?.lastName?.message, t)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <FloatingLabel
                                        controlId="username"
                                        className="small"
                                        label={
                                            <>
                                                <span>{t('IDS_USERNAME')}</span>
                                                <HelpTooltipIcon
                                                    tooltipId="tooltipUsername"
                                                    tooltipText={t('IDS_HELP_CLIENTADDAPPLICANTUSERNAME_MESSAGE')}
                                                    tooltipTitle={t('IDS_HELP_CLIENTADDAPPLICANTUSERNAME_TITLE')}
                                                />
                                            </>
                                        }
                                    >
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            {...register('username')}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={8}>
                                    <InputGroup className="flex-nowrap">
                                        <FloatingLabel
                                            controlId="email"
                                            className="small flex-fill"
                                            label={t('IDS_EMAIL_ADDRESS')}
                                        >
                                            <Form.Control
                                                type="text"
                                                maxLength={50}
                                                required={watchEmailInformation}
                                                {...register('email')}
                                                isInvalid={watchEmailInformation && !!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {watchEmailInformation && parseValidationMsg(errors?.email?.message, t)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="active"
                                            className="small has-tooltip label-flex"
                                            style={{ flex: 'unset', width: 'unset' }}
                                            onClick={(e) => {
                                                if (e.target instanceof Element && !e.target.matches('input')) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            label={
                                                <>
                                                    <span>{t('IDS_EMAIL_LOGON_INFO')}</span>
                                                    <HelpTooltipIcon
                                                        tooltipId="tooltipEmailInformation"
                                                        tooltipText={t('IDS_HELP_CLIENTADDAPPLICANTEMAIL_MESSAGE')}
                                                        tooltipTitle={t('IDS_HELP_CLIENTADDAPPLICANTEMAIL_TITLE')}
                                                    />
                                                </>
                                            }
                                        >
                                            <Form.Check
                                                type="switch"
                                                className="form-control text-center"
                                                style={{ width: '9.2rem' }}
                                                {...register('emailInformation', {})}
                                            />
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <InputGroup className="flex-nowrap">
                                        <FloatingLabel
                                            controlId="positionTitle"
                                            className="small flex-fill"
                                            label={t('IDS_POSITION')}
                                        >
                                            <Form.Control
                                                type="text"
                                                required
                                                {...register('positionTitle')}
                                                isInvalid={!!errors.positionTitle}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {parseValidationMsg(errors?.positionTitle?.message, t)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                        <InputGroup.Text className="px-2" style={{ height: 'calc(3.5rem + 2px)' }}>
                                            <Button
                                                variant="dark-ocean"
                                                className="d-flex align-items-center justify-content-center rounded-circle lh-1 m-0 p-1"
                                                onClick={() => setShowModalPositionLookup(true)}
                                                title={t('IDS_SELECT_A_POSITION')}
                                            >
                                                <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth size="xs" />
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <ApplicantItemsContainer showAssignedAndViewCols={false} />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="silver" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CANCEL')}
                        </Button>
                        <LoadingButton
                            form="formApplicantAdd"
                            type="submit"
                            variant="dark-ocean"
                            className="mw-8rem"
                            loading={isSubmitting}
                            disabled={!isValid || !isDirty || isSubmitting}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_ADD')}
                        </LoadingButton>
                    </Modal.Footer>
                    <ModalPositionLookup showModal={showModalPositionLookup} setShowModal={setShowModalPositionLookup} />
                </FormProvider>
            </Modal>
        </div>
    );
}