import { SyntheticEvent, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import agent from '../../app/api/agent';
import { BsModalAlert } from '../../app/components/BsModal';
import LoadingButton from '../../app/components/LoadingButton';
import { FieldValuesRecoverPassword } from '../../app/models/fieldValueTypes';
import { getTitle, parseValidationMsg } from '../../i18n';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaBranding from './RecaptchaBranding';

export default function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showAlert, setShowAlert] = useState(false);
    
    const { formState: { isValid, isSubmitting, errors }, handleSubmit, register, setValue } = useForm<FieldValuesRecoverPassword>({
        mode: 'all',
        resolver: yupResolver(yup.object({
            username: yup.string().required({ resKeys: ['IDS_IEP_REQUIRED', 'IDS_USERNAME']})
        }))
    });

    async function submitForm(data: FieldValuesRecoverPassword) {
        try {
            if (!executeRecaptcha) return;
            const token = await executeRecaptcha('recoverApplicantPassword');
            sessionStorage.setItem('reCaptchaToken', token);

            await agent.UserAccount.recoverApplicantPassword(data);
            setShowAlert(true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_PASSWORD_RESET')}</title>
            </Helmet>
            <div className="main-content-login d-flex justify-content-center flex-column">
                <h1 className="mb-4 fw-bold">{t('IDS_PASSWORD_RESET')}</h1>
                <div className="help-text mb-4 smaller">{t('IDS_FORGOT_PASSWORD_INSTRUCT')}</div>
                <Form onSubmit={handleSubmit(submitForm)} className="needs-validation" noValidate>
                    <FloatingLabel
                        controlId="inputUsername"
                        className="small mb-3"
                        label={t('IDS_USERNAME')}
                    >
                        <Form.Control
                            type="text"
                            maxLength={50}
                            required
                            {...register('username', {
                                onChange: (e: SyntheticEvent<HTMLInputElement>) => {
                                    e.currentTarget.value = e.currentTarget.value.trim();
                                    setValue('username', e.currentTarget.value, { shouldValidate: true });
                                }
                            })}
                            isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {parseValidationMsg(errors?.username?.message, t)}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <LoadingButton
                        variant="dark-ocean"
                        type="submit"
                        className="mw-8rem mb-4"
                        loading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                    >
                        <FontAwesomeIcon icon={faKey} className="me-2" />{t('IDS_RECOVER_PASSWORD')}
                    </LoadingButton>
                    <RecaptchaBranding />
                </Form>
            </div>
            
            <BsModalAlert
                title={t('IDS_SUCCESS')}
                message={t('IDS_EMAIL_SENT_PASSWORD')}
                showModal={showAlert}
                setShowModal={setShowAlert}
                hideCallback={() => navigate('/AuthApplicant')}
            />
        </>
    );
}