import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FilterMode } from '../enums/filterMode';
import {
    setAccessibilityFilterMode, setAccessibilityTextSize, setShowAccessibilityModal
} from '../store/accessibilitySlice';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';
import CustomCloseBtn from './CustomCloseBtn';

export function AccessibilityModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { settings: { filterMode, textSize }, showModal } = useAppSelector(state => state.accessibilitySettings);

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={() => dispatch(setShowAccessibilityModal(false))}
            id="modalAccessibility"
            centered={true}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>{t('IDS_ACCESSIBILITY_OPTIONS')}</Modal.Title>
                <CustomCloseBtn hideFcn={() => dispatch(setShowAccessibilityModal(false))} />
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label className="small" htmlFor="divColorAdjustmentSwitches">{t('IDS_COLOR_ADJUSTMENTS')}</Form.Label>
                            <div id="divColorAdjustmentSwitches">
                                <Form.Check
                                    type="switch"
                                    className="check-group-item"
                                    id="switchDarkContrast"
                                    label={t('IDS_DARK_CONTRAST')}
                                    checked={filterMode === FilterMode.DarkContrast}
                                    onChange={(e) => {
                                        dispatch(setAccessibilityFilterMode(e.currentTarget.checked ? FilterMode.DarkContrast : FilterMode.None));
                                    }}
                                />
                                <Form.Check
                                    type="switch"
                                    className="check-group-item"
                                    id="switchHighContrast"
                                    label={t('IDS_HIGH_CONTRAST')}
                                    checked={filterMode === FilterMode.HighContrast}
                                    onChange={(e) => {
                                        dispatch(setAccessibilityFilterMode(e.currentTarget.checked ? FilterMode.HighContrast : FilterMode.None));
                                    }}
                                />
                                <Form.Check
                                    type="switch"
                                    className="check-group-item"
                                    id="switchMonochrome"
                                    label={t('IDS_MONOCHROME')}
                                    checked={filterMode === FilterMode.Monochrome}
                                    onChange={(e) => {
                                        dispatch(setAccessibilityFilterMode(e.currentTarget.checked ? FilterMode.Monochrome : FilterMode.None));
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="rangeTextSize">
                            <Form.Label className="small">{t('IDS_TEXT_SIZE')}</Form.Label>
                            <Form.Range
                                min={70}
                                step={10}
                                max={130}
                                value={textSize}
                                onChange={(e) => {
                                    dispatch(setAccessibilityTextSize(`${e.currentTarget.valueAsNumber}`));
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="silver" onClick={() => dispatch(setShowAccessibilityModal(false))}>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}