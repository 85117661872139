import 'react-datepicker/dist/react-datepicker.min.css';

import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Column, Filters, SortingRule, useFilters, usePagination, useSortBy, useTable
} from 'react-table';

import { faPlus, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colorVars from '../layout/styles/_colors.module.scss';
import { transType } from '../../i18next';
import { LoadingComponentControlled } from '../layout/LoadingComponent';
import { BsGridFetchDataParams } from '../models/bsGridFetchDataParams';
import BsGridPager from './BsGrid/bsGridPager';

interface Props<T extends object> {
    addRecordFcn?: () => void;
    columns: Column<T>[];
    data: T[];
    defaultFilters?: Filters<T>;
    defaultSortBy?: SortingRule<T>;
    fetchData: (tableState: BsGridFetchDataParams<T>) => void;
    hiddenColumnIds?: string[];
    i18nKeys?: { itemName?: string, addItem?: string };
    loading: boolean;
    pageCount: number;
    totalRecords: number;
}

export default function BsGrid<T extends object>({
    addRecordFcn,
    columns,
    data,
    defaultFilters = [],
    defaultSortBy,
    fetchData,
    hiddenColumnIds = [],
    i18nKeys: { itemName = 'Record', addItem = 'Add Record' } = { itemName: 'Record', addItem: 'Add Record' },
    loading,
    pageCount: controlledPageCount,
    totalRecords
}: Props<T>) {
    const [pageInputValue, setPageInputValue] = useState('1');
    const { t } = useTranslation();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns,
            data,
            manualFilters: true,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
                sortBy: defaultSortBy ? [defaultSortBy] : [],
                hiddenColumns: hiddenColumnIds,
                filters: defaultFilters
            },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageInputValue(`${pageIndex + 1}`);
        fetchData({ pageIndex, pageSize, sortBy, filters });
    }, [fetchData, pageIndex, pageSize, sortBy, filters])

    return (
        <div className="position-relative">
            <LoadingComponentControlled delay={1000} show={loading} />
            <BsGridPager
                {...({
                    isBelow: false,
                    pageOptions,
                    totalRecords,
                    itemName,
                    canPreviousPage,
                    canNextPage,
                    pageIndex,
                    pageCount,
                    pageSize,
                    pageInputValue,
                    setPageInputValue,
                    gotoPage,
                    previousPage,
                    nextPage,
                    setPageSize
                })}
            />
            <div className="border border-dark-eggplant border-1 bg-white small table-responsive rounded">
                <Table striped borderless hover {...getTableProps()} variant="dark-eggplant">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="align-top">
                                        <div {...column.getSortByToggleProps()} className="text-nowrap">
                                            {column.render('Header')}
                                            {!(column.disableSortBy ?? false) &&
                                                <span className="fa-layers fa-fw ms-2">
                                                    <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        size="lg"
                                                        className={`${column.isSorted && !column.isSortedDesc ? '' : 'opacity-25'}`}
                                                        color={colorVars.lightocean}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        size="lg"
                                                        className={`${column.isSorted && column.isSortedDesc ? '' : 'opacity-25'}`}
                                                        color={colorVars.lightocean}
                                                    />
                                                </span>
                                            }
                                        </div>
                                        <div className="mt-1 d-inline-flex align-items-center w-100">{column.canFilter && column.render('Filter')}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td className="text-nowrap" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        {addRecordFcn &&
                            <tr>
                                <td className="pt-4" colSpan={columns.length - hiddenColumnIds.length}>
                                    <Button variant="link" className="link-dark-ocean" onClick={addRecordFcn}>
                                        {t(addItem as keyof transType)}<FontAwesomeIcon icon={faPlus} className="ms-2" />
                                    </Button>
                                </td>
                            </tr>
                        }
                    </tfoot>
                </Table>
            </div>
            <BsGridPager
                {...({
                    isBelow: true,
                    pageOptions,
                    totalRecords,
                    itemName,
                    canPreviousPage,
                    canNextPage,
                    pageIndex,
                    pageCount,
                    pageSize,
                    pageInputValue,
                    setPageInputValue,
                    gotoPage,
                    previousPage,
                    nextPage,
                    setPageSize
                })}
            />
        </div>
    );
}