import { i18n, TFunction } from 'i18next';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { transType } from '../../i18next';
import { HandledError } from '../models/handledError';
import { setError } from '../store/handledErrorSlice';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';
import CustomCloseBtn from './CustomCloseBtn';
import { signOut } from '../store/accountSlice';

interface BaseProps {
    title: string;
    message: string;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    hideCallback?: () => void;
    bodyClass?: string;
}

interface ConfirmProps extends BaseProps {
    confirmCallback: () => void;
}

function getErrorTitle(handledError: HandledError, t: TFunction) {
    if (handledError != null && typeof handledError.data === 'object' && typeof handledError.data?.title === 'string') {
        return handledError.data.title;
    }
    return t('IDS_ERROR');
}

function getErrorType(handledError: HandledError, addColon: boolean) {
    let retErr = '';
    if (handledError != null && handledError.status > 0) {
        retErr += `${handledError.status}`;
        if (handledError.statusText) {
            retErr += ` - ${handledError.statusText}`;
        }
        retErr += addColon ? ':' : '';
    }
    return retErr;
}

function getErrorDetails(handledError: HandledError, i18n: i18n, t: TFunction) {
    let retErr = 'An error has occurred.'
    if (handledError != null) {
        if (typeof handledError.data === 'string') {
            if (i18n.exists(handledError.data)) {
                return t(handledError.data as keyof transType);
            }
            return handledError.data;
        } else if (typeof handledError.data === 'object' && typeof handledError.data?.detail === 'string') {
            let detail = handledError.data.detail as string;
            if (detail.includes('|')) {
                let split = detail.split('|');
                let params: { [key: number]: string } = {};
                for (let i = 0; i < split.length; i++) {
                    params[i] = i18n.exists(split[i + 1]) ? t(split[i + 1] as keyof transType) : split[i + 1];
                }
                return t(split[0] as keyof transType, params);
            } else if (i18n.exists(detail)) {
                return t(detail as keyof transType);
            }
            return detail;
        }
    }
    return retErr;
}

export function BsModalHandledError() {
    const { i18n, t } = useTranslation();
    const dispatch = useAppDispatch();
    const { handledError } = useAppSelector(state => state.handledError);

    const handleHide = () => {
        if (handledError !== null) {
            let { redirectToLogout } = handledError;
            dispatch(setError(null));
            if (redirectToLogout) {
                dispatch(signOut());
            }
        }
    }

    return handledError != null ? (
        <Modal
            scrollable={true}
            show={handledError != null}
            onHide={handleHide}
            id="modalError"
            centered={true}
            backdrop="static"
            {...(handledError?.status === 500 && handledError?.data?.title !== 'Error' ? { size: 'xl' } : {})}
        >
            <Modal.Header>
                <Modal.Title>{getErrorTitle(handledError, t)}</Modal.Title>
                <button type="button" className="m-0 p-0 border border-0 bg-transparent" aria-label={t('IDS_CLOSE')} onClick={handleHide}>
                    <span className="d-flex justify-content-center align-items-center p-2 rounded-circle justify-content-center align-items-center text-white bg-dark-ocean">
                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {handledError?.status === 500 && handledError?.data?.title !== 'Error'
                    ?
                    <>
                        {getErrorType(handledError, false)}
                        <pre className="overflow-visible">{getErrorDetails(handledError, i18n, t)}</pre>
                    </>
                    :
                    (handledError?.status === 400 && handledError?.data.errors
                        ?
                        <>
                            {getErrorType(handledError, true)}
                            <ul>
                                {Object.keys(handledError?.data.errors).map((key: string, idx: number) => (
                                    <li key={idx}>
                                        {t(handledError.data.errors[key]).includes('{{0}}')
                                            ? t(handledError.data.errors[key], { 0: t<any>(key) })
                                            : t(handledError.data.errors[key])}
                                    </li>
                                ))}
                            </ul>
                        </>
                        :
                        `${getErrorType(handledError, true)} ${getErrorDetails(handledError, i18n, t)}`)
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="silver" onClick={handleHide}>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
    : null;
}

export function BsModalAlert({title, message, showModal, setShowModal, hideCallback, bodyClass = ''}: BaseProps) {
    const { t } = useTranslation();

    const handleHide = hideCallback || (() => setShowModal(false));

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={handleHide}
            centered={true}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <CustomCloseBtn hideFcn={handleHide} />
            </Modal.Header>
            <Modal.Body className={`white-space-pre-line ${bodyClass}`}>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="silver" onClick={handleHide} autoFocus>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export function BsModalConfirm({title, message, confirmCallback, showModal, setShowModal}: ConfirmProps) {
    const { t } = useTranslation();

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={() => setShowModal(false)}
            centered={true}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <CustomCloseBtn hideFcn={() => setShowModal(false)} />
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="silver" onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />{t('IDS_CLOSE')}
                </Button>
                <Button variant="dark-ocean" type="submit" className="mw-8rem" onClick={confirmCallback}>
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />{t('IDS_CONFIRM')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}