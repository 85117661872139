import { FieldValues } from 'react-hook-form';

import { getLangOrDefault, localeCode, locales } from '../../../i18n';
import { dateTimeIsMinVal, localDate } from '../../utility/util';

export interface MaintenanceDto {
    messages: MaintenanceMessage[];
    startTime: string;
    endTime: string;
    startDisplayTime: string;
    endDisplayTime: string;
}

export interface MaintenanceMessage {
    languageCode: string;
    message: string;
}

interface MaintenanceState {
    inMaintRange: boolean;
    inDisplayRange: boolean;
    parsedMsg: string;
}

export function formDataToMaintenanceDto(formData: FieldValues) {
    const languageCodes = Object.keys(locales);
    let maintMessages: MaintenanceMessage[] = [];
    for (let lang of languageCodes) {
        maintMessages.push({
            languageCode: lang,
            message: formData[`${lang === "en-US" ? "enUS" : lang}`]
        });
    }

    let maintenanceDto: MaintenanceDto = {
        messages: maintMessages,
        startTime: formData.startTime,
        endTime: formData.endTime,
        startDisplayTime: formData.startDisplayTime,
        endDisplayTime: formData.endDisplayTime
    }

    return maintenanceDto;
}

export function getMaintenanceState(maintInfo: MaintenanceDto | null): MaintenanceState {
    let maintState: MaintenanceState = {
        inMaintRange: false,
        inDisplayRange: false,
        parsedMsg: ''
    };

    if (maintInfo !== null && Object.keys(maintInfo).length) {
        let userLang = getLangOrDefault();
        let now = new Date().getTime();

        if (!dateTimeIsMinVal(maintInfo.startDisplayTime) && !dateTimeIsMinVal(maintInfo.endDisplayTime)) {
            let dtStartDisplay = new Date(maintInfo.startDisplayTime).getTime();
            let dtEndDisplay = new Date(maintInfo.endDisplayTime).getTime();

            if (now >= dtStartDisplay && now <= dtEndDisplay) {
                maintState.inDisplayRange = true;
            }
        }
        
        if (!dateTimeIsMinVal(maintInfo.startTime) && !dateTimeIsMinVal(maintInfo.endTime)) {
            let dtStart = new Date(maintInfo.startTime).getTime();
            let dtEnd = new Date(maintInfo.endTime).getTime();

            if (now >= dtStart && now <= dtEnd) {
                maintState.inMaintRange = true;
            }
        }
        
        let maintMessage = maintInfo.messages.find(x => x.languageCode === userLang || x.languageCode === localeCode.enUS);
        if (maintMessage) {
            maintState.parsedMsg = maintMessage.message
                .replaceAll('{0}', localDate(maintInfo.startTime))
                .replaceAll('{1}', localDate(maintInfo.endTime));
        }
    }

    return maintState;
}