import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    hideFcn: () => void;
}

export default function CustomCloseBtn({hideFcn}: Props) {
    const { t } = useTranslation();
    
    return (
        <button type="button" className="m-0 p-0 border border-0 bg-transparent" aria-label={t('IDS_CLOSE')} title={t('IDS_CLOSE')} onClick={hideFcn}>
            <span className="d-flex justify-content-center align-items-center p-2 rounded-circle justify-content-center align-items-center text-white bg-dark-ocean">
                <FontAwesomeIcon icon={faTimes} fixedWidth />
            </span>
        </button>
    );
}