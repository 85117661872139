import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';

import BsGrid from '../../../app/components/BsGrid';
import TextColumnFilter from '../../../app/components/BsGrid/textColumnFilter';
import CustomCloseBtn from '../../../app/components/CustomCloseBtn';
import { BsGridFetchDataParams } from '../../../app/models/bsGridFetchDataParams';
import { CCasAccountBo } from '../../../app/models/clientAppSecurityWebService/CCasAccountBo';
import { useAppSelector } from '../../../app/store/storeHooks';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

export interface ClientAccountColumns {
    id: number;
    clientNumber: string;
    name: string;
    addedDate: string;
    city: string;
    postalCode: string;
};

export default function ModalClientLookup({ showModal, setShowModal }: Props) {
    const { t } = useTranslation();
    const { user } = useAppSelector(state => state.account);
    const { setValue, getValues } = useFormContext();
    const [data, setData] = useState<CCasAccountBo[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchIdRef = useRef(0);
    const hasMultipleCompanies = user?.casObject?.casCompanyBC && user.casObject.casCompanyBC.length > 1;

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }: BsGridFetchDataParams<CCasAccountBo>) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true);

        if (fetchId === fetchIdRef.current) {
            const startRow = pageSize * pageIndex;
            const endRow = startRow + pageSize;

            let accountData = [...(user!.casObject!.casAccountBC!)];
            if (hasMultipleCompanies) {
                accountData = accountData.filter(data => data.companyID === Number(getValues('selectCompany')));
            }

            if (filters.length) {
                filters.forEach((item) => {
                    accountData = accountData.filter(data => data[item.id as keyof CCasAccountBo]!.toString().toLowerCase().includes(item.value.vals[0].toLowerCase()));
                });
            }

            if (!sortBy.length) {
                accountData.sort((a, b) => (a.accountNumber! > b.accountNumber!) ? 1 : -1)
            } else if (!(sortBy[0].desc ?? false)) {
                accountData.sort((a, b) => (a[sortBy[0].id as keyof CCasAccountBo]! > b[sortBy[0].id as keyof CCasAccountBo]!) ? 1 : -1)
            } else {
                accountData.sort((a, b) => (a[sortBy[0].id as keyof CCasAccountBo]! < b[sortBy[0].id as keyof CCasAccountBo]!) ? 1 : -1)
            }
            setData(accountData.slice(startRow, endRow));

            // Server would send back total page count / total records -- let's fake it
            setPageCount(Math.ceil(accountData.length / pageSize));
            setTotalRecords(accountData.length);
            setLoading(false);
        }
    }, [getValues, hasMultipleCompanies, user]);

    const columns = useMemo<Column<CCasAccountBo>[]>(
        () => [
            {
                accessor: 'accountID',
                disableFilters: true
            },
            {
                Header: `${t('IDS_ACCOUNT_NUM')}`,
                accessor: 'accountNumber',
                Filter: (filterProps) => TextColumnFilter(filterProps),
                Cell: ({ row }: CellProps<CCasAccountBo>) => {
                    return (
                        <Button
                            variant="link"
                            className="link-dark-ocean py-0 fs-inherit"
                            data-client-number={row.original.accountNumber}
                            onClick={(e) => {
                                setValue('clientNumber', e.currentTarget.dataset['clientNumber'], { shouldValidate: true });
                                setShowModal(false);
                            }}
                        >
                            {row.original.accountNumber}
                        </Button>);
                }
            },
            {
                Header: `${t('IDS_NAME')}`,
                accessor: 'name',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_CITY')}`,
                accessor: 'city',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            },
            {
                Header: `${t('IDS_POSTAL_CODE')}`,
                accessor: 'postalCode',
                Filter: (filterProps) => TextColumnFilter(filterProps)
            }
        ],
        [t, setValue, setShowModal]
    );

    return (
        <Modal
            scrollable={true}
            show={showModal}
            onHide={() => setShowModal(false)}
            id="modalAccountLookup"
            centered={true}
            backdrop="static"
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>{t('IDS_CLIENT_ACCOUNT_LOOKUP')}</Modal.Title>
                <CustomCloseBtn hideFcn={() => setShowModal(false)} />
            </Modal.Header>
            <Modal.Body>
                <BsGrid
                    columns={columns}
                    data={data}
                    defaultSortBy={{ id: 'accountNumber', desc: false }}
                    fetchData={fetchData}
                    hiddenColumnIds={['accountID']}
                    i18nKeys={{ itemName: 'IDS_CLIENT' }}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                />
            </Modal.Body>
        </Modal>
    );
}