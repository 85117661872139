import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UserType } from '../../app/enums/userType';
import { ApplicantSessionDto } from '../../app/models/applicantSession';
import { useAppSelector } from '../../app/store/storeHooks';
import { RouteAuthCtx } from '../../app/utility/routeAuthCtx';
import { pathFromUserType } from '../../app/utility/util';

interface Props {
    children: JSX.Element;
}

export default function RequireAuth({ children }: Props) {
    const authCtx = useContext(RouteAuthCtx);
    const { user } = useAppSelector(state => state.account);
    const { applicant, isInApplication, isInAchiever, isInAptitudeTest, isInDPATInhouse }
        = useAppSelector(state => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const { clientSession } = useAppSelector(state => state.clientSession);
    const location = useLocation();
    const curPath = location.pathname.toLowerCase();

    if (!user)
        return (<Navigate to={`/Auth${authCtx.userTypePath}/`} replace state={{ path: location.pathname }} />);
    else if (!user.twoFactorAuthVerified && !curPath.includes('verify'))
        return (<Navigate to={`/Auth${pathFromUserType(user.userType)}/Verify`} replace state={{ path: location.pathname }} />);
    else if (user.userType === UserType.Client && authCtx.userType === UserType.Applicant)
        return (<Navigate to="/Client" replace state={{ path: location.pathname }} />);
    else if (user.userType === UserType.Applicant && authCtx.userType === UserType.Client)
        return (<Navigate to="/Applicant" replace state={{ path: location.pathname }} />);
    else if (user.forcePasswordReset && !curPath.includes('verify') && !curPath.includes('changepassword') && !curPath.includes('selectlanguage'))
        return (<Navigate to={`/Auth${pathFromUserType(user.userType)}/ChangePassword`} replace state={{ path: location.pathname }} />);
    else if (authCtx.userType === UserType.Client && !clientSession && !curPath.includes('verify') && !curPath.includes('changepassword') && !curPath.includes('selectclient') && user.casObject?.casAccountBC != null && user.casObject.casAccountBC.length > 1)
        return (<Navigate to="/AuthClient/SelectClient" replace state={{ path: location.pathname }} />);
    else if ((curPath.includes('client/setup') && !clientSession?.updateClientSetup) || (curPath.includes('client/positions') && !clientSession?.maintainPositions))
        return (<Navigate to="/Client" replace state={{ path: location.pathname }} />);
    else if (authCtx.userType === UserType.Applicant && curPath.includes('application') && (applicant.testOnly || !isInApplication))
        return (<Navigate to="/Applicant" replace state={{ path: location.pathname }} />);
    else if (authCtx.userType === UserType.Applicant && curPath.includes('achiever') && (!applicant.achiever || !isInAchiever))
        return (<Navigate to="/Applicant" replace state={{ path: location.pathname }} />);
    else if (authCtx.userType === UserType.Applicant && curPath.includes('dpat') && !curPath.includes('dpatinhouse') && (!applicant.dpat || !isInAptitudeTest || applicant.locked))
        return (<Navigate to="/Applicant" replace state={{ path: location.pathname }} />);
    else if (authCtx.userType === UserType.Applicant && curPath.includes('dpatinhouse') && (!applicant.dpatInhouse || !isInDPATInhouse || applicant.locked))
        return (<Navigate to="/Applicant" replace state={{ path: location.pathname }} />);
    else
        return children;
}