import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt, faListUl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import agent from '../../../app/api/agent';
import { ApplicantTestProgress } from '../../../app/enums/applicantTestProgress';
import { LoadingComponentControlled } from '../../../app/layout/LoadingComponent';
import { ApplicantSessionDto } from '../../../app/models/applicantSession';
import { ApplicantDto } from '../../../app/models/responseHelpers/applicantDto';
import { getRestarts } from '../../../app/models/responseHelpers/applicantUnlockDto';
import { ClientNoteDto } from '../../../app/models/responseHelpers/clientNoteDto';
import { checkLockForApplicant } from '../../../app/store/accountSlice';
import { useAppDispatch, useAppSelector } from '../../../app/store/storeHooks';
import { getLangOrDefault, getTitle, localeCode, locales } from '../../../i18n';
import { transType } from '../../../i18next';

interface ApplicantMessageNavLink {
    text: string,
    route: string,
    icon: IconDefinition
}

interface ApplicantMessagesState {
    titleMsg: string;
    instructionsMsg: string[];
    navLink: ApplicantMessageNavLink | null;
}

export default function HomePage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const userLang = getLangOrDefault();
    const {
        applicant: { applicantId, unlockLog, clientName, clientId, locked },
        progress: { progressId, dpatStopCount, dpatInhouseStopCount, progress: progressKey },
        client: { openingNotes: clientOpeningNotes, closingNotes: clientClosingNotes, lockoutText: clientLockoutText, languageCodes, clientNotesForLanguage },
        isInApplication,
        isInAchiever,
        isInAptitudeTest,
        isInDPATInhouse,
    } = useAppSelector((state) => state.account.user?.applicantSessionDto || ({} as ApplicantSessionDto));
    const selectDefault = !languageCodes.some(x => locales[x as localeCode] && x === userLang);
    const { openingNotes: clientOpeningNotesForLang, closingNotes: clientClosingNotesForLang, lockoutNotes: clientLockoutNotesForLang } =
        ((!selectDefault && clientNotesForLanguage !== null)
            ? clientNotesForLanguage
            : {
                clientId: 0,
                language: '',
                openingNotes: '',
                closingNotes: '',
                lockedNotes: '',
                lockoutNotes: ''
            }) as ClientNoteDto;
    const restarts = getRestarts(unlockLog);
    
    const [loading, setLoading] = useState(false);
    const [applicantMessages, setApplicantMessages] = useState<ApplicantMessagesState>({ titleMsg: '', instructionsMsg: [], navLink: null });
    
    useEffect(() => {
        let isMounted = true;
        const fetchSetupData = async () => {
            try {
                if (isMounted && clientId > 0) {
                    setLoading(true);
                    let titleMsg = '';
                    let instructionsMsg = [];
                    let navLink: ApplicantMessageNavLink | null = null;
                    
                    if (progressId !== ApplicantTestProgress.Complete) {
                        titleMsg = !selectDefault && clientOpeningNotesForLang ? clientOpeningNotesForLang : clientOpeningNotes;

                        if (isInApplication) {
                            instructionsMsg.push(t('IDS_INFO_INSTRUCTIONS_APP'));
                            navLink = {
                                route: '/Applicant/Application',
                                text: 'IDS_APPLICATION',
                                icon: faFileAlt
                            };
                        }

                        let progressLabel = progressKey ? t(progressKey as keyof transType) : '';
                        
                        if (isInAchiever) {
                            instructionsMsg.push(t('IDS_INFO_INSTRUCTIONS_ASSESS', { 0: progressLabel }));
                            navLink = {
                                route: '/Applicant/Achiever',
                                text: 'IDS_ASSESSMENT',
                                icon: faListUl
                            };
                        }
                        if (isInAptitudeTest || isInDPATInhouse) {
                            instructionsMsg.push(t('IDS_INFO_INSTRUCTIONS_DPAT', { 0: progressLabel }));
                            navLink = {
                                route: isInDPATInhouse ? '/Applicant/DPATInhouse' : '/Applicant/DPAT',
                                text: isInDPATInhouse ? 'IDS_APTITUDE_TEST_INH' : 'IDS_APTITUDE_TEST',
                                icon: faListUl
                            };
                        }

                        let { applicantSessionDto: { applicant: { locked: lockedUpdated } = {} as ApplicantDto } = {} } = await dispatch(checkLockForApplicant(false)).unwrap();
                        if (lockedUpdated) {
                            titleMsg = '';
                            instructionsMsg = [];
                            instructionsMsg.push(!selectDefault && clientLockoutNotesForLang ? clientLockoutNotesForLang : clientLockoutText);

                            if (instructionsMsg[0].length === 0) {
                                instructionsMsg[0] = t('IDS_DEFAULT_LOCKOUT_MSG');
                            }

                            if (dpatStopCount > restarts || dpatInhouseStopCount > restarts) {
                                instructionsMsg[0] += ' (Msg 1001)';
                            } else {
                                instructionsMsg[0] += ' (Msg 1002)';
                            }

                            await agent.Applicant.insertApplicantEvent({ applicantId, message: instructionsMsg[0], eventType: 'Error-LockedOut' });
                        }
                    }
                    else {
                        titleMsg = !selectDefault && clientClosingNotesForLang ? clientClosingNotesForLang : clientClosingNotes;
                    }

                    setApplicantMessages({ titleMsg, instructionsMsg, navLink });
                }
            } catch (error) {
                console.log(error);
            } finally {
                if (isMounted) setLoading(false);
            }
        }

        fetchSetupData();

        return () => { isMounted = false; };
    }, [
        clientId,
        applicantId,
        userLang,
        t,
        dispatch,
        restarts,
        isInApplication,
        isInAchiever,
        isInAptitudeTest,
        isInDPATInhouse,
        progressId,
        dpatStopCount,
        dpatInhouseStopCount,
        progressKey,
        selectDefault,
        clientOpeningNotes,
        clientClosingNotes,
        clientLockoutText,
        clientOpeningNotesForLang,
        clientClosingNotesForLang,
        clientLockoutNotesForLang
    ]);
    
    return (
        <>
            <Helmet>
                <title>{getTitle(t, 'IDS_HOME')}</title>
            </Helmet>
            <div className="main-titlebar bg-lightest-ocean py-3">
                <span className="fs-5 line-height-100">{`${t('IDS_HOME')} - ${clientName}`}</span>
            </div>
            <div className="main-content bg-light-silver">
                <LoadingComponentControlled
                    delay={10}
                    positionClass="position-fixed"
                    show={loading} />
                {applicantMessages.titleMsg &&
                    <p className="small">{applicantMessages.titleMsg}</p>
                }
                {applicantMessages.instructionsMsg.map((val, idx) => (
                    <p
                        key={idx}
                        className={`small ${locked ? 'text-cherry' : ''}`}
                    >
                        {val}
                    </p>
                ))}
                {applicantMessages.navLink !== null &&
                    <NavLink to={applicantMessages.navLink.route} end>
                        <FontAwesomeIcon icon={applicantMessages.navLink.icon} className="me-2" fixedWidth />{t(applicantMessages.navLink.text as keyof transType)}
                    </NavLink>
                }
            </div>
        </>
    );
}
