import printJS from 'print-js';
import { useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import {
    faDownload, faPrint, faSearchMinus, faSearchPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingComponent from '../layout/LoadingComponent';
import { setPdfDocument } from '../store/pdfDocumentSlice';
import { useAppDispatch, useAppSelector } from '../store/storeHooks';
import { downloadPdf } from '../utility/util';
import CustomCloseBtn from './CustomCloseBtn';

export function DocumentModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { pdfObject, status: pdfStatus } = useAppSelector(state => state.pdfDocument);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [zoom, setZoom] = useState(1.6);

    const handleClose = () => {
        dispatch(setPdfDocument(null));
        setNumPages(null);
    };

    if (pdfStatus.includes('pending'))
        return <LoadingComponent />
    else
        return (
            <Modal
                show={pdfObject != null}
                onHide={handleClose}
                id="modalDocument"
                fullscreen
                backdrop="static"
            >
                <Modal.Header className="bg-dark-eggplant">
                    <ButtonGroup className="mx-auto">
                        <Button
                            variant="silver"
                            aria-label={t('IDS_DOWNLOAD')}
                            title={t('IDS_DOWNLOAD')}
                            onClick={() => {
                                if (pdfObject && pdfObject.pdfBytes) {
                                    downloadPdf(pdfObject?.pdfBytes, pdfObject?.pdfFileName);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faDownload} fixedWidth size="lg" />
                        </Button>
                        <Button
                            variant="silver"
                            aria-label={t('IDS_PRINT')}
                            title={t('IDS_PRINT')}
                            onClick={() => {
                                if (pdfObject && pdfObject.pdfBytes) {
                                    printJS({ printable: pdfObject.pdfBytes, type: 'pdf', base64: true });
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faPrint} fixedWidth size="lg" />
                        </Button>
                        <Button
                            variant="silver"
                            aria-label={t('IDS_ZOOM_OUT')}
                            title={t('IDS_ZOOM_OUT')}
                            onClick={() => {
                                setZoom(prevZoom => prevZoom > 0.4 ? prevZoom - 0.2 : prevZoom);
                            }}
                        >
                            <FontAwesomeIcon icon={faSearchMinus} fixedWidth size="lg" />
                        </Button>
                        <Button
                            variant="silver"
                            aria-label={t('IDS_ZOOM_IN')}
                            title={t('IDS_ZOOM_IN')}
                            onClick={() => {
                                setZoom(prevZoom => prevZoom < 2.8 ? prevZoom + 0.2 : prevZoom);
                            }}
                        >
                            <FontAwesomeIcon icon={faSearchPlus} fixedWidth size="lg" />
                        </Button>
                    </ButtonGroup>
                    <CustomCloseBtn hideFcn={handleClose} />
                </Modal.Header>
                <Modal.Body className="d-grid" style={{backgroundColor: '#999999'}}>
                    {pdfObject &&
                        <Document
                            file={`data:application/pdf;base64,${pdfObject?.pdfBytes}`}
                            onLoadSuccess={({ numPages: nextNumPages }) => setNumPages(nextNumPages)}
                            options={{
                                standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
                                cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                cMapPacked: true
                            }}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    scale={zoom}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    className={`${(index + 1) === numPages ? '' : 'pb-2'}`}
                                />
                            ))}
                        </Document>
                    }
                </Modal.Body>
            </Modal>
        );
}