import { FieldValues, UseFormGetValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';

export interface ApplicationTabsFormState<T extends FieldValues> {
    isValid: boolean;
    isDirty: boolean;
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    handleSubmit: UseFormHandleSubmit<T> | null;
    getValues?: UseFormGetValues<T> | null;
    reset: UseFormReset<T> | null;
}

export function getApplicationTabsDefaultState<T extends FieldValues>() {
    let defaultState = {
        isValid: true,
        isDirty: false,
        isSubmitting: false,
        isSubmitSuccessful: false,
        handleSubmit: null,
        getValues: null,
        reset: null
    } as ApplicationTabsFormState<T>;
    return defaultState;
}