import { createSlice } from '@reduxjs/toolkit';

import { FilterMode } from '../enums/filterMode';
import { setFilterModeClasses } from '../utility/util';

export interface AccessibilitySettings {
    filterMode: FilterMode;
    textSize: string;
}

export interface AccessibilitySettingsState {
    settings: AccessibilitySettings;
    showModal: boolean;
}

const initialState: AccessibilitySettingsState = {
    settings: {
        filterMode: FilterMode.None,
        textSize: '100'
    },
    showModal: false
};

export const accessibilitySettingsSlice = createSlice({
    name: 'accessibilitySettings',
    initialState,
    reducers: {
        setAccessibilityFilterMode: (state, action: { payload: FilterMode }) => {
            state.settings.filterMode = action.payload;
            sessionStorage.setItem('filterMode', action.payload);
            setFilterModeClasses(action.payload);
        },
        setAccessibilityTextSize: (state, action: { payload: string }) => {
            state.settings.textSize = action.payload;
            sessionStorage.setItem('textSize', action.payload);
            document.documentElement.style.fontSize = Number(action.payload) === 100 ? '' : `${action.payload}%`;
        },
        setShowAccessibilityModal: (state, action: { payload: boolean }) => {
            state.showModal = action.payload;
        }
    }
});

export const { setShowAccessibilityModal, setAccessibilityFilterMode, setAccessibilityTextSize } = accessibilitySettingsSlice.actions;
export default accessibilitySettingsSlice.reducer;